
































































































































































































































































































































































import HeaderLayout from '../../layouts/nested/HeaderLayout.vue';
import SidebarLayout from '../../layouts/nested/SidebarLayout.vue';
import {Component} from 'vue-property-decorator';
import Base from '@/views/Base';
import ItemSection from './ItemSection.vue';
import WSidebarLink from '@/components/WSidebarLink.vue';
import WFormSelect from '@/components/WFormSelect.vue';
import BookingService from '@/service/BookingService';
import {Booking, Email, NationalNumber, RecordingMode, ScheduleType} from '@/models/api';
import {Playback} from '@/models/Playback';
import PlaybackService from '@/service/PlaybackService';
import SelectRegDateModal from '@/components/modals/SelectRegDateModal.vue';
import {downloadConfirmation, StringMap} from '@/utils/pdf';
import SelectSurveyModal from '@/components/modals/SelectSurveyModal.vue';
import {Validation} from 'vuelidate';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import {validateEmail} from '@/utils/validators';
import {formatNames} from '@/utils/filters';
import {SelectOption} from '@/models/common';
import BrandingService from '@/service/BrandingService';
import RegistrationService from '@/service/RegistrationService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';

@Component({
  components: {
    WHeaderBtn,
    SelectSurveyModal,
    SelectRegDateModal,
    HeaderLayout,
    SidebarLayout,
    ItemSection,
    WSidebarLink,
    WFormSelect
  }
})
export default class ConferenceRunningItem extends Base {
  booking: Booking | null = null;

  termin: { [key: string]: string } = {};
  options: { [key: string]: string } = {};
  dialIn: { [key: string]: string } = {};
  nationalNumbers: NationalNumber[] = [];

  files: File[] = [];
  mail: Email = {
    replyTo: '',
    subject: '',
    text: '',
    attachments: [],
    brandingId: null
  };
  beginDate = '';
  mailTested = false;
  sendingMail = false;
  sendingTestMail = false;

  playbacks: Playback[] = [];
  formatNames = formatNames;

  @Validations()
  validations = {
    mail: {
      replyTo: {required, email: validateEmail},
      subject: {required},
      text: {required}
    }
  };
  brandingOptions: SelectOption[] = [];

  get dialNumber(): string {
    return this.t('conference.dialNumbers');
  }

  mounted(): void {
    const id = this.$route.params.id;
    if (id) this.getBooking(id);

    BookingService.getNationalNumbers().then(
        (numbers) => (this.nationalNumbers = numbers)
    );
    PlaybackService.getPlaybacks().then((p) => (this.playbacks = p));

    this.getBrandings();
  }

  getBrandings(): void {
    BrandingService.getBrandings().then((res) => {
      this.brandingOptions = [];
      res.map((branding) => {
        this.brandingOptions.push({
          text: branding.title,
          value: branding.id
        });
      });
      this.brandingOptions.unshift({
        text: this.t('forms.noBranding'),
        value: null
      });
    });
  }

  getBooking(id: string): void {
    BookingService.getBooking(id)
        .then((booking) => {
          console.log(booking.audioId);
          this.booking = booking;
          this.fillData();

          return BookingService.getNationalNumbers();
        })
        .then((numbers) => {
          const number = numbers.filter((n) => n.active)[0];
          if (number) {
            this.dialIn = {
              ...this.dialIn,
              dialNumbers: `${number.displayNumber || number.number} ${
                  number.description ? '(' + number.description + ')' : ''
              }`
            };
          }
        })
        .catch(this.showNetworkError);
  }

  fillData(): void {
    const playback = this.playbacks.find((p) => p.id === this.booking?.audioId);
    const t = this.t;
    const typeStr = playback
        ? t('conference.playback') + ' (' + playback.name + ')'
        : t('conference.live');

    this.termin = {
      languageInEmails:
          this.booking?.language?.toLowerCase() === 'de'
              ? t('common.german')
              : t('common.english'),
      eventType: typeStr,
      costCenter: this.booking?.costCenter || '-'
    };

    if (!this.isRoom && this.booking) {
      if (this.booking.regDate) {
        this.termin['regForm'] = `${this.booking.regDate.title} (${new Date(this.booking.regDate.start).toLocaleString(undefined, {
          dateStyle: 'short',
          timeStyle: 'short'
        })})`;
      } else {
        this.termin['regForm'] = '-';
      }
      if (this.booking.survey) {
        this.termin['surveyForm'] = `${this.booking.survey.label || this.booking.survey.title}`;
      } else {
        this.termin['surveyForm'] = '-';
      }
    }

    const value = (key: string) => t('conference.sectionValues.' + key);
    this.options = {
      mode: this.booking?.joinMuted ? value('schooling') : value('talk'),
      waitingRoom: this.booking?.waitingRoom
          ? value('activated')
          : value('deactivated'),
      conferenceEnd: this.booking?.autoClose
          ? value('whenModEnds')
          : value('manual'),
      recording:
          this.booking?.recordingMode === RecordingMode.AUTOMATIC
              ? value('automatic')
              : value('manual'),
      tone: this.booking?.beep ? value('activated') : value('deactivated'),
      confirmation: this.booking?.dialOutCalleeConfirm
          ? value('activated')
          : value('deactivated')
    };
    this.dialIn = {
      modPin: this.booking?.dialIn
          ? this.booking?.moderatorAccessCode || '-'
          : '-',
      partPin: this.booking?.dialIn
          ? this.booking?.participantAccessCode || '-'
          : '-'
    };
    if (this.booking?.start) {
      this.beginDate = new Date(this.booking?.start).toLocaleString(undefined, {
        dateStyle: 'medium',
        timeStyle: 'short'
      });
    }
  }

  scheduleType(type?: ScheduleType): string {
    switch (type) {
      case ScheduleType.ANYTIME:
        return this.t('conference.anytime');
      case ScheduleType.IMMEDIATE:
        return this.t('conference.immediate');
      case ScheduleType.ONETIME:
        return this.t('conference.onetime');
      case ScheduleType.WEEKLY:
      case ScheduleType.MONTHLY:
      case ScheduleType.DAILY:
        return this.t('conference.repeated');
      default:
        return '';
    }
  }

  copy(): void {
    if (this.booking?.schedule?.type === ScheduleType.ANYTIME) {
      this.$router.push(`/conference/booking-room?copyId=${this.booking?.id}`);
    } else {
      this.$router.push(`/conference/booking?copyId=${this.booking?.id}&step=0`);
    }
  }

  openNationalNumbers(): void {
    this.$root.$emit('bv::show::modal', 'nationalNumbers');
  }

  bookingToBookingListItem = BookingService.bookingToConferenceItem;

  get isRoom(): boolean {
    return this.$route.fullPath.includes('room');
  }

  downloadConfirmation(
      dialIn: StringMap,
      termin: StringMap,
      options: StringMap,
      nationalNumbers: NationalNumber[],
      beginDate: string,
      booking: Booking | null
  ): void {
    const dialInKeys = Object.keys(dialIn);
    const localisedDialIn: StringMap = {};
    dialInKeys.forEach((key) => {
      localisedDialIn[this.keyName(key)] = dialIn[key];
    });

    const terminKeys = Object.keys(termin);
    const localisedTermin: StringMap = {};
    terminKeys.forEach((key) => {
      localisedTermin[this.keyName(key)] = termin[key];
    });

    const optionsKeys = Object.keys(options);
    const localisedOptions: StringMap = {};
    optionsKeys.forEach((key) => {
      localisedOptions[this.keyName(key)] = options[key];
    });

    downloadConfirmation(
        localisedDialIn,
        localisedTermin,
        localisedOptions,
        nationalNumbers,
        beginDate,
        booking,
        this
    );
  }

  get isSeries(): boolean {
    if (!this.booking || !this.booking.schedule) return false;
    return (
        this.booking.schedule &&
        BookingService.isSeries(this.booking.schedule.type)
    );
  }

  openMailModal(): void {
    this.$bvModal.show('mailModal');
  }

  resetMail(): void {
    this.mail = {
      replyTo: '',
      subject: '',
      text: '',
      attachments: [],
      brandingId: null
    };
    this.files = [];
    this.$v.mail.$reset();
  }

  validateState(objectName: string, name: string): boolean | null {
    const validate = this.$v[objectName][name] as Validation;
    return validate.$dirty ? !validate.$error : null;
  }

  sendTestMail(): void {
    if (!this.$v.mail.$invalid) {
      this.sendingTestMail = true;
      this.uploadMailFiles().then(() => this.sendTestEmailToParticipants());
    }
  }

  private sendTestEmailToParticipants() {
    if (this.booking && this.booking.id) {
      BookingService.sendTestEmailToParticipants(this.booking.id, this.mail)
          .then(() => {
            this.sendingTestMail = false;
            this.toast(this.t('forms.testMailSuccess'), 'success');
            this.mailTested = true;
          })
          .catch(this.showNetworkError);
    }
  }

  sendMail(ok: () => void): void {
    this.sendingMail = true;
    if (!this.$v.mail.$invalid) {
      this.uploadMailFiles().then(() => {
        this.sendEmailToParticipants(ok);
      });
    }
  }

  private sendEmailToParticipants(ok: () => void) {
    if (this.booking && this.booking.id) {
      BookingService.sendEmailToParticipants(this.booking.id, this.mail)
          .then(() => {
            this.toast(this.t('forms.sendMailSuccess'), 'success');
            this.resetMail();
            this.sendingMail = false;
            ok();
          })
          .catch(this.showNetworkError);
    }
  }

  private uploadMailFiles(): Promise<void | void[]> {
    this.mail.attachments = [];
    return Promise.all(
        this.files.map((file) =>
            RegistrationService.addFile(file)
                .then((value) => {
                  if (this.mail.attachments) this.mail.attachments.push(value);
                })
                .catch((err) => console.log(file.name, err.message))
        )
    ).catch(() => {
      this.toast(this.t('forms.fileNotUploaded'));

      this.mail.attachments = [];
      this.files = [];
    });
  }

  keyName(key: string): string {
    const translation = this.$t('conference.sections.' + key) as string;
    if (!translation.includes('.')) {
      return translation;
    }
    return key;
  }
}
