















































































































































































































































































import Base from '@/views/Base';
import {Component, Prop} from 'vue-property-decorator';
import {Validation} from 'vuelidate';
import {trimString} from '@/utils/filters';
import {Survey, SurveyParticipantDynamicField, SurveyResponse} from '@/models/Survey';
import {FormCustomField} from "@/models/CustomField";

@Component({})
export default class ResponseModal extends Base {
  @Prop() currentResponse!: SurveyResponse;
  @Prop() survey!: Survey;
  @Prop() validation!: any;

  get customFields(): SurveyParticipantDynamicField[] {
    if (this.survey) return this.survey.participantDataToSave.customFields;
    return [];
  }

  customFieldValue(fieldId?: number): string {
    if (fieldId && this.currentResponse && this.currentResponse.customValidatedFieldValues) {
      return (
          this.currentResponse.customValidatedFieldValues.find(v => v.fieldId === fieldId)?.value || ' '
      );
    }
    return '';
  }

  get checkboxes(): SurveyParticipantDynamicField[] {
    if (this.survey) return this.survey.participantDataToSave.customCheckboxes;
    return [];
  }

  validateState(name: string): boolean | null {
    const validate = this.validation[name] as Validation;
    return validate.$dirty ? !validate.$error : null;
  }

  checkboxChanged(value: boolean, fieldId: string): void {
    if (this.currentResponse.customCheckboxValues) {
      let val = this.currentResponse.customCheckboxValues.find(v => v.id === fieldId);
      if (val) val.value = value.toString();
      else this.currentResponse.customCheckboxValues.push({id: fieldId, value: value.toString()});
    } else {
      this.currentResponse.customCheckboxValues = [{id: fieldId, value: value.toString()}];
    }
  }

  findCustomField(fieldId: number): FormCustomField | undefined {
    const field = this.survey.customFields.find(i => i.id === fieldId);
    if (!field) {
      console.log(`Field with id ${fieldId} does not exist, but there is a field with the id`);
    }
    return field;
  }

  checkboxValue(fieldId: string): string | boolean {
    if (!this.currentResponse.customCheckboxValues) return false;
    return this.currentResponse.customCheckboxValues.find(v => v.id === fieldId)?.value || false;
  }

  fieldChanged(value: string | null, fieldId: string): void {
    if (value === null) value = '';
    if (this.currentResponse.customFieldValues) {
      let val = this.currentResponse.customFieldValues.find(f => f.id === fieldId);
      if (val) val.value = value;
      else
        this.currentResponse.customFieldValues.push({id: fieldId, value: value});
    } else {
      this.currentResponse.customFieldValues = [{id: fieldId, value: value}];
    }
  }

  customFieldChanged(value: string | null, fieldId: number): void {
    if (value === null) value = '';
    if (this.currentResponse.customValidatedFieldValues) {
      let val = this.currentResponse.customValidatedFieldValues.find(f => f.fieldId === fieldId);
      if (val) val.value = value;
      else
        this.currentResponse.customValidatedFieldValues.push({fieldId: fieldId, value: value});
    } else {
      this.currentResponse.customValidatedFieldValues = [{fieldId: fieldId, value: value}];
    }
  }

  fieldValue(fieldId: string): string {
    if (!this.currentResponse.customFieldValues) return '';
    return this.currentResponse.customFieldValues?.find(f => f.id === fieldId)?.value || '';
  }

  trimCheckbox(str: string): string {
    return trimString(str, 50);
  }

  trimField(str: string): string {
    return trimString(str, 24);
  }
}
