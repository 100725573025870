




































































































































import {BookingListItem} from '@/models/Booking';
import {Component, Prop} from 'vue-property-decorator';
import Base from '../Base';
import HomeListItem from './ListItem.vue';
import HomeListItemSkeleton from './ListItemSkeleton.vue';

@Component({
	components: {
		HomeListItem,
		HomeListItemSkeleton
	}
})
export default class Lists extends Base {
	@Prop() scheduled!: BookingListItem[];
	@Prop() past!: BookingListItem[];
	@Prop() conferences!: BookingListItem[];
	@Prop() size!: { scheduled: number; past: number };
	@Prop() loading!: { past: boolean; scheduled: boolean; running: boolean };

  addConference(): void {
    this.$router.push('/conference/booking');
  }
}
