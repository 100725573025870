
























import { Component, Prop, Vue } from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';

@Component({})
export default class InlineEdit extends Vue {

  @Prop({ type: String, default: '' }) value!: string;

  editing = false;

  model = '';

  mounted() {
    this.model = this.value;
  }

  showInput(): void {
    setTimeout(() => {
      this.editing = true;
      document.body.addEventListener('mousedown', this.submit);
      setTimeout(() => {
        (this.$refs['input'] as BFormInput).focus();
      });
    }, 10);
  }

  submit(): void {
    this.$emit('input', this.model);
    this.close();
  }

  close(): void {
    this.editing = false;
    document.body.removeEventListener('mousedown', this.submit);
  }


}
