





















import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class CustomValidationExampleModal extends Vue {
  @Prop() title!: string
  @Prop() items!: any[]
  @Prop() modalId!: string


}
