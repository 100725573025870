import { CreateSpeakerCard, SpeakerCardModel } from '@/models/SpeakerCard';
import axios from 'axios';
import { id } from '@/utils/secureRandom';

export default {
  getSpeakerCards(search: string, order: string, direction: string): Promise<SpeakerCardModel[]> {
    const params = {
      search, order, direction
    }

    return axios.get('/speaker_card', { params }).then((response) => response.data);
  },
  getSpeakerCard(id: string): Promise<SpeakerCardModel> {
    return axios.get(`/speaker_card/${id}`).then((response) => response.data);
  },
  createSpeakerCard(speakerCard: CreateSpeakerCard): Promise<SpeakerCardModel> {
    return axios.post('/speaker_card', speakerCard).then((response) => response.data);
  },
  updateSpeakerCard(id: string, speakerCard: CreateSpeakerCard): Promise<SpeakerCardModel> {
    console.log(JSON.stringify(speakerCard));
    return axios.put(`/speaker_card/${id}`, speakerCard).then((response) => response.data);
  },
  deleteSpeakerCard(id: string): Promise<void> {
    return axios.delete(`/speaker_card/${id}`).then((response) => response.data);
  },
  duplicateSpeakerCard(id: string): Promise<SpeakerCardModel> {
    return axios.post(`/speaker_card/${id}/duplicate`).then((response)=>response.data)
  },
  getEmptySpeakerCard(): CreateSpeakerCard {
    return {
      url: id(),
      profileActive: true,
      color: '#FFFFFF',
      name: '',
      position: '',
      photoScale: 1.5,
      speakerProfile: {
       address: '', buttons: [], email: '', homepageUrl: '', infobox: '', phone: '', socialLinks: []
      },
    };
  },
  uploadSpeakerPhoto(id: string, file: File): Promise<void> {
    const form = new FormData();
    form.append('file', file, file.name);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    return axios
      .post(`/speaker_card/${id}/upload/photo`, form, { headers })
      .then((res) => res.data);

  },
  getSpeakerPhotoUrl(id: string, filename: string): string {
    return axios.defaults.baseURL + '/speaker/' + id + '/' + filename;
  },
  uploadCompanyLogo(id: string, file: File): Promise<void> {
    const form = new FormData();
    form.append('file', file, file.name);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    return axios
      .post(`/speaker_card/${id}/upload/logo`, form, { headers })
      .then((res) => res.data);
  },
  deletePhoto(id: string): Promise<void> {
    return axios.delete(`/speaker_card/${id}/photo`);
  },
  deleteLogo(id: string): Promise<void> {
    return axios.delete(`/speaker_card/${id}/logo`);
  },
  urlExists(url: string): Promise<boolean> {
    return axios.get(`/speaker_card/${url}/exists`).then(res=>res.data)
  }
};