





















































































































import {EmailTemplate} from '@/models/Registration';
import RegistrationService from '@/service/RegistrationService';
import {Component, Prop, PropSync, Watch} from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import {FormMail, SurveyMailType} from '@/models/Survey';
import PlaceholdersModal from '@/views/forms/components/PlaceholdersModal.vue';
import {Email} from '@/models/api';
import {Account} from '@/models/Account';

@Component({
  components: {WSwitch, PlaceholdersModal}
})
export default class EmailForm extends Base {
  @Prop() form!: Email | FormMail;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() validation!: any;
  @PropSync('language') lang!: string;
  @Prop({default: 'registration'}) formType!: 'registration' | 'survey';
  @PropSync('mailEnabled') sendMail!: boolean;
  @Prop({default: false, type: Boolean}) phoneRequired!: boolean;

  open = true;

  templates: EmailTemplate | null = null;
  templateItems: { [key: string]: string | boolean | number }[] = [
    {text: this.t('forms.components.email.selectMailTemplate'), value: '', disabled: true}
  ];
  // selectedTemplate = '';
  loaded = false;

  mailTypeOptions: { value: SurveyMailType; text: string }[] = [
    {
      value: SurveyMailType.AnswersAndPersonalData,
      text: this.t('forms.components.email.answersAndPersonalData')
    },
    {value: SurveyMailType.Answers, text: this.t('forms.components.email.answers')},
    {value: SurveyMailType.PersonalData, text: this.t('forms.components.email.personalData')},
    {value: SurveyMailType.None, text: this.t('forms.components.email.none')}
  ];

  mounted(): void {
    this.getMailTemplates();

    if (!this.form.replyTo) {
      this.form.replyTo = this.user?.contact?.email || '';
    }
  }

  async getMailTemplates(): Promise<void> {
    return RegistrationService.getEmailTemplates(this.formType)
        .then((res) => {
          this.templates = res.templates;
          if (this.lang) {
            this.templateItems = [
              this.templateItems[0],
              ...this.templates[this.lang.toLowerCase()].map((item) => ({
                text: item.title,
                value: +item.id,
                content: item.text,
                subject: item.subject
              }))
            ];
            this.loaded = true;
          }
        })
        .catch(this.showNetworkError);
  }

  @Watch('sendMail')
  setOpen(): void {
    this.open = this.sendMail;
    if (!this.form.replyTo) this.form.replyTo = this.user.contact.email;
  }

  @Watch('lang')
  languageChanged(newLang: string, oldLang: string): void {
    if (newLang !== oldLang) {
      this.getMailTemplates().then(() => this.templateChanged());
    }
  }

  templateChanged(): void {
    const template = this.templateItems.find(temp => temp.value === this.form.mailTextTemplateId);
    if (this.formType === 'survey') {
      // Auto select surveyMailType depending on selected mail template
      if (template) {
        if ((template.text as string).includes('anonym'))
          this.form.type = 'NONE';
        else if ((template.text as string).includes('Webinar'))
          this.form.type = 'ANSWERS_AND_PERSONAL_DATA';
      }
    }
    if (template && template.value !== '') {
      this.form.text = template.content as string;
      this.form.subject = template.subject as string;
      this.form.mailTextTemplateId = template.value as string;
    }
  }

  toggleOpen(): void {
    this.open = !this.open;
  }

  validateState(name: string): boolean | null {
    const validate = this.validation[name];
    return validate.$dirty ? !validate.$error : null;
  }

  get user(): Account {
    return this.$store.getters.account;
  }

  get dataConferenceURL(): string | undefined {
    return this.user.customerShortDTO ? this.user.customerShortDTO.dataConferenceURL : undefined;
  }
}
