

























































































































































































































































import Base from '@/views/Base';
import {Component, Watch} from 'vue-property-decorator';
import ListWithHeaderLayout from '@/layouts/nested/ListWithHeaderLayout.vue';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import WOperatorConfContext from '@/components/WOperatorConfContext.vue';
import {ConferenceListItem} from '@/models/Conference';
import AdminConsoleService from '@/service/AdminService';
import {ScheduledConferenceWrapper} from '@/models/AdminConsole';
import AuthenticationService from '@/service/AuthenticationService';
import {durationStr, formatNumberString} from '@/utils/filters';
import {SelectOption} from '@/models/common';

@Component({
  components: {
    WOperatorConfContext,
    ListWithHeaderLayout,
    HeaderLayout,
    WListOptions,
    WPagination
  },
  i18n: {
    messages: {
      en: {
        common: {
          duration: 'Dur.',
          customer: 'Custm.'
        }
      }
    }
  }
})
export default class ScheduledConferences extends Base {
  items: ConferenceListItem[] = [];

  fields = [
    {
      key: 'start' as keyof ConferenceListItem,
      label: this.$t('common.start'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {paddingLeft: '1.5rem', width: 'calc(160px + 1.5rem)'}
    },
    {
      key: 'duration' as keyof ConferenceListItem,
      label: this.$t('common.duration'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '75px'}
    },
    {
      key: 'customerNumber' as keyof ConferenceListItem,
      label: this.$t('common.customer'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '75px'}
    },
    {
      key: 'company' as keyof ConferenceListItem,
      label: this.$t('common.company'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '180px'}
    },
    {
      key: 'username' as keyof ConferenceListItem,
      label: this.$t('common.username'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '160px'}
    },
    {
      key: 'participantLimit' as keyof ConferenceListItem,
      label: this.$t('common.limit'),
      sortable: false,
      thStyle: {width: '50px'}
    },
    {
      key: 'pin' as keyof ConferenceListItem,
      label: 'MP/TP',
      sortable: false,
      thStyle: {width: '75px'}
    },
    {
      key: 'numberOfParticipants' as keyof ConferenceListItem,
      label: this.t('conference.participantShort') + '.',
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '105px'}
    },
    {
      key: 'rec' as keyof ConferenceListItem,
      label: this.t('conference.rec'),
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '55px'}
    },
    {
      key: 'functions' as keyof ConferenceListItem,
      label: this.$t('common.type'),
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '75px'}
    },
    {
      key: 'action' as keyof ConferenceListItem,
      label: this.$t('common.action'),
      sortable: false
    }
  ];

  options = {
    entryCount: 10,
    searchTerm: '',
    currentPage: 1,
    totalRows: 0,
    timeRange: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    }
  };

  sortDesc = false;
  sortBy = 'start';
  loading = false;

  wrapper: ScheduledConferenceWrapper = {
    totalConferences: 0,
    currentPhoneParticipants: 0,
    totalPhoneParticipants: 0,
    currentStreamParticipants: 0,
    registeredParticipants: 0,
    conferences: []
  };

  mounted(): void {
    this.getScheduledConfs();
  }

  private getScheduledConfs() {
    this.loading = true;
    AdminConsoleService.getScheduledConferences(this.options.timeRange)
        .then((conferencesWrapper) => {
          if (conferencesWrapper.conferences) {
            this.items = conferencesWrapper.conferences.map(
                AdminConsoleService.scheduledConferenceToConferenceItem
            );
            this.wrapper = conferencesWrapper;
          } else {
            this.items = [];
            this.wrapper = {
              totalConferences: 0,
              currentPhoneParticipants: 0,
              totalPhoneParticipants: 0,
              currentStreamParticipants: 0,
              registeredParticipants: 0,
              conferences: []
            };
          }
        })
        .catch((e) => this.showNetworkError(e))
        .finally(() => (this.loading = false));
  }

  get perPage(): number {
    return this.options.entryCount;
  }

  set perPage(count: number) {
    this.options.entryCount = count;
  }

  get totalPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  get totalRows(): number {
    return this.items.length;
  }

  get showPagination(): boolean {
    return this.perPage <= this.wrapper.totalConferences;
  }

  get totalParticipants(): string {
    return formatNumberString(this.wrapper.totalPhoneParticipants);
  }

  formatNumberString = formatNumberString;

  durationString = durationStr;

  formatDate(t: number): string {
    const d = new Date(t);
    return d.toLocaleString(undefined, {
      dateStyle: 'short',
      timeStyle: 'short'
    });
  }

  editCustomer(item: any): void {
    this.$router.push(`/admin/customers/${item.internalCustomerId}`);
  }

  editUser(item: any): void {
    this.$router.push(`/admin/users/${item.username}`);
  }

  loginAs(item: any, showConference: boolean): void {
    AuthenticationService.impersonate({
      parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
      childUsername: item.username
    })
        .then(() => {
          if (showConference) {
            this.$router.push(`/conference/scheduled/conf:${item.id}`);
          } else {
            this.$router.push('/');
          }
        })
        .catch(this.showNetworkError);
  }

  get timeRangeOptions(): SelectOption[] {
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), 28);
    const options: SelectOption[] = [];
    for (let i = 0; i < 6; i++) {
      options.unshift({
        value: {year: date.getFullYear(), month: date.getMonth() + 1},
        text: date.toLocaleDateString([], {
          month: 'long',
          year: 'numeric'
        })
      });
      date.setMonth(date.getMonth() + 1);
    }
    return options.reverse();
  }

  @Watch('options.timeRange')
  timeRangeChanged(): void {
    this.getScheduledConfs();
  }
}
