import {Account, AccountRole, AccountWrapper, ManualLink, Permission} from '@/models/Account';
import {NewsItem} from '@/models/News';
import {ServiceLink} from '@/models/ServiceLink';
import {Theme} from '@/models/Theme';
import {id} from '@/utils/secureRandom';
import axios from 'axios';
import Countries from 'i18n-iso-countries';

/**
 * API calls for booking
 * @module service/AccountService
 */
export default {
	/**
	 * Rest Functions accounts
	 */
	getAccounts(
		page: number,
		size: number,
		order: string,
		direction: 'ASC' | 'DESC',
		role?: AccountRole,
		search?: string,
		customerId?: number,
		ignoreCase?: boolean
	): Promise<AccountWrapper> {
		const params: { [key: string]: any } = {
			page,
			size,
			order,
			direction,
			ignoreCase
		};
		if (search) params['search'] = search;
		if (customerId && customerId > 0) params['customerId'] = customerId;
		if (role) params['role'] = role;
		return axios.get('/account', { params }).then((response) => response.data);
	},
	getAccount(username: string): Promise<Account> {
		return axios.get('/account/' + username).then((response) => response.data);
	},
	updateAccount(account: Account, username?: string): Promise<Account> {
		return axios
			.put(`/account/${!username ? account.username : username}`, account)
			.then((response) => response.data);
	},
	getInfo(username: string): Promise<string> {
		return axios
			.get(`/account/${username}/info`)
			.then((res) => res.data.info || '');
	},
	updateInfo(username: string, info: string): Promise<string> {
		return axios.put(`/account/${username}/info`, { id: 2, info }).then();
	},
	updatePermissions(
		username: string,
		permissions: Permission[]
	): Promise<void> {
		return axios.put(`/account/${username}/admin`, { id: 0, permissions });
	},
	addAccount(account: Account): Promise<Account> {
		return axios.post(`/account`, account).then((res) => res.data);
	},
	deleteAccount(username: string): Promise<void> {
		return axios.delete(`/account/${username}`).then();
	},
	activateAccount(username: string): Promise<Account> {
		return axios.put(`/account/${username}/activate`).then((res) => res.data);
	},
	deactivateAccount(username: string): Promise<Account> {
		return axios.put(`/account/${username}/deactivate`).then((res) => res.data);
	},
	getManualVideos(language: string, isProd: boolean): Promise<ManualLink[]> {
		language = language.toLowerCase();
		const url = `https://static.csn${isProd ? '' : 'dev'}.de/manual/manual-video${language.includes('de') ? '' : '-en'}.json`
		return axios
			.get(url, {
				transformRequest: (data: any, headers: any) => {
					delete headers.common.Authorization;
					delete headers.common['X-Client-Source'];
					return data;
				}
			})
			.then((res) => res.data);
	},
	getManualHandbooks(language: string, isProd: boolean): Promise<ManualLink[]> {
		language = language.toLowerCase();
		const url = `https://static.csn${isProd ? '' : 'dev'}.de/manual/manual-handbook${language.includes('de') ? '' : '-en'}.json`
		return axios
			.get(url, {
				transformRequest: (data: any, headers: any) => {
					delete headers.common.Authorization;
					delete headers.common['X-Client-Source'];
					return data;
				}
			})
			.then((res) => res.data);
	},
	getNews(isProd: boolean): Promise<NewsItem[]> {
		return axios
			.get(`https://static.csn${isProd ? '' : 'dev'}.de/news/news.json`, {
				transformRequest: (data: any, headers: any) => {
					delete headers.common.Authorization;
					delete headers.common['X-Client-Source'];
					return data;
				}
			})
			.then((res) => res.data);
	},
	getThemes(isProd: boolean): Promise<Theme | Theme[]> {
		return axios
			.get(`https://static.csn${isProd ? '' : 'dev'}.de/theme/theme.json?v=${id()}`, {
				transformRequest: (data: any, headers: any) => {
					delete headers.common.Authorization;
					delete headers.common['X-Client-Source'];
					return data;
				}
			})
			.then((res) => res.data);
	},
	getExtras(language: string, isProd: boolean): Promise<ServiceLink[]> {
		language = language.toLowerCase();
		const url = `https://static.csn${isProd ? '' : 'dev'}.de/service-center/service-center${language.includes('de') ? '' : '-en'}.json`
		return axios
			.get(url, {
				transformRequest: (data: any, headers: any) => {
					delete headers.common.Authorization;
					delete headers.common['X-Client-Source'];
					return data;
				}
			})
			.then((res) => res.data);
	},

	/**
	 * Functions
	 */
	getCountry(key: string | undefined, lang: string): string {
		return key ? Countries.getName(key, lang) : '';
	}
};
