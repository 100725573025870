
















































import { BookingItemFunctions } from '@/models/Booking';
import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class WFunctions extends Base {
	@Prop({
		default: () => {
			return { dialIn: true, dialOut: false, microphone: false };
		}
	})
	functions!: BookingItemFunctions;

	@Prop({ default: false, type: Boolean }) noMic!: boolean;
}
