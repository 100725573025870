

























































import {Component, Watch} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '../../Base';
import {AdminRegForms, AdminRegDate} from '@/models/AdminForms';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import AdminService from '@/service/AdminService';
import AuthenticationService from '@/service/AuthenticationService';
import RegistrationFormsDay from './RegistrationFormsDay.vue';

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: {HeaderLayout, WListOptions, RegistrationFormsDay},
  i18n: {
    messages: {
      en: {
        common: {
          customer: 'Custm.'
        }
      }
    }
  }
})
export default class Admins extends Base {
  regForm: AdminRegForms = {
    regDates: [],
    totalRegDates: 0,
    totalRegisteredUsers: 0
  };

  datesMap = new Map<string, AdminRegDate[]>();

  daysFromNow = 30;
  daysFromNowOptions = [
    ...[7, 30, 90, 180].map((n) => ({text: this.$t('admin.nDaysPreview', {n: n}), value: n})),
    {text: this.t('common.today'), value: 1},
    ...[-7, -30, -90, -180].map((n) => ({
      text: this.$t('admin.nDaysPreview', {n: n}),
      value: n
    }))
  ];
  searchTerm = '';

  @Watch('daysFromNow')
  daysFromNowChanged(): void {
    this.getRegDates();
  }
  @Watch('searchTerm')
  searchChanged(): void {
    this.getRegDates();
  }

  fields = [
    {
      key: 'number',
      label: this.t('common.numberShort'),
      thStyle: {
        width: 'calc(50px + 1.5rem)',
        paddingLeft: '1.5rem'
      }
    },
    {
      key: 'start',
      label: this.t('common.start'),
      thStyle: {width: '125px'}
    },
    {
			key: 'lastRegistration',
      label: this.t('forms.deadline'),
      thStyle: {width: '140px'}
    },
    {
      key: 'customerNumber',
      label: this.t('common.customer'),
      thStyle: {width: '75px'}
    },
    {
      key: 'accountCompany',
      label: this.t('common.company'),
      thStyle: {width: '120px'}
    },
    {
      key: 'username',
      label: this.t('common.username'),
      thStyle: {width: '145px'}
    },
    {
      key: 'countRegisteredUsers',
      label: this.t('conference.registeredUsersShort'),
      thStyle: {width: '64px'}
    },
    {
      key: 'perDay',
      label: this.t('admin.perDay'),
      thStyle: {width: '70px'}
    },
    {
      key: 'systemload',
      label: this.t('admin.systemload'),
      thStyle: {width: '110px'}
    },
    {
      key: 'admin',
      label: this.t('conference.operatorShort'),
      thStyle: {width: '55px'}
    },
    {
      key: 'action',
      label: this.t('common.action')
    }
  ];

  mounted(): void {
    this.getRegDates();
  }

  private getRegDates(): void {
    AdminService.getAdminRegDatesFromNow(this.daysFromNow, this.searchTerm.trim()).then((res) => {
      this.regForm = res;
      this.setData();
    });
  }

  setData(): void {
    //
    this.datesMap.clear();
    if (this.regForm.regDates && this.regForm.regDates.length > 0) {
      this.regForm.regDates.sort((a, b) => a.start - b.start);
      this.regForm.regDates.forEach((regDate) => {
        const date = new Date(regDate.start).toISOString().split('T')[0];
        let mapDates = this.datesMap.get(date) || [];
        mapDates.push(regDate);
        mapDates.sort((a, b) => a.start - b.start);
        this.datesMap.set(date, mapDates);
      });
    }
  }

  loginAs(item: any): void {
    AuthenticationService.impersonate({
      parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
      childUsername: item.username
    })
        .then(() => {
          this.$router.push('/');
        })
        .catch(this.showNetworkError);
  }

  openForm(url: string): void {
    window.open(process.env.VUE_APP_REGISTRATION_URL + url, '_blank');
  }
}
