















































import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import {DownloadListItem} from "@/models/Presentation";

@Component
export default class ElementCard extends Base {
  @Prop({type: Number, required: true}) columns!: number;
  @Prop({required: true}) item!: DownloadListItem;
}
