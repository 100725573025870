



































































import {Component, Prop, PropSync} from 'vue-property-decorator';
import WSwitch from '@/components/WSwitch.vue';
import {BookingOptionsForm} from '@/models/BookingForm';
import Base from '@/views/Base';

@Component({
	components: { WSwitch }
})
export default class BookingOptions extends Base {
	@Prop() form!: BookingOptionsForm;
	@Prop() bookingType!: 'LIVE' | 'PLAYBACK';
	@PropSync('valid') syncedValid!: boolean;
	@PropSync('tag') syncedTag!: string;
}
