




























import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import {SelectOption} from "@/models/common";

@Component
export default class BatchEdit extends Base {
  @Prop({type: Array, required: true}) editIds!: string[];
  @Prop({type: [String, Number], required: true}) columns!: string | number;

  editOptions: SelectOption[] = [
    {value: 'action', text: this.$t('common.changeSelection') as string, disabled: true},
    {value: 'invert', text: this.$t('common.invertSelection') as string},
    {value: 'all', text: this.$t('common.selectAll') as string},
    {value: 'none', text: this.$t('common.deselectAll') as string}
  ]
  selectedEditOption: 'invert' | 'all' | 'none' | 'action' = 'action';
}
