import {
    PastConference,
    PastConferenceStatistics,
    PastConferenceWrapper,
    RunningConference,
    RunningConferencesWrapper,
    ScheduledConference,
    ScheduledConferenceWrapper
} from '@/models/AdminConsole';
import axios from 'axios';
import {ConferenceListItem} from '@/models/Conference';
import {TimeRange} from '@/models/api';
import {AdminRegForms} from '@/models/AdminForms';
import {AdminSurveyStatistic, SurveyWrapper} from "@/models/Survey";

/**
 * API calls for booking
 * @module service/AdminConsoleService
 */
export default {
    /**
     * Rest Functions bookings
     */
    getRunningConferences(): Promise<RunningConferencesWrapper> {
        return axios.get('/admin/running').then((res) => res.data);
    },

    getPastConferences(
        timeRange: TimeRange,
        page: number,
        size: number,
        order: string,
        direction: string,
        search: string,
        ignoreCase?: boolean
    ): Promise<PastConferenceWrapper> {
        return axios
            .get('/admin/historical', {
                params: {
                    page,
                    size,
                    order,
                    direction,
                    year: timeRange.year,
                    month: timeRange.month,
                    search,
                    ignoreCase
                }
            })
            .then((res) => res.data);
    },

    getPastConferencesStatistics(
        timeRange: TimeRange,
        search: string
    ): Promise<PastConferenceStatistics> {
        return axios
            .get('/admin/historical/statistic', {
                params: {year: timeRange.year, month: timeRange.month, search}
            })
            .then((res) => res.data);
    },

    getScheduledConferences(
        timeRange: TimeRange
    ): Promise<ScheduledConferenceWrapper> {
        return axios
            .get('/admin/planned', {
                params: {year: timeRange.year, month: timeRange.month}
            })
            .then((res) => res.data);
    },

	getAdminRegDatesFromNow(daysFromNow: number, searchQuery: string): Promise<AdminRegForms> {
		const params = { daysFromNow, searchQuery};
        return axios.get('admin/regdate', {params}).then((res) => res.data);
    },

    getAdminSurveys(
        page: number,
        size: number,
        order: string,
        direction: 'ASC' | 'DESC', search: string, filter: string): Promise<SurveyWrapper> {
        const params = {
            search,
            filter,
            size,
            page,
            order,
            direction
        };
        return axios.get('/admin/surveys', {params}).then(
            res => {
                return res.data
            }
        )
    },
    getAdminSurveyStatistic(): Promise<AdminSurveyStatistic>{
        return axios.get('/admin/surveys/statistic').then(res=> res.data)
    },

    // Functions
    runningConferenceToConferenceItem(
        conference: RunningConference
    ): ConferenceListItem {
        return {
            start: conference.startTime,
            numberOfParticipants: conference.totalPhoneParticipants,
            activeCount: conference.currentPhoneParticipants,
            company: conference.company,
            customerNumber: conference.customerNumber?.toString(),
            internalCustomerId: conference.internalCustomerId,
            username: conference.username,
            duration: conference.duration,
            participantLimit: conference.participantLimit,
            functions: {
                dialIn: conference.dialIn,
                dialOut: conference.dialOut,
                microphone: conference.recordingOn
            },
            pin: {
                participant: conference.participantAccessCode,
                moderator: conference.moderatorAccessCode
            },
            id: conference.conferenceId,
            rec: conference.recordingOn
        };
    },

    pastConferenceToConferenceItem(
        conference: PastConference
    ): ConferenceListItem {
        return {
            start: conference.start,
            duration: conference.duration,
            customerNumber: conference.customerNumber?.toString(),
            internalCustomerId: conference.internalCustomerId,
            numberOfParticipants: conference.numberOfParticipants,
            company: conference.company,
            username: conference.username,
            participantLimit: conference.participantLimit,
            functions: {
                dialIn: conference.dialIn,
                dialOut: conference.dialOut,
                microphone: conference.record
            },
            pin: {
                participant: conference.participantAccessCode,
                moderator: conference.moderatorAccessCode
            },
            rec: conference.record,
            id: conference.id,
            activeCount: 0
        };
    },

    scheduledConferenceToConferenceItem(
        conference: ScheduledConference
    ): ConferenceListItem {
        return {
            start: conference.startTime,
            duration: conference.duration,
            customerNumber: conference.customerNumber?.toString(),
            internalCustomerId: conference.internalCustomerId,
            numberOfParticipants: conference.totalPhoneParticipants,
            company: conference.company,
            username: conference.username,
            participantLimit: conference.participantLimit,
            functions: {
                dialIn: conference.dialIn,
                dialOut: conference.dialOut,
                microphone: conference.record
            },
            pin: {
                participant: conference.participantAccessCode,
                moderator: conference.moderatorAccessCode
            },
            rec: conference.record,
            id: conference.conferenceId,
            activeCount: 0
        };
    }
};
