

























import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component
export default class ConferenceScheduledItemSection extends Vue {
	@Prop() title!: string;
	@Prop({ default: 0 }) wizardStep!: number;
	@Prop() data!: { [key: string]: string };
	@Prop({ default: false, type: Boolean }) notEditable!: boolean;

	get dataKeys(): string[] {
		return Object.keys(this.data);
	}

	keyName(key: string): string {
		const translation = this.$t('conference.sections.' + key) as string;
		if (!translation.includes('.')) {
			return translation;
		}
		return key;
	}

	edit(): void {
		this.$emit('edit');
	}
}
