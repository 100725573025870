



















































import { Component, Prop } from 'vue-property-decorator';
import WExpandableCard from '@/components/WExpandableCard.vue';
import { SpeakerCardButton, SpeakerCardModel } from '@/models/SpeakerCard';
import Base from '@/views/Base';
import { Validations } from 'vuelidate-property-decorators';
import { required, url } from 'vuelidate/lib/validators';

@Component({
  components: { WExpandableCard }
})
export default class CustomButtons extends Base {
  @Prop() validation!: any;
  @Prop() speakerCard!: SpeakerCardModel;
  @Prop({ required: false, default: false, type: Boolean }) disabled!: boolean;

  button: SpeakerCardButton = {url: '', title: ''}
  editingButton: SpeakerCardButton | null = null;

  @Validations()
  validations = {
    button: {
      url: { url, required },
      title: { required }
    }
  };

  editButton(button: SpeakerCardButton) {
    (this.$refs.card as any).setOpen();
    this.button.url = button.url;
    this.button.title = button.title;
    this.editingButton = button;
  }

  addButton() {
    this.$v.button.$touch();
    if (this.$v.button.$invalid) {
      return;
    }
    if (this.editingButton == null) {
      this.speakerCard.speakerProfile?.buttons?.push(
        { title: this.button.title, url: this.button.url }
      );
    } else {
      this.editingButton.url = this.button.url;
      this.editingButton.title = this.button.title;
      this.editingButton = null;
    }

    this.button.title = '';
    this.button.url = '';
    this.$v.button.$reset();
  }

  cancelEditing(){
    this.$v.$reset();
    this.editingButton = null;
    this.button.url = '';
    this.button.title = ''
  }

  buttonUrlBlur() {
    const button=this.button;
    if (button.url) {
      if (button.url.startsWith('http://')) {
        button.url = 'https://' + button.url.substring(7);
      } else if (!button.url.startsWith('https://')) {
        button.url = 'https://' + button.url;
      }
    }
  }
}
