
























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import Base from '@/views/Base';
import { ScheduleType } from '@/models/api';
import BookingService from '@/service/BookingService';
import { BookingDateForm } from '@/models/BookingForm';
import { dateToDateString } from '@/utils/filters';
import { daysWithMondayFirst } from '@/utils/date';

@Component({
	filters: {
		dayInitial(i: number, days: string[]) {
			return days[i].substring(0, 2);
		}
	}
})
export default class BookingDateModal extends Base {
	@Prop() form!: BookingDateForm;
	@Prop() selectedStartDate!: string;

	endDateString = '';
	recurrenceDates: string[] = [];
	days = daysWithMondayFirst(this);

	dateOptions = [
		{ value: ScheduleType.DAILY, text: this.t('conference.daily') },
		{ value: ScheduleType.WEEKLY + ',1', text: this.t('conference.weekly') },
		{
			value: ScheduleType.WEEKLY + ',2',
			text: this.t('conference.everyTwoWeeks')
		},
		{
			value: ScheduleType.WEEKLY + ',3',
			text: this.t('conference.everyThreeWeeks')
		},
		{
			value: ScheduleType.WEEKLY + ',4',
			text: this.t('conference.everyVierWeeks')
		},
		{ value: ScheduleType.MONTHLY, text: this.t('conference.monthly') }
	];

	monthlyOptions = [
		{ value: 1, text: this.t('conference.everyFirst') },
		{ value: 2, text: this.t('conference.everySecond') },
		{ value: 3, text: this.t('conference.everyThird') },
		{ value: 4, text: this.t('conference.everyFourth') },
		{ value: -1, text: this.t('conference.everyLast') }
	];

	get endDate(): string {
		const date = this.form.schedule.end;
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		return `${date.getFullYear()}-${month}-${day}`;
	}

	set endDate(date: string) {
		const year = +date.split('-')[0];
		const month = +date.split('-')[1];
		const day = +date.split('-')[2];
		const endDate = new Date();
		if (!isNaN(year) && year > 2000) endDate.setFullYear(year);
		if (!isNaN(month)) endDate.setMonth(month - 1);
		if (!isNaN(day)) endDate.setDate(day);
		this.form.schedule.end = endDate;
	}

	get startAfterEnd(): boolean {
		return (
			this.form.schedule.start.getTime() > this.form.schedule.end.getTime()
		);
	}

	mounted(): void {
		this.endDateString = dateToDateString(this.form.schedule.end);
		this.calculateDates();
	}

	handleSeriesEnter(): void {
		this.$emit('handleSeriesEnter');
	}

	daySelected(index: number): boolean {
		return this.form.schedule?.allowedWeekdays[index];
	}

	toggleDay(index: number): void {
		this.form.schedule.allowedWeekdays[index] =
			!this.form.schedule.allowedWeekdays[index];
		this.form.schedule.allowedWeekdays = [
			...this.form.schedule.allowedWeekdays
		];
	}

	@Watch('form.schedule.periodicityStr')
	periodicityChanged(): void {
		const periodicityStr = this.form.schedule.periodicityStr;
		if (periodicityStr.includes('WEEKLY')) {
			const gap = periodicityStr.split(',')[1];
			this.form.schedule.periodicity = 'WEEKLY';
			this.form.schedule.timeInterval = +gap;
		} else {
			const periodicity = periodicityStr === 'MONTHLY' ? 'MONTHLY' : 'DAILY';
			this.form.schedule.periodicity = periodicity;
		}
		this.calculateDates();
	}

	@Watch('form.schedule.ordinalDayOfMonth')
	gapChanged(newValue: any): void {
		this.calculateDates();
	}

	@Watch('form.schedule.allowedWeekdays')
	weekdaysChanged(newValue: any): void {
		this.calculateDates();
	}

	@Watch('form.schedule.end')
	scheduleChanged(newValue: any): void {
		this.calculateDates();
	}

	@Watch('endDateString')
	endDateChanged(newValue: string): void {
		this.endDate = this.endDateString;
	}

	private calculateDates(): void {
		if (this.form.schedule.end.getTime() > this.form.schedule.start.getTime()) {
			const series = BookingService.scheduleFormToSchedule(this.form.schedule);
			setTimeout(() => {
				BookingService.datesFromSeries(series).then(
					(dates) =>
						(this.recurrenceDates = dates.map((date) =>
							new Date(date).toLocaleDateString(undefined, {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit',
								weekday: 'short'
							})
						))
				);
			});
		} else {
			this.recurrenceDates = [];
		}
	}
}
