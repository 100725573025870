

























































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { RegistrationForm } from '@/models/RegistrationForm';
import RegistrationService from '@/service/RegistrationService';
import { Component, Watch } from 'vue-property-decorator';
import Base from '../Base';
import General from './components/General.vue';
import RegistrationSpeakers from './components/Speakers.vue';
import RegistrationDates from './components/Dates.vue';
import Participation from './components/Participation.vue';
import EmailForm from './components/Email.vue';
import Privacy from './components/Privacy.vue';
import RegistrationDoubleOptIn from './components/DoubleOptIn.vue';
import RegistrationCertificate from './components/Certificate.vue';
import { Validations } from 'vuelidate-property-decorators';
import { helpers, maxLength, minValue, required, requiredIf, url } from 'vuelidate/lib/validators';
import { RegistrationDate } from '@/models/Registration';
import { Branding } from '@/models/Branding';
import BrandingService from '@/service/BrandingService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import { validateEmail } from '@/utils/validators';
import { registrationUrlExists } from '@/utils/formUrls';
import EndUrl from '@/views/forms/components/EndUrl.vue';

@Component({
  components: {
    EndUrl,
    HeaderLayout,
    RegistrationGeneral: General,
    RegistrationSpeakers,
    RegistrationDates,
    RegistrationParticipation: Participation,
    RegistrationNewEmail: EmailForm,
    RegistrationPrivacy: Privacy,
    RegistrationDoubleOptIn,
    RegistrationCertificate,
    WHeaderBtn
  }
})
export default class RegistrationNew extends Base {
  form: RegistrationForm = RegistrationService.newRegistrationForm();

  branding: Branding | null = null;
  tempFile = '';
  file!: File;

  @Validations()
  validations = {
    form: {
      general: {
        title: { required, maxLength: maxLength(255) },
        subtitle: { maxLength: maxLength(255) },
        url: {
          required,
          noSpecialChars: helpers.regex('alpha', /^[a-zA-Z0-9_.-]*$/),
          maxLength: maxLength(255),
          exists: this.urlExistsValidator
        },
        endUrl: {
          url,
          maxLength: maxLength(255)
        }
      },
      dates: {
        required,
        $each: {
          title: { maxLength: maxLength(255) },
          start: {
            required,
            duplicate: this.startDateDuplicateValidator,
            minValue: this.startDateMinValueValidator
          },
          end: {
            required,
            minValue: this.endDateMinValueValidator
          },
          countdown: {
            belowLimit: this.countdownBelowLimitValidator,
            minValue: minValue(-1)
          },
          userLimit: {
            minValue: minValue(-1)
          }
        }
      },
      mail: {
        subject: { required, maxLength: maxLength(255) },
        replyTo: { required, maxLength: maxLength(255), email: validateEmail },
        bcc: { maxLength: maxLength(255) },
        text: { required }
      },
      doi: {
        type: {},
        subject: {
          required: requiredIf(context => this.isDoi() && context && context.type !== 'DISABLED'),
          maxLength: maxLength(255)
        },
        text: {
          required: requiredIf(context => this.isDoi() && context && context.type !== 'DISABLED'),
          containsDoiLink: (text: string) => this.form.doi && this.form.doi.type !== 'DISABLED'
            ? text.indexOf('{DOI_LINK}') > 0
            : true
        },
        replyTo: {
          required: requiredIf(context => this.isDoi() && context && context.type !== 'DISABLED'),
          maxLength: maxLength(255),
          email: validateEmail
        }
      },
      participation: {
        checkboxes: {
          $each: {
            title: {
              required,
              maxLength: maxLength(4096)
            }
          }
        },
        fields: {
          $each: {
            title: {
              required,
              maxLength: maxLength(4096)
            }
          }
        }
      },
      speakers: {
        $each: {
          name: {
            maxLength: maxLength(255),
            required: requiredIf(val => !!val.image)
          },
          image: {}
        }
      },
      legal: {
        privacyURL: { required: requiredIf(()=> this.isPrivacyRequired()), url, maxLength: maxLength(255) },
        imprintName: { required, maxLength: maxLength(255) },
        imprintCompany: { maxLength: maxLength(255) }
      }
    }
  };

  isPrivacyRequired() {
    return this.form.general.branding == null || (this.branding && !this.branding.privacy);
  }

  @Watch('form.general.branding')
  getBranding(): void {
    if (this.form.general.branding !== null) {
      BrandingService.getBranding(+this.form.general.branding)
        .then((b) => (this.branding = b))
        .catch(this.showNetworkError);
    } else {
      this.branding = null;
    }
  }

  get brandingObj(): Branding | null {
    return this.branding;
  }

  get phoneRequired(): boolean {
    return (this.form.participation.phone.enabled && this.form.participation.phone.required) || false;
  }

  mounted(): void {
    const { id, brandingId } = this.$route.query;
    if (id) {
      RegistrationService.getRegistration(id as string)
        .then(registration => {
          this.form = RegistrationService.registrationToRegistrationForm(registration);
          this.form.dates = [RegistrationService.newDate(1)];
          this.form.general.url = this.id();
        })
        .catch(this.showNetworkError);
    }
    if (brandingId) {
      setTimeout(() => {
        this.form.general.branding = brandingId as string;
        this.getBranding();
      }, 100);
    }
  }

  async submit(): Promise<void> {
    this.$v.form.$touch();

    if (this.$v.$pending) {
      setTimeout(this.submit, 50);
      return;
    }

    if (this.$v.form.$invalid) {
      this.toast(this.t('forms.formHasErrors'), 'danger');
      return;
    }

    // dial out template
    if(!this.phoneRequired && this.form.mail.mailTextTemplateId == '2'){
      this.toast(this.t('forms.components.email.dialOutWarning'), 'danger');
      return;
    }

    this.baseLoading = true;
    if (!this.isDoi()) this.form.doi = undefined;
    const registration = RegistrationService.registrationFormToRegistration(this.form);

    const reg = await RegistrationService.addRegistration(registration).catch(this.showNetworkError);

    const speakers = this.form.speakers.filter(sp => sp.name !== '' && !!sp.image);
    const id = reg?.id;
    // add images for speakers
    if (speakers.length > 0 && id) {
      await Promise.all(
        speakers.map(speaker =>
          RegistrationService.addSpeaker(id, speaker).then((sp) => {
            const image = speaker.image;
            if (image && image.file && sp.id) {
              return RegistrationService.addImage(sp.id as number, id, image.file);
            }
          })
        )
      ).catch(this.showNetworkError);
    }
    console.log('added photos');
    // if (this.file && id) {
    // 	await RegistrationService.addCertificate(id, this.file).catch(this.showNetworkError);
    // }
    // console.log('added file');
    this.$router.push('/forms/registration?formUrl=' + this.form.general.url);
    this.baseLoading = false;
  }

  urlExistsValidator(value: string): Promise<boolean> | boolean {
    const url = this.$v.form.general?.url;
    if (!url?.$dirty) return true;
    return registrationUrlExists(value).then(
      (value) => !value
    );
  }

  private findSimilarDate(date: RegistrationDate): RegistrationDate | undefined {
    return this.form.dates.find(d =>
      Math.floor(d.start / 1000) === Math.floor(date.start / 1000) &&
      date.id !== d.id
    );
  }

  private findDate(date: RegistrationDate): RegistrationDate | undefined {
    const index = this.form.dates.findIndex((d) => d.id === date.id);
    return this.form.dates[index];
  }

  // validators
  private endDateMinValueValidator(value: number | undefined, date: RegistrationDate): boolean {
    const d = this.findDate(date);
    if (!d) return false;
    if (!d.start) return true;
    if (!value) return false;
    return d.start < value && d.start > Date.now();
  }

  private startDateMinValueValidator(value: number | undefined, date: RegistrationDate): boolean {
    const d = this.findDate(date);
    return d ? d.start > Date.now() : false;
  }

  private countdownBelowLimitValidator(value: number | undefined, date: RegistrationDate): boolean {
    const d = this.findDate(date);
    if (!d) return true;
    if (!d.userLimit) return !value;
    if (d.userLimit === -1) return true;
    if (!value) return true;
    return value <= d.userLimit;
  }

  private startDateDuplicateValidator(value: number | undefined, date: RegistrationDate): boolean {
    return !this.findSimilarDate(date);
  }
}
