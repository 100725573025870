





































































































































































import Base from '@/views/Base';
import {Component, Prop, Watch} from 'vue-property-decorator';
import WPagination from '@/components/WPagination.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import ContactsService from '@/service/ContactsService';
import {debounce} from 'lodash';
import {AddedContact, TableContact} from '@/models/Contact';
import GroupsService from '@/service/GroupsService';

@Component({
	components: { WPagination, WListOptions }
})
export default class BookingAddressbook extends Base {
	@Prop() addedContacts!: AddedContact[];

	currentPage = 1;
	perPage = 10;
	searchTerm = '';
	group = '';

	loadingContacts = false;
	totalPages = 0;

	groups = [
		{
			title: this.t('adressbook.noGroup'),
			text: this.t('adressbook.noGroup'),
			value: '',
			count: 0
		}
	];
	totalRows = 0;
	totalContacts = 0;

	groupIsAdmin = false;
	participantTypes = [
		{
			title: this.t('adressbook.asModerator'),
			text: this.t('adressbook.asModerator'),
			value: true
		},
		{
			title: this.t('adressbook.asParticipant'),
			text: this.t('adressbook.asParticipant'),
			value: false
		}
	];

	fields = [
		{
			key: 'selected',
			label: '',
			sortable: true,
			thStyle: { width: '35px' },
			class: ' va-middle'
		},
		{
			key: 'name',
			label: this.t('common.name'),
			sortable: true,
			sortDirection: 'desc',
			tdClass: 'truncate va-middle'
		},
		{
			key: 'telephone',
			label: this.t('common.phone'),
			sortable: true,
			tdClass: 'truncate va-middle'
		},
		{
			key: 'email',
			label: this.t('common.email'),
			sortable: true,
			class: 'truncate va-middle'
		},
		{
			key: 'actions',
			label: this.t('common.add'),
			thStyle: { width: '175px' },
			class: 'show-md-none'
		}
	];

	sortBy = 'dateCreated';
	sortDesc = false;

	rows: TableContact[] = [];
	editItems: TableContact[] = [];

	debounceGetContacts = debounce(this.getContacts, 200);

	@Watch('currentPage')
	currentTablePageChanged(): void {
		if (!this.loadingContacts) {
			setTimeout(() => {
				this.debounceGetContacts();
			});
		}
	}

	@Watch('group')
	currentGroupChanged(): void {
		this.loadingContacts = true;
		this.currentPage = 1;
		setTimeout(() => {
			this.debounceGetContacts();
		}, 10);
	}

	@Watch('sortBy')
	sortByChanged(): void {
		setTimeout(() => {
			this.debounceGetContacts();
		}, 10);
	}

	@Watch('sortDesc')
	sortDescChanged(): void {
		setTimeout(() => {
			this.debounceGetContacts();
		}, 10);
	}

	@Watch('searchTerm')
	searchChanged(): void {
		setTimeout(() => {
			this.debounceGetContacts();
		}, 1000);
	}

	get showPagination(): boolean {
		return this.perPage <= this.totalRows;
	}

	get editMode(): boolean {
		return this.editItems.length > 0;
	}

	mounted(): void {
		this.getGroups();
		this.debounceGetContacts();
	}

  addGroupContacts(): void {
    const group = this.groups.find((g) => g.title === this.group);
    if (!group) return;
    this.loadingContacts = true;
    ContactsService
        .getContacts(0, group.count, this.sortBy, this.sortDesc ? 'DESC' : 'ASC', '', this.group)
        .then(wrapper => {
          if (wrapper.empty) return;
          this.$emit(
              'addContacts',
              wrapper.content.map(contact => ({
                ...contact,
                added: false,
                selected: false,
                admin: this.groupIsAdmin
              }))
          );
        })
        .catch(this.showNetworkError)
        .finally(() => this.loadingContacts = false);
  }

	addSelected(role: 'MODERATOR' | 'PARTICIPANT'): void {
    if (this.editItems.length <= 0) return;
    this.$emit(
        'addContacts',
        this.editItems.map(contact => ({
          ...contact,
          added: false,
          selected: false,
          admin: role === 'MODERATOR'
        }))
    );
	}

	addItem(item: any): void {
		if (item.added) return;
    item.selected = item.selected
        ? this.rowSelected(false, item)
        : item.selected;
    this.$emit('addContact', item);
	}

	getContacts(): void {
		this.loadingContacts = true;
		ContactsService.getContacts(
			this.currentPage - 1,
			this.perPage,
			this.sortBy === 'selected' ? 'dateCreated' : this.sortBy,
			this.sortDesc ? 'DESC' : 'ASC',
			this.searchTerm,
			this.group
		)
			.then((wrapper) => {
				this.currentPage = wrapper.pageNumber + 1;
				this.totalPages = wrapper.totalPages;
				this.totalRows = wrapper.totalSize;
				if (this.totalContacts === 0) this.totalContacts = wrapper.totalSize;
				if (!wrapper.empty) {
					this.rows = wrapper.content.map((row) => ({
						...row,
						added: this.addedContacts.some(contact =>
							contact.phone === row.telephone.split(' ').join('')
							|| contact.email?.toLowerCase() === row.email?.toLowerCase() && !!contact.email && !!row.email
						),
						admin: false,
						selected:
							this.editItems.map((item) => item.id).includes(row.id || '') ||
							false
					}));
				} else {
					this.rows = [];
				}
				this.loadingContacts = false;
			})
			.catch(this.showNetworkError);
	}

	getGroups(): void {
		GroupsService.getGroups()
			.then((groups) => {
				this.groups = [
					this.groups[0],
					...groups.map((group) => {
						const count = group.numberOfContact ? +group.numberOfContact : 0;
						const name = `${group.name} (${count})`;
						return { title: group.name, value: group.name, text: name, count };
					})
				];
				this.group = '';
			})
			.catch(this.showNetworkError);
	}

	// Table
	rowClass(item: any): string {
		let cssClass = '';
		if (item && item.selected) {
			cssClass += 'table-primary';
		}
		return cssClass + ' w-table no-select';
	}

	rowClicked(item: any, index: number, event: any): void {
		if (event.target.dataset.label !== this.t('common.add')) {
			this.rowSelected(!item.selected, item, event.shiftKey);
		}
	}

	rowSelected(value: boolean, item: any, shiftKey?: boolean): void {
		if (item.added) return;
		if (item.selected !== value) item.selected = value;
		if (value) {
			if (item) this.editItems.push(item);
		} else {
			this.editItems = this.editItems.filter((i) => i.id !== item.id);
		}
		if (shiftKey && value) {
			this.selectBetween(item, value);
		}
	}

	selectAll(value: boolean): void {
		this.rows.forEach((item) => {
			if (item.selected !== value) {
				this.rowSelected(value, item);
			}
		});
		if (!value) this.editItems = [];
	}

	selectBetween(item: any, value: boolean): void {
		const index = this.rows.findIndex((row) => item.id === row.id);
		const selectedIndex = this.rows.findIndex(
			(row) => row.selected === value && row.id !== item.id
		);
		let startIndex = 0,
			endIndex = 0;
		if (index < selectedIndex) {
			startIndex = index;
			endIndex = selectedIndex;
		} else {
			startIndex = selectedIndex === -1 ? 0 : selectedIndex;
			endIndex = index;
		}
		this.rows.slice(startIndex, endIndex).forEach((item) => {
			if (item.selected !== value) {
				this.rowSelected(value, item);
			}
		});
	}
}
