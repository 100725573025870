
















































































































































import Base from '../../Base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ConferencePlanningForm, OperatorForm } from '@/models/OperatorForm';
import SelectRegDateModal from '@/views/operator/components/modals/SelectOperatorRegDate.vue';
import SelectConferenceModal from '@/views/operator/components/modals/SelectConferenceModal.vue';
import BookingService from '@/service/BookingService';
import { SelectOption } from '@/models/common';
import { Booking, RecordingMode } from '@/models/api';
import RegistrationService from '@/service/RegistrationService';
import { Registration } from '@/models/Registration';
import SelectSurveyModal from '@/views/operator/components/modals/SelectOperatorSurvey.vue';
import SurveyService from '@/service/SurveyService';
import { Survey } from '@/models/Survey';

@Component({
	components: { SelectSurveyModal, SelectRegDateModal, SelectConferenceModal }
})
export default class ConferencePlanning extends Base {
	@Prop() form!: ConferencePlanningForm;
	@Prop() validation!: any;
	@Prop() operatorForm!: OperatorForm;

	open = true;

	bookingOptions: SelectOption[] = [
		{ text: this.t('common.noConference'), value: '' }
	];
	bookings: Booking[] = [];
	booking: Booking | null = null;

	regForms: SelectOption[] = [];
	regForm: Registration | null = null;
	survey: Survey | null = null;

	get isRoom(): boolean {
		const { bookingId } = this.$route.query;
		return bookingId ? bookingId.includes('room') : false;
	}

	mounted(): void {
		this.getBookings();
		this.getRegistrations();
		const { bookingId } = this.$route.query;
		if (bookingId) {
			this.form.bookingId = bookingId as string;
			this.updateBookingData();
		}
	}

	toggleOpen(): void {
		this.open = !this.open;
	}

	getBookings(): void {
		BookingService.getBookings()
			.then((bookings) => {
				if (this.form.bookingId)
					this.booking =
						bookings.find((booking) => booking.id === this.form.bookingId) ||
						null;
				this.bookings = bookings
					.filter(
						(booking) =>
							booking.schedule && booking.schedule?.start >= Date.now()
					)
					.sort((a, b) =>
						a.schedule && b.schedule ? a.schedule.start - b.schedule.start : 0
					);
				this.bookingOptions = [
					this.bookingOptions[0],
					...this.bookings.map((booking) => ({
						text: `${booking.topic || this.t('common.noTopic')} (${new Date(
							booking.schedule?.start || ''
						).toLocaleString(undefined, {
							dateStyle: 'short',
							timeStyle: 'short'
						})})`,
						value: booking.id || ''
					}))
				];
			})
			.catch(this.showNetworkError);
	}

	getRegistrations(): void {
		RegistrationService.getRegistrations(0, 9999, 'dateCreated', 'DESC')
			.then((forms) => {
				if (!forms.empty) {
					if (this.form.registration.regDateId) {
						this.regForm =
							forms.content.find(
								(form) => form.id === +this.form.registration.regFormId
							) || null;
					}
					this.regForms = forms.content.map((form) => ({
						text: form.label || form.title,
						value: form.id ? form.id.toString() : '',
						data: form.regDates
					}));
					this.regForms.unshift({
						text: this.t('conference.noRegForm'),
						value: '',
						data: []
					});
				}
			})
			.catch(this.showNetworkError);
	}

	updateBookingData(): void {
		if (this.form.bookingId) {
			BookingService.getBooking(this.form.bookingId)
				.then((booking) => {
					this.booking = booking;
					let updateRegDateData = false;
					if (this.booking.regDate) {
						this.form.registration.regFormId =
							this.booking.regDate.regId.toString();
						this.form.registration.regDateId =
							this.booking.regDate.dateId.toString();
						updateRegDateData = true;
					}
					if (this.booking.survey) {
						this.form.surveyId = this.booking.survey.surveyId.toString();
					}
					if (this.booking.participants && this.booking.schedule) {
						if (!this.isRoom)
							this.operatorForm.date.start = this.booking.schedule.start;
						this.operatorForm.date.durationPreMeeting = 10;
						this.operatorForm.type.dialIn = this.booking.dialIn;
						this.operatorForm.type.dialOut = this.booking.dialOut;
						this.operatorForm.services.serviceRecord =
							this.booking.recordingMode === RecordingMode.AUTOMATIC;
						this.operatorForm.organization.pin =
							this.booking.dialIn && this.booking.participantAccessCode
								? this.booking.participantAccessCode
								: '';
						this.operatorForm.organization.language = (
							this.booking.language || this.user.language
						).toUpperCase();
						this.operatorForm.agents = this.booking.participants
							.filter((p) => p.moderator)
							.map((p) => ({
								email: p.email || '',
								name: p.name,
								id: p.idx,
								phone: p.phone
							}));
					}
					if (updateRegDateData) this.updateRegDateData();
					if (this.isRoom) {
						this.form.bookingId = '';
					}
				})
				.catch(this.showNetworkError);
		}
	}

	updateRegDateData(): void {
		if (this.form.registration.regFormId) {
			RegistrationService.getRegistration(this.form.registration.regFormId)
				.then((form) => {
					this.regForm = form;
					const date = this.regForm.regDates?.find(
						(date) => date.id === +this.form.registration.regDateId
					);
					if (date) {
						this.operatorForm.date.start = date.start;
						this.operatorForm.date.duration = this.calculateDuration(
							date.end - date.start
						);
						if (this.booking && this.booking.schedule) {
							this.operatorForm.date.durationPreMeeting = Math.min(
								Math.max(
									Math.round(
										(date.start - this.booking.schedule.start) / 300000
									) * 5,
									0
								),
								20
							);
						}
					}
				})
				.catch(this.showNetworkError);
		}
	}

	@Watch('form.surveyId')
	surveyIdChanged(): void {
		if (this.form.surveyId) {
			SurveyService.getSurvey(this.form.surveyId)
				.then((survey) => (this.survey = survey))
				.catch(this.showNetworkError);
		} else {
			this.survey = null;
		}
	}

	get selectedRegDateText(): { title: string; date: string } | null {
		if (this.regForm) {
			let date = this.regForm.regDates?.find(
				(date) => date.id === +this.form.registration.regDateId
			);
			if (date)
				return {
					title: date.title,
					date: new Date(date.start).toLocaleString(undefined, {
						dateStyle: 'short',
						timeStyle: 'short'
					})
				};
		}
		return null;
	}

	get selectedSurveyText(): string {
		return this.survey ? this.survey.label || this.survey.title : '';
	}

	get selectedBookingText(): { title: string; date: string } | null {
		if (this.booking) {
			if (this.booking.id === this.form.bookingId) {
				return {
					title: this.booking.topic || this.t('common.noTopic'),
					date: new Date(this.booking.schedule?.start || '').toLocaleString(
						undefined,
						{
							dateStyle: 'short',
							timeStyle: 'short'
						}
					)
				};
			}
		}
		return null;
	}

	calculateDuration(duration: number): number {
		const durations = [
			15, 30, 45, 60, 90, 120, 150, 180, 240, 300, 360, 420, 480
		];
		const durationMinutes = Math.round(duration / 60000);
		return durations.reduce((a, b) =>
			Math.abs(b - durationMinutes) < Math.abs(a - durationMinutes) ? b : a
		);
	}
}
