// Get translated languages

export function getLanguages(instance: Vue): any[] {
    return [
        {value: 'EN', text: instance.$t('common.english') as string},
        {value: 'DE', text: instance.$t('common.german') as string}
    ];
}

export function getSelectLanguages(instance: Vue): any[] {
    return [
		{ value: null, text: instance.$t('common.selectLanguage') as string + '...', disabled: true },
		{ text: instance.$t('common.german') as string, value: 'DE' },
		{ text: instance.$t('common.english') as string, value: 'EN' }
	];
}

// returns language code in upper case
export function getCurrentLanguage(): string {
    return (localStorage.getItem('language') || 'DE').toUpperCase();
}