

















import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import AddSurveyModal from "@/views/presentation/components/AddSurveyModal.vue";

@Component({
  components: {AddSurveyModal}
})
export default class SurveyElementForm extends Base {
  @Prop({required: true}) count!: number;
}
