























































import {Component} from 'vue-property-decorator';
import Base from '../Base';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {NewsItem} from '@/models/News';
import WSwitch from '@/components/WSwitch.vue';
import AccountsService from '@/service/AccountsService';
import {Permission} from '@/models/Account';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: { HeaderLayout, WSwitch }
})
export default class UserNews extends Base {
	news = [] as NewsItem[];

	mounted(): void {
		AccountsService.getNews(this.prod).then((news) => {
			this.news = news;
			const { id } = this.$route.query;
			const element = this.$refs['news-' + id] as HTMLElement[];
			if (element && element.length > 0) {
				window.scrollTo(0, element[0].offsetTop);
			}

			const readNews: string[] = this.user.settingsObject?.readNews || [];
			this.news.forEach((item) => (item.read = readNews.includes(item.id)));
		});
	}

	readAll(): void {
		if (this.user.settingsObject && this.$store.getters.unreadNews !== 0) {
			this.user.settingsObject.readNews = this.news.map((n) => n.id);
			this.news.forEach((item) => (item.read = true));
			this.$store.commit('setUnreadNews', 0);
			this.$forceUpdate();
			AccountsService.updateAccount({
				...this.user,
				permissions:
					(this.user.permissions as Permission[])?.map((p) => p.name) || [],
				customerId: this.user.customerShortDTO?.id,
				settings: JSON.stringify(this.user.settingsObject || {})
			}).then();
		}
	}

	change(): void {
		const readNews = this.news.filter((i) => i.read).map((i) => i.id);
		this.user.settingsObject = {
			...this.user.settingsObject,
			readNews
		};
		this.$store.commit('setUnreadNews', this.news.length - readNews.length);
		this.$forceUpdate();
		AccountsService.updateAccount({
			...this.user,
			permissions:
				(this.user.permissions as Permission[])?.map((p) => p.name) || [],
			customerId: this.user.customerShortDTO?.id,
			settings: JSON.stringify(this.user.settingsObject || {})
		}).then();
	}
}
