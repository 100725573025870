






















































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { Component } from 'vue-property-decorator';
import Base from '@/views/Base';
import { Playback } from '@/models/Playback';
import PlaybackService from '@/service/PlaybackService';
import { saveAs } from 'file-saver';

@Component({
	components: {
		HeaderLayout
	}
})
export default class Playbacks extends Base {
	files: Playback[] = [];

	loading = false;

	mounted(): void {
		this.getPlaybacks();
	}

	getPlaybacks(): void {
		PlaybackService.getPlaybacks().then((files) => (this.files = files));
	}

	upload(files: File[]): void {
		const file: File = files[0];
		this.loading = true;
		PlaybackService.uploadPlayback(file.name, file)
			.then(() => {
				this.toast(this.t('audio.uploaded'), 'success');
				this.getPlaybacks();
			})
			.catch(this.showNetworkError)
			.finally(() => (this.loading = false));
	}

	download(id: number, name: string): void {
		PlaybackService.downloadPlayback(id, name)
			.then((res) => saveAs(new Blob([res]), name))
			.catch(this.showNetworkError);
	}

	deleteFile(id: number): void {
		PlaybackService.deletePlayback(id)
			.then(() => {
				this.toast(this.t('audio.deleted'), 'success');
				this.getPlaybacks();
			})
			.catch(this.showNetworkError);
	}
}
