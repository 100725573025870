import 'regenerator-runtime/runtime';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export function createXLSX(
	filename: string,
	columns: any[],
	entries: any[],
	workbookName: string
): void {
	console.log(columns, entries);
	const workbook = new ExcelJS.Workbook();
	workbook.created = new Date(Date.now());
	const sheet = workbook.addWorksheet(workbookName, {
		views: [{ state: 'frozen', ySplit: 1 }]
	});

	sheet.columns = columns;
	sheet.addRows(entries);
	sheet.getRow(1).font = { bold: true };

	saveXLSX(workbook, `${filename}.xlsx`);
}

function saveXLSX(workbook: ExcelJS.Workbook, filename: string) {
	workbook.xlsx.writeBuffer().then((data) => {
		const blob = new Blob([data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		});
		saveAs(blob, filename);
	});
}
