export default {
	state: {
		activeInlineEdit: '',
		activeEditInvalid: false
	},
	mutations: {
		setActiveInlineEdit: (state: any, inlineEdit: string) =>
			(state.activeInlineEdit = inlineEdit),
		setActiveEditInvalid: (state: any, invalid: boolean) =>
			(state.activeEditInvalid = invalid)
	},
	getters: {
		activeInlineEdit: (state: any) => state.activeInlineEdit,
		activeEditInvalid: (state: any) => state.activeEditInvalid
	}
};
