import {PermissionName} from "@/models/Account";

// UI data for showing permission checkboxes
export const productPermList: { key: PermissionName; titleKey: string; disabled: boolean, indented?: boolean }[] = [
    { key: 'DIAL_OUT', titleKey: 'common.dialOut', disabled: true },
    { key: 'DIAL_IN', titleKey: 'common.dialInEn', disabled: true },
    { key: 'RECORDING', titleKey: 'common.recordings', disabled: true },
    { key: 'PLAYBACK', titleKey: 'conference.playbacks', disabled: true },
    { key: 'REGISTRATION_FORM', titleKey: 'common.regForms', disabled: true },
    { key: 'POLL', titleKey: 'common.surveyForms', disabled: true },
    { key: 'BRANDING', titleKey: 'admin.brandingsAfUf', disabled: false },
    { key: 'W2_ACCESS', titleKey: 'common.w2Access', disabled: false },
    { key: 'SPEAKER_PROFILE', titleKey: 'common.speakerProfile', disabled: false, indented: true },
    { key: 'LIVE_VIDEO_BACKGROUND', titleKey: 'common.liveVideoBackground', disabled: false, indented: true },
    { key: 'PRESENTATION', titleKey: 'common.presentation', disabled: true, indented: true },
    { key: 'PIN_RESERVATION', titleKey: 'common.pinReservation', disabled: false, indented: true },
];

// UI data for showing permission checkboxes
export const managementPermList: { key: PermissionName; titleKey: string; bullet: boolean; disabled: boolean }[] = [
    { key: 'CUSTOMER_CREATE_USER', titleKey: 'admin.createDelete', bullet: true, disabled: false },
    { key: 'CUSTOMER_CHANGE_FLATRATE', titleKey: 'admin.changeFlat', bullet: true, disabled: true },
    { key: 'CUSTOMER_IMPERSONATE', titleKey: 'admin.loginAs', bullet: true, disabled: true },
    { key: 'CUSTOMER_INVOICE', titleKey: 'admin.changeBillingDetails', bullet: false, disabled: true },
    { key: 'CUSTOMER_CONTACT', titleKey: 'admin.changeContactPartner', bullet: false, disabled: true },
    { key: 'CUSTOMER_CONFERENCE_HISTORY', titleKey: 'admin.conferenceHistory', bullet: false, disabled: true },
    { key: 'CUSTOMER_STATISTIC', titleKey: 'admin.userStats', bullet: false, disabled: true },
    { key: 'CUSTOMER_MEDIA', titleKey: 'admin.mediaLibrary', bullet: false, disabled: true },
    { key: 'CUSTOMER_BRANDING', titleKey: 'admin.globalBrandings', bullet: false, disabled: false },
    { key: 'CUSTOMER_INPUT_FIELD', titleKey: 'admin.globalInputFields', bullet: false, disabled: false },
];
