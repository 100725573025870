



























































































import {Component} from 'vue-property-decorator';
import Base from '../Base';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WSidebarLink from '@/components/WSidebarLink.vue';
import {ManualLink} from '@/models/Account';
import AccountsService from '@/service/AccountsService';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: { HeaderLayout, WSidebarLink }
})
export default class UserManual extends Base {
	videos: ManualLink[] = [];
	links: ManualLink[] = [];

	videoUnavailable(link: ManualLink): boolean {
		return !link.videoUrl;
	}

	handbookUnavailable(link: ManualLink): boolean {
		return !link.handbookUrl;
	}

	async mounted(): Promise<void> {
		this.videos = await AccountsService.getManualVideos(this.$i18n.locale, this.prod);
		this.links = await AccountsService.getManualHandbooks(this.$i18n.locale, this.prod);
	}
}
