import Vue from 'vue';
import Vuex from 'vuex';
import BookingStoreModule from '@/store/modules/booking';
import axios from 'axios';
import {Account} from '@/models/Account';
import {Conference} from '@/models/Conference';
import {AuthenticationStorage} from '@/service/AuthenticationService';
import {EventBus, Events} from '@/EventBus';
import {Theme} from '@/models/Theme';

Vue.use(Vuex);

// Typing the state, see: https://stackoverflow.com/questions/53807294/how-is-the-correct-way-to-work-with-vuex-and-typescript
class State {
	layout = ''; // overwrite the layout from route meta attribute or default layout
	brand = 'default'; // Branding of the client app
	theme = {} as Theme;
	allThemes = {} as Theme[];
	featureLevel = process.env.VUE_APP_FEATURE_LEVEL; // development, production

	authentication: AuthenticationStorage | null = null;

	account: Account | null = null;
	conferences: Conference[] = [];
	conferencesUpdate = false;
	numbersCount = 1;

	lastActionTime = Date.now(); // Number of milliseconds
	unreadNews = 0;
}

export default new Vuex.Store({
	state: new State(),
	mutations: {
		setLastActionTimestamp: (state) => (state.lastActionTime = Date.now()),
		setLayout: (state, layout: string) => (state.layout = layout),
		setBrand: (state, brand: { title: string; theme: Theme; css: string }) => {
			state.brand = brand.title;
			state.theme = brand.theme;

			if (brand.title !== 'default') {
				const style = document.createElement('style');
				style.appendChild(document.createTextNode(brand.css));
				document.head.append(style);
				document.title = brand.theme.tabTitle || 'CSN Conferencing';
			}
		},
		setAllThemes: (state, allThemes: Theme[]) => (state.allThemes = allThemes),
		setAuthentication(state, authentication: AuthenticationStorage) {
			console.log("Set authentication", authentication)
			state.authentication = authentication;
			const authenticationJSON = JSON.stringify(state.authentication);
			if (localStorage.getItem('authentication') !== authenticationJSON) {
				console.log("Set JSON authentication in localstorage", authenticationJSON)
				localStorage.setItem('authentication', authenticationJSON);
			}
			axios.defaults.headers.common[
				'Authorization'
			] = `Bearer ${authentication.accessToken}`;
		},
		clearAuthentication(state) {
			console.log('clearAuthentication');
			localStorage.removeItem('authentication');
			state.authentication = null;
			state.account = null;
			delete axios.defaults.headers.common['Authorization'];
			EventBus.$emit(Events.AuthenticationCleared);
			const searchKeys = Object.keys(localStorage).filter((key) =>
				key.startsWith('search:')
			);
			if (searchKeys.length > 0)
				searchKeys.forEach((key) => {
					localStorage.removeItem(key);
				});
		},
		setAccount: (state, account) => (state.account = account),
		setConferences: (state, conferences) => (state.conferences = conferences),
		setConferencesUpdate: (state, conferencesUpdate) =>
			(state.conferencesUpdate = conferencesUpdate),
		setNumbersCount: (state, numbersCount) =>
			(state.numbersCount = numbersCount),
		setUnreadNews: (state, unreadNews) => (state.unreadNews = unreadNews)
	},
	actions: {
		loadAuthentication(context) {
			const authenticationJSON = localStorage.getItem('authentication');
			if (authenticationJSON !== null) {
				console.log('Authentication object in localstorage found');
				context.commit('setAuthentication', JSON.parse(authenticationJSON));
			} else {
				console.log('No authentication object in localstorage found');
				context.commit('clearAuthentication');
			}
		}
	},
	getters: {
		featureLevel: (state) => state.featureLevel,
		lastActionTime: (state) => state.lastActionTime,
		layout: (state) => state.layout,
		brand: (state) => state.brand,
		theme: (state) => state.theme,
		allThemes: (state) => state.allThemes,

		authentication: (state) => state.authentication,
		accessToken: (state) =>
			state.authentication ? state.authentication.accessToken : undefined,
		refreshToken: (state) =>
			state.authentication ? state.authentication.refreshToken : undefined,
		expiration: (state) =>
			state.authentication ? state.authentication.expiration : undefined,
		username: (state) =>
			state.authentication ? state.authentication.username : undefined,

		isAuthenticated: (state) => state.authentication && state.account,
		account: (state) => state.account,
		conferences: (state) => state.conferences,
		conferencesUpdate: (state) => state.conferencesUpdate,
		numbersCount: (state) => state.numbersCount,
		unreadNews: (state) => state.unreadNews
	},
	modules: {
		teleconference: BookingStoreModule
	}
});
