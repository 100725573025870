
































import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import {DownloadListItem} from "@/models/Presentation";
import {formatFileSize} from "@/utils/filters";
import {MimeTypes} from "@/utils/i18n";

@Component
export default class PresentationDownloadRow extends Base {
  @Prop({required: true}) item!: DownloadListItem;

  get fileSize(): string {
    return formatFileSize(this.item.fileSize);
  }

  get fileType(): string {
    return this.$t(MimeTypes.getI18nKeyForMimeType(this.item.fileType)) as string;
  }
}
