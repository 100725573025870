
































































































import { Component } from 'vue-property-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import ContactsService from '@/service/ContactsService';
import Base from '@/views/Base';
import { validateEmail } from '@/utils/validators';

@Component
export default class NewContact extends Base {
	@Validations()
	validations = {
		newContactForm: {
			name: { required, maxLength: maxLength(128) },
			telephone: {
				required,
				noLetters: ContactsService.noLettersValidator,
				format: this.phoneFormatValidator
			},
			email: { email: validateEmail, maxLength: maxLength(128) },
			company: { maxLength: maxLength(255) },
			admin: { maxLength: maxLength(255) }
		}
	};

	newContactForm = {
		name: '',
		telephone: '',
		email: '',
		company: '',
		groups: []
	};
	resetContactForm = {
		name: '',
		telephone: '',
		email: '',
		company: '',
		groups: []
	};

	phoneFormatValidator(): boolean | Promise<boolean> {
		const telephone = this.$v.newContactForm.telephone;
		if (!telephone?.$dirty) return false;

		return ContactsService.validatePhoneNumbers([this.newContactForm.telephone])
			.then(validatedNumbers => {
				const validNumber = validatedNumbers[0];
				const phoneIsValid = validNumber.validNumber;
				if (phoneIsValid) this.newContactForm.telephone = validNumber.international;
				return phoneIsValid;
			})
			.catch(e => {
				this.showNetworkError(e);
				return false;
			});
	}

	validateState(name: string): boolean | null {
		const validate: any = this.$v.newContactForm[name];
		return validate.$dirty ? !validate.$error : null;
	}

	addContact(): void {
		if (!this.$v.newContactForm.$invalid) {
			this.$emit('newContact', {
				...this.newContactForm,
				groups: [...this.newContactForm.groups]
			});
			this.newContactForm = { ...this.resetContactForm, groups: [] };
			this.$v.newContactForm.$reset();
		} else {
			this.$v.newContactForm.$touch();
		}
	}
}
