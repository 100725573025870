













































































































































































import {Component} from 'vue-property-decorator';
import HeaderLayout from "@/layouts/nested/HeaderLayout.vue";
import Base from "@/views/Base";
import WHeaderBtn from "@/components/WHeaderBtn.vue";
import WPagination from "@/components/WPagination.vue";
import CustomFieldService from "@/service/CustomFieldService";
import {CustomField, ReorderModel} from "@/models/CustomField";

@Component({
  components: {WPagination, WHeaderBtn, HeaderLayout}
})
export default class CustomValidationsOverview extends Base {
  items: CustomField[] = [];
  baseLoading = false;
  firstNonGlobalItem = -1;
  fields = [
    {
      key: 'index',
      label: this.t('forms.customFields.position'),
      thStyle: {width: '70px', paddingLeft: '1.5rem'}
    },
    {
      key: 'title',
      label: this.t('forms.customFields.inputField'),
      thStyle: {width: '90px'}
    },
    {
      key: 'description',
      label: this.t('forms.customFields.noticeShort'),
      thStyle: {width: '180px'}
    },
    {
      key: 'customFormat',
      label: this.t('forms.customFields.validation'),
      thStyle: {width: '150px'}
    },
    {
      key: 'maxLength',
      label: this.t('forms.customFields.chars'),
      title: this.t('forms.customFields.maxChars'),
      thStyle: {width: '50px'}
    },
    {
      key: 'ignoreCase',
      label: this.t('forms.customFields.ignoreCaseShort'),
      title: this.t('forms.customFields.ignoreCase'),
      thStyle: {width: '20px'}
    },
    {
      key: 'global',
      label: this.t('brandings.global'),
      thStyle: {width: '40px'}
    },
    {
      key: 'used',
      label: this.t('forms.customFields.usedInAfUf'),
      thStyle: {width: '90px'}
    },
    {
      key: 'actions',
      label: this.t('common.actions'),
      thStyle: {width: '60px'},
    },
  ];


  mounted() {
    this.getFields();
  }

  getFields() {
    CustomFieldService.getFields().then(
        res => {
          this.items = CustomFieldService.sortFields(res);
          this.firstNonGlobalItem = this.items.filter(i => i.global).length;
        }
    )
  }

  deleteField(id: number) {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteField.description'), {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteField.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteField.title')
        })
        .then(res => {
          if (!res) return;
          CustomFieldService.deleteField(id).then(() => {
            this.getFields();
            this.toast(this.t('forms.customFields.fieldDeleted'), 'success');
          }).catch(this.showNetworkError)
        });
  }

  editField(id: number) {
    this.$router.push('/forms/custom-fields/' + id)
  }

  hasGlobalPermission(global: boolean): boolean {
    return !global || this.hasPermission('CUSTOMER_INPUT_FIELD');
  }

  moveUp(idx: number) {
    const tmp = this.items[idx];
    this.items[idx] = this.items[idx - 1];
    this.items[idx - 1] = tmp;

    this.reorder();
  }

  moveDown(idx: number) {
    const tmp = this.items[idx];
    this.items[idx] = this.items[idx + 1];
    this.items[idx + 1] = tmp;

    this.reorder();
  }

  deleteDisabled(item: CustomField): boolean {
    return (item.usedRegForms || []).length > 0 || (item.usedSurveys || []).length > 0
        || (item.global && !this.hasGlobalPermission(item.global));
  }

  deleteTooltip(item: CustomField): string {
    if (!this.deleteDisabled(item)) return '';
    if (!this.hasGlobalPermission(item.global) && item.global) {
      return this.t('forms.customFields.cantDeleteGlobal');
    }
    if (item.global) {
      const accounts = Object.keys(item.globalUsedRegForms || []);
      accounts.concat(Object.keys(item.globalUsedRegForms || []));
      const uniq = [...new Set(accounts)];
      return this.t('forms.customFields.cantDelete') + uniq.join(', ');
    } else {
      const uniq = [...new Set(item.usedRegForms.concat(item.usedSurveys))];
    return this.t('forms.customFields.cantDeleteUsed') + uniq.join(', ');
    }

  }

  editTooltip(item: CustomField): string {
    if (!(item.global && !this.hasGlobalPermission(item.global))) return '';

    if (!this.hasGlobalPermission(item.global) && item.global) {
      return this.t('forms.customFields.cantEditGlobal');
    }
    return this.t('forms.customFields.cantEditGlobal');
  }

  reorder() {
    const model: ReorderModel = {
      global: [],
      local: []
    };

    this.items.forEach(i => {
      if (i.global) {
        model.global.push(i.id);
      } else {
        model.local.push(i.id);
      }
    })
    this.baseLoading = true;
    CustomFieldService.reorder(model).then(res => {
      this.items = CustomFieldService.sortFields(res);
      this.firstNonGlobalItem = this.items.filter(i => i.global).length;
    }).catch(this.showNetworkError)
        .finally(() => {
          this.baseLoading = false
        })
  }

  countItems(map: Map<string, string[]>): number {
    let sum = 0;
    Object.values(map).forEach((v) => sum += v.length)
    return sum;
  }

  itemsToString(map: Map<string, string[]>): string {
    const str = Object.entries(map)
        .map(([key, value]) => `${key}: (${value.join(', ')})`)
        .join(', ');
    if (str.length > 256) {
      return str.substring(0, 256) + '...';
    } else return str
  }
}
