

















import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import WSwitch from "@/components/WSwitch.vue";
import {ElementListItem} from "@/models/Presentation";
import axios from "axios";

@Component({
  components: {WSwitch}
})
export default class ElementPlayerModal extends Base {
  @Prop({type: Number, required: true}) presentationId!: number;
  @Prop() element!: ElementListItem;

  loadScript() {
    // Check if script already exists to prevent duplicates
    if (document.querySelector(`script[src="${this.baseUrl}"]`)) {
      return;
    }

    const script = document.createElement("script");
    script.src = this.baseUrl;
    script.type = "text/javascript";
    script.onload = () => console.log("Script loaded successfully");
    script.onerror = () => console.error("Script failed to load");

    document.body.appendChild(script);
  }

  get isPptx(): boolean {
    return this.element.type === 'PPTX';
  }

  get baseUrl(): string {
    if (!this.element || !this.element.pptxProcessId) return '';
    return axios.defaults.baseURL + `/presentation/${this.presentationId}/pptx/converted/${this.element.pptxProcessId}/script.js`;
  }
}
