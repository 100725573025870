























import {Vue, Component} from 'vue-property-decorator';
import WExpandableCard from "@/components/WExpandableCard.vue";
import Base from "@/views/Base";
import CustomValidationExampleModal from "@/components/modals/CustomValidationExampleModal.vue";

@Component({
  components: {CustomValidationExampleModal, WExpandableCard}
})
export default class CustomFieldSimpleExample extends Base {

  examples = [
    {
      character: '#',
      charDescription: this.t('forms.customFields.hash'),
      description: this.t('forms.customFields.hashDescription')
    },
    {
      character: '%',
      charDescription: this.t('forms.customFields.percent'),
      description: this.t('forms.customFields.percentDescription')
    },
    {
      character: '.',
      charDescription: this.t('forms.customFields.dot'),
      description: this.t('forms.customFields.dotDescription')
    },
  ];

  modalExamples = [
    {
      expression: '######',
      description: this.t('forms.customFields.exampleModalTexts.hashDescription'),
      example: this.t('forms.customFields.exampleModalTexts.hashExample')
    },
    {
      expression: '%%%',
      description: this.t('forms.customFields.exampleModalTexts.percentDescription'),
      example: this.t('forms.customFields.exampleModalTexts.hashExample')
    },
    {
      expression: '..',
      description: this.t('forms.customFields.exampleModalTexts.dotDescription'),
      example: this.t('forms.customFields.exampleModalTexts.dotExample')
    },
  ];

  showExampleDialog() {
    this.$bvModal.show("simple-validation-example");
  }

  showReservedModal() {
    this.$bvModal.show("reservedCharsModal");
  }

}
