


















import { BookingListItem, BookingListItemType } from '@/models/Booking';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import WCard from './WCard.vue';

@Component({
	components: { WCard }
})
export default class WCards extends Vue {
	@Prop() items!: BookingListItem[];
	@Prop() perPage!: number;
	@Prop() currentPage!: number;

	get selectedItems(): BookingListItem[] {
		return this.items.filter(
			(v, i) =>
				i < this.perPage * this.currentPage &&
				i >= this.perPage * (this.currentPage - 1)
		);
	}

	get type(): BookingListItemType {
		return this.items[0].type;
	}
}
