































































import { Branding } from '@/models/Branding';
import { Registration, RegistrationLegal, RegistrationsObject } from '@/models/Registration';
import { Survey, SurveyApiObject } from '@/models/Survey';
import RegistrationService from '@/service/RegistrationService';
import SurveyService from '@/service/SurveyService';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Base from '../../Base';

@Component({})
export default class Privacy extends Base {
	@Prop() form!: RegistrationLegal;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Prop() validation!: any;
	@Prop() branding!: Branding;
	@Prop() formType!: 'registration' | 'survey';

	open = true;
	registrations!: RegistrationsObject;
	surveys!: SurveyApiObject;

	privacyText = this.t('forms.components.privacy.dsgvoDescription');
	impressumText = this.t('forms.components.privacy.impressumDescription');

	@Watch('form', { immediate: true })
	async formChanged(newForm?: RegistrationLegal): Promise<void> {
		if (newForm && !this.form.privacyURL) {
			try {
				this.registrations = await RegistrationService.getRegistrations(
					0,
					2,
					'dateCreated',
					'DESC'
				);
				this.surveys = await SurveyService.getSurveys(
					0,
					2,
					'dateCreated',
					'DESC'
				);
				this.setLegalFromLastFormOrFromAccount();
			} catch (error: any) {
				this.showNetworkError(error);
			}
		}
	}

	setLegalFromLastFormOrFromAccount(): void {
		if (!this.form.privacyURL) {
			let items!: SurveyApiObject | RegistrationsObject;

			if (this.surveys.empty && this.registrations.empty) {
				const contact = this.user.contact;
				if (contact && contact.firstName && contact.lastName) {
					this.form.imprintName = `${contact.firstName} ${contact.lastName}`;
				}
				if (contact && contact.company) {
					this.form.imprintCompany = `${contact.company}`;
				}
			} else {
				if (this.formType === 'survey')
					items = !this.surveys.empty ? this.surveys : this.registrations;
				if (this.formType === 'registration')
					items = this.registrations.empty ? this.surveys : this.registrations;
				if (!this.surveys.empty && !this.registrations.empty) {
					const surveyUpdated = this.surveys.content[0].dateUpdated;
					const regUpdated = this.registrations.content[0].dateUpdated;
					if (surveyUpdated && regUpdated) {
						items =
							surveyUpdated < regUpdated ? this.registrations : this.surveys;
					}
				}

				const firstLegal =
					(items.content[0] as Registration).legal ||
					(items.content[0] as Survey).surveyLegal;
				if (firstLegal.privacyURL) {
					this.form.privacyURL = firstLegal.privacyURL;
				}
				if (firstLegal.imprintCompany) {
					this.form.imprintCompany = firstLegal.imprintCompany;
				}
				if (firstLegal.imprintName) {
					this.form.imprintName = firstLegal.imprintName;
				}
			}
		}
	}

	toggleOpen(): void {
		this.open = !this.open;
	}

	validateState(name: string): boolean | null {
		const validate = this.validation[name];
		return validate.$dirty ? !validate.$error : null;
	}
}
