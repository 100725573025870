



































import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import WSwitch from "@/components/WSwitch.vue";
import {PresentationForm} from "@/models/Presentation";

@Component({
  components: {WSwitch}
})
export default class PresentationGeneral extends Base {
  @Prop() form!: PresentationForm;
  @Prop() validation!: any;

  titleEdit = false;

  validateState(name: string): boolean | null {
    const validate = this.validation[name];
    return validate.$dirty ? !validate.$error : null;
  }
}
