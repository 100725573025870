
















































































import { Component, Prop, PropSync } from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';

@Component({
	components: { WSwitch }
})
export default class SurveyDuration extends Base {
	@Prop() form!: number[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Prop() validation!: any;
	@PropSync('active') activeSync!: boolean;

	open = true;
	index = 2;

	toggleOpen(): void {
		this.open = !this.open;
	}

	selectedDate(dateMilli: number): string {
		return new Date(dateMilli).toISOString().split('T')[0];
	}

	selectedTime(dateMilli: number): string {
		const date = new Date(dateMilli);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		return `${hours}:${minutes}`;
	}

	updateStartDate(newDate: string): void {
		const year = +newDate.split('-')[0];
		const month = +newDate.split('-')[1];
		const day = +newDate.split('-')[2];
		const fullDate = new Date(this.form[0]);
		if (!isNaN(year)) fullDate.setFullYear(year);
		if (!isNaN(month)) fullDate.setMonth(month - 1);
		if (!isNaN(day)) fullDate.setDate(day);
		this.form[0] = fullDate.getTime();
		this.validation.$touch();
	}
	updateEndDate(newDate: string): void {
		const year = +newDate.split('-')[0];
		const month = +newDate.split('-')[1];
		const day = +newDate.split('-')[2];
		const endDate = new Date(this.form[1]);
		if (!isNaN(year)) endDate.setFullYear(year);
		if (!isNaN(month)) endDate.setMonth(month - 1);
		if (!isNaN(day)) endDate.setDate(day);
		this.form[1] = endDate.getTime();
		this.validation.$touch();
	}

	updateStartTime(newTime: string): void {
		const hours = +newTime.split(':')[0];
		const minutes = +newTime.split(':')[1];
		const fullDate = new Date(this.form[0]);
		if (!isNaN(hours)) fullDate.setHours(hours);
		if (!isNaN(minutes)) fullDate.setMinutes(minutes);
		this.form[0] = fullDate.getTime();
		this.validation.$touch();
	}

	updateEndTime(newTime: string): void {
		const hours = +newTime.split(':')[0];
		const minutes = +newTime.split(':')[1];
		const fullDate = new Date(this.form[0]);
		if (!isNaN(hours)) fullDate.setHours(hours);
		if (!isNaN(minutes)) fullDate.setMinutes(minutes);
		this.form[1] = fullDate.getTime();
		this.validation.$touch();
	}

	validateState(): boolean | null {
		const validate = this.validation;
		return validate.$dirty ? !validate.$error : null;
	}
}
