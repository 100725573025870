







































import { BookingDateForm } from '@/models/BookingForm';
import { Playback } from '@/models/Playback';
import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class BookingDate extends Base {
	@Prop() playbacks!: Playback[];
	@Prop() form!: BookingDateForm;

	selectedPlayback: Playback | undefined = undefined;

	setPlayback(playbackId: number): void {
		this.form.playbackId = playbackId;
		this.$emit('playbackChanged', playbackId);
	}
}
