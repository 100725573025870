











import {Component} from 'vue-property-decorator';
import Base from '@/views/Base';
import ListWithHeaderLayout from '@/layouts/nested/ListWithHeaderLayout.vue';
import {RecordingMode, ScheduleType} from '@/models/api';
import BookingService from '@/service/BookingService';
import {EventBus, Events} from '@/EventBus';
import {BookingListItem} from '@/models/Booking';

@Component({
	components: {
		ListWithHeaderLayout
	}
})
export default class ConferenceRooms extends Base {
	items: { [key: string]: any } = [];

	fields = [
		{
			key: 'topic',
			label: this.t('conference.nameOfRoom'),
			sortable: true,
			sortDirection: 'desc',
			class: 'pl-34'
		},
		{
			key: 'costCenter',
			label: this.t('conference.costCenter'),
			sortable: true,
			thStyle: { width: '170px' }
		},
		{
			key: 'pin',
			label: this.t('conference.pinLong'),
			thStyle: { width: '200px' }
		},
		{
			key: 'limit',
			label: this.t('common.limit'),
			sortable: true,
			thStyle: { width: '105px' }
		},
		{
			key: 'options',
			label: this.t('common.settings'),
			thStyle: { width: '240px' }
		},
		{
			key: 'actions',
			label: this.t('common.settings'),
			thStyle: { width: '45px' },
			thClass: 'show-content-md'
		}
	];

	mounted(): void {
		this.getRooms();

		// Eventbus
		EventBus.$on(Events.DeleteConference, (item: BookingListItem) => {
			BookingService.deleteBooking(item.id)
				.then(() =>
					this.toast(this.t('conference.conferenceDeleted'), 'success')
				)
				.catch(this.showNetworkError)
				.then(() => this.getRooms());
		});

    EventBus.$on(Events.EditConference, (item: BookingListItem) => this.$router.push(`/conference/booking-room?id=${item.id}`)
    );

		EventBus.$on(Events.ConferenceDetail, (booking: BookingListItem) => {
			this.$router.push('/conference/room/' + booking.id);
		});
		EventBus.$on(Events.RepeatConference, (item: BookingListItem) => this.$router.push(`/conference/booking-room?copyId=${item?.id}&step=0`));
	}

	beforeDestroy(): void {
		EventBus.$off(Events.DeleteConference);
		EventBus.$off(Events.EditConference);
		EventBus.$off(Events.ConferenceDetail);
		EventBus.$off(Events.RepeatConference);
	}

	getRooms(): void {
		BookingService.getBookings()
			.then((bookings) => {
				this.items = bookings
					.filter(
						(bookings) => bookings.schedule?.type === ScheduleType.ANYTIME
					)
					.map((booking) => {
						console.log(booking);
						return {
							id: booking.id,
							date: booking.schedule?.start,
							topic: booking.topic || this.t('common.noTopicSelected'),
							recording: booking.recordingMode,
							type: 'room',
							operatorBookingId: booking.operatorBooking?.id,
							participants: 0,
							limit:
								this.user.participantLimit > 0
									? this.user.participantLimit
									: this.t('conference.unlimited'),
							dialupNumbers: ['?', '?'],
							costCenter: booking.costCenter,
							pin: {
								moderator: booking.moderatorAccessCode,
								participant: booking.participantAccessCode
							},
							options: {
								schooling: booking.joinMuted,
								waitingRoom: booking.waitingRoom,
								endConference: booking.autoClose,
								autoConversationCut:
									booking.recordingMode === RecordingMode.AUTOMATIC,
								beep: booking.beep,
								dialOutCalleeConfirm: booking.dialOutCalleeConfirm
							}
						};
					});
			})
			.catch(this.showNetworkError);
	}
}
