








































import { Component, Prop } from 'vue-property-decorator';

import WPin from '@/components/WPin.vue';
import WConfContext from '@/components/WConfContext.vue';
import { BookingListItem } from '@/models/Booking';
import Base from '../Base';

@Component({
	components: { WPin, WConfContext }
})
export default class RoomItem extends Base {
	@Prop() item!: BookingListItem;
}
