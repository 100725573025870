








































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WizardStep } from '../models/Wizard';

@Component
export default class WWizard extends Vue {
	@Prop() currentStep!: WizardStep;
	@Prop() steps!: WizardStep[];
	@Prop() allDisabled!: boolean;

	get filteredSteps(): WizardStep[] {
		return this.steps.filter((s) => !s.disabled);
	}

	mounted(): void {
		this.currentStep.touched = true;
	}

	valid(step: WizardStep): boolean | undefined {
		return step.valid;
	}

	stepChanged(step: WizardStep, filteredIndex: number): void {
		const index = this.steps.indexOf(this.filteredSteps[filteredIndex]);
		this.$emit('step-changed', { step, index });
	}
}
