


































































































import {Component, Prop, PropSync} from "vue-property-decorator";
import Base from "@/views/Base";
import {PresentationTableItem} from "@/models/Presentation";
import {dateToDateTimeString} from "@/utils/filters";

@Component
export default class PresentationTable extends Base {
  @Prop() items!: PresentationTableItem[];
  @Prop() fields!: any;
  @Prop() options!: any;
  @PropSync('isSortDesc', {type: Boolean}) isSortDescSync!: boolean;
  @Prop({type: Boolean}) allSelected!: boolean;
  dateToDateTimeString = dateToDateTimeString;

  rowSelected(item: PresentationTableItem, shiftKey: boolean) {
    this.$emit('row-selected', item, shiftKey);
  }

  handleHeaderClick(isSortable: boolean, event: Event) {
    if (!isSortable) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
