




















































































































































































































































































import {Registration, RegistrationField, RegistrationUser} from '@/models/Registration';
import Base from '@/views/Base';
import {Component, Prop} from 'vue-property-decorator';
import {Validation} from 'vuelidate';
import {trimString} from '@/utils/filters';
import {FormCustomField} from "@/models/CustomField";

@Component({})
export default class UserModal extends Base {
  @Prop() currentUser!: RegistrationUser;
  @Prop() registration!: Registration;
  @Prop() validation!: any;

  get customFields(): RegistrationField[] {
    if (this.registration)
      return this.registration.fields
          ? this.registration.fields
              .filter((f) => f.type !== 'CHECKBOX')
              .sort((a, b) => a.idx - b.idx)
          : [];
    return [];
  }

  get checkboxes(): RegistrationField[] {
    if (this.registration)
      return this.registration.fields
          ? this.registration.fields
              .filter((f) => f.type === 'CHECKBOX')
              .sort((a, b) => a.idx - b.idx)
          : [];
    return [];
  }

  validateState(name: string): boolean | null {
    const validate = this.validation[name] as Validation;
    return validate.$dirty ? !validate.$error : null;
  }

  fieldValue(fieldId?: number): string | boolean {
    if (!(fieldId && this.currentUser && this.currentUser.values)) return '';
    return this.currentUser.values.find(v => v.fieldId === fieldId)?.value || ' ';
  }

  fieldChanged(value: string | boolean | null, fieldId: number): void {
    if (fieldId && this.currentUser) {
      if (value === null) value = '';
      if (this.currentUser.values) {
        let val = this.currentUser.values.find((v) => v.fieldId === fieldId);
        if (val) val.value = value.toString();
        else this.currentUser.values.push({fieldId, value: value.toString()});
      } else {
        this.currentUser.values = [{fieldId, value: value.toString()}];
      }
    }
  }

  findCustomField(fieldId: number): FormCustomField | undefined {
    const field = this.registration.customFields.find(i => i.id === fieldId);
    if (!field) console.log(`Field with id ${fieldId} does not exist, but there is a field with the id`);
    return field;
  }

  trimCheckbox(str: string): string {
    return trimString(str, 50);
  }

  trimField(str: string): string {
    return trimString(str, 24);
  }
}
