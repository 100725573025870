









































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import {Component} from 'vue-property-decorator';
import Base from '../../Base';
import {Validations} from 'vuelidate-property-decorators';
import {maxLength, required} from 'vuelidate/lib/validators';
import BookingService from '@/service/BookingService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import {Booking, NationalNumber, RecordingMode, ScheduleType} from '@/models/api';
import AuthenticationService from '@/service/AuthenticationService';
import {BookingDialInForm} from '@/models/BookingForm';
import {PinCheck} from '@/models/Booking';
import {getSelectLanguages} from "@/utils/languages";
import {pinValidator} from "@/utils/validators";

@Component({
	components: { HeaderLayout, WSwitch, WCostCenter, WHeaderBtn }
})
export default class TeleconferenceBooking extends Base {
	form = {
		id: '',
		topic: '',
		language: 'DE',
		costCenter: '',
		moderatorAccessCode: BookingService.generatePin(),
		participantAccessCode: BookingService.generatePin(),
		recordingMode: false,
		beep: false,
		dialOutCalleeConfirm: false,
		schooling: true,
		waitingRoom: false,
		endConference: false
	};

	nationalNumbers: NationalNumber[] = [];

	languages = getSelectLanguages(this)

	resetPin: BookingDialInForm = {
		moderatorAccessCode: '',
		participantAccessCode: ''
	};

	get editMode(): boolean {
		return !!this.$route.query.id;
	}

	@Validations()
	validations = {
		form: {
			topic: {maxLength: maxLength(100)},
			language: {},
			costCenter: {},
			moderatorAccessCode: {
				sixIntegers: pinValidator,
				unique: this.uniqueValidator,
				required
			},
			participantAccessCode: {
				sixIntegers: pinValidator,
				unique: this.uniqueValidator,
				required
			}
		}
	};

	get numbersCount(): number {
		return this.$store.getters.numbersCount;
	}

	mounted(): void {
		if (this.user) {
			this.form.costCenter = this.user.costCenter || '';
			this.form.language = this.user.language || 'DE';
		}
		if (this.$route.query.id) {
			const id = this.$route.query.id as string;
			BookingService.getBooking(id)
				.then(booking => {
					let costCenter = '';
					if (!booking.costCenter) costCenter = `|${this.user.costCenter}`;
					else {
						costCenter =
							booking.costCenter +
							'|' +
							this.user.costCenter
								?.split('|')
								.filter((cs) => cs !== booking.costCenter)
								.join('|');
					}
					this.form = this.roomToRoomForm(booking, costCenter);
					this.resetPin = {
						moderatorAccessCode: this.form.moderatorAccessCode,
						participantAccessCode: this.form.participantAccessCode
					};
				})
				.catch(this.showNetworkError);
		}
		if (this.$route.query.copyId) {
			const id = this.$route.query.copyId as string;
			BookingService.getBooking(id).then((booking) => {
				let costCenter = '';
				if (!booking.costCenter) costCenter = `|${this.user.costCenter}`;
				else {
					costCenter =
						booking.costCenter +
						'|' +
						this.user.costCenter
							?.split('|')
							.filter((cs) => cs !== booking.costCenter)
							.join('|');
				}
				this.form = this.roomToRoomForm(booking, costCenter);
			});
		}
		BookingService.getNationalNumbers()
			.then(numbers => {
				this.nationalNumbers = numbers
					.filter((num) => num.active)
					.splice(0, this.numbersCount);
			})
			.catch(this.showNetworkError);
	}

	submit(): void {
		this.$v.form.$touch();
		if (this.$v.form.$invalid) return;
		const booking: Booking = this.roomFormToRoom();
		BookingService.testBooking(booking).then(v => {
			if (v.pinOK) {
				return BookingService.createBooking(booking)
					.then(res => this.redirect(res[0]))
					.catch(this.handleServerError);
			} else {
				this.handlePinInvalidError(v);
			}
		});
	}

	async edit(): Promise<void> {
		this.$v.form.$touch();
		if (this.$v.form.$invalid) return;
		const booking: Booking = this.roomFormToRoom();
		const resetModPin = this.resetPin.moderatorAccessCode;
		const bookingModPin = booking.moderatorAccessCode;
		const resetPartPin = this.resetPin.participantAccessCode;
		const bookingPartPin = booking.participantAccessCode;

		if (resetModPin === bookingModPin && resetPartPin === bookingPartPin) {
			this.updateBooking(booking);
		} else {
			let tempPartPin!: string;
			let tempModPin!: string;
			if (resetPartPin === bookingPartPin) {
				// change part pin for check
				tempPartPin = BookingService.generatePin();
			} else if (resetModPin === bookingModPin) {
				// chagne mod pin for check
				tempModPin = BookingService.generatePin();
			}

			const tempBooking = {
				...booking,
				participantAccessCode: tempPartPin || booking.participantAccessCode,
				moderatorAccessCode: tempModPin || booking.moderatorAccessCode
			};

			const check = await BookingService.testBooking(tempBooking).catch(this.handleServerError);
			if (check) {
				if (check.pinOK) {
					return this.updateBooking(booking);
				} else {
					this.handlePinInvalidError(check);
				}
			}
		}
	}

	private handleServerError(error: any): void {
		const msg = this.errorMessage(error);
		if (
			msg.includes(
				'Der Moderator-Zugangscode ist zu diesem Zeitpunkt nicht möglich.'
			)
		) {
			this.toast(this.t('conference.pinErrors.modCodeExists'), 'danger');
		} else if (
			msg.includes(
				'Der Teilnehmer-Zugangscode ist zu diesem Zeitpunkt nicht möglich.'
			)
		) {
			this.toast(this.t('conference.pinErrors.partCodeExists'), 'danger');
		} else {
			this.showNetworkError(error);
		}
		this.$v.form.moderatorAccessCode?.$reset();
		this.$v.form.participantAccessCode?.$reset();
	}

	private handlePinInvalidError(v: PinCheck): any {
		if (v.errorWithModeratorPin) {
			this.toast(this.t('conference.pinErrors.modCodeExists'), 'danger');
		}
		if (v.errorWithParticipantPin) {
			this.toast(this.t('conference.pinErrors.partCodeExists'), 'danger');
		}
		this.$v.form.moderatorAccessCode?.$reset();
		this.$v.form.participantAccessCode?.$reset();
	}

	private updateBooking(booking: Booking) {
		return BookingService.updateBooking(booking)
			.then(res => this.redirect(res[0]))
			.catch(error => {
				const msg = this.errorMessage(error);
				if (msg.includes('Der Moderator-Zugangscode ist zu diesem Zeitpunkt nicht möglich.')
					&& msg.includes('Der Teilnehmer-Zugangscode ist zu diesem Zeitpunkt nicht möglich.')) {
					this.toast(this.t('conference.noChangePossible'), 'danger');
				} else {
					this.showNetworkError(error);
				}
			});
	}

	redirect(booking: string): void {
		this.$router.push('/conference/room/' + booking);
	}

	generate(user: string): void {
		const generatedPin = BookingService.generatePin();
		user === 'mod'
			? (this.form.moderatorAccessCode = generatedPin)
			: (this.form.participantAccessCode = generatedPin);
	}

	reset(user: string): void {
		user === 'mod'
			? (this.form.moderatorAccessCode = '')
			: (this.form.participantAccessCode = '');
	}

	uniqueValidator(): boolean {
		return this.form.moderatorAccessCode !== this.form.participantAccessCode;
	}

	validateState(name: string): boolean | null {
		const validate: any = this.$v.form[name];
		return validate.$dirty ? !validate.$error : null;
	}

	saveCostCenters(): void {
		const account = {
			...this.user,
			costCenter: this.form.costCenter,
			customerId: this.user.customerShortDTO?.id
		};
		AuthenticationService.updateMyAccount(account).then().catch();
	}

	openNationalNumbers(): void {
		this.$root.$emit('bv::show::modal', 'nationalNumbers');
	}

	private roomToRoomForm(booking: Booking, costCenter: any): any {
		return {
			id: booking.id || '',
			topic: booking.topic || '',
			language: booking.language?.toUpperCase() || this.form.language,
			costCenter,
			moderatorAccessCode:
				booking.moderatorAccessCode || this.form.moderatorAccessCode,
			participantAccessCode:
				booking.participantAccessCode || this.form.participantAccessCode,
			recordingMode:
				booking.recordingMode === RecordingMode.AUTOMATIC ||
				this.form.recordingMode,
			beep: !!booking.beep,
			dialOutCalleeConfirm: !!booking.dialOutCalleeConfirm,
			schooling: !!booking.joinMuted,
			waitingRoom: !!booking.waitingRoom,
			endConference: !!booking.autoClose
		};
	}

	private roomFormToRoom(): Booking {
		return {
			id: this.form.id,
			topic: this.form.topic,
			moderatorAccessCode: this.form.moderatorAccessCode,
			participantAccessCode: this.form.participantAccessCode,
			costCenter: this.form.costCenter.split('|')[0],
			language: this.form.language,
			schedule: {
				type: ScheduleType.ANYTIME,
				start: Date.now()
			},
			waitingRoom: this.form.waitingRoom,
			beep: this.form.beep,
			dialOutCalleeConfirm: this.form.dialOutCalleeConfirm,
			autoClose: this.form.endConference,
			joinMuted: this.form.schooling,
			recordingMode: this.form.recordingMode
				? RecordingMode.AUTOMATIC
				: RecordingMode.NONE,
			dialIn: true,
			dialOut: false
		};
	}
}
