








































import {DropdownItem, NavItem} from '@/models/Nav';
import {Component, Prop} from 'vue-property-decorator';
import WDropdownItem from '@/components/applayout/WDropdownItem.vue';
import Base from '@/views/Base';

@Component({
	components: { WDropdownItem },
	filters: {}
})
export default class WNavDropdown extends Base {
	@Prop() item!: NavItem;
	@Prop() right!: boolean;

	isIcon(str: string): boolean {
		return this.item.icon?.name === str;
	}

	navItemIsActive(navItem: NavItem, route: any): boolean {
		const isHome = route.name === 'Home' && navItem.id === 'Conference';
		return isHome || route.name?.includes(navItem?.id || '') || false;
	}
	navDropdownItemIsActive(dropdownItem: DropdownItem, route: any): boolean {
    if (dropdownItem.active != undefined) return dropdownItem.active;
    const str = (dropdownItem?.to as any)?.name || dropdownItem?.to || '';
    return route.name === str || false;
	}

  isVisible(item: DropdownItem): boolean {
    if (!item || !item.role) return true;
    switch (item.role) {
      case 'user':
        return this.isUser();
      case 'user_creates':
        return (
          this.isUser() && this.hasPermission('CUSTOMER_CREATE_USER')
        );
      case 'admin':
        return this.isAdmin();
      case 'sadmin':
        return this.isSAdmin();
      case 'any_admin':
        return (this.isAdmin() || this.isSAdmin());
      case 'presentation':
        return (this.hasPermission("PRESENTATION"))
      case 'live-video-background':
        return (this.hasPermission("LIVE_VIDEO_BACKGROUND"))
      case 'speaker-profile':
        return (this.hasPermission("SPEAKER_PROFILE"))
      case 'pin-reservation':
        return (this.hasPermission("PIN_RESERVATION"))
      default:
        return true;
    }
  }
}
