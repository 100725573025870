



























































































































import {ParticipationForm} from '@/models/RegistrationForm';
import {Component, Prop, Watch} from 'vue-property-decorator';
import Base from '../../Base';
import ParticipationField from './ParticipationField.vue';
import WSwitch from '@/components/WSwitch.vue';
import {RegistrationField} from '@/models/Registration';
import CustomFieldService from "@/service/CustomFieldService";
import {CustomField, CustomFieldCreate} from "@/models/CustomField";

@Component({
  components: {ParticipationField, WSwitch}
})
export default class Participation extends Base {
  @Prop() form!: ParticipationForm;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() validation!: any;
  @Prop() formType!: 'registration' | 'survey';

  open = true;
  customFields: { field: CustomField, edit: CustomFieldCreate }[] | null = null;

  mounted() {
    CustomFieldService.getFields().then(res => {
      const sorted = CustomFieldService.sortFields(res);

      this.customFields = sorted.map(i => {
            const edit = this.form.customFields.find(j => j.customFieldId == i.id) || {
              required: false,
              enabled: false,
              customFieldId: i.id
            };
            this.form.customFields.push(edit);
            return {
              field: i,
              edit: edit
            };
          }
      );
    })
  }

  get index(): number {
    return (
        Math.max(
            this.form.checkboxes.reduce(
                (previousValue, currentValue) =>
                    previousValue.idx > currentValue.idx ? previousValue : currentValue,
                {idx: 0}
            ).idx,
            this.form.fields.reduce(
                (previousValue, currentValue) =>
                    previousValue.idx > currentValue.idx ? previousValue : currentValue,
                {idx: 0}
            ).idx
        ) + 1
    );
  }

  get fields(): RegistrationField[] {
    return this.form.fields.sort((a, b) => a.idx - b.idx);
  }

  get checkboxes(): RegistrationField[] {
    return this.form.checkboxes.sort((a, b) => a.idx - b.idx);
  }

  get isReg(): boolean {
    return this.formType !== 'survey';
  }

  get phoneHtml(): string {
    return this.formType === 'survey'
        ? this.t('common.phone')
        : `${this.t('common.phone')} <span class="inactive">(${this.t('forms.components.participation.dialOutRequired')})</span>`;
  }

  toggleOpen(): void {
    this.open = !this.open;
  }

  removeField(i: number): void {
    this.form.fields.splice(i, 1);
  }

  removeCheckbox(i: number): void {
    this.form.checkboxes.splice(i, 1);
  }

  addField(): void {
    this.form.fields.push({title: '', type: 'TEXT_LINE', idx: this.index});
  }

  addCheckbox(): void {
    this.form.checkboxes.push({title: '', type: 'CHECKBOX', idx: this.index});
  }

  @Watch('form.saveParticipantData')
  saveParticipantDataChanged(): void {
    if (this.form.saveParticipantData) {
      this.form.phone.enabled = true;
      this.form.phone.required = true;
      this.form.email.enabled = true;
      this.form.email.required = true;
      this.form.name.enabled = true;
      this.form.name.required = true;
      this.form.salutation.enabled = true;
    } else {
      this.form.phone.enabled = false;
      this.form.phone.required = false;
      this.form.email.enabled = false;
      this.form.email.required = false;
      this.form.name.enabled = false;
      this.form.name.required = false;
      this.form.salutation.enabled = false;
    }
  }
}
