















import { Component, Prop } from 'vue-property-decorator';
import WExpandableCard from '@/components/WExpandableCard.vue';
import { quillEditor } from 'vue-quill-editor';
import { CreateSpeakerCard } from '@/models/SpeakerCard';
import Base from '@/views/Base';

@Component({
  components: { WExpandableCard, quillEditor }
})
export default class Infobox extends Base {
  @Prop() speakerCard!: CreateSpeakerCard;
  @Prop() validation!: any;
  @Prop({ required: false, default: false, type: Boolean }) disabled!: boolean;

  editorOptions = {
    theme: 'snow',
    placeholder: '',
    align: 'right',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],
        ['link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean']
      ]
    }
  };
}
