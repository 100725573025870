
































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { Component } from 'vue-property-decorator';
import Base from '../../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import { Branding, BrandingData } from '@/models/Branding';
import BrandingService from '@/service/BrandingService';
import BrandingPreview from './Preview.vue';

@Component({
	components: { HeaderLayout, WHeaderBtn, BrandingPreview }
})
export default class FormsBranding extends Base {
	brandings: Branding[] = [];

	mounted(): void {
		this.getBrandings();
	}

	editable(branding: Branding): boolean {
		return (
			(branding.global && this.hasPermission('CUSTOMER_BRANDING')) ||
			(!branding.global && this.hasPermission('BRANDING'))
		);
	}

  getCountForms(branding: Branding): number {
    if (branding.global && this.hasPermission('CUSTOMER_BRANDING')) {
      // Only the 'branding manager' can see all usages
      return branding.countRegForms + branding.countSurveys
    }
    return branding.countRegFormsForCurrentAccount + branding.countSurveysForCurrentAccount
  }

  deleteBranding(id: number): void {
		BrandingService.deleteBranding(id)
			.then(() => {
				this.toast(this.t('brandings.brandingDeleted'), 'success');
				this.getBrandings();
			})
			.catch(this.showNetworkError);
	}

	logoFlex(branding: Branding): string {
		const data = branding.data as BrandingData;
		const align =
			data.logoPosition === 'right'
				? 'end'
				: data.logoPosition === 'middle'
				? 'center'
				: 'start';
		return 'justify-content-' + align;
	}

	brandingData(branding: Branding): BrandingData {
		return branding.data as BrandingData;
	}

	private getBrandings(): void {
		BrandingService.getBrandings()
			.then((brandings) => {
				brandings.forEach(
					(branding) => (branding.data = JSON.parse(branding.data as string))
				);
				this.brandings = brandings
					.sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1))
					.sort((a, b) =>
						a.global
							? b.global
								? a.dateCreated > b.dateCreated
									? -1
									: 1
								: -1
							: 0
					);
				this.brandings.forEach((branding) => {
					if (branding.logoFileExists) {
						branding.logo = {
							backendUrl:
								BrandingService.getLogoUrl(branding.id) +
								`?v=${branding.version}`
						};
					}
					if (branding.bannerFileExists) {
						branding.banner = {
							backendUrl:
								BrandingService.getBannerUrl(branding.id) +
								`?v=${branding.version}`
						};
					}
				});
			})
			.catch(this.showNetworkError);
	}
}
