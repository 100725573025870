







































import {Component, Prop} from 'vue-property-decorator';
import Base from '../../Base';
import {GeneralForm} from "@/models/RegistrationForm";

@Component({})
export default class EndUrl extends Base {
  @Prop() form!: GeneralForm;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() validation!: any;
  @Prop() formType!: 'registration' | 'survey';

  open = true;

  toggleOpen(): void {
    this.open = !this.open;
  }

  validateState(name: string): boolean | null {
    const validate = this.validation[name];
    return validate.$dirty ? !validate.$error : null;
  }
}
