








































































































































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {Registration, RegistrationDate, RegistrationsObject, RegistrationStatistic} from '@/models/Registration';
import RegistrationService from '@/service/RegistrationService';
import {Component, Watch} from 'vue-property-decorator';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import BlacklistModal from '@/components/modals/BlocklistModal.vue';
import {ListOptions} from '@/models/Booking';
import BrandingService from '@/service/BrandingService';
import RegistrationLink from './RegistrationLink.vue';

@Component({
  components: {
    HeaderLayout,
    WHeaderBtn,
    WListOptions,
    WPagination,
    BlacklistModal,
    RegistrationLink
  }
})
export default class RegistrationOverview extends Base {
  registrations: Registration[] = [];

  tableOptions: ListOptions = {
    entryCount: 10,
    searchTerm: '',
    sortBy: 'dateCreated',
    sortDirection: 'DESC'
  };

  totalRows = 0;
  currentPage = 1;

  selectedReg: any | null = null;

  stats: RegistrationStatistic = {
    activeDates: 0,
    activeForms: 0,
    activeUser: 0,
    activeDoiConferenceUser: 0,
    activeDoiNewsletterUser: 0,
    pastForms: 0,
    pastUser: 0,
    brandings: 0
  };

  formMode: 'active' | 'past' = 'active';

  @Watch('formMode')
  getRegistrations(): void {
    if (this.formMode === 'active') this.getActiveRegistrations();
    else this.getPastRegistrations();
  }

  @Watch('currentPage')
  currentPageChanged(): void {
    this.getRegistrations();
  }

  @Watch('tableOptions.searchTerm')
  search(): void {
    this.getRegistrations();
  }

  @Watch('tableOptions.entryCount')
  countChanged(): void {
    this.getRegistrations();
  }

  sort(): void {
    if (this.tableOptions.sortBy && this.tableOptions.sortDirection) {
      localStorage.setItem('registrations.sortBy', this.tableOptions.sortBy);
      localStorage.setItem('registrations.sortDirection', this.tableOptions.sortDirection);
    }
    this.getRegistrations();
  }

  mounted(): void {
    const sortBy = localStorage.getItem('registrations.sortBy');
    const sortDirection = localStorage.getItem('registrations.sortDirection');
    if (sortBy && sortDirection) {
      this.tableOptions.sortBy = sortBy;
      this.tableOptions.sortDirection = sortDirection as 'ASC' | 'DESC';
    }
    const {mode, formUrl} = this.$route.query;
    if (mode && mode === 'past') {
      this.formMode = 'past';
      this.getPastRegistrations();
    } else {
      this.getActiveRegistrations();
    }
    this.getStatistics();

    if (formUrl) {
      this.selectedReg = {url: ('/' + formUrl) as string};
      setTimeout(() => {
        this.$bvModal.show('infoModal');
        this.$router.replace({query: {}});
      }, 1000);
    }
  }

  getActiveRegistrations(): void {
    this.formMode = 'active';
    RegistrationService.getActiveRegistrations(
        this.currentPage - 1,
        this.tableOptions.entryCount,
        this.tableOptions.sortBy || 'dateCreated',
        this.tableOptions.sortDirection || 'DESC',
        this.tableOptions.searchTerm
    )
        .then(regs => this.setRegistrations(regs))
        .catch(this.showNetworkError);
  }

  getPastRegistrations(): void {
    this.formMode = 'past';
    RegistrationService.getPastRegistrations(
        this.currentPage - 1,
        this.tableOptions.entryCount,
        this.tableOptions.sortBy || 'dateCreated',
        this.tableOptions.sortDirection || 'DESC',
        this.tableOptions.searchTerm
    )
        .then(regs => this.setRegistrations(regs))
        .catch(this.showNetworkError);
  }

  private setRegistrations(regs: RegistrationsObject): void {
    if (regs && regs.content) {
      this.registrations = regs.content;
      this.registrations.forEach((reg) => {
        if (reg.regDates)
          reg.regDates = reg.regDates.sort((a, b) => a.start - b.start);
      });
      this.totalRows = regs.totalSize;
    } else {
      this.registrations = [];
      this.totalRows = 0;
    }
  }

  getStatistics(): void {
    RegistrationService.getRegistrationStatistics()
        .then(st => {
          this.stats = st;
          return BrandingService.getBrandings();
        })
        .then(b => {
          this.stats = {
            ...this.stats,
            brandings: b.length
          };
        })
        .catch(this.showNetworkError);
  }

  deleteRegistration(id: number): void {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteFormModal.description'), {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteFormModal.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteFormModal.title')
        })
        .then(res => {
          if (!res) return;
          RegistrationService.deleteRegistration(id)
              .then(() => {
                this.toast(this.t('modals.deleteFormModal.deleted'), 'success');
                this.getRegistrations();
                this.getStatistics();
              })
              .catch(this.showNetworkError);
        });
  }

  dates(reg: Registration): RegistrationDate[] {
    if (!reg.regDates) return [];
    if (this.formMode === 'past') return reg.regDates;
    return reg.regDates.filter((date) => date.start >= Date.now());
  }

  deleteParticipants(reg?: Registration): void {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteParticipantsModal.description'), {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteParticipantsModal.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteParticipantsModal.title')
        })
        .then(res => {
          if (!res) return;
          if (reg && reg.regDates) {
            const regId = reg.id;
            if (regId)
              Promise.all(
                  reg.regDates.map(date =>
                      date.id
                          ? RegistrationService.deleteUsersForDate(regId, +date.id)
                          : Promise.resolve()
                  ))
                  .then(() => {
                    this.toast(this.t('modals.deleteParticipantsModal.deleted'), 'success');
                    this.getRegistrations();
                    this.getStatistics();
                  })
                  .catch(this.showNetworkError);
          }
        });
  }

  openLink(urlPath: string): void {
    window.open(this.url(urlPath), '_blank');
  }

  url(urlPath: string): string {
    return process.env.VUE_APP_REGISTRATION_URL + urlPath;
  }

  openInfoModal(reg: Registration): void {
    this.selectedReg = reg;
    this.$bvModal.show('infoModal');
  }

  openDateModal(reg: Registration): void {
    this.selectedReg = reg;
    this.$bvModal.show('datesModal');
  }

  copyLink(urlPath: string): void {
    window.navigator.clipboard.writeText(
        process.env.VUE_APP_REGISTRATION_URL + urlPath
    );
    this.toast('URL kopiert', 'dark');
  }

  toggleLock(reg: Registration): void {
    // lock
    if (reg.id) {
      if (reg.active) {
        RegistrationService.deactivateForm(reg.id)
            .then(() => (reg.active = false))
            .catch(this.showNetworkError);
      } else {
        RegistrationService.activateForm(reg.id)
            .then(() => (reg.active = true))
            .catch(this.showNetworkError);
      }
    }
  }

  duplicate(reg: Registration): void {
    if (reg.id) {
      RegistrationService.duplicateRegistration(reg.id)
          .then((newReg) => {
            if (newReg.id) {
              this.toast(this.t('forms.duplicated'), 'success');
              this.getActiveRegistrations()
            }
          })
          .catch(this.showNetworkError);
    }
  }

  deleteAllPastParticipants(): void {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteOldParticipantsModal.description'), {
          title: this.t('modals.deleteOldParticipantsModal.title'),
          centered: true,
          okVariant: 'danger',
          okTitle: this.t('modals.deleteOldParticipantsModal.ok'),
          cancelTitle: 'Abbrechen'
        })
        .then((res) => {
          if (res) {
            RegistrationService.deleteAllPastRegisteredUsers()
                .then(() => {
                  this.toast(
                      this.t('modals.deleteOldParticipantsModal.deleted'),
                      'success'
                  );
                  this.getPastRegistrations();
                  this.getStatistics();
                })
                .catch(this.showNetworkError);
          }
        });
  }

  deleteAllParticipantsAndDates(): void {
    this.$bvModal
        .msgBoxConfirm(
            this.t('modals.deleteOldRegDatesAndParticipantsModal.description'),
            {
              title: this.t('modals.deleteOldRegDatesAndParticipantsModal.title'),
              centered: true,
              okVariant: 'danger',
              okTitle: this.t('modals.deleteOldRegDatesAndParticipantsModal.ok'),
              cancelTitle: this.t('common.cancel')
            }
        )
        .then((res) => {
          if (res) {
            RegistrationService.deleteAllPastRegisteredUsers()
                .then(() => RegistrationService.deleteAllPastRegistrationDates())
                .then(() => {
                  this.toast(
                      this.t('modals.deleteOldRegDatesAndParticipantsModal.delete'),
                      'success'
                  );
                  this.getPastRegistrations();
                  this.getStatistics();
                })
                .catch(this.showNetworkError);
          }
        });
  }

  deleteAllPastForms(): void {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteAllOldRegModal.description'), {
          title: this.t('modals.deleteAllOldRegModal.title'),
          centered: true,
          okVariant: 'danger',
          okTitle: this.t('modals.deleteAllOldRegModal.ok'),
          cancelTitle: this.t('common.cancel')
        })
        .then((res) => {
          if (res) {
            RegistrationService.deleteAllPastRegistrationForms()
                .then(() => {
                  this.toast(
                      this.t('modals.deleteAllOldRegModal.deleted'),
                      'success'
                  );
                  this.stats.pastForms = 0;
                  this.getPastRegistrations();
                  this.getStatistics();
                })
                .catch(this.showNetworkError);
          }
        });
  }

  async enableBlacklist(reg: Registration): Promise<void> {
    if (reg.id) {
      reg.ignoreBlacklist = false;
      await RegistrationService.updateGeneral(reg.id, reg);
      this.toast(this.t('forms.blacklistEnabled'), 'success');
    }
  }

  async disableBlacklist(reg: Registration): Promise<void> {
    if (reg.id) {
      reg.ignoreBlacklist = true;
      await RegistrationService.updateGeneral(reg.id, reg);
      this.toast(this.t('forms.blacklistDisabled'), 'success');
    }
  }

  get totalParticipants(): number {
    return this.stats.activeUser + this.stats.activeDoiNewsletterUser + this.stats.activeDoiConferenceUser;
  }
}
