
































































import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import { SelectOption } from '@/models/common';
import AccountsService from '@/service/AccountsService';
import { Operator } from '@/models/Operator';
import { Account } from '@/models/Account';
import OperatorService from '@/service/OperatorService';

@Component
export default class OperatorModal extends Base {
	@Prop() service!: Operator;

	adminsOptions: SelectOption[] = [];
	admins: Account[] = [];

	_value = '';

	get selectedAdmin(): string {
		return this.service.admins && this.service.admins.length > 0
			? this.service.admins[0].username
			: '';
	}

	mounted(): void {
		AccountsService.getAccounts(0, 1000, 'dateCreated', 'DESC', 'ADMIN').then(
			(res) => {
				this.admins = res.content;
				this.adminsOptions = res.content.map((admin) => ({
					text: `${admin.username} (${admin.contact.firstName} ${admin.contact.lastName})`,
					value: admin.username
				}));
				this.adminsOptions.unshift({
					text: this.$t('operator.noOperator') as string,
					value: ''
				});
				this._value = this.selectedAdmin;
			}
		);
	}

	updateOperator(ok: () => void): void {
		let admins: string[] = [];
		if (this._value !== '') {
			const admin = this.admins.find((a) => a.username === this._value);
			if (admin) {
				admins = [admin.username];
			}
		}
		if (this.service) {
			OperatorService.updateAdmins(this.service.id, { usernames: admins })
				.then((service) => {
					this.service.admins = service.admins;
					this.toast(this.$t('operator.changeSuccess') as string, 'success');
					ok();
					this.resetOperatorForm();
				})
				.catch(this.showNetworkError);
		}
	}

	resetOperatorForm(): void {
		this._value = this.selectedAdmin;
	}

	get saveButtonEnabled(): boolean {
		return true;
	}
}
