





























import {Component} from 'vue-property-decorator';
import WExpandableCard from "@/components/WExpandableCard.vue";
import Base from "@/views/Base";
import CustomValidationExampleModal from "@/components/modals/CustomValidationExampleModal.vue";

@Component({
  components: {CustomValidationExampleModal, WExpandableCard}
})
export default class CustomFieldSimpleExample extends Base {

  examples = [
    {
      expression: '*',
      charDescription: this.t('forms.customFields.star'),
      description: this.t('forms.customFields.starDescription')
    },
    {
      expression: '+',
      charDescription: this.t('forms.customFields.plus'),
      description: this.t('forms.customFields.plusDescription')
    },
    {
      expression: '?',
      charDescription: this.t('forms.customFields.questionMarkShort'),
      description: this.t('forms.customFields.questionMarkDescription')
    },
    {
      expression: '[...]',
      description: this.t('forms.customFields.setDescription')
    },
    {
      expression: '[^...]',
      description: this.t('forms.customFields.notSetDescription')
    },
    {
      expression: '{n}',
      description: this.t('forms.customFields.charsExactDescription')
    },
    {
      expression: '{n,}',
      description: this.t('forms.customFields.charsAtLeastDescription')
    },
    {
      expression: '{n,m}',
      description: this.t('forms.customFields.charsBetweenDescription')
    },
    {
      expression: '|',
      charDescription: this.t('forms.customFields.pipe'),
      description: this.t('forms.customFields.pipeDescription')
    },
    {
      expression: '(...)',
      description: this.t('forms.customFields.groupDescription')
    },
  ];

  modalExamples = [
    {
      expression: '#*',
      description: this.t('forms.customFields.exampleModalTexts.anyDigitsDescription'),
      example: this.t('forms.customFields.exampleModalTexts.anyDigitsExample')
    },
    {
      expression: '#+',
      description: this.t('forms.customFields.exampleModalTexts.atLeastOneDigitDescription'),
      example: this.t('forms.customFields.exampleModalTexts.atLeastOneDigitExample')
    },
    {
      expression: '#?',
      description: this.t('forms.customFields.exampleModalTexts.optionalDigitDescription'),
      example: this.t('forms.customFields.exampleModalTexts.optionalDigitExample')
    },
    {
      expression: '%%#*',
      description: this.t('forms.customFields.exampleModalTexts.twoLettersAnyDigitsDescription'),
      example: this.t('forms.customFields.exampleModalTexts.twoLettersAnyDigitsExample')
    },
    {
      expression: '[a-zA-Z]',
      description: this.t('forms.customFields.exampleModalTexts.letterRegexDescription'),
    },
    {
      expression: '[4-6P]',
      description: this.t('forms.customFields.exampleModalTexts.charRangeDescription'),
    },
    {
      expression: '[JN]',
      description: this.t('forms.customFields.exampleModalTexts.charSet'),
    },
    {
      expression: '[^a-cA-C]',
      description: this.t('forms.customFields.exampleModalTexts.notCharsDescription'),
      example: this.t('forms.customFields.exampleModalTexts.notCharsExample')
    },
    {
      expression: '#{6}',
      description: this.t('forms.customFields.exampleModalTexts.digitsExactDescription'),
      example: this.t('forms.customFields.exampleModalTexts.digitsExactExample')
    },
    {
      expression: '#{4,6}',
      description: this.t('forms.customFields.exampleModalTexts.digitsBetweenDescription'),
      example: this.t('forms.customFields.exampleModalTexts.digitsBetweenExample')
    },
    {
      expression: '(#{4}|#{6})',
      description: this.t('forms.customFields.exampleModalTexts.digitsOrDescription'),
      example: this.t('forms.customFields.exampleModalTexts.digitsOrExample')
    },
    {
      expression: this.t('forms.customFields.exampleModalTexts.yesOrNoExpression'),
      description: this.t('forms.customFields.exampleModalTexts.yesOrNoDescription'),
    },
  ]

  showExampleDialog() {
    this.$bvModal.show("complex-validation-example");
  }

   showReservedModal() {
    this.$bvModal.show("reservedCharsModal");
  }
}
