



























import Base from "@/views/Base";
import {Component, Prop, Watch} from "vue-property-decorator";
import ElementCard from "@/views/presentation/components/ElementCard.vue";
import draggable from "vuedraggable";
import {DownloadListItem} from "@/models/Presentation";
import DownloadCard from "@/views/presentation/components/DownloadCard.vue";

@Component({
  components: {DownloadCard, ElementCard, draggable}
})
export default class PresentationDownloadList extends Base {
  @Prop({type: Number, default: 3}) columns!: number;
  @Prop({required: true}) value!: DownloadListItem[];

  items: DownloadListItem[] = [];
  dragging = false;

  created(): void {
    this.items = this.value;
  }

  @Watch('value', {immediate: true, deep: true})
  onItemsChanged(newItems: DownloadListItem[]): void {
    this.items = [...newItems];
  }

  updateItems(): void {
    this.dragging = false;
    this.$emit('input', this.items);
  }

}
