































import { EventBus, Events } from '@/EventBus';
import { BookingListItem } from '@/models/Booking';
import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import HistoricalService from '@/service/HistoricalService';
import { saveAs } from 'file-saver';
import { HistoricalMedia } from '@/models/api';

@Component
export default class WConfContext extends Base {
	@Prop() item!: BookingListItem;
	@Prop() type!: 'scheduled' | 'past' | 'room' | 'running';

	get isRunning(): boolean {
		return this.type === 'running';
	}

	get isScheduled(): boolean {
		return this.type === 'scheduled';
	}

	get isPast(): boolean {
		return this.type === 'past';
	}

	get isRoom(): boolean {
		return this.type === 'room';
	}

	get isDialOut(): boolean {
		return this.item.functions?.dialOut || false;
	}

	deleteItem(): void {
		EventBus.$emit(Events.DeleteConference, this.item);
	}

	editItem(): void {
		EventBus.$emit(Events.EditConference, this.item);
	}

	openItem(): void {
		EventBus.$emit(Events.ConferenceDetail, this.item);
	}

	repeatItem(): void {
		EventBus.$emit(Events.RepeatConference, this.item);
	}

	openCCP(): void {
		EventBus.$emit(Events.OpenCCP, this.item.id);
	}

	endConference(): void {
		EventBus.$emit(Events.EndConference, this.item.id);
	}

	openNationalNumbers(): void {
		this.$root.$emit('bv::show::modal', 'nationalNumbers');
	}

	startConference(): void {
		EventBus.$emit(Events.StartConference, this.item.id);
	}

	editOperator(): void {
		if (this.isRoom) {
			this.$router.push(
				`/operator/${this.item.operatorBookingId}?bookingId=${this.item.id}`
			);
		} else {
			this.$router.push(`/operator/${this.item.operatorBookingId}`);
		}
	}

	addOperator(): void {
		this.$router.push(`/operator/new?bookingId=${this.item.id}`);
	}

	downloadMedia(): void {
		if (this.item.id) {
			let file!: HistoricalMedia;
			let url!: string;
			HistoricalService.getMediaList(this.item.id)
				.then(list => {
					if (list.length > 0 && list[0].filename.includes('mp3') && this.item.id) {
						file = list[0];
						url = HistoricalService.getMediaFileUrl(this.item.id, file.filename);
						if (this.item.id && file && url) {
							HistoricalService.downloadMediaFile(url)
								.then(blob => saveAs(blob, file.filename))
								.catch(this.showNetworkError);
						}
					} else {
						this.toast(this.$t('common.noFile') as string, 'primary');
					}
				})
				.catch(this.showNetworkError);
		}
	}
}
