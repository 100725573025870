





















import { Component, Prop, Watch } from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import { SurveyQuestionOption } from '@/models/Survey';

@Component({
	components: { WSwitch }
})
export default class QuestionField extends Base {
	@Prop({ default: () => ({ caption: '', rightAnswer: false }) })
	value!: SurveyQuestionOption;
	@Prop() label!: string;
	@Prop() type!: string;
	@Prop() disabled!: boolean;
	@Prop() validation!: any;

	@Watch('value.rightAnswer')
	rightAnswerChange(newValue: boolean, oldValue: boolean): void {
		if (!oldValue && newValue && this.type === 'RADIO') {
			this.$emit('answerRightChanged');
		}
	}

	@Watch('value.caption')
	captionChanged(): void {
		this.validation.$touch();
	}

	validateState(): boolean | null {
		const validate = this.validation.caption;
		return validate.$dirty ? !validate.$error : null;
	}
}
