









































































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import Base from '@/views/Base';
import WPagination from '@/components/WPagination.vue';
import {ListOptions} from '@/models/Booking';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import SurveyService from '@/service/SurveyService';
import {Validations} from 'vuelidate-property-decorators';
import {required, requiredIf} from 'vuelidate/lib/validators';
import {Validation} from 'vuelidate';
import {
  FormMail,
  Survey,
  SurveyCustomValidatedFieldResponse,
  SurveyResponse,
  SurveyResult,
  SurveyResultTableRow
} from '@/models/Survey';
import UserModal from './components/UserModal.vue';
import {createSingleResponseXLSX} from '@/utils/excel';
import ResponseModal from '@/views/forms/components/ResponseModal.vue';
import {saveAs} from 'file-saver';
import {formatDateCreated, formatDateForFiles} from '@/utils/filters';
import {quillEditor} from 'vue-quill-editor';
import CertificateMailModal from '@/components/modals/CertificateMailModal.vue';
import {validateEmail, validateVvId} from '@/utils/validators';
import {FormCustomField} from "@/models/CustomField";

@Component({
  components: {
    ResponseModal,
    WListOptions,
    WPagination,
    UserModal,
    quillEditor,
    CertificateModal: CertificateMailModal
  }
})
export default class SurveyItemTable extends Base {
  @Prop() survey!: Survey;

  sortBy = 'dateCreated';
  sortDesc = true;

  allSelected = false;
  editIds: string[] = [];

  tableOptions: ListOptions = {
    entryCount: 0,
    searchTerm: ''
  };

  loadingCertificate = false;
  loadingSendCertificate = false;

  currentPage = 1;
  perPage = 10;
  totalRows = 0;

  fields = [
    {
      key: 'selected',
      label: '',
      thStyle: {width: '35px'},
      class: ' va-middle'
    },
    {
      key: 'dateCreated',
      label: this.t('forms.registration'),
      thStyle: {width: '170px'},
      sortable: true,
      class: 'va-middle'
    },
    {
      key: 'name',
      label: this.t('common.name'),
      sortable: true,
      sortDirection: 'desc',
      tdClass: 'truncate'
    },
    {
      key: 'hasMail',
      label: this.t('common.email'),
      sortable: false,
      thStyle: {width: '45px'}
    },
    {
      key: 'points',
      label: this.t('common.points'),
      sortable: true,
      tdClass: 'truncate',
      thStyle: {width: '95px', paddingRight: '0'}
    },
    {
      key: 'minutes',
      label: this.t('common.minutesLong'),
      sortable: true,
      tdClass: 'truncate',
      thStyle: {width: '90px', paddingRight: '0'}
    },
    {
      key: 'actions',
      label: this.t('common.actions'),
      sortable: false,
      thStyle: {width: '90px'}
    }
  ];

  files: File[] = [];

  results: SurveyResultTableRow[] = [];

  currentResponse: SurveyResponse | null = null;
  mail: FormMail = {
    replyTo: '',
    subject: '',
    text: '',
    attachments: [],
    enabled: true
  };
  mailTested = false;

  loadingUsers = false;

  selectedRow: SurveyResultTableRow | null = null;

  // number of up-to-date certs
  numberOfUTDCerts = 0;
  totalPassed = 0;

  get totalPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  get showPagination(): boolean {
    return this.perPage <= this.totalRows;
  }

  mounted(): void {
    this.loadingUsers = true;
    this.getUsers();
  }

  @Validations()
  validations(): any {
    return {
      currentResponse: {
        firstname: {required: requiredIf(() => this.survey?.participantDataToSave.name.required)},
        lastname: {required: requiredIf(() => this.survey?.participantDataToSave.name.required)},
        email: {
          required: requiredIf(() => this.survey?.participantDataToSave.email.required),
          email: validateEmail
        },
        phone: {required: requiredIf(() => this.survey?.participantDataToSave.phone.required)},
        salutation: {required: requiredIf(() => this.survey?.participantDataToSave.salutation.required)},
        addressStreet: {required: requiredIf(() => this.survey?.participantDataToSave.address.required)},
        addressCity: {required: requiredIf(() => this.survey?.participantDataToSave.address.required)},
        company: {required: requiredIf(() => this.survey?.participantDataToSave.company.required)},
        partnerId: {required: requiredIf(() => this.survey?.participantDataToSave.partnerId.required)},
        comment: {required: requiredIf(() => this.survey?.participantDataToSave.comment.required)},
        vvId: {
          required: requiredIf(() => this.survey?.participantDataToSave.vvId.required),
          validateVvId: validateVvId
        },
        customValidatedFieldValues: {
          $each: {
            value: {
              required: requiredIf((field: SurveyCustomValidatedFieldResponse) => this.findCustomField(field.fieldId)?.required),
              regex: (value: string, vm: SurveyCustomValidatedFieldResponse) => {
                if (!value || value == "") {
                  return true;
                }
                const field = this.findCustomField(vm.fieldId);
                if (field && (field.regex && field.regex != "")) {
                  const regexPattern = new RegExp("^" + field.regex + "$", field.ignoreCase ? "i" : "");
                  return regexPattern.test(value);
                }
                return true;
              },
              length: (value: string, vm: SurveyCustomValidatedFieldResponse) => {
                if (value == undefined || value == "") {
                  return true;
                }
                const field = this.findCustomField(vm.fieldId);
                if (field?.maxLength != undefined && !(field.maxLength <= 0)) {
                  return !(value.length > field.maxLength);
                }
                return true;
              }
            }
          }
        },
        customUrlParams: {
          $each: {
            key: {},
            value: {}
          }
        }
      },
      mail: {
        replyTo: {required, email: validateEmail},
        subject: {required},
        text: {required}
      }
    };
  }

  getUsers(): void {
    this.editIds = [];
    if (this.survey && this.survey.id) {
      SurveyService.getResults(this.survey.id)
          .then((res) => {
            this.totalPassed = 0;
            if (
                !this.survey.certificateData.participationDurationCondition &&
                this.fields[5].key === 'minutes'
            )
              this.fields.splice(5, 1);

            if (res.length > 0) {
              this.results = res.map((s, i) => {
                if (this.hasPassed(s)) this.totalPassed++;
                return SurveyService.resultToRow(s, i);
              });
              this.totalRows = this.results.length;
            } else {
              this.results = [];
              this.totalRows = 0;
            }
            this.$emit('updateCertCount', {
              totalPassed: this.totalPassed,
              totalRows: this.totalRows
            });
          })
          .catch(this.showNetworkError)
          .finally(() => (this.loadingUsers = false));
    }
  }

  tableFiltered(e: any[]): void {
    this.currentPage = 1;
    this.totalRows = e.length;
  }

  @Watch('files')
  filesChanged(): void {
    if (this.mailTested) {
      this.mailTested = false;
      if (this.mail.attachments) {
        this.mail.attachments = [];
      }
    }
  }

  get editMode(): boolean {
    return this.editIds.length > 0;
  }

  get form(): Survey {
    return this.survey;
  }

  async editUser(responseId: number): Promise<void> {
    if (!this.survey.id) return;
    const resp = await SurveyService.getResponse(this.survey.id, responseId);
    if (this.survey.participantDataToSave.address.required) {
      resp.addressStreet = resp.addressStreet || '';
      resp.addressCity = resp.addressCity || '';
    }
    if (this.survey.participantDataToSave.name.required) {
      resp.firstname = resp.firstname || '';
      resp.lastname = resp.lastname || '';
    }
    if (this.survey.participantDataToSave.company.required) {
      resp.company = resp.company || '';
    }
    if (this.survey.participantDataToSave.phone.required) {
      resp.phone = resp.phone || '';
    }
    if (this.survey.participantDataToSave.email.required) {
      resp.email = resp.email || '';
    }
    if (this.survey.participantDataToSave.comment.required) {
      resp.comment = resp.comment || '';
    }
    if (this.survey.participantDataToSave.partnerId.required) {
      resp.partnerId = resp.partnerId || '';
    }
    if (this.survey.participantDataToSave.vvId.required) {
      resp.vvId = resp.vvId || '';
    }
    if (this.survey.participantDataToSave.salutation.required) {
      resp.salutation = resp.salutation || '';
    }
    this.currentResponse = resp;
    if (!this.currentResponse.customValidatedFieldValues) {
      this.currentResponse.customValidatedFieldValues = [];
    }
    if (!this.survey.customFields) {
      this.survey.customFields = [];
    }
    this.survey.customFields.forEach(field => {
      if (!this.currentResponse?.customValidatedFieldValues?.find(i => i.fieldId === field.id)) {
        // Fill not existing custom fields with empty strings
        this.currentResponse?.customValidatedFieldValues?.push({
          fieldId: field.id,
          value: ""
        })
      }
    });
    this.$bvModal.show('response-modal');
  }

  cancelEditUser(): void {
    this.$bvModal.hide('response-modal');
    setTimeout(() => {
      this.currentResponse = null;
      this.$v.currentResponse.$reset();
    }, 333);
  }

  hasPassedByPoints(row: SurveyResultTableRow | SurveyResult): boolean {
    return this.survey.certificateData.multipleChoiceCreditsCondition
        ? !!row.passed
        : true;
  }

  hasPassedByParticipation(row: SurveyResultTableRow | SurveyResult): boolean {
    return this.survey.certificateData.participationDurationCondition
        ? !!row.participated
        : true;
  }

  hasPassed(row: SurveyResultTableRow | SurveyResult): boolean {
    return this.hasPassedByPoints(row) && this.hasPassedByParticipation(row);
  }

  submitEditUser(ok: () => void): void {
    if (!this.survey.id || !this.currentResponse) return;
    SurveyService.updateResponse(this.survey.id, this.currentResponse.id, this.currentResponse)
        .then(() => {
          this.toast(this.t('forms.userEdited'), 'success');
          this.getUsers();
          this.currentResponse = null;
          this.$v.currentResponse.$reset();
          ok();
          this.$emit('updateSurvey');
        })
        .catch(this.showNetworkError);
  }

  deleteUser(id: string): void {
    this.$bvModal
        .msgBoxConfirm(
            this.$t('modals.deleteParticipant.description', {name: ''}) as string,
            {
              title: this.t('modals.deleteParticipant.title'),
              centered: true,
              okVariant: 'danger',
              okTitle: this.t('modals.deleteParticipant.ok'),
              cancelTitle: this.t('common.cancel')
            }
        )
        .then(res => {
          if (res && this.survey.id) {
            SurveyService.deleteResponse(this.survey.id, id).then(() => {
              this.toast(this.t('modals.deleteParticipant.deleted'), 'success');
              this.getUsers();
              this.editIds = [];
            });
          }
        })
        .catch(this.showNetworkError);
  }

  deleteSelectedIds(): void {
    this.$bvModal
        .msgBoxConfirm(
            this.$t('modals.deleteParticipants.description', {
              count: this.editIds.length
            }) as string,
            {
              title: this.t('modals.deleteParticipants.title'),
              centered: true,
              okVariant: 'danger',
              okTitle: this.t('modals.deleteParticipants.title'),
              cancelTitle: this.t('common.cancel')
            }
        )
        .then((res) => {
          if (res) {
            Promise.allSettled(
                this.editIds.map((id) => {
                  if (this.survey.id)
                    return SurveyService.deleteResponse(this.survey.id, id);
                })
            ).then(() => {
              this.toast(this.t('modals.deleteParticipants.deleted'), 'success');
              this.getUsers();
              this.editIds = [];
            });
          }
        })
        .catch(this.showNetworkError);
  }

  openMailModal(): void {
    // this.$bvModal.show('mailModal');
  }

  openCertificateModal(row: SurveyResultTableRow): void {
    this.mail.subject =
        this.t('forms.certificateAtThe') +
        ' ' +
        formatDateCreated(row.response.dateCreated);
    this.selectedRow = row;
    this.$bvModal.show('certificate-modal');
  }

  downloadSingleResult(result: SurveyResult): void {
    createSingleResponseXLSX(this.survey, result, this);
  }

  downloadSingleCertificate(response: SurveyResponse): void {
    if (!this.survey.id) return;
    this.loadingCertificate = true;
    SurveyService.downloadCertificate(this.survey.id, response.id)
        .then(certificate => {
          let filename = `Zertifikat_${formatDateForFiles(response.dateCreated)}`;
          filename += response.firstname && response.lastname
              ? `_${response.firstname}-${response.lastname}.pdf`
              : '';
          saveAs(certificate, filename);
        })
        .catch(e => this.showNetworkError(e))
        .finally(() => this.loadingCertificate = false);
  }

  async sendCertificate(res: {
    ok: () => void;
    mail: FormMail;
  }): Promise<void> {
    if (this.survey.id && this.selectedRow !== null) {
      this.loadingSendCertificate = true;
      try {
        await SurveyService.sendCertificate(
            this.survey.id,
            this.selectedRow.response.id,
            res.mail
        );
        this.toast(this.t('forms.certificateSent'), 'success');
        this.selectedRow.certificateSent = true;
        res.ok();
        this.resetMail();
        this.$emit('updateSurvey');
      } catch (error: any) {
        this.showNetworkError(error);
      } finally {
        this.loadingSendCertificate = false;
      }
    }
  }

  resetMail(): void {
    this.mail = {
      replyTo: '',
      subject: '',
      text: '',
      attachments: [],
      enabled: true
    };
    this.editIds = [];
    this.selectedRow = null;
  }

  formatNames(files: File[]): string {
    return files.length === 1
        ? files[0].name
        : `${files.length} files selected`;
  }

  validateState(objectName: string, name: string): boolean | null {
    const validate = this.$v[objectName][name] as Validation;
    return validate.$dirty ? !validate.$error : null;
  }

  // Table
  rowClass(item: any): string {
    let cssClass = '';
    if (item && item.selected) {
      cssClass += 'table-primary';
    }
    return cssClass + ' w-table no-select';
  }

  rowClicked(item: any, index: number, event: any): void {
    if (event.target.dataset.key !== 'actions') {
      this.rowSelected(!item.selected, item, event.shiftKey);
    }
  }

  get displayMinutes(): string {
    if (this.survey)
      return this.survey.certificateData.participationDurationCondition
          ? ''
          : 'd-none';
    else return '';
  }

  private findCustomField(fieldId: number): FormCustomField | undefined {
    const field = this.survey.customFields.find(i => i.id === fieldId);
    if (!field) {
      console.log(`Field with id ${fieldId} does not exist, but there is a field with the id`);
    }
    return field;
  }

  rowSelected(value: boolean, item: any, shiftKey?: boolean): void {
    if (item.selected !== value) item.selected = value;
    if (value) {
      if (item.id) this.editIds.push(item.id);
    } else {
      this.editIds = this.editIds.filter((id) => id !== item.id);
    }
    if (shiftKey && value) {
      this.selectBetween(item, value);
    }
  }

  selectAll(value: boolean): void {
    this.results.forEach((item) => {
      if (item.selected !== value) {
        this.rowSelected(value, item);
      }
    });
    if (!value) this.editIds = [];
  }

  selectBetween(item: any, value: boolean): void {
    const index = this.results.findIndex((row) => item.id === row.id);
    const selectedIndex = this.results.findIndex(
        (row) => row.selected === value && row.id !== item.id
    );
    let startIndex = 0,
        endIndex = 0;
    if (index < selectedIndex) {
      startIndex = index;
      endIndex = selectedIndex;
    } else {
      startIndex = selectedIndex === -1 ? 0 : selectedIndex;
      endIndex = index;
    }
    this.results.slice(startIndex, endIndex).forEach((item) => {
      if (item.selected !== value) {
        this.rowSelected(value, item);
      }
    });
  }
}
