
























































import { Validations } from 'vuelidate-property-decorators';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BNavItemDropdown } from 'bootstrap-vue';
import { maxLength } from 'vuelidate/lib/validators';

@Component
export default class WCostCenter extends Vue {
	@Prop() value!: string;
	@Prop({ default: false, type: Boolean }) noDelete!: boolean;

	costCenters = [
		{ text: this.$t('conference.noCostCenter'), value: '', undeletable: true }
	];
	newCostCenter = '';
	selectedCostCenter = '';

	@Validations()
	validations = {
		newCostCenter: {maxLength: maxLength(64)}
	};

	mounted(): void {
		const noCostCenter = this.costCenters[0];
		this.costCenters = [
			noCostCenter,
			...this.value
				.split('|')
				.filter((v) => v)
				.map((v) => ({ text: v, value: v, undeletable: false }))
		];
		this.init();
	}

	@Watch('value')
	init(): void {
		const value = this.value.split('|')[0];
		if (!this.costCenters.map((cc) => cc.value).includes(value)) {
			this.costCenters.push({ text: value, value: value, undeletable: false });
		}
		this.selectedCostCenter = value;
	}

	addCostCenter(): void {
		if (this.newCostCenter !== '') {
			this.costCenters.push({
				text: this.newCostCenter,
				value: this.newCostCenter,
				undeletable: false
			});
			this.$v.newCostCenter.$reset();
			this.selectedCostCenter = this.newCostCenter;
			this.newCostCenter = '';
			this.save();
			setTimeout(() => {
				(this.$refs['costCenterDropdown'] as BNavItemDropdown).hide(true);
			}, 100);
		}
	}

	deleteCostCenter(value: string): void {
		this.costCenters = this.costCenters.filter((cc) => cc.value !== value);
		if (this.selectedCostCenter === value) {
			this.selectedCostCenter = '';
		}
		this.save();
	}

	save(): void {
		setTimeout(() => {
			if (this.value || this.costCenters.length > 1) {
				const filteredCostCenter = this.costCenters
					.filter((cc, i) => cc.value !== this.selectedCostCenter && i > 0)
					.map((cc) => cc.value);
				this.$emit(
					'input',
					[this.selectedCostCenter, ...filteredCostCenter].join('|')
				);
			}
		});
	}
}
