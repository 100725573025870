





























































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { Component, Watch } from 'vue-property-decorator';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import OperatorService from '@/service/OperatorService';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import { Operator } from '@/models/Operator';
import {
	formatNumberString
} from '@/utils/filters';
import { SelectOption } from '@/models/common';
import OperatorModal from '@/components/modals/OperatorModal.vue';
import { abbr } from '@/utils/filters';
import AuthenticationService from '@/service/AuthenticationService';
import {months} from '@/utils/date'

@Component({
	components: {
		HeaderLayout,
		WHeaderBtn,
		WListOptions,
		WPagination,
		OperatorModal
	},
  i18n: {
    messages: {
      en: {
        common: {
          duration: 'Dur.',
        }
      }
    }
  }
})
export default class Operators extends Base {
	operators: Operator[] = [];

	fields = [
		{
			key: 'start' as keyof Operator,
			label: this.t('common.appointment'),
			sortable: true,
			thStyle: { paddingLeft: '1.75rem', width: '164px' }
		},
		{
			key: 'duration' as keyof Operator,
			label: this.t('common.duration'),
			sortable: true,
			thStyle: { width: '80px' }
		},
		{
			key: 'durationPreMeeting' as keyof Operator,
			label: this.t('common.preAppointmentShort'),
			thStyle: { width: '80px' }
		},
		{
			key: 'customerNumber' as keyof Operator,
			label: this.t('admin.customerNumberShort'),
			thStyle: { width: '70px' }
		},
		{
			key: 'company' as keyof Operator,
			label: this.t('common.company'),
			tdClass: 'truncate'
		},
		{
			key: 'registeredNumber' as keyof Operator,
			label: this.t('common.registered'),
			sortable: true,
			thStyle: { width: '110px' }
		},
		{
			key: 'expectedNumber' as keyof Operator,
			label: this.t('common.expected'),
			sortable: true,
			thStyle: { width: '110px' }
		},
		{
			key: 'admins' as keyof Operator,
			label: this.t('conference.operatorShort'),
			sortable: true,
			thStyle: { width: '60px' }
		},
		{
			key: 'pin' as keyof Operator,
			label: this.t('conference.pin'),
			thStyle: { width: '75px' }
		},
		{
			key: 'type' as keyof Operator,
			label: this.t('common.type'),
			thStyle: { width: '180px' }
		},
		{
			key: 'action' as keyof Operator,
			label: this.t('common.action'),
			thStyle: { width: '90px' }
		}
	];

	abbr = abbr;
	selectedService: Operator | null = null;

	get time(): string {
		return `${this.options.month},${this.options.year}`;
	}

	set time(value: string) {
		this.options.month = +value.split(',')[0];
		this.options.year = +value.split(',')[1];
	}

	options = {
		entryCount: 100,
		searchTerm: '',
		currentPage: 1,
		totalRows: 0,
		year: new Date().getFullYear(),
		month: new Date().getMonth()
	};

	currentPage = 1;
	sortDesc = true;
	sortBy = 'start';

	statistics = {
		planned: 0,
		passed: 0
	};

	// calculate +-9 months
	get timeOptions(): SelectOption[] {
		const now = new Date();
		const month = now.getMonth();
		const year = now.getFullYear();
		const options = [];
    const monthList = months(this)
		for (let i = month - 6; i < month; i++) {
			const newMonth = (i + 12) % 12;
			const newYear = i < 0 ? year - 1 : year;
			options.push({
				text: `${monthList[newMonth]} ${newYear}`,
				value: newMonth + ',' + newYear
			});
		}
		for (let i = month; i < month + 18; i++) {
			const newMonth = (i + 12) % 12;
			const newYear = i > 11 ? year + 1 : year;
			options.push({
				text: `${monthList[newMonth]} ${newYear}`,
				value: newMonth + ',' + newYear
			});
		}
		return options;
	}

	get perPage(): number {
		return this.options.entryCount;
	}

	set perPage(count: number) {
		this.options.entryCount = count;
	}

	get totalPages(): number {
		return Math.ceil(this.totalRows / this.perPage);
	}

	get totalRows(): number {
		return this.options.totalRows;
	}

	get showPagination(): boolean {
		return this.perPage <= this.totalRows;
	}

	formatNumberString = formatNumberString;

	@Watch('time')
	timeChanged(): void {
		this.getServices();
		this.getStatistics();
	}

	@Watch('options.searchTerm')
	searchChanged(): void {
		this.getServices();
	}

	@Watch('sortBy')
	sortByChanged(): void {
		this.getServices();
	}

	@Watch('sortDesc')
	sortDescChanged(): void {
		this.getServices();
	}

	@Watch('currentPage')
	currentPageChanged(): void {
		this.getServices();
	}

	@Watch('options.entryCount')
	entryCountChanged(): void {
		this.getServices();
	}

	mounted(): void {
		this.getServices();
		this.getStatistics();
	}

	private getServices() {
		OperatorService.getAdminOperatorServices(
			this.currentPage - 1,
			this.perPage,
			this.sortBy,
			this.sortDesc ? 'DESC' : 'ASC',
			this.options.year,
			this.options.month + 1,
			this.options.searchTerm
		).then((obj) => {
			if (!obj.empty) {
				this.operators = obj.content;
				this.options.totalRows = obj.totalSize;
			} else {
				this.operators = [];
			}
		});
	}

	private getStatistics() {
    console.log('Get stats')
		OperatorService.getStatistics(
			this.options.year,
			this.options.month + 1
		).then((stats) => (this.statistics = stats));
	}

	openOperatorModal(item: Operator): void {
		this.selectedService = item;
		setTimeout(() => {
			this.$bvModal.show('operator-modal');
		});
	}

	deleteService(id: number): void {
		OperatorService.deleteAdminOperatorService(id)
			.then(() => {
				const idx = this.operators.findIndex((op) => op.id === id);
				this.operators.splice(idx, 1);
				this.toast('Operatorbuchung erfolgreich storniert!', 'success');
			})
			.catch(this.showNetworkError);
	}

	adminTooltip(item: Operator): string {
		const admins = item.admins;
		return !admins
			? ''
			: `
      ${admins[0].firstname} ${admins[0].lastname} <br>
      ${admins[0].phone} <br>
      ${admins[0].email}
    `;
	}

	loginAs(item: any): void {
		AuthenticationService.impersonate({
			parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
			childUsername: item.username
		})
			.then(() => {
				this.$router.push('/');
			})
			.catch(this.showNetworkError);
	}

	mailTo(operator: Operator): void {
		const subject = encodeURI(
			`Operator-Service am ${new Date(operator.start).toLocaleString([], {
				dateStyle: 'short',
				timeStyle: 'short'
			})}`
		);
		let mail = 'info@csn.de';
		let body = 'Sehr geehrtes Operator-Team,';
		if (operator.admins && operator.admins.length > 0) {
			const admin = operator.admins[0];
			mail = operator.admins[0].email;
			body = encodeURI(`Sehr geehrte*r ${admin.firstname} ${admin.lastname},`);
		}
		window.open(`mailto:${mail}?subject=${subject}&body=${body}`);
	}

	mailToOrganizer(operator: Operator): void {
		const subject = encodeURI(
			`Operator-Service am ${new Date(operator.start).toLocaleString([], {
				dateStyle: 'short',
				timeStyle: 'short'
			})}`
		);
		let mail = 'info@csn.de';
		let body = 'Sehr geehrte*r Veranstalter*in,';
		mail = operator.organizerEmail;
		body = encodeURI(`Sehr geehrte*r ${operator.organizerName},`);

		window.open(`mailto:${mail}?subject=${subject}&body=${body}`);
	}
}
