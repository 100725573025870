














































import { Component, Prop } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '@/views/Base';
import { RegistrationUser } from '@/models/Registration';
import { validateEmail } from '@/utils/validators';

@Component
export default class ImportItem extends Base {
	@Prop() item!: RegistrationUser;

	@Validations()
	validations = {
		item: {
			lastname: { required },
			phone: {
				required,
				noLetters: ContactsService.noLettersValidator,
				format: this.phoneValidation
			},
			email: { required, email: validateEmail },
			company: {}
		}
	};

	created(): void {
		this.$v.item.$touch();
	}

	focus(): void {
		this.$v.item.phone?.$reset();
	}

	submit(): void {
		if (!this.$v.item.$invalid) {
			this.item.valid = true;
			this.$emit('submit');
		}
	}

	phoneValidation(): Promise<boolean> | boolean {
		const phone = this.$v.item.phone;
		if (phone?.$dirty) {
			return ContactsService.validatePhoneNumbers([this.item.phone])
				.then((validatedNumbers) => {
					const validNumber = validatedNumbers[0];
					const phoneIsValid = validNumber.validNumber;
					if (phoneIsValid) {
						this.item.phone = validNumber.international;
					}
					return phoneIsValid;
				})
				.catch((err) => {
					this.showNetworkError(err);
					return false;
				});
		}
		return false;
	}

	validateState(val: string): boolean | null {
		const validate: any = this.$v.item[val];
		return validate.$dirty ? !validate.$error : null;
	}
}
