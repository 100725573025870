


























































































































































































































































































import {Component, Prop} from 'vue-property-decorator';
import Base from '@/views/Base';
import WFunctions from '@/components/WFunctions.vue';
import WPagination from '@/components/WPagination.vue';
import {ListOptions} from '@/models/Booking';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import {Email, Historical, HistoricalParticipant} from '@/models/api';
import HistoricalService from '@/service/HistoricalService';
import GroupsService from '@/service/GroupsService';
import {ContactGroup} from '@/models/Group';
import ContactsService from '@/service/ContactsService';
import {durationStr, formatNames, formatStatus} from '@/utils/filters';
import {Validation} from 'vuelidate';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import RegistrationService from '@/service/RegistrationService';
import BookingService from '@/service/BookingService';
import BrandingService from '@/service/BrandingService';
import {SelectOption} from '@/models/common';
import {validateEmail} from '@/utils/validators';

@Component({
	components: {
		WFunctions,
		WListOptions,
		WPagination
	}
})
export default class ConferencePastItemTable extends Base {
	@Prop() historical!: Historical;

	sortBy = '';
	sortDesc = false;

	allSelected = false;
	editIds: string[] = [];

	tableOptions: ListOptions = {
		entryCount: 0,
		searchTerm: ''
	};

	loading = false;

	currentPage = 1;
	perPage = 9999;

	files: File[] = [];
	mail: Email = {
		replyTo: '',
		subject: '',
		text: '',
		attachments: [],
		brandingId: null
	};
	mailTested = false;
	sendingMail = false;
	sendingTestMail = false;
	brandingOptions: SelectOption[] = [];

	fields = [
		{
			key: 'selected',
			label: this.t('common.select'),
			thStyle: { width: '35px' },
			class: ' va-middle'
		},
		{
			key: 'start',
			label: this.t('common.start'),
			thStyle: { width: '80px' },
			sortable: true,
			sortDirection: 'desc',
			tdClass: 'truncate'
		},
		{
			key: 'duration',
			label: this.t('common.duration'),
			thStyle: { width: '90px' },
			sortable: true,
			sortDirection: 'desc',
			tdClass: 'truncate'
		},
		{
			key: 'name',
			label: this.t('common.name'),
			sortable: true,
			sortDirection: 'desc',
			tdClass: 'truncate'
		},
		{
			key: 'telephone',
			label: this.t('common.phone'),
			sortable: true,
			tdClass: 'truncate'
		},
		// { key: 'email', label: 'E-Mail', thStyle: {width: '250px'}, sortable: true, tdClass: 'truncate'},
		{
			key: 'mod',
			label: this.t('conference.moderatorShort'),
			thStyle: { width: '55px' },
			sortable: true
		},
		{
			key: 'functions',
			label: this.t('common.type'),
			thStyle: { width: '80px' },
			class: 'va-middle'
		},
		{
			key: 'status',
			label: this.t('common.status'),
			thStyle: { width: '100px', paddingRight: '0' },
			sortable: true,
			tdClass: 'pr-0'
		}
	];

	participants: any[] = [];

	loadingGroups = false;
	groups: ContactGroup[] = [];

	@Validations()
	validations = {
		mail: {
			replyTo: { required, email: validateEmail },
			subject: { required },
			text: { required }
		}
	};

	mounted(): void {
		if (this.historical && this.historical.id) {
			this.loading = true;
			Promise.all([
				BookingService.getBooking(this.historical.bookingId),
				HistoricalService.getHistoricalParticipants(this.historical.id)
			]).then((res) => {
				this.participants = res[1]
					.map((p) => {
						const idxExists = res[0].participants?.find(
							(oldParticipant) =>
								oldParticipant.phone === p.phone.split(' ').join('')
						);
						return {
							...this.participantToRow(p),
							idx: idxExists ? idxExists.idx : undefined
						};
					})
					.sort(BookingService.sortPastParticipants);
				this.loading = false;
			});
			this.getGroups();
			this.getBrandings();
		}
	}

	getGroups(): void {
		this.loadingGroups = true;
		GroupsService.getGroups()
			.then(groups => {
				groups.forEach(group => group.numberOfContact = group.numberOfContact ? group.numberOfContact : 0);
				this.groups = groups;
				this.loadingGroups = false;
			})
			.catch(err => console.error(err.message));
	}

	getBrandings(): void {
		BrandingService.getBrandings().then((res) => {
			this.brandingOptions = [];
			res.map((branding) => {
				this.brandingOptions.push({
					text: branding.title,
					value: branding.id
				});
			});
			this.brandingOptions.unshift({
				text: this.t('forms.noBranding'),
				value: null
			});
		});
	}

	toggleAll(groupId?: string): void {
		const contacts = this.participants.map((p) => ({
			name: p.name,
			telephone: p.telephone,
			company: p.company,
			groupId,
			email: p.email || ''
		}));
		ContactsService.addContacts(contacts)
			.then(() => this.toast(this.t('adressbook.addedSuccessfully'), 'success'))
			.catch(this.showNetworkError);
	}

	toggleSelected(groupId?: string): void {
		const contacts = this.participants
			.filter((p) => this.editIds.includes(p.id))
			.map((p) => ({
				name: p.name,
				telephone: p.telephone,
				company: p.company,
				groupId,
				email: p.email || ''
			}));
		ContactsService.addContacts(contacts)
			.then(() => this.toast(this.t('adressbook.addedSuccessfully'), 'success'))
			.catch(this.showNetworkError);
	}

	private participantToRow(participant: HistoricalParticipant) {
		const date = new Date(participant.beginDate);
		const duration = durationStr(participant.duration);
		return {
			id: participant.id + '',
			selected: false,
			name: participant.name,
			telephone: participant.phone,
			duration,
			start:
				duration === '-'
					? '-'
					: date.toLocaleTimeString(undefined, { timeStyle: 'short' }),
			type: {
				dialIn: participant.type === 'DIALIN',
				dialOut: participant.type === 'DIALOUT',
				microphone: null
			},
			status: formatStatus(participant.status, this),
			mod: participant.role === 'MODERATOR'
		};
	}

	filter(row: any, searchTerm: string): any {
		const numberSearchTerm = searchTerm
			.replace(' ', '')
			.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '')
			.replace('-', '')
			.replace('_', '');
		let term = searchTerm.toLowerCase();
		if (numberSearchTerm.startsWith('00')) term = numberSearchTerm.substring(2);
		else if (numberSearchTerm.startsWith('0')) term = numberSearchTerm.substring(1);
		return !!(
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
        || row.telephone.includes(term)
        || row.telephone.replaceAll(' ', '').includes(term)
		);
	}

	// Table
	rowClass(item: any): string {
		let cssClass = '';
		if (item && item.selected) {
			cssClass += 'table-primary';
		}
		return cssClass + ' w-table';
	}

	resetMail(): void {
		this.mail = {
			replyTo: '',
			subject: '',
			text: '',
			attachments: [],
			brandingId: null
		};
		this.files = [];
		this.$v.mail.$reset();
	}

	openMailModal(): void {
		this.$bvModal.show('mailModal');
	}

	validateState(objectName: string, name: string): boolean | null {
		const validate = this.$v[objectName][name] as Validation;
		return validate.$dirty ? !validate.$error : null;
	}

	formatNames = formatNames;

	sendTestMail(): void {
		if (!this.$v.mail.$invalid) {
			this.sendingTestMail = true;
			this.uploadMailFiles().then(() => this.sendTestEmailToParticipants());
		}
	}

	private sendTestEmailToParticipants() {
		if (this.historical.id) {
			HistoricalService.sendTestEmailToParticipants(
				+this.historical.id,
				this.mail,
				this.editIds
			)
				.then(() => {
					this.sendingTestMail = false;
					this.toast(this.t('forms.sendMailSuccess'), 'success');
					this.mailTested = true;
				})
				.catch(this.showNetworkError);
		}
	}

	sendMail(ok: () => void): void {
		this.sendingMail = true;
		if (!this.$v.mail.$invalid) {
			this.uploadMailFiles().then(() => {
				this.sendEmailToParticipants(ok);
			});
		}
	}

	private sendEmailToParticipants(ok: () => void) {
		if (this.historical.id) {
			HistoricalService.sendEmailToParticipants(
				+this.historical.id,
				this.mail,
				this.editIds
			)
				.then(() => {
					this.toast(this.t('forms.sendMailSuccess'), 'success');
					this.resetMail();
					this.sendingMail = false;
					ok();
				})
				.catch(this.showNetworkError);
		}
	}

	private uploadMailFiles(): Promise<void | void[]> {
		this.mail.attachments = [];
		return Promise.all(
			this.files.map((file) =>
				RegistrationService.addFile(file)
					.then((value) => {
						if (this.mail.attachments) this.mail.attachments.push(value);
					})
					.catch((err) => console.log(file.name, err.message))
			)
		).catch(() => {
			this.toast(this.t('forms.fileNotUploaded'));
			this.mail.attachments = [];
			this.files = [];
		});
	}

	rowClicked(item: any, index: number, event: any): void {
		if (event.target.dataset.key === 'select') {
			this.rowSelected(!item.selected, item, event.shiftKey);
		}
	}

	rowSelected(value: boolean, item: any, shiftKey?: boolean): void {
		if (item.selected !== value) item.selected = value;
		if (value) {
			if (item.id) this.editIds.push(item.id);
		} else {
			this.editIds = this.editIds.filter((id) => id !== item.id);
		}
		if (shiftKey && value) {
			this.selectBetween(item, value);
		}
	}

	selectAll(value: boolean): void {
		for (let i = 0; i < this.participants.length; i++) {
			const item = this.participants[i];
			if (item.selected !== value) {
				this.rowSelected(value, item);
			}
		}
		if (!value) this.editIds = [];
	}

	selectBetween(item: any, value: boolean): void {
		const index = this.participants.findIndex((row) => item.id === row.id);
		const selectedIndex = this.participants.findIndex(
			(row) => row.selected === value && row.id !== item.id
		);
		let startIndex = 0,
			endIndex = 0;
		if (index < selectedIndex) {
			startIndex = index;
			endIndex = selectedIndex;
		} else {
			startIndex = selectedIndex === -1 ? 0 : selectedIndex;
			endIndex = index;
		}
		this.participants.slice(startIndex, endIndex).forEach((item) => {
			if (item.selected !== value) {
				this.rowSelected(value, item);
			}
		});
	}
}
