






































































































































































import WInlineEdit from '@/components/WInlineEdit.vue';
import WPagination from '@/components/WPagination.vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import { TableContact } from '@/models/Contact';
import { Group } from '@/models/Group';
import Base from '@/views/Base';
import {validateEmail} from "@/utils/validators";

@Component({
	components: { WInlineEdit, WPagination },
	filters: {}
})
export default class EditableTable extends Base {
	@Prop() rows!: TableContact[];
	@Prop() groups!: Group[];
	@Prop() loading!: boolean;
	@Prop() visibilities!: { mail: boolean; company: boolean };

	@PropSync('selectedIds') editIds!: string[];

	sortBy = '';
	sortDesc = false;

	required = required;
	email = validateEmail;

	allSelected = false;

	fields = [
		{
			key: 'id',
			label: this.t('common.select'),
			thStyle: { width: '70px' },
			sortable: true,
			class: 'pl-34 va-middle'
		},
		{
			key: 'name',
			label: this.t('common.name'),
			sortable: true,
			sortDirection: 'desc',
			tdClass: 'truncate'
		},
		{
			key: 'telephone',
			label: this.t('common.phone'),
			sortable: true,
			tdClass: 'truncate'
		},
		{
			key: 'email',
			label: this.t('common.email'),
			thStyle: {
				width: '150px',
				opacity: '1',
				whiteSpace: 'nowrap',
				padding: ''
			},
			sortable: true,
			tdClass: 'truncate'
		},
		{
			key: 'company',
			label: this.t('common.company'),
			thStyle: { width: '150px', opacity: '1', padding: '' },
			sortable: true,
			tdClass: 'truncate'
		},
		{
			key: 'groups',
			label: this.t('adressbook.groups'),
			thStyle: { width: '80px' },
			sortable: false
		},
		{
			key: 'actions',
			label: this.t('common.delete'),
			thStyle: { width: '55px' },
			class: 'va-middle',
			thClass: 'show-content-md'
		}
	];

	@Watch('sortBy')
	sortByChanged(): void {
		this.closeActiveInlineEdit();
		this.$emit('sortBy', this.sortBy);
	}

	@Watch('sortDesc')
	sortDescChanged(): void {
		this.closeActiveInlineEdit();
		this.$emit('sortDesc', this.sortDesc);
	}

	@Watch('visibilities.mail')
	mailVisibilitiesChanged(): void {
		this.fields.map(s => {
			if (s.key === 'email') {
				s.thStyle = this.visibilities.mail
					? { opacity: '1', width: '150px', whiteSpace: 'nowrap', padding: '' }
					: { opacity: '0', width: '0px', whiteSpace: 'nowrap', padding: '0' };
			}
			return s;
		});
	}

	@Watch('visibilities.company')
	companyVisibilitiesChanged(): void {
		this.fields.map(s => {
			if (s.key === 'company') {
				s.thStyle = this.visibilities.company
					? { opacity: '1', width: '150px', padding: '0' }
					: { opacity: '0', width: '0px', padding: '0' };
			}
			return s;
		});
	}

	beforeDestroy(): void {
		this.$store.commit('setActiveInlineEdit', '');
		this.$store.commit('setActiveEditInvalid', false);
	}

	setGroupValues(groupIds: string[], item: TableContact): void {
		if (!item.groups) {
			item.groups = [];
		}
		if (!groupIds) groupIds = [];
		const itemGroups = item.groups;
		if (groupIds.length > itemGroups.length) {
			const newId = groupIds.find(
				(id) => !itemGroups.map((g) => g.id).includes(id)
			);
			this.$emit('addGroup', { newId, item });
		} else {
			const oldId = itemGroups
				.map((g) => g.id)
				.find((id) => !groupIds.includes(id || ''));
			this.$emit('removeGroup', { oldId, item });
		}
		item.groups = this.groups.filter((group) =>
			groupIds.includes(group.id || '')
		);
	}

	rowClass(item: TableContact): string {
		let cssClass = '';
		if (item && item.selected) {
			cssClass += 'table-primary';
		}
		return cssClass + ' w-table';
	}

	inlinePhoneValidation(row: TableContact): any {
		return {
			required: [required, this.t('common.requiredInvalid')],
			phone: [this.phoneFormatValidator(row), this.t('common.phoneInvalid')]
		};
	}

	rowClicked(item: TableContact, index: number, event: any): void {
		if (event.target.dataset.label === this.t('common.select')) {
			this.rowSelected(!item.selected, item, event.shiftKey);
		}
	}

	rowSelected(value: boolean, item: TableContact, shiftKey?: boolean): void {
		if (item.selected !== value) item.selected = value;
		if (value) {
			if (item.id) this.editIds.push(item.id);
		} else {
			this.editIds = this.editIds.filter((id) => id !== item.id);
		}
		if (shiftKey && value) {
			this.selectBetween(item, value);
		}
	}

	selectAll(value: boolean): void {
		this.rows.forEach(item => {
			if (item.selected !== value) this.rowSelected(value, item);
		});
		if (!value) this.editIds = [];
	}

	selectBetween(item: TableContact, value: boolean): void {
		const index = this.rows.findIndex((row) => item.id === row.id);
		const selectedIndex = this.rows.findIndex(row => row.selected === value && row.id !== item.id);
		let startIndex = 0, endIndex = 0;
		if (index < selectedIndex) {
			startIndex = index;
			endIndex = selectedIndex;
		} else {
			startIndex = selectedIndex === -1 ? 0 : selectedIndex;
			endIndex = index;
		}
		this.rows.slice(startIndex, endIndex).forEach(item => {
			if (item.selected !== value) this.rowSelected(value, item);
		});
	}

	closeActiveInlineEdit(): void {
		if (this.activeInlineEdit !== null && this.activeInlineEdit !== '') {
			(this.$refs['w-edit-' + this.activeInlineEdit] as WInlineEdit).close();
		}
	}

	isEditable(row: any): boolean {
		const editId = this.getEditId(row);
		const refs = Object.keys(this.$refs);
		const index = refs.indexOf('w-edit-' + editId);
		const prevField = this.$refs[refs[index - 1]] as WInlineEdit;
		return this.activeEditInvalid && this.activeInlineEdit === editId
			|| this.activeInlineEdit === ''
			|| prevField?.editMode && !prevField.hasErrors;
	}

	getEditId(row: any): string {
		return row.index + row.field.key;
	}

	deleteRow(item: TableContact): void {
		if (this.activeInlineEdit) {
			const currentOpenField = this.$refs['w-edit-' + this.activeInlineEdit] as WInlineEdit;
			currentOpenField.close();
		}
		if (item.id && this.editIds.includes(item.id)) {
			this.editIds = this.editIds.filter((id) => id !== item.id);
		}
		this.$emit('deleteRow', item);
	}

	openNextField(row: any, shift: boolean): void {
		const currentFieldId = this.getEditId(row);
		let refKeys = Object.keys(this.$refs);
		refKeys = refKeys.filter((key) => key.includes('w-edit-'));
		const currentFieldIndex = refKeys.indexOf('w-edit-' + currentFieldId);
		const newIndex = !shift ? currentFieldIndex + 1 : currentFieldIndex - 1;
		const nextFieldIndex = refKeys[newIndex];
		const nextField = this.$refs[nextFieldIndex] as WInlineEdit;
		const currentField = this.$refs[refKeys[currentFieldIndex]] as WInlineEdit;
		setTimeout(() => {
			if (!currentField.loading) {
				if (nextField) nextField.showInput();
			}
		}, 100);
	}

	phoneFormatValidator(row: any): () => Promise<boolean> | boolean {
		const field = this.$refs['w-edit-' + this.getEditId(row)] as WInlineEdit;
		return function (this: WInlineEdit) {
			const phone = this.$v.newVal;
			if (!phone?.$dirty || !phone.required) return false;
			this.loading = true;
			return ContactsService.validatePhoneNumbers([this.newVal])
				.then(validatedNumbers => {
					const validNumber = validatedNumbers[0];
					const phoneIsValid = validNumber.validNumber;
					if (phoneIsValid) this.newVal = validNumber.international;

					return phoneIsValid;
				})
				.catch(e => false)
				.finally(() => setTimeout(() => {
					this.loading = false;
				}, 50));
		}.bind(field);
	}

	// Store Functions
	get activeInlineEdit(): string {
		return this.$store.state.teleconference.activeInlineEdit;
	}

	get activeEditInvalid(): boolean {
		return this.$store.state.teleconference.activeEditInvalid;
	}
}
