







































































































































import { OrganisationForm } from '@/models/OperatorForm';
import { Component, Prop } from 'vue-property-decorator';
import Base from '../../Base';
import {getLanguages} from "@/utils/languages";

@Component({})
export default class ConferenceOrganisation extends Base {
	@Prop() form!: OrganisationForm;
	@Prop() validation!: any;

	open = true;

	languages = getLanguages(this);

	mounted(): void {
		setTimeout(() => {
			if (
				!this.form.organizerName &&
				!this.form.organizerPhone &&
				!this.form.organizerMail
			) {
				this.form.organizerName = `${this.user.contact.firstName} ${this.user.contact.lastName}`;
				this.form.organizerPhone = `${this.user.contact.phone}`;
				this.form.organizerMail = `${this.user.contact.email}`;
			}
			if (!this.form.language) {
				this.form.language = this.user.language;
			}
		});
	}

	toggleOpen(): void {
		this.open = !this.open;
	}

	validateState(name: string): boolean | null {
		const validate = this.validation[name];
		return validate.$dirty ? !validate.$error : null;
	}
}
