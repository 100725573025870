

































import { Component, Prop, Vue } from 'vue-property-decorator';
import WSwitch from '@/components/WSwitch.vue';
import { EventBus, Events } from '@/EventBus';

@Component({
  components: { WSwitch }
})
export default class WExpandableCard extends Vue {

  @Prop({ default: false, type: Boolean }) expandable!: boolean;
  @Prop() title!: string;
  @Prop() link?: string;
  @Prop({ default: true, type: Boolean }) defaultExpanded!: boolean;
  @Prop({ default: false, type: Boolean }) collapseAll!: boolean;

  open = true;

  mounted() {
    this.open = this.defaultExpanded;
    EventBus.$on(Events.CollapseAll, () => {
      this.open = false;
    });
    EventBus.$on(Events.ExpandAll, () => {
      this.open = true;
    });
  }

  beforeDestroy() {
    EventBus.$off(Events.CollapseAll);
    EventBus.$off(Events.ExpandAll);
  }

  toggleOpen(): void {
    this.open = !this.open;
  }

  setOpen(): void {
    this.open = true;
  }

  linkClicked() {
    this.$emit('link-clicked');
  }

  collapse() {
    if (this.open) {
      EventBus.$emit(Events.CollapseAll);
    } else {
      EventBus.$emit(Events.ExpandAll);
    }

  }

}
