import JsPDF from 'jspdf';
import { Booking, NationalNumber, ScheduleType } from '@/models/api';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { formatDateTimeForFiles } from '@/utils/filters';

export function downloadConfirmation(
	dialIn: StringMap,
	termin: StringMap,
	options: StringMap,
	nationalNumbers: NationalNumber[],
	beginDate: string,
	booking: Booking | null,
	instance: Vue
): void {
	const pdf = new JsPDF();
	const title = pdf.splitTextToSize(booking?.topic || instance.$t('common.noTopicSelected') as string, 150);
	pdf.setFontSize(18);
	let height = pdf.getTextDimensions(title).h;
	pdf.text(title, 15.5, 15);

	const subTitle = pdf.splitTextToSize(
		conferenceDateString(beginDate, booking, instance),
		150
	);
	pdf.setFontSize(10);
	pdf.setTextColor(131, 142, 153);
	height += 14 + pdf.getTextDimensions(subTitle).h;
	pdf.text(subTitle, 15.5, height);
	pdf.text(instance.$t('conference.bookedFunctions') as string, 194.5, height, { align: 'right' });

	height = addItemSectionToPdf(
		pdf,
		height,
		instance.$t('conference.event') as string,
		Object.entries(termin).map((option) => ({
			key: option[0],
			value: option[1]
		}))
	);

	height = addItemSectionToPdf(
		pdf,
		height,
		instance.$t('conference.settings') as string,
		Object.entries(options).map((option) => ({
			key: option[0],
			value: option[1]
		}))
	);

	if (booking?.dialIn) {
		const data = Object.entries(dialIn).map((option) => ({
			key: option[0],
			value: option[1]
		}));

		// Remove dial-in numbers to render all active dial-in numbers instead of only one
		data.pop();
		nationalNumbers.forEach((number, index) => {
			if (number.active) {
				data.push({
					key: index === 0 ? instance.$t('conference.dialNumbers') as string + ':' : '',
					value: `${number.displayNumber} (${number.description})`
				});
			}
		});

		height = addItemSectionToPdf(pdf, height, instance.$t('conference.dialInConference') as string, data);
	}
	if (booking?.participants) {
		addItemSectionToPdf(
			pdf,
			height,
			instance.$t('common.participants') as string,
			booking.participants?.map((participant) => ({
				key: participant.name + (participant.moderator ? ' (' + instance.$t('conference.moderator') as string + ')' : ''),
				value:
					participant.phone +
					(participant.email ? `, ${participant.email}` : '')
			}))
		);
	}

	addPageCountToPdf(pdf);
	addCallFunctionImageToPdf(pdf, booking, instance);
}

/*
 * Translates an ItemSection Component to jsPDF rendering
 * and returns the final Y-Coordinate to use for upcoming elements that require it
 */

function addItemSectionToPdf(
	pdf: JsPDF,
	startY: number,
	title: string,
	data: Array<{ key: string; value: string }>
): number {
	// ItemSection Title Text
	pdf.setFontSize(13);
	pdf.setTextColor(0, 0, 0);
	pdf.text(title, 15.5, 15 + startY);

	// Grey parting line between title and key-value pairs
	pdf.setDrawColor(226, 232, 240);
	pdf.line(15.5, 18 + startY, pdf.internal.pageSize.width - 15.5, 18 + startY);

	// Listing key-value pairs
	autoTable(pdf, {
		body: data,
		startY: 20 + startY,
		bodyStyles: { textColor: [131, 142, 153] },
		columnStyles: { 0: { cellWidth: 85 } }
	});

	// Return final y-coordinate for upcoming additions to pdf document
	return (pdf as any).lastAutoTable.finalY;
}

/*
 * Adds a page counter to each page of a jsPDF document in the bottem right corner
 */

function addPageCountToPdf(pdf: JsPDF): void {
	for (let i = 1; i <= pdf.getNumberOfPages(); i++) {
		pdf.setPage(i);
		pdf.setFontSize(8);
		pdf.setTextColor(0, 0, 0);
		pdf.text(`Seite ${i} von ${pdf.getNumberOfPages()}`, 210 - 30, 297 - 10);
	}
}

function addCallFunctionImageToPdf(pdf: JsPDF, booking: Booking | null, instance: Vue): void {
	const callFunctionsContainer = document.getElementById(
		'conf-functions-container'
	);
	if (callFunctionsContainer) {
		html2canvas(callFunctionsContainer).then((canvas) => {
			pdf.setPage(1);
			pdf.addImage(
				canvas,
				194.5 - (canvas.width / 4) * (1 / window.devicePixelRatio),
				10,
				(canvas.width / 4) * (1 / window.devicePixelRatio),
				(canvas.height / 4) * (1 / window.devicePixelRatio)
			);
			pdf.save(
				`${instance.$t('conference.bookingConfirmation') as string}_${booking?.topic}_${formatDateTimeForFiles(
					booking?.start
				)}.pdf`
			);
		});
	}
}

function conferenceDateString(beginDate: string, booking: Booking | null, instance: Vue): string {
	return `${beginDate} ${booking && booking.schedule ? scheduleType(instance, booking.schedule.type) : ''}`;
}

function scheduleType(instance: Vue, type?: ScheduleType): string {
	switch (type) {
		case ScheduleType.ANYTIME:
			return instance.$t('conference.anytime') as string;
		case ScheduleType.IMMEDIATE:
		case ScheduleType.ONETIME:
			return 'einmalig';
		case ScheduleType.WEEKLY:
		case ScheduleType.MONTHLY:
		case ScheduleType.DAILY:
			return instance.$t('conference.repeated') as string;
		default:
			return '';
	}
}

export interface StringMap {
	[key: string]: string;
}
