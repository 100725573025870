









































































import {Component} from "vue-property-decorator";
import HeaderLayout from "@/layouts/nested/HeaderLayout.vue";
import WHeaderBtn from "@/components/WHeaderBtn.vue";
import Base from "@/views/Base";
import PresentationService from "@/service/PresentationService";
import {PresentationForm} from "@/models/Presentation";
import PresentationGeneral from "@/views/presentation/components/General.vue";
import PresentationElements from "@/views/presentation/components/Elements.vue";
import PresentationDownloads from "@/views/presentation/components/Downloads.vue";
import {Validations} from "vuelidate-property-decorators";
import {required} from "vuelidate/lib/validators";

@Component({
  components: {
    WHeaderBtn,
    HeaderLayout,
    PresentationGeneral,
    PresentationElements,
    PresentationDownloads
  }
})
export default class PresentationEdit extends Base {

  isNewPresentation = false;
  form: PresentationForm | null = null;
  baseLoading = false;
  elementsTabSelected = true;

  created() {
    this.isNewPresentation = this.$route.query.new === 'true';
  }

  mounted() {
    this.getPresentation();
  }

  @Validations()
  validations = {
    form: {
      id: {},
      title: {required},
      isGlobal: {}
    }
  }

  getPresentation(): void {
    const {id} = this.$route.params;
    if (!id) this.$router.push('/presentation');
    this.baseLoading = true;
    PresentationService
      .getPresentation(id)
      .then(presentation => this.form = PresentationService.presentationToForm(presentation))
      .catch(this.showNetworkError)
      .finally(() => this.baseLoading = false);
  }

  submit(): void {
    this.$v.form.$touch();

    if (this.$v.$pending) {
      setTimeout(this.submit, 50);
      return;
    }

    if (this.$v.form.$invalid) {
      this.toast(this.t('forms.formHasErrors'), 'danger');
      return;
    }

    if (!this.form) return;
    this.baseLoading = true;

    PresentationService
      .updatePresentation(this.form)
      .then(() => {
        this.toast(this.t('presentation.savedSuccessfully'), 'success');
        this.$router.push('/presentation');
      })
      .catch(this.showNetworkError)
      .finally(() => this.baseLoading = false);
  }

  get headerTitle() {
    return this.isNewPresentation ? this.$t('presentation.newPresentation') : this.$t('presentation.editPresentation');
  }

  get saveBtnText() {
    return this.isNewPresentation ? this.$t('common.save') : this.$t('forms.saveChanges');
  }

  tabClass(selected: boolean): string {
    return selected ? 'bg-white border-bottom-0' : '';
  }
}
