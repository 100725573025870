


























































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Validation} from 'vuelidate';
import {Validations} from 'vuelidate-property-decorators';
import Base from '../../Base';
import {required} from 'vuelidate/lib/validators';
import {SetupForm} from '@/models/RegistrationForm';
import {Account} from '@/models/Account';
import {RegistrationDate} from '@/models/Registration';
import BookingService from '@/service/BookingService';

@Component({
	components: {}
})
export default class RegistrationSetupModal extends Base {
	@Prop() dateName!: string;
	@Prop() setup!: string;
	@Prop() date!: RegistrationDate;

	title = this.t('modals.gutBeratenSetup.title');
	bookingStart: number | null = null;

	learningTypeOptions = [
		{ text: this.t('modals.gutBeratenSetup.learningTypes.face2face'), value: 'Präsenzveranstaltung' },
		{ text: this.t('modals.gutBeratenSetup.learningTypes.individual'), value: 'Einzeltraining' },
		{ text: this.t('modals.gutBeratenSetup.learningTypes.blended'), value: 'Blended Learning' },
		{ text: this.t('modals.gutBeratenSetup.learningTypes.controlled'), value: 'gesteuertes E-Learning' },
		{
			text: this.t('modals.gutBeratenSetup.learningTypes.selfControlled'),
			value: 'selbstgesteuertes E-Learning'
		},
		{ text: this.t('modals.gutBeratenSetup.learningTypes.workplace'), value: 'Lernen am Arbeitsplatz' }
	];

	learningContentOptions = [
		{
			text: this.t('modals.gutBeratenSetup.learningContents.privatePreventionLifePensionInsurance'),
			value: 'Privat-Vorsorge-Lebens-/Rentenversicherung'
		},
		{
			text: this.t('modals.gutBeratenSetup.learningContents.privatePreventionHealthCareInsurance'),
			value: 'Privat-Vorsorge-Kranken-/Pflegeversicherung'
		},
		{
			text: this.t('modals.gutBeratenSetup.learningContents.privatePropertyDamageInsurance'),
			value: 'Privat-Sach-/Schadenversicherung'
		},
		{
			text: this.t('modals.gutBeratenSetup.learningContents.corporateCustomerPrevention'),
			value: 'Firmenkunden-Vorsorge (bAV/Personenversicherung)'
		},
		{
			text: this.t('modals.gutBeratenSetup.learningContents.corporateCustomerPropertyDamageInsurance'),
			value: 'Firmenkunden-Sach-/Schadenversicherung'
		},
		{ text: this.t('modals.gutBeratenSetup.learningContents.crossDivisional'), value: 'Spartenübergreifend' },
		{ text: this.t('modals.gutBeratenSetup.learningContents.consultingSkills'), value: 'Beratungskompetenz' }
	];

	form: SetupForm = this.newSetupForm();
	setupExists = false;

	@Watch('user')
	userChanged(newValue: Account, oldValue: Account | null): void {
		if (oldValue === null) {
			this.setupChanged(this.setup);
		}
	}

	@Watch('dateName')
	dateNameChanged(newValue: string): void {
		if (!this.setupExists) {
			this.form.name = newValue;
			this.$v.form.$reset();
		}
	}

	@Watch('setup')
	setupChanged(newValue: string): void {
		const form = newValue
			? (JSON.parse(newValue) as { setup?: SetupForm })
			: undefined;
		if (newValue && form && form.setup) {
			this.form = { ...this.form, ...form.setup };
			this.setupExists = true;
		} else {
			this.form = { ...this.form, ...this.newSetupForm() };
			this.form.name = this.dateName;
			this.setupExists = false;
		}
	}

	@Watch('date')
	dateChanged(): void {
		if (this.date && this.date.bookingId) {
			BookingService.getBooking(this.date.bookingId).then((booking) =>
				booking.start ? (this.bookingStart = booking.start) : ''
			);
		}
	}

	@Validations()
	validations = {
		form: {
			minConnection: { required },
			educationBonus: { required },
			name: { required },
			learningType: { required },
			learningContent: { required },
			internalBookingId: {},
			contactTitle: {},
			contactFirstname: {},
			contactLastname: {},
			contactPhone: {},
			contactEmail: {}
		}
	};
	validateState(name: string): boolean | null {
		const validate = this.$v.form[name] as Validation;
		return validate.$dirty ? !validate.$error : null;
	}

	saveSetup(ok: () => void): void {
		this.$v.form.$touch();
		if (!this.$v.form.$invalid) {
			this.$emit('save', { form: this.form, ok });
			this.$v.form.$reset();
		} else {
			this.toast(this.t('forms.fixErrorInForm'), 'danger');
		}
	}

	private newSetupForm(): SetupForm {
		const userSetup =
			this.user && this.user.settings
				? JSON.parse(this.user.settings).setup
				: undefined;
		return userSetup
			? userSetup
			: {
					minConnection: 0,
					educationBonus: 45,
					name: '',
					learningType: 'gesteuertes E-Learning',
					learningContent: 'Beratungskompetenz',
					internalBookingId: '',
					contactTitle: '',
					contactFirstname: '',
					contactLastname: '',
					contactPhone: '',
					contactEmail: ''
        };
	}
}
