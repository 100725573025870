



































































































































import {Component, Prop} from 'vue-property-decorator';
import Base from '@/views/Base';
import WSwitch from '@/components/WSwitch.vue';
import {RegistrationDate} from '@/models/Registration';
import RegistrationService from '@/service/RegistrationService';
import SurveyService from '@/service/SurveyService';
import {saveAs} from 'file-saver';
import PlaceholdersModal from '@/views/forms/components/PlaceholdersModal.vue';

@Component({
	components: { PlaceholdersModal, WSwitch }
})
export default class CertificateSetupModal extends Base {
	@Prop() regDate!: RegistrationDate;
	@Prop() regId!: number;

	file: File | null = null;
	tempFile = '';
	minDurationEnabled = false;
	generateCert = false;
	minDurationForCert = 0;

	isValid: boolean | null = null;
	loadingPreview = false;
	loadingTempFile = false;

	updateMinDuration(event: boolean): void {
		if (event && this.minDurationForCert === 0) this.minDurationForCert = 10;
		if (!event && this.minDurationForCert !== 0) this.minDurationForCert = 0;
	}

	init(): void {
		if (this.regDate.certificateFileName) this.tempFile = this.regDate.certificateFileName;
		else this.tempFile = '';

		this.generateCert = this.regDate.generateCert;
		this.minDurationForCert = this.regDate.minDurationForCert || 0;
		if (this.minDurationForCert > 0) this.minDurationEnabled = true;

		this.loadingPreview = false;
		this.isValid = null;
	}

	uploadTemp(): void {
		if (!this.file) return;
		this.loadingTempFile = true;
		RegistrationService.addFile(this.file)
			.then(value => {
				this.tempFile = value;
				this.generateCert = true;
			})
			.finally(() => this.loadingTempFile = false);
	}

	deleteFile(): void {
		this.tempFile = '';
		this.file = null;
	}

	submit(ok: () => void): void {
		const date: RegistrationDate = {
			...this.regDate,
			generateCert: this.generateCert,
			minDurationForCert: this.minDurationForCert,
			certificateFileName: undefined
		}

		if (this.generateCert) {
			if (!this.tempFile) {
				this.isValid = false;
				return;
			}
			this.$emit('update-cert', { date, file: this.file });
		}

		if (!this.generateCert) {
			this.$emit(
				'delete-cert',
				{ date: {...date, minDurationForCert: 0}, filename: this.regDate.certificateFileName }
			);
		}
		ok();
	}

	downloadPreview(): void {
		this.loadingPreview = true;
		const isTemp = this.tempFile !== 'certificate.pptx';

		if (isTemp) {
			SurveyService.downloadTempPreview(this.tempFile)
				.then(res => saveAs(new Blob([res]), this.t('forms.certificatePreview') + '.pdf'))
				.catch(this.showNetworkError)
				.finally(() => this.loadingPreview = false);
		} else {
			RegistrationService.downloadExampleCertificate(this.regId, this.regDate.id)
				.then(res => saveAs(new Blob([res]), this.t('forms.certificatePreview') + '.pdf'))
				.catch(this.showNetworkError)
				.finally(() => this.loadingPreview = false);
		}
	}

	get hasFile(): boolean | null {
		return this.generateCert ? this.tempFile !== '' : null;
	}

}
