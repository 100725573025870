



































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import {Component} from 'vue-property-decorator';
import Base from '../Base';
import WSidebarLink from '@/components/WSidebarLink.vue';
import ItemSection from '../conference/ItemSection.vue';
import SurveyService from '@/service/SurveyService';
import {FormMail, Survey} from '@/models/Survey';
import SurveyItemTable from './SurveyItemTable.vue';
import {createResponsesXLSX} from '@/utils/excel';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import store from '@/store';
import CertificateMailModal from '@/components/modals/CertificateMailModal.vue';
import {saveAs} from 'file-saver';
import {formatDateTimeForFiles} from '@/utils/filters';
import axios from 'axios';

@Component({
	components: {
		WHeaderBtn,
		HeaderLayout,
		SidebarLayout,
		WSidebarLink,
		ItemSection,
		SurveyItemTable,
		CertificateModal: CertificateMailModal
	}
})
export default class SurveyItem extends Base {
	survey: Survey | null = null;
	fields: { [key: string]: string } = {};
	mail: FormMail = {
		replyTo: '',
		subject: '',
		text: '',
		attachments: [],
		enabled: true
	};
	loadingSendCertificate = false;
	loadingResponses = false;
	loadingStatistic = false;
	loadingCertificates = false;
	loadingCertificatesDownload = false;
	totalPassed = 0;
	totalRows = 0;
	pollSurveyInterval: any | null = null;
	sendCertificateInterval!: any;

	get generatedCertPercent(): number {
		if (!this.survey) return 0;
		const availableCerts =
			this.survey.countCertFileUpToDate === undefined
				? this.totalRows
				: this.survey.countCertFileUpToDate;
		return Math.round((availableCerts / this.totalRows) * 100);
	}

	get certificatesGenerating(): boolean {
		if (!this.survey || !this.survey.countCertFileUpToDate) return true;
		return this.survey.countCertFileUpToDate < this.totalRows;
	}

	mounted(): void {
		this.init();
	}

	beforeDestroy(): void {
		this.clearPollSurveyInterval();
		if (this.sendCertificateInterval)
			clearInterval(this.sendCertificateInterval);
	}

  clearPollSurveyInterval(){
    if (this.pollSurveyInterval) {
      clearInterval(this.pollSurveyInterval);
      this.pollSurveyInterval = null;
    }
  }

	async init(): Promise<void> {
		const { id } = this.$route.params;
		if (id) {
			await this.getSurvey(id);
			if (this.survey?.mailToAllProgress && !this.certificatesGenerating) {
				this.pollSurveyForCertificateSend(true);
			}
		}
	}

	private getSurvey(id: string | number): any {
		return SurveyService.getSurvey(id)
			.then(res => {
				this.survey = res;
				if (res.countRegisteredUser && res.countRegisteredUser !== this.totalRows) {
					this.totalRows = res.countRegisteredUser;
				}
			})
			.catch(this.showNetworkError);
	}

	updateCertCount(res: { totalPassed: number; totalRows: number }): void {
		this.totalPassed = res.totalPassed;
		this.pollSurveyForCertificateDownload();
	}

	get downloadCertificatesUrl(): string {
		return `${axios.defaults.baseURL}/survey/${this.$route.params.id}/result/certificates.zip?access_token=${store.getters.accessToken}`;
	}

	deleteAllUsers(): void {
		this.$bvModal
			.msgBoxConfirm(this.t('modals.deleteParticipantsModal.description'), {
				okVariant: 'danger',
				okTitle: this.t('modals.deleteParticipantsModal.ok'),
				cancelTitle: this.t('common.cancel'),
				centered: true,
				title: this.t('modals.deleteParticipantsModal.title')
			})
			.then((res) => {
				if (res) {
					if (this.survey && this.survey.id) {
						let surveyId = this.survey.id;
						SurveyService.deleteAllResponses(this.survey?.id)
							.then(() => {
								this.toast(
									this.t('modals.deleteParticipantsModal.deleted'),
									'success'
								);
								this.survey = null;
								this.getSurvey(surveyId);
							})
							.catch(this.showNetworkError);
					}
				}
			});
	}

	edit(): void {
		if (this.survey && this.survey.id)
			this.$router.push({
				path: `/forms/survey/${this.survey.id}/edit?mode=${
					this.$route.query.mode || 'active'
				}`
			});
	}

	duplicate(): void {
		if (this.survey && this.survey.id)
			this.$router.push({
				path: '/forms/survey/new',
				query: { id: this.survey.id.toString() }
			});
	}

	downloadResponses(): void {
		if (this.survey && this.survey.id) {
			this.loadingResponses = true;
			const survey = this.survey;
			SurveyService.getResults(this.survey.id)
				.then(results => createResponsesXLSX(survey, results, this))
				.catch(err => this.showNetworkError(err))
				.finally(() => (this.loadingResponses = false));
		}
	}

	downloadStatistic(): void {
		if (this.survey && this.survey.id) {
			this.loadingStatistic = true;
			SurveyService.downloadStatistic(this.survey.id, this.de ? "de": "en")
				.then(res => saveAs(res, `${this.t('forms.surveyStatistics')}_${formatDateTimeForFiles(Date.now())}.pdf`))
				.catch(err => this.showNetworkError(err))
				.finally(() => (this.loadingStatistic = false));
		}
	}

	async openCertificateModal(): Promise<void> {
    const downloadOK = await this.checkDownloadOK();
    if (downloadOK) {
      this.$bvModal.show('survey-certificate-modal');
    }
	}

	async downloadCertificates() {
		if (!this.survey) return;
		if (!this.survey.id) return;

		this.loadingCertificatesDownload = true;
    const downloadOK = await this.checkDownloadOK();
		if (downloadOK) {
			this.toast(this.t('forms.certificatesDownloading'), 'primary');
			window.location.href = this.downloadCertificatesUrl;
		}
    this.loadingCertificatesDownload = false;
	}

  async checkDownloadOK(): Promise<boolean> {
    if (!this.survey?.id) return false;
    let downloadOK;
    try {
      downloadOK = await SurveyService.isCertificatesDownloadOK(this.survey.id);
    } catch (e) {
      downloadOK = false;
      this.showNetworkError(e);
    }
    if (!downloadOK) {
      await this.pollSurveyForCertificateDownload();
    }
    return downloadOK
  }

	async pollSurveyForCertificateDownload(): Promise<void> {
		const { id } = this.$route.params;
		if (id) {
			await this.getSurvey(id);
			if (!this.survey) return;
			let availableCerts =
				this.survey.countCertFileUpToDate === undefined
					? this.totalRows
					: this.survey.countCertFileUpToDate;
			if (availableCerts < this.totalRows && this.pollSurveyInterval == null) {
				this.pollSurveyInterval = setInterval(async () => {
					await this.getSurvey(id);
					if (!this.survey) return;
					availableCerts =
						this.survey.countCertFileUpToDate === undefined
							? this.totalRows
							: this.survey.countCertFileUpToDate;
					if (availableCerts >= this.totalRows) {
						this.clearPollSurveyInterval();
					}
				}, 3000);
			}
		}
	}

	async sendCertificateToAllUsers(res: {
		ok: () => void;
		mail: FormMail;
		onlyToNew: boolean;
	}): Promise<void> {
		if (this.survey && this.survey.id) {
			this.loadingSendCertificate = true;
			const onlyToNew = res.onlyToNew;
			await SurveyService.sendCertificateToAllParticipants(this.survey.id, {
				...res.mail,
				onlyToNew
			});
			this.loadingSendCertificate = false;
			res.ok();
			this.pollSurveyForCertificateSend();
		}
	}

	async pollSurveyForCertificateSend(noStatus?: boolean): Promise<void> {
		const { id } = this.$route.params;
		if (id) {
			this.loadingCertificates = true;
			await this.getSurvey(id);
			if (this.survey !== null && this.survey.mailToAllProgress) {
				if (!noStatus)
					this.toast(this.t('forms.certDownloadStarted'), 'primary');
				this.sendCertificateInterval = setInterval(async () => {
					await this.getSurvey(id);
					if (this.survey !== null && !this.survey.mailToAllProgress) {
						this.toast(this.t('forms.certificatesSent'), 'success');
						clearInterval(this.sendCertificateInterval);
						(
							this.$refs['survey-item-table'] as SurveyItemTable
						).results.forEach((result) => {
							if (result.passed) result.certificateSent = true;
						});
						this.loadingCertificates = false;
					}
				}, 4000);
			} else {
				this.toast(this.t('forms.certificatesSent'), 'success');
				this.loadingCertificates = false;
			}
		}
	}
}
