import axios from 'axios';
import store from '../store/index';
import jwt_decode from 'jwt-decode';
import {Account, DCPSessionResult} from '@/models/Account';

// Authentication data stored in localstorage
export interface AuthenticationStorage {
	username: string;
	accessToken: string;
	refreshToken: string;
	expiration: number;
}

// Data returned with the login endpoint
interface LoginData {
	username: string;
	access_token: string;
	expires_in: number;
	token_type: string;
	roles: Array<string>;
	refresh_token: string;
}

export interface TokenPayload {
	exp: number;
	iat: number;
	iss: string;
	nbf: number;
	roles: Array<string>;
	sub: string;
}

export interface PasswordReset {
	username: string;
	oldPasswordPlain?: string;
	newPasswordPlain: string;
	newPasswordPlainConfirmation?: string;
}

export interface ImpersonateNames {
	childUsername: string;
	parentUsername: string;
}

export interface Tokens {
	accessToken: string;
	refreshToken: string;
}

/**
 * API calls for authentication
 * @module service/AuthenticationService
 */
export default {
	login(username: string, password: string): Promise<Account> {
		return axios.post(`/login`, { username, password }).then((response) => {
			const login: LoginData = response.data;
			const expiration = Date.now() + login.expires_in * 1000;
			store.commit('setAuthentication', {
				accessToken: login.access_token,
				refreshToken: login.refresh_token,
				username: login.username,
				expiration
			});
			return this.fetchMyAccount();
		});
	},

	logout(): void {
		store.commit('clearAuthentication');
	},

	fetchMyAccount(): Promise<Account> {
		let username = store.getters.username;
		const split = store.getters.username.split('|');
		if (split.length === 2) {
			username = split[1];
		}
		return axios
			.get(`/account/${username}`)
			.then((response) => {
				store.commit('setAccount', response.data);
				return response.data;
			})
			.catch((err) => {
				console.log('Error fetching account', err);
				store.commit('clearAuthentication');
			});
	},

	updateMyAccount(account: Account): Promise<Account> {
		return axios
			.put(`/account/${account.username}`, account)
			.then((response) => {
				store.commit('setAccount', response.data);
				return response.data;
			});
	},

	decodeToken(token: string): TokenPayload {
		return jwt_decode<TokenPayload>(token);
	},

	setNewPassword(reset: PasswordReset): Promise<Account> {
		return axios.post('/password', reset).then((res) => res.data);
	},

	refreshToken(refreshToken: string): Promise<Tokens> {
		return axios.post('/refresh', { refreshToken }).then((res) => res.data);
	},

	impersonate(names: ImpersonateNames): Promise<Account> {
		return axios
			.post(`/impersonate/start`, names)
			.then((response) => this.setAuthentication(response.data));
	},

	stopImpersonate(names: ImpersonateNames): Promise<Account> {
		return axios
			.post(`/impersonate/stop`, names)
			.then((response) => this.setAuthentication(response.data));
	},

	setAuthentication(tokens: Tokens): Promise<Account> {
		const payload: TokenPayload = jwt_decode<TokenPayload>(tokens.accessToken);
		const expiration = payload.exp * 1000;
		console.log('Access token username', payload.sub);
		store.commit('setAuthentication', {
			username: payload.sub,
			accessToken: tokens.accessToken,
			refreshToken: tokens.refreshToken,
			expiration
		});
		return this.fetchMyAccount();
	},

	checkDCPSessionExists(
		name: string,
		pin: string,
		pinCheckUrl: string
	): Promise<{ result: DCPSessionResult }> {
		return axios.get(`${pinCheckUrl}/client/isSessionOpen/1?pin=${pin}&name=${name}`, {
			transformRequest: [(data: any, headers: any) => {
				if (headers) {
					delete headers.common['X-Client-Source'];
					delete headers.common.Authorization;
				}
				return headers;
			}],
		}).then((res) => res.data);
	}
};
