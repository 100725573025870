





































import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import { SelectOption } from '@/models/common';

@Component
export default class SelectConferenceModal extends Base {
	@Prop() value!: string;
	@Prop() conferenceForms!: SelectOption[];

	value_ = '';

	submit(ok: () => void): void {
		this.$emit('input', this.value_);
		ok();
		this.value_ = '';
	}
}
