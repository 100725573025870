





































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class WButtonSwitch extends Vue {
	@Prop() value!: boolean;
	@Prop() title!: string;
	@Prop() description!: string;
	@Prop() icon!: string;
	@Prop() noTitle!: boolean;

	isIcon(str: string): boolean {
		return this.icon === str;
	}

	set _value(val: boolean) {
		setTimeout(() => {
			this.$emit('input', val);
		});
	}

	get _value(): boolean {
		return this.value;
	}

	toggle(): void {
		this._value = !this._value;
	}
}
