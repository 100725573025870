


































































































































































import {Component} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '@/views/Base';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import {Validations} from 'vuelidate-property-decorators';
import {helpers, required} from 'vuelidate/lib/validators';
import PinReservationService from '@/service/PinReservationService';
import WTable from '@/components/listlayout/WTable.vue';
import {PinReservation} from '@/models/PinReservation';

@Component({
  components: { WTable, WListOptions, HeaderLayout }
})
export default class Index extends Base {
  pins: any[] = [];

  newPin = '';

  selectedPins: number[] = [];

  options = {
    searchTerm: ''
  };

  sortOptions = {
    sortBy: 'pin',
    sortDesc: true
  };

  @Validations()
  validations = {
    newPin: {
      required,
      format: helpers.regex('format', /^[0-9]{6}$/)
    }
  };

  fields = [
    {
      key: 'check',
      label: '',
      thStyle: { width: '10%' }
    },
    {
      key: 'pin',
      label: this.t('pinReservation.pin'),
      thStyle: { width: '22%' },
      sortable: true
    },
    {
      key: 'used',
      label: this.t('pinReservation.usage'),
      thStyle: { width: '22%' },
      sortable: true
    },
    {
      key: 'daysValid',
      label: this.t('pinReservation.reservationTime'),
      thStyle: { width: '36%' },
      sortable: true
    },
    {
      key: 'actions',
      label: '',
      thStyle: { width: '10%' }
    }
  ];

  selectAll(value: boolean) {
    if (!value) {
      this.selectedPins = [];
    } else {
      this.selectedPins = this.pins.map(pin => pin.id);
    }
  }

  getValidDays(date: Date): number {
    return ((date as any) - (new Date() as any)) / 1000 / 3600 / 24;
  }

  filterFunction(item: PinReservation, filter: string): boolean {
    return item.pin.toLowerCase().includes(filter.toLowerCase());
  }

  mounted() {
    this.loadReservations();
  }

  loadReservations() {
    this.baseLoading = true;
    PinReservationService.getReservations()
      .then((pins) => {
        this.pins = pins.map(pin => {
          pin.daysValid = this.getValidDays(pin.validUntil);
          return pin;
        });
        this.selectedPins = this.selectedPins.filter(id =>
          this.pins.some(pin => pin.id === id)
        );
      })
      .catch(this.showNetworkError)
      .finally(() => this.baseLoading = false);
  }

  submit() {
    this.$v.$touch();
    if (this.$v.$error) {
      return;
    }

    PinReservationService.createReservation({ pin: this.newPin })
      .then(() => {
        this.toast(this.t('pinReservation.pinCreated'), 'success');
        this.newPin = '';
        this.$v.$reset();
        this.loadReservations();
      })
      .catch((e) => {
        if (e && (e as any).response.status == 400) {
          this.toast(this.t('pinReservation.pinAlreadyExists'), 'danger');
        } else {
          this.showNetworkError(e)
        }
      });
  }

  async deleteReservation(id: number) {
    if (await this.$bvModal.msgBoxConfirm(this.t('pinReservation.deleteConfirmModalText'), {
      okVariant: 'danger',
      okTitle: this.t('pinReservation.deleteConfirmModalOk'),
      cancelTitle: this.t('common.cancel'),
      centered: true,
      title: this.t('pinReservation.deleteConfirmModalTitle')
    })) {
      PinReservationService.deleteReservation(id).then(
        () => {
          this.loadReservations();
        }
      ).catch(this.showNetworkError);
    }
  }

  renew(id: number) {
    PinReservationService.renew(id)
      .then(this.loadReservations)
      .catch(this.showNetworkError);
  }

  deleteSelected() {
    PinReservationService.deleteReservationsByIds(this.selectedPins)
      .then(this.loadReservations)
      .catch(this.showNetworkError);
  }
}
