import axios from 'axios';
import { CreateLiveVideoBackground, LiveVideoBackground } from '@/models/liveVideoBackground';

export default {
  getVideobackgrounds(search: string, order: string, direction: string): Promise<LiveVideoBackground[]> {
    const params = {
      search, order, direction
    }
    return axios.get('/live-video-background', { params }).then((response) => response.data);
  },
  getVideoBackground(id: number): Promise<LiveVideoBackground> {
    return axios.get(`/live-video-background/${id}`).then((response) => response.data);
  },
  createVideoBackground(file: File): Promise<LiveVideoBackground> {
    const form = new FormData();
    form.append('file', file, file.name);

    const headers = {
      'Content-Type': 'multipart/form-data'
    };

    return axios
      .post(`/live-video-background/`, form, { headers })
      .then((res) => res.data);
  },
  updateVideoBackground(id: number, bg: CreateLiveVideoBackground): Promise<LiveVideoBackground> {
    return axios.put('/live-video-background/' + id, bg).then((response) => response.data);
  },
  deleteVideoBackground(id: number): Promise<void> {
    return axios.delete(`/live-video-background/${id}`).then((response) => response.data);
  },
  deleteVideoBackgroundsByIds(ids: number[]): Promise<void>{
    const queryParams = ids.map(id => `ids=${id}`).join('&');
    const url = `?${queryParams}`;

    return axios.delete('/live-video-background/' + url).then((response) => response.data);
  },
  getPhotoUrl(id: number, filename: string): string {
    return axios.defaults.baseURL + '/live-video-background/' +id + '/' + filename;
  },
};