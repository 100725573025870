





































































































































import { ConferenceDateForm } from '@/models/OperatorForm';
import { Component, Prop } from 'vue-property-decorator';
import Base from '../../Base';

@Component({})
export default class ConferenceDate extends Base {
	@Prop() form!: ConferenceDateForm;
	@Prop() validation!: any;

	open = true;

	durationOptions = [
		{ value: 15, text: this.$t('operator.date.caMinute', { min: 15 }) },
		{ value: 30, text: this.$t('operator.date.caMinute', { min: 30 }) },
		{ value: 45, text: this.$t('operator.date.caMinute', { min: 45 }) },
		{ value: 60, text: this.$t('operator.date.caHour', { hour: '1,0' }) },
		{ value: 90, text: this.$t('operator.date.caHour', { hour: '1,5' }) },
		{ value: 120, text: this.$t('operator.date.caHour', { hour: '2,0' }) },
		{ value: 150, text: this.$t('operator.date.caHour', { hour: '2,5' }) },
		{ value: 180, text: this.$t('operator.date.caHour', { hour: '3,0' }) },
		{ value: 240, text: this.$t('operator.date.caHour', { hour: '4,0' }) },
		{ value: 300, text: this.$t('operator.date.caHour', { hour: '5,0' }) },
		{ value: 360, text: this.$t('operator.date.caHour', { hour: '6,0' }) },
		{ value: 420, text: this.$t('operator.date.caHour', { hour: '7,0' }) },
		{ value: 480, text: this.$t('operator.date.caHour', { hour: '8,0' }) }
	];

	preMeetingOptions = [
		{ value: 0, text: this.t('common.none') },
		{ value: 5, text: this.$t('operator.date.beforeMinute', { min: 5 }) },
		{ value: 10, text: this.$t('operator.date.beforeMinute', { min: 10 }) },
		{ value: 15, text: this.$t('operator.date.beforeMinute', { min: 15 }) },
		{ value: 20, text: this.$t('operator.date.beforeMinute', { min: 20 }) }
	];

	get selectedTime(): string {
		const date = new Date(this.form.start);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		return `${hours}:${minutes}`;
	}

	set selectedTime(newTime: string) {
		const hours = +newTime.split(':')[0];
		const minutes = +newTime.split(':')[1];
		const date = new Date(this.form.start);
		if (!isNaN(hours)) date.setHours(hours);
		if (!isNaN(minutes)) date.setMinutes(minutes);
		date.setSeconds(0);
		this.form.start = date.getTime();
		this.validation.start.$touch();
	}

	get selectedDate(): string {
		const date = new Date(this.form.start);
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		return `${date.getFullYear()}-${month}-${day}`;
	}

	set selectedDate(date: string) {
		const year = +date.split('-')[0];
		const month = +date.split('-')[1];
		const day = +date.split('-')[2];
		const beginDate = new Date(this.form.start);
		if (!isNaN(year)) beginDate.setFullYear(year);
		if (!isNaN(month)) beginDate.setMonth(month - 1);
		if (!isNaN(day)) beginDate.setDate(day);
		this.form.start = beginDate.getTime();
		this.validation.start.$touch();
	}

	toggleOpen(): void {
		this.open = !this.open;
	}

	validateState(name: string): boolean | null {
		const validate = this.validation[name];
		return validate.$dirty ? !validate.$error : null;
	}
}
