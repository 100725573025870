











































import { RegistrationDate } from '@/models/Registration';
import { Component, Prop } from 'vue-property-decorator';
import Base from '../../Base';

@Component({
	components: {}
})
export default class RegistrationDateItem extends Base {
	@Prop() date!: RegistrationDate;
	@Prop() index!: number;
	@Prop() validation!: any;

	startDate = new Date();
	endDate = new Date();

	mounted(): void {
		this.startDate = new Date(this.date.start);
		this.endDate = new Date(this.date.end);
	}

	get selectedStartDate(): string {
		const date = this.startDate;
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		return `${date.getFullYear()}-${month}-${day}`;
	}

	set selectedStartDate(newDate: string) {
		const year = +newDate.split('-')[0];
		const month = +newDate.split('-')[1];
		const day = +newDate.split('-')[2];
		const fullDate = this.startDate;
		if (!isNaN(year)) fullDate.setFullYear(year);
		if (!isNaN(month)) fullDate.setMonth(month - 1);
		if (!isNaN(day)) fullDate.setDate(day);
		this.date.start = fullDate.getTime();
		const endDate = this.endDate;
		if (!isNaN(year)) endDate.setFullYear(year);
		if (!isNaN(month)) endDate.setMonth(month - 1);
		if (!isNaN(day)) endDate.setDate(day);
		this.date.end = endDate.getTime();
		this.validation.$touch();
		if (
			this.date.countRegisteredUser &&
			this.date.countRegisteredUser > 0 &&
			this.date.deleteCustomersAlert === undefined
		) {
			this.date.deleteCustomersAlert = true;
			this.$emit('deleteCustomersAlert');
		}
	}

	selectedTime(dateMilli: number): string {
		const date = new Date(dateMilli);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		return `${hours}:${minutes}`;
	}

	updateStartTime(newTime: string, date: RegistrationDate): void {
		const hours = +newTime.split(':')[0];
		const minutes = +newTime.split(':')[1];
		const fullDate = this.startDate;
		if (!isNaN(hours)) fullDate.setHours(hours);
		if (!isNaN(minutes)) fullDate.setMinutes(minutes);
		date.start = fullDate.getTime();

		// when changing startTime, endTime should update itself to startTime + 1h
		const newEndTime = `${Math.min(+hours + 1, 23)}:${
			hours >= 23 ? '59' : minutes
		}`;
		this.updateEndTime(newEndTime, date);

		this.validation.$touch();
		if (
			date.countRegisteredUser &&
			date.countRegisteredUser > 0 &&
			date.deleteCustomersAlert === undefined
		) {
			date.deleteCustomersAlert = true;
			this.$emit('deleteCustomersAlert');
		}
	}

	updateEndTime(newTime: string, date: RegistrationDate): void {
		const hours = +newTime.split(':')[0];
		const minutes = +newTime.split(':')[1];
		const fullDate = this.startDate;
		if (!isNaN(hours)) fullDate.setHours(hours);
		if (!isNaN(minutes)) fullDate.setMinutes(minutes);
		date.end = fullDate.getTime();
		this.validation.$touch();
	}

	validateState(name: string): boolean | null {
		const validate = this.validation;
		return validate.$dirty ? !validate.$error : null;
	}
}
