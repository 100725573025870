



























































































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { ListOptions } from '@/models/Booking';

@Component
export default class WListOptions extends Vue {
  @Prop() value!: ListOptions;
  @Prop() customerOptions?: { [key: string]: string | number }[];
  @Prop({ default: false, type: Boolean }) noEntryCount!: boolean;
  @Prop({ default: false, type: Boolean }) selectX!: boolean;
  @Prop({ default: false, type: Boolean }) searchX!: boolean;
  @Prop({ default: false, type: Boolean }) businessCard!: boolean;
  @Prop({ default: false, type: Boolean }) background!: boolean;
  @Prop({ default: false, type: Boolean }) presentation!: boolean;

  entryCountOptions = [
    { value: 10, text: '10 ' + this.$t('filter.entriesPerPage') },
    { value: 25, text: '25 ' + this.$t('filter.entriesPerPage') },
    { value: 50, text: '50 ' + this.$t('filter.entriesPerPage') },
    { value: 100, text: '100 ' + this.$t('filter.entriesPerPage') }
  ];

  mounted(): void {
    if (this.value.mode) {
      const mode = localStorage.getItem('list-options-mode') as
        | 'cards'
        | 'table'
        | null;
      if (mode && mode !== null) {
        this.value.mode = mode;
      }
    }

    const searchValue = localStorage.getItem('search:' + this.$route.path);
    if (searchValue) {
      this.value.searchTerm = searchValue;
    }
  }

  get sortByNameOption(): string {
    return this.businessCard ? 'name' : 'title';
  }

  get sortByDateDescString(): string {
    if (this.presentation) return this.$t('filter.newPresentationsDesc') as string;
    else if (this.businessCard) return this.$t('filter.newCardsDesc') as string;
    else if (this.background) return this.$t('filter.newBackgroundsDesc') as string;
    else return this.$t('filter.newFormsDesc') as string;
  }

  get sortByDateAscString(): string {
    if (this.presentation) return this.$t('filter.newPresentationsAsc') as string;
    else if (this.businessCard) return this.$t('filter.newCardsAsc') as string;
    else if (this.background) return this.$t('filter.newBackgroundsAsc') as string;
    else return this.$t('filter.newFormsAsc') as string;
  }

  @Watch('value.mode')
  modeChanged(): void {
    localStorage.setItem('list-options-mode', this.value.mode as string);
  }

  searchTermChanged(value: string): void {
    localStorage.setItem('search:' + this.$route.path, value);
  }

  setSort(sortBy: string, sortDirection: 'ASC' | 'DESC'): void {
    this.value.sortBy = sortBy;
    this.value.sortDirection = sortDirection;
    this.$emit('sort');
  }
}
