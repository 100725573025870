




































import {Component, Prop, PropSync, Watch} from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import {RegistrationFieldOptions} from '@/models/Registration';
import {quillEditor} from 'vue-quill-editor';

@Component({
  components: {WSwitch, quillEditor}
})
export default class ParticipationField extends Base {
  @Prop({default: () => ({enabled: false, required: false})})
  value!: RegistrationFieldOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @PropSync('title') titleSync!: any;
  @Prop() text!: string;
  @Prop({default: null, type: String}) html!: string | null;
  @Prop() idx!: number;
  @Prop({default: false, type: Boolean}) editable!: boolean;
  @Prop({default: false, type: Boolean}) withEnabled!: boolean;
  @Prop({default: false, type: Boolean}) noEditor!: boolean;
  @Prop({default: false, type: Boolean}) disabled!: boolean;
  @Prop({default: false, type: Boolean}) isCheckbox!: boolean;

  editorOptions = {
    theme: 'bubble',
    placeholder: this.t('forms.labelExtraField') + '...',
    modules: {
      toolbar: [['link', 'bold', 'italic']]
    }
  };

  @Watch('value.enabled')
  enabledChanged(newValue: boolean, oldValue: boolean): void {
    if (!newValue) this.value.required = false;
  }

  get validateState(): boolean | null {
    const validate = this.titleSync;
    return validate.$dirty ? !validate.$error : null;
  }

  get placeholder(): string {
    return this.isCheckbox ? this.t('forms.labelCheckbox') : this.t('forms.labelExtraField')
  }
}
