import {Account, Permission, PermissionName} from '@/models/Account';
import Vue from 'vue';
import Component from 'vue-class-component';
import {secureRandom} from '@/utils/secureRandom';
import {AxiosError} from 'axios';
import {Theme} from '@/models/Theme';

// Base class for all views with common functions
@Component
export default class Base extends Vue {
	defaultError = 'Ein Fehler ist aufgetreten';
	baseLoading = false;

	get user(): Account {
		return this.$store.getters.account;
	}

	get prod(): boolean {
		return this.$store.getters.featureLevel === 'production';
	}

	get dev(): boolean {
		return this.$store.getters.featureLevel === 'development';
	}

	get csn(): boolean {
		return this.$store.getters.brand === 'default';
	}

	get theme(): Theme {
		return this.$store.getters.theme;
	}

	get isFirefox(): boolean {
		return window.navigator.userAgent.indexOf('Firefox') > -1;
	}

	get isChrome(): boolean {
		return window.navigator.userAgent.indexOf('Chrome') > -1;
	}

	get isSafari(): boolean {
		return window.navigator.userAgent.indexOf('Safari') > -1;
	}

	get en(): boolean {
		return this.$i18n.locale === 'en';
	}

	get de(): boolean {
		return this.$i18n.locale === 'de';
	}

	toast(message: string, variant?: string, delay = 3000): void {
		this.$root.$bvToast.toast(message, {
			toaster: 'b-toaster-top-center',
			variant: variant ? variant : 'dark',
			autoHideDelay: delay
		});
	}

	id(): string {
		return Date.now().toString(36) + secureRandom().toString(36).substr(2);
	}

	isSAdmin(): boolean {
		const account = this.$store.getters.account as Account;
		return account ? account.role === 'SUPERADMIN' : false;
	}

	isAdmin(): boolean {
		const account = this.$store.getters.account as Account;
		return account ? account.role === 'ADMIN' : false;
	}

	isUser(): boolean {
		const account = this.$store.getters.account as Account;
		return account ? account.role === 'USER' : false;
	}

	isDoi(): boolean {
		if (this.user?.settings && !this.user.settingsObject) {
			this.user.settingsObject = JSON.parse(this.user.settings);
		}
		return this.user?.settingsObject?.doi || false;
	}

	hasPermission(permission: PermissionName): boolean {
		const account = this.$store.getters.account as Account;
		return account && account.permissions
			? account.permissions
					.map((p) => (p as Permission).name)
					.includes(permission)
			: false;
	}

	scrollToTop(): void {
		window.scrollTo(0, 0);
	}

	t(key: string): string {
		return this.$t(key) as string;
	}

	/**
	 *
	 * @param error this is the axios error object (https://github.com/axios/axios#handling-errors)
	 */
	showNetworkError(error: AxiosError): void {
		const msg = this.errorMessage(error);
		if (msg !== this.defaultError) {
			this.toast(msg, 'danger');
		} else {
			console.error(error);
		}
	}

	errorMessage(error: AxiosError): string {
		if (error.response && error.response.data) {
			if (
				error.response.data._embedded &&
				error.response.data._embedded.errors.length > 0
			) {
				return error.response.data._embedded.errors[0].message;
			}
			return error.response.data.message;
		}
		if (error.response && error.response.status === 403) {
			return 'Unauthorized';
		}
		return this.defaultError;
	}
}
