














import {Component, Prop} from 'vue-property-decorator';
import Base from "@/views/Base";
import WExpandableCard from "@/components/WExpandableCard.vue";
import {Account} from "@/models/Account";
import {NewCustomField} from "@/models/CustomField";

@Component({
  components: {WExpandableCard}
})
export default class CustomFieldHelp extends Base {

  @Prop() item!: NewCustomField;

  get helpMailLink(): string {
    const account = this.$store.getters.account as Account | null;
    if (account == null){
      console.log("Account is null")
      return "";
    }

    const params = {
      customerId: account.customerShortDTO?.customerNumber,
      username: account.username,
      name: account.contact.firstName + " " + account.contact.lastName,
      phone: account.contact.phone,
      mail: account.contact.email,
      title: this.item.title,
      tooltip: this.item.description,
      validation: this.item.customFormat,
      maxChars: this.item.maxLength || '-',
      ignoreCase: this.item.ignoreCase ? this.t('common.yes') : this.t('common.no'),
    }
    return "mailto:info@csn.de"
        + "?subject=" + encodeURIComponent(this.t('forms.customFields.helpMailSubject'))
        + "&body=" + encodeURIComponent(this.$t('forms.customFields.helpMailText', params) as string);
  }

}
