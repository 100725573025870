















































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { Component } from 'vue-property-decorator';
import Base from '../Base';
import ConferenceDate from './components/ConferenceDate.vue';
import ConferencePlanning from './components/ConferencePlanning.vue';
import ConferenceType from './components/ConferenceType.vue';
import ConferenceOrganisation from './components/ConferenceOrganisation.vue';
import ConferenceServices from './components/ConferenceServices.vue';
import ConferenceAgents from './components/ConferenceAgents.vue';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import { OperatorForm } from '@/models/OperatorForm';
import OperatorService from '@/service/OperatorService';
import { Validations } from 'vuelidate-property-decorators';
import { minValue, required } from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import { validateEmail } from '@/utils/validators';

@Component({
	components: {
		HeaderLayout,
		ConferenceDate,
		ConferencePlanning,
		ConferenceType,
		ConferenceOrganisation,
		ConferenceServices,
		ConferenceAgents,
		WHeaderBtn
	}
})
export default class OperatorServiceForm extends Base {
	form: OperatorForm = OperatorService.newOperatorForm();

	@Validations()
	validations = {
		form: {
			planning: {
				bookingId: {},
				surveyId: {},
				registration: {
					regFormId: {},
					regDateId: {}
				}
			},
			date: {
				duration: { required },
				durationPreMeeting: { minValue: minValue(0) },
				expectedNumber: { minValue: minValue(0) },
				start: { minValue: this.dateMinValidator }
			},
			type: {
				required: this.typeValidator,
				data: {},
				dialIn: {},
				dialOut: {},
				video: {}
			},
			agents: {
				required,
				$each: {
					name: { required },
					phone: { required, format: this.formatValidation },
					email: { required, email: validateEmail }
				}
			},
			services: {
				serviceGroupManagement: {},
				serviceInit: {},
				servicePreMeeting: {},
				serviceQA: {},
				serviceRecord: {},
				serviceSalutation: {},
				serviceTest: {},
				serviceVideo: {}
			},
			organization: {
				note: {},
				language: {},
				organizerMail: {},
				organizerName: {},
				organizerPhone: {},
				pin: { sixIntegers: this.sixIntegerValidator }
			}
		}
	};

	get editMode(): boolean {
		return !!this.$route.params.id;
	}

	created(): void {
		const { id } = this.$route.params;
		const copyId = this.$route.query.copyId as string;
		if (id || copyId) {
			OperatorService.getOperatorService(id || copyId).then((op) => {
				this.form = OperatorService.operatorToOperatorForm(op);
				this.$v.$reset();
			});
		}
	}

	mounted(): void {
		const { bookingId } = this.$route.query;
		if (bookingId) this.form.planning.bookingId = bookingId as string;
	}

	submit(): void {
		this.$v.$touch();
		if (this.$v.$pending) {
			setTimeout(this.submit, 150);
			return;
		}

		if (!this.$v.$invalid) {
			this.baseLoading = true;
			const operator = OperatorService.operatorFormToOperator(this.form);
			let request = this.editMode
				? OperatorService.updateOperatorService(operator.id, operator)
				: OperatorService.createOperatorService(operator);
			request
				.then(() => {
					this.toast(
						this.editMode
							? this.t('operator.bookingChanged')
							: this.t('operator.booked'),
						'success'
					);
					this.$router.push('/operator');
				})
				.catch((err) => {
					if (
						err.response.data._embedded.errors[0].message.includes(
							'is already assigned'
						)
					) {
						this.toast(this.t('operator.alreadyAssigned'), 'danger');
					} else {
						this.showNetworkError(err);
					}
				})
				.finally(() => (this.baseLoading = false));
		} else {
			this.toast(this.t('operator.formInvalid'), 'danger');
		}
	}

	typeValidator(): boolean {
		const type = this.form.type;
		// one should always be active
		return type.data || type.dialIn || type.dialOut || type.video;
	}

	dateMinValidator(): boolean {
		return this.form.date.start > Date.now();
	}

	sixIntegerValidator(value: string): boolean {
		return (value.length === 6 && /^\d+$/.test(value)) || value === '';
	}

	formatValidation(value: string, model: any): boolean | Promise<boolean> {
		return ContactsService.validatePhoneNumbers([value])
			.then((validatedNumbers) => {
				const validNumber = validatedNumbers[0];
				const phoneIsValid = validNumber.validNumber;
				if (phoneIsValid) model.phone = validNumber.international;
				return phoneIsValid;
			})
			.catch((err) => {
				this.showNetworkError(err);
				return false;
			});
	}

	get isPastOperatorService(): boolean {
		return this.editMode && this.form.date.start < Date.now();
	}
}
