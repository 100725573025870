



















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class WSidebarLink extends Vue {
	@Prop() icon!: string;
	@Prop({ default: false, type: Boolean }) text!: boolean;
	@Prop({ default: 'center', type: String }) align!: string;
	@Prop({ default: false, type: Boolean }) loading!: boolean;

	isIcon(str: string): boolean {
		return this.icon === str;
	}
}
