
















































































import {Component, Prop} from 'vue-property-decorator';

import WFunctions from '@/components/WFunctions.vue';
import WConfContext from '@/components/WConfContext.vue';
import {BookingListItem} from '@/models/Booking';
import {EventBus, Events} from '@/EventBus';
import BookingService from '@/service/BookingService';
import {abbr} from '@/utils/filters';
import Base from '../Base';

@Component({
	components: { WFunctions, WConfContext }
})
export default class ListItem extends Base {
	@Prop() item!: BookingListItem;
	@Prop() type!: 'past' | 'scheduled' | 'running';

	abbr = abbr;

	get past(): boolean {
		return this.type === 'past';
	}

	get running(): boolean {
		return this.type === 'running';
	}

	get variant(): 'primary' | 'secondary' | 'danger' | 'header' {
		switch (this.type) {
			case 'past':
				return 'header';
			case 'scheduled':
				return 'primary';
			case 'running':
				return 'danger';
			default:
				return 'secondary';
		}
	}

	get isSeries(): boolean {
		return this.item.schedule
			? BookingService.isSeries(this.item.schedule.type)
			: false;
	}

	openCCP(): void {
		EventBus.$emit(Events.OpenCCP, this.item.id);
	}
}
