







import { Component, Prop, Watch } from 'vue-property-decorator';
import Base from '@/views/Base';
import { SelectOption } from '@/models/common';
import RegistrationService from '@/service/RegistrationService';
import { Booking } from '@/models/api';

@Component({
	components: {}
})
export default class WFormSelect extends Base {
	@Prop() label!: string;
	@Prop() type!: 'registration' | 'survey';
	@Prop() booking!: Booking;

	options: SelectOption[] = [
		{ value: null, text: this.$t('forms.noForm') as string }
	];

	get isRegistration(): boolean {
		return this.type === 'registration';
	}

	get isSurvey(): boolean {
		return this.type === 'survey';
	}

	get value(): number | null {
		return this.booking?.regDate?.dateId || null;
	}

	mounted(): void {
		if (this.isRegistration) {
			this.getRegistrations();
		} else {
			// this.getSurveys();
		}
	}

	private getRegistrations(): void {
		RegistrationService.getRegistrations(0, 9999, 'dateCreated', 'DESC').then(
			(regs) => {
				this.options = [
					this.options[0],
					...regs.content.map((reg) => ({
						text: reg.title,
						value: reg.id || 0,
						title: reg.title,
						data: reg
					}))
				];
			}
		);
	}

	formChanged(id: string | null): void {
		if (this.isRegistration) {
			this.updateRegistration(id);
		} else {
			// this.updateSurvey(id);
		}
	}

	updateRegistration(newId: string | null): void {
		// const id = newId === null ? this.booking.regDateBooking?.id : newId;
		// const regOption = this.options.find(
		//   (reg) => reg.value === id
		// );
		// if (regOption && regOption.data && id) {
		//   RegistrationService.updateGeneral(+id, {
		//     ...regOption.data,
		//     bookingId: newId === null ? null : this.booking.id
		//   }).then((reg) => {
		//     if (reg.id) {
		//       if(newId === null) {
		//         this.booking.regDateBooking = undefined;
		//       } else {
		//         this.booking.regDateBooking = {
		//           regId: reg.id,
		//           title: reg.title,
		//           url: reg.url
		//         };
		//       }
		//       this.toast('Anmeldeformular erfolgreich geändert', 'success');
		//     }
		//   });
		// }
	}
}
