



















































































































































import {GeneralForm} from '@/models/RegistrationForm';
import {Component, Prop} from 'vue-property-decorator';
import Base from '../../Base';
import {quillEditor} from 'vue-quill-editor';
import {BFormInput} from 'bootstrap-vue';
import BrandingService from '@/service/BrandingService';
import {SelectOption} from '@/models/common';
import {defaultEditorOptions} from '@/utils/quill';
import {getSelectLanguages} from "@/utils/languages";

@Component({
	components: {
		quillEditor
	}
})
export default class General extends Base {
	@Prop() form!: GeneralForm;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Prop() validation!: any;
	@Prop({ default: 'registration' }) formType!: 'registration' | 'survey';

	editorOptions = defaultEditorOptions;

	brandings: SelectOption[] = [{ value: null, text: this.t('forms.noBranding') }];
	languages: SelectOption[] = getSelectLanguages(this);

	labelEdit = false;
	titleEdit = false;
	subtitleEdit = false;

	get formTypePlaceholder(): string {
		return this.formType === 'registration'
			? 'Anmeldeformulars'
			: 'Umfrageformulars';
	}

	get url(): string {
		return this.formType === 'survey'
			? process.env.VUE_APP_SURVEY_URL ?? ''
			: process.env.VUE_APP_REGISTRATION_URL ?? '';
	}

	mounted(): void {
		BrandingService.getBrandings().then(brandings => {
			this.brandings = [
				this.brandings[0],
				...brandings
					.sort(a => a.global ? -1 : 0)
					.map(branding => ({
						value: branding.id.toString(),
						text: branding.title + (branding.global ? ' (' + this.t('brandings.global').toLowerCase() + ')' : ''),
						disabled: false
					}))
			];
		});
	}

	validateState(name: string): boolean | null {
		const validate = this.validation[name];
		return validate.$dirty ? !validate.$error : null;
	}

	copyLink(): void {
		window.navigator.clipboard.writeText(`${this.url}/${this.form.url}`);
		this.toast(this.t('forms.urlCopied'), 'dark');
	}

	editTitle(): void {
		this.titleEdit = true;
		setTimeout(() => {
			(this.$refs['title-input'] as BFormInput).focus();
		}, 50);
	}

	editSubtitle(): void {
		this.subtitleEdit = true;
		setTimeout(() => {
			(this.$refs['subtitle-input'] as BFormInput).focus();
		}, 50);
	}

	editLabel(): void {
		this.labelEdit = true;
		setTimeout(() => {
			(this.$refs['label-input'] as BFormInput).focus();
		}, 50);
	}
}
