












































































import Vue from 'vue';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';

@Component
export default class WPagination extends Vue {
	@Prop() title!: string;
	@Prop() totalRows!: number;
	@PropSync('perPage') entryCount!: number;
	@Prop() pagesSize!: number;
	@Prop({ type: Boolean, default: false }) minimal!: boolean;
	@PropSync('currentPage') syncedCurrentPage!: number;
	@Prop() disabled!: boolean;
	@Prop({ type: Boolean, default: false }) isCards!: boolean;
	@Prop({ type: Boolean, default: false }) noEntryCount!: boolean;

	entryCountOptions = [
		{ value: 10, text: '10 ' + this.$t('filter.entriesPerPage') },
		{ value: 25, text: '25 ' + this.$t('filter.entriesPerPage') },
		{ value: 50, text: '50 ' + this.$t('filter.entriesPerPage') },
		{ value: 100, text: '100 ' + this.$t('filter.entriesPerPage') }
	];

	get totalPages(): number {
		return this.pagesSize || Math.ceil(this.totalRows / this.entryCount);
	}

	get shownRows(): string {
		const shown = this.syncedCurrentPage * this.entryCount;
		const preShown = shown - this.entryCount + 1;
		if (preShown > this.totalRows) this.prevRows();
		return preShown + ' - ' + (shown > this.totalRows ? this.totalRows : shown);
	}

	@Watch('totalRows')
	totalRowsChanged(): void {
		this.entryCountOptions[4] = {
			value: this.totalRows,
			text: this.$t('filter.allEntriesPerPage') as string
		};
	}

	mounted(): void {
		if (window.innerWidth < 768) {
			this.minimal = true;
		}
	}

	nextRows(): void {
		if (this.syncedCurrentPage < this.totalPages) {
			this.syncedCurrentPage++;
			this.$emit('next');
		}
	}

	prevRows(): void {
		if (this.syncedCurrentPage > 1) {
			this.syncedCurrentPage--;
			this.$emit('previous');
		}
	}

	toStart(): void {
		if (this.syncedCurrentPage !== 1) {
			this.syncedCurrentPage = 1;
			this.$emit('previous');
		}
	}

	toEnd(): void {
		if (this.syncedCurrentPage < this.totalPages) {
			this.syncedCurrentPage = this.totalPages;
			this.$emit('next');
		}
	}
}
