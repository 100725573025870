


























































import {Component, Watch} from 'vue-property-decorator';
import Base from "@/views/Base";
import HeaderLayout from "@/layouts/nested/HeaderLayout.vue";
import WHeaderBtn from "@/components/WHeaderBtn.vue";
import CustomFieldService from "@/service/CustomFieldService";
import CustomFieldEdit from "@/views/forms/customFields/CustomFieldEdit.vue";
import CustomFieldHelp from "@/views/forms/customFields/CustomFieldHelp.vue";
import CustomFieldSimpleExample from "@/views/forms/customFields/CustomFieldSimpleExample.vue";
import CustomFieldComplexExample from "@/views/forms/customFields/CustomFieldComplexExample.vue";
import CustomFieldTry from "@/views/forms/customFields/CustomFieldTry.vue";
import ReservedCharsModal from "@/views/forms/customFields/ReservedCharsModal.vue";
import {Validations} from "vuelidate-property-decorators";
import {required, maxLength, minValue} from "vuelidate/lib/validators";
import {CustomField, FormCustomField} from "@/models/CustomField";

@Component({
  components: {
    ReservedCharsModal,
    CustomFieldTry,
    CustomFieldComplexExample,
    CustomFieldSimpleExample, CustomFieldHelp, CustomFieldEdit, WHeaderBtn, HeaderLayout
  }
})
export default class CustomFieldItem extends Base {
  item = CustomFieldService.getEmptyField();
  existingItem: CustomField | null = null;
  regex: string | null = "";

  mounted() {
    if (this.$route.params.id == "new") {
      return
    } else {
      const id = Number(this.$route.params.id);
      if (isNaN(id)) {
        this.$router.push("/forms/custom-fields");
      }
      CustomFieldService.getField(id).then(res => {
        this.existingItem = res;
        this.item = {
          title: res.title,
          description: res.description,
          customFormat: res.customFormat,
          global: res.global,
          ignoreCase: res.ignoreCase,
          maxLength: res.maxLength,
        }
      }).catch(() => {
        this.existingItem = null;
        this.toast("Das Feld wurde nicht gefunden", "danger")
      })
    }
  }

  @Validations()
  validations = {
    item: {
      title: {required, maxlength: maxLength(255)},
      customFormat: {regex: this.customFormatValidator},
    }
  };

  customFormatValidator(value: string): Promise<boolean> | boolean {
    if(!value || value == ""){
      return true;
    }
    return CustomFieldService.translate(value).then((regex) => {
      this.regex = regex;
      return true;
    }).catch(() => {
      this.regex = null;
      return false;
    })
  }

  submit(): void {
    this.$v.item.$touch();

    if (this.$v.$pending) {
      setTimeout(this.submit, 50);
      return;
    }

    if (this.$v.item.$invalid) {
      this.toast(this.t('forms.formHasErrors'), 'danger');
      return;
    }

    if (this.existingItem) {
      CustomFieldService.updateField(this.existingItem.id, this.item).then(
          () => {
            this.$router.push('/forms/custom-fields');
          }
      ).catch(
          this.showNetworkError
      )
    } else {
      CustomFieldService.createField(this.item).then(
          () => {
            this.$router.push('/forms/custom-fields');
          }
      ).catch(
          this.showNetworkError
      )
    }

  }

}
