












































import {Component, Watch} from 'vue-property-decorator';

import Base from '@/views/Base';
import {NewsItem} from '@/models/News';
import AccountsService from '@/service/AccountsService';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: {}
})
export default class WNewsDropdown extends Base {
	ddNews = [] as NewsItem[];

	get unreadCount(): number {
		return this.$store.getters.unreadNews;
	}

	mounted(): void {
		if (this.user.settings) {
			this.user.settingsObject = JSON.parse(this.user.settings);
		} else {
			this.user.settingsObject = {
				wizard: true,
				readNews: []
			};
		}
		AccountsService.getNews(this.prod).then((news) => {
			const readNews = this.user.settingsObject?.readNews;
			if (!readNews) {
				this.$store.commit('setUnreadNews', news.length);
				this.user.settingsObject
					? (this.user.settingsObject.readNews = [] as string[])
					: '';
			} else {
				this.$store.commit('setUnreadNews', news.length - readNews.length);
			}
			news.forEach(
				(item) => (item.read = readNews ? readNews.includes(item.id) : false)
			);
			this.ddNews = news.slice(0, 2);
		});
	}

	@Watch('unreadCount')
	setUnread(): void {
		const readNews = this.user.settingsObject?.readNews;
		if (readNews) {
			this.ddNews.forEach(
				(item) => (item.read = readNews ? readNews.includes(item.id) : false)
			);
		}
	}
}
