







































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {RegistrationForm} from '@/models/RegistrationForm';
import RegistrationService from '@/service/RegistrationService';
import {Component, Watch} from 'vue-property-decorator';
import Base from '../Base';
import General from './components/General.vue';
import RegistrationSpeakers from './components/Speakers.vue';
import RegistrationDates from './components/Dates.vue';
import Participation from './components/Participation.vue';
import EmailForm from './components/Email.vue';
import Privacy from './components/Privacy.vue';
import RegistrationCertificate from './components/Certificate.vue';
import {Validations} from 'vuelidate-property-decorators';
import {helpers, maxLength, minValue, required, requiredIf, url} from 'vuelidate/lib/validators';
import {Registration, RegistrationDate} from '@/models/Registration';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import {Branding} from '@/models/Branding';
import BrandingService from '@/service/BrandingService';
import {validateEmail} from '@/utils/validators';
import RegistrationDoubleOptIn from './components/DoubleOptIn.vue';
import {AxiosError} from 'axios';
import {registrationUrlExists} from "@/utils/formUrls";
import EndUrl from "@/views/forms/components/EndUrl.vue";

@Component({
	components: {
    EndUrl,
		HeaderLayout,
		RegistrationGeneral: General,
		RegistrationSpeakers,
		RegistrationDates,
		RegistrationParticipation: Participation,
		RegistrationNewEmail: EmailForm,
		RegistrationPrivacy: Privacy,
		RegistrationDoubleOptIn,
		RegistrationCertificate,
		WHeaderBtn
	}
})
export default class RegistrationEdit extends Base {
	form: RegistrationForm = RegistrationService.newRegistrationForm();
	registration: Registration | null = null;
	regId = 0;
  oldUrl: string|null = null;

	branding: Branding | null = null;
	tempFile = '';
	file!: File;

	isEdit = false;

	@Validations()
	validations = {
		form: {
			general: {
				title: { required, maxLength: maxLength(255) },
				subtitle: { maxLength: maxLength(255) },
				url: {
					required,
					noSpecialChars: helpers.regex('alpha', /^[a-zA-Z0-9_.-]*$/),
					maxLength: maxLength(255),
          exists: this.urlExistsValidator
				},
        endUrl: {
          url,
          maxLength: maxLength(255)
        }
			},
			dates: {
				required,
				$each: {
					title: { maxLength: maxLength(255) },
					start: {
						required,
						duplicate: this.startDateDuplicateValidator
					},
					end: {
						required,
						minValue: this.endDateMinValueValidator
					},
					countdown: {
						maxValue: this.countdownBelowLimitValidator,
						minValue: minValue(-1)
					},
					userLimit: {
						minValue: minValue(-1)
					}
				}
			},
			mail: {
				subject: { required, maxLength: maxLength(255) },
				replyTo: { required, maxLength: maxLength(255), email: validateEmail },
				bcc: { maxLength: maxLength(255) },
				text: { required }
			},
			doi: {
				type: {},
				subject: {
					required: requiredIf(context => this.isDoi() && context && context.type !== 'DISABLED'),
					maxLength: maxLength(255)
				},
				text: {
					required: requiredIf(context => this.isDoi() && context && context.type !== 'DISABLED'),
					containsDoiLink: (text: string) => this.form.doi && this.form.doi.type !== 'DISABLED'
						? text.indexOf('{DOI_LINK}') > 0
						: true
				},
				replyTo: {
					required: requiredIf(context => this.isDoi() && context && context.type !== 'DISABLED'),
					maxLength: maxLength(255),
					email: validateEmail
				}
			},
			participation: {
				checkboxes: {
					$each: {
						title: {
							required,
							maxLength: maxLength(4096)
						}
					}
				},
				fields: {
					$each: {
						title: {
							required,
							maxLength: maxLength(4096)
						}
					}
				}
			},
			speakers: {
				$each: {
					name: {
						maxLength: maxLength(255),
						required: requiredIf(val => !!val.image)
					},
					image: {}
				}
			},
			legal: {
				privacyURL: { url,
          required: requiredIf(()=>this.isPrivacyRequired()),
          maxLength: maxLength(255) },
				imprintName: { required, maxLength: maxLength(255) },
				imprintCompany: { maxLength: maxLength(255) }
			}
		}
	};

  isPrivacyRequired() {
    return this.form.general.branding == null || (this.branding && !this.branding.privacy);
  }

	@Watch('form.general.branding')
	getBranding(): void {
		if (this.form.general.branding !== null) {
			BrandingService.getBranding(+this.form.general.branding)
				.then((b) => (this.branding = b))
				.catch(this.showNetworkError);
		} else {
			this.branding = null;
		}
	}

	mounted(): void {
		this.init();
	}

	init(): void {
		const { id } = this.$route.params;
		if (!id) this.$router.push({ name: 'Forms-New-Registration' });
		this.regId = +id;
		// get registration form
		RegistrationService.getRegistration(id)
			.then(reg => {
				if (reg.fields) reg.fields = reg.fields.reverse();
				if (reg.speakers) reg.speakers = reg.speakers.sort((a, b) => (a.idx || 0) - (b.idx || 0));

				this.registration = reg;
				this.form = RegistrationService.registrationToRegistrationForm(reg);
        this.oldUrl = this.form.general.url;

				if (reg.speakers && reg.speakers.length > 0) {
					this.form.speakers.forEach(speaker => {
						if (!speaker.id) return;
						const imageUrl = RegistrationService.getSpeakerImageUrl(this.regId, speaker.id as number);
						speaker.image = { src: imageUrl };
					});
				}

				this.getBranding();
				this.isEdit = true;
				if (reg.title === reg.label) this.form.general.label = '';

				this.$forceUpdate();
			})
			.catch(this.showNetworkError);
	}

	openLink(): void {
		if (!this.registration) return;
		window.open(process.env.VUE_APP_REGISTRATION_URL + this.registration.url, '_blank');
	}

  get phoneRequired(): boolean{
    return (this.form.participation.phone.enabled && this.form.participation.phone.required) || false;
  }

	async submit(): Promise<void> {
		this.$v.form.$touch();

    if (this.$v.$pending) {
      setTimeout(this.submit, 50);
      return;
    }

		if (this.$v.form.$invalid) {
			this.toast(this.t('forms.formHasErrors'), 'danger');
			return;
		}

    // Dial out template
    if(!this.phoneRequired && this.form.mail.mailTextTemplateId == '2'){
      this.toast(this.t('forms.components.email.dialOutWarning'), 'danger');
      return;
    }

		// else if (this.tempFile === '' && this.form.certificate.generateCert) {
		// 	this.toast(this.t('forms.noCertificate'), 'danger');
		// 	return;
		// }

		this.baseLoading = true;

		try {
			const newReg = RegistrationService.registrationFormToRegistration(this.form);
			const oldReg = this.registration;
			if (newReg.label === '') newReg.label = newReg.title;

			if (oldReg !== null) {
				// TODO Reihenfolge updaten
				// update general
				if (oldReg.id) await RegistrationService.updateGeneral(oldReg.id, newReg).catch(this.showNetworkError);

				// compare speakers and speaker images
				const oldSpeakers = oldReg?.speakers;
				const newSpeakers = newReg.speakers;
				if (oldSpeakers && newSpeakers) {
					for (let i = 0; i < oldSpeakers.length; i++) {
						const oldSpeaker = oldSpeakers[i];
						const newSpeaker = newSpeakers.find(sp => sp.id === oldSpeaker.id);

						if (newSpeaker) {
							// compare speaker names
							if (oldSpeaker.name !== newSpeaker.name || oldSpeaker.idx !== newSpeaker.idx) {
								if (oldReg.id && oldSpeaker.id) {
									await RegistrationService.updateSpeaker(oldReg.id, oldSpeaker.id as number, newSpeaker)
										.catch(this.showNetworkError);
								}
							}
						} else {
							// remove speaker
							if (oldReg.id && oldSpeaker.id) {
								await RegistrationService.deleteSpeaker(oldReg.id, oldSpeaker.id as number)
									.catch(this.showNetworkError);
							}
						}
					}
				}
				// add new speakers
				const oldSpeakerIds = oldSpeakers?.map((oldSp) => oldSp.id) || [];
				newSpeakers.filter(newSp => !oldSpeakerIds.includes(newSp.id))
					.forEach(async newSp => {
						if (oldReg.id) {
							const resSp = await RegistrationService.addSpeaker(oldReg.id, newSp)
								.catch(this.showNetworkError);
							if (resSp?.id && oldReg.id && newSp.image?.file) {
								RegistrationService.addImage(resSp.id as number, oldReg.id, newSp.image.file)
									.then(() => console.log('added image and speaker'));
							}
						}
					});

				// compare dates
				const oldDates = oldReg.regDates;
				const newDates = newReg.regDates;
				if (oldDates && newDates) {
					for (let i = 0; i < oldDates.length; i++) {
						const oldDate = oldDates[i];
						const newDate = newDates.find((date) => date.id === oldDate.id);
						if (newDate) {
							if (!newDate.hasLimit) newDate.userLimit = -1;
							if (!newDate.hasCountdown) newDate.countdown = 0;
							// update old date with new one
							if (oldReg.id && newDate.id) {
								await RegistrationService.updateDate(oldReg.id, +newDate.id, newDate)
									.catch(this.showNetworkError);
							}
						} else {
							// delete
							if (oldReg.id && oldDate.id) {
								await RegistrationService.deleteDate(oldReg.id, +oldDate.id)
									.catch(this.showNetworkError);
							}
						}
					}
				}

				// add new nonexistent dates
				if (newDates) {
					const oldDateIds = oldDates?.map(oldDate => oldDate.id) || [];
					newDates.filter(newDate => !oldDateIds.includes(newDate.id))
						.forEach(newDate => {
							if (!oldReg.id) return;
							RegistrationService.addDate(oldReg.id, newDate).catch(this.showNetworkError);
						});
				}

				// compare fields
				const oldFields = oldReg.fields;
				const newFields = newReg.fields;
				if (oldFields && newFields) {
					for (let i = 0; i < oldFields.length; i++) {
						const oldField = oldFields[i];
						const newField = newFields.find((field) => field.id === oldField.id);
						if (newField) {
							// update existing field
							if (oldReg.id && newField.id) {
								await RegistrationService.updateField(oldReg.id, +newField.id, oldField)
									.catch(this.showNetworkError);
							}
						} else {
							// delete existing field
							if (oldReg.id && oldField.id) {
								await RegistrationService.deleteField(oldReg.id, +oldField.id)
									.catch(this.showNetworkError);
							}
						}
					}
				}

				// add new fields
				if (newFields) {
					const oldFieldIds = oldFields?.map(oldField => oldField.id) || [];
					newFields.filter(newField => !oldFieldIds.includes(newField.id))
						.forEach(newField => {
							if (!oldReg.id) return;
							RegistrationService.addField(oldReg.id, newField).catch(this.showNetworkError);
						});
				}
			}

			console.log('all requests resolved');
			this.toast(this.t('forms.formSaved'), 'success');
			this.$router.push('/forms/registration?mode=' + (this.$route.query.mode || 'active') + `&formUrl=${this.form.general.url}`);
		} catch (err: any) {
			this.init();
			console.log(err);
			this.showNetworkError(err);
		}

		this.baseLoading = false;
	}

	errorHandler(err: AxiosError): void {
		this.init();
		this.showNetworkError(err);
	}

	// Validators

	private endDateMinValueValidator(value: number | undefined, date: RegistrationDate): boolean {
		const d = this.findDate(date);
		if (!d) return false;
		if (!d.start) return true;
		if (!value) return false;
		return d.start < value;
	}


	private countdownBelowLimitValidator(value: number | undefined, date: RegistrationDate): boolean {
		const d = this.findDate(date);
		if (!d) return true;
		if (!d.userLimit) return !value;
		if (d.userLimit === -1) return true;
		if (!value) return true;
		return value <= d.userLimit;
	}

	private findDate(date: RegistrationDate): RegistrationDate | undefined {
		const index = this.form.dates.findIndex((d) => d.id === date.id);
		return this.form.dates[index];
	}

	private findSimilarDate(date: RegistrationDate): RegistrationDate | undefined {
		return this.form.dates.find(d =>
				Math.floor(d.start / 1000) === Math.floor(date.start / 1000) &&
				date.id !== d.id
		);
	}

	private startDateDuplicateValidator(value: number | undefined, date: RegistrationDate): boolean {
		return !this.findSimilarDate(date);
	}

  urlExistsValidator(value: string): Promise<boolean> | boolean {
    const url = this.$v.form.general?.url;
    if(value === this.oldUrl) return true;
    if (!url?.$dirty) return true;
    return registrationUrlExists(value).then(
        (value) => !value
    )
  }
}
