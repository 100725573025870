













































import { OperatorAgent } from '@/models/Operator';
import { Component, Prop } from 'vue-property-decorator';
import Base from '../../Base';

@Component({
	components: {}
})
export default class ConferenceAgentsField extends Base {
	@Prop() agent!: OperatorAgent;
	@Prop() validation!: any;

	validateState(name: string): boolean | null {
		const validate = this.validation[name];
		return validate.$dirty ? !validate.$error : null;
	}
}
