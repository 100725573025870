























































































































































































































import {Component, Watch} from 'vue-property-decorator';
import HeaderLayout from "@/layouts/nested/HeaderLayout.vue";
import Base from "@/views/Base";
import {formatNumberString} from "@/utils/filters";
import WPagination from "@/components/WPagination.vue";
import AdminService from "@/service/AdminService";
import {AdminSurvey} from "@/models/Survey"
import AuthenticationService from "@/service/AuthenticationService";

@Component({
  methods: {formatNumberString},
  components: {WPagination, HeaderLayout},
  i18n: {
    messages: {
      en: {
        common: {
          customer: 'Cust.'
        }
      }
    }
  }
})
export default class AdminSurveyForms extends Base {
  surveyOptions = [
    {text: this.t('admin.surveys.activeForms'), value: 'active'},
    {text: this.t('admin.surveys.allForms'), value: 'all'},
    {text: this.$tc('forms.oldForms', 2) as string, value: 'old'},
  ];
  currentOption = 'active';

  searchTerm = '';

  sortBy = 'dateCreated';
  sortDesc = false;

  perPage = 10;
  totalRows = 0;
  totalPages = 0
  currentPage = 1;

  totalActive = 0;
  totalResponses = 0;

  items: AdminSurvey[] = [];

  fields = [
    {
      key: 'index',
      label: this.t('common.numberShort'),
      sortable: false,
      thStyle: {
        width: '50px',
      }
    },
    {
      key: 'dateCreated',
      label: this.t('common.created'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '150px'}
    },
    {
      key: 'lastParticipationDate',
      label: this.t('admin.surveys.lastParticipantShort'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '150px'}
    },
    {
      key: 'countQuestions',
      label: this.t('admin.surveys.questionsShort'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '60px'}
    },
    {
      key: 'customerId',
      label: this.t('common.customer'),
      sortable: false,
      thStyle: {width: '60px'}
    },
    {
      key: 'company',
      label: this.t('common.company'),
      sortable: false,
      thStyle: {width: '150px'}
    },
    {
      key: 'username',
      label: this.t('common.username'),
      sortable: false,
      thStyle: {width: '120px'}
    },
    {
      key: 'participantCount',
      label: this.t('common.participants'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '120px'}
    },
    {
      key: 'evaluation',
      label: '',
      title: this.t('forms.evaluation'),
      // TODO
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '50px'}
    },
    {
      key: 'certificateState',
      label: '',
      // TODO
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '50px'}
    },
    {
      key: 'action',
      label: this.t('common.actions'),
      sortable: false,
    },
  ]

  mounted() {
    this.getAdminSurveys();
    this.getAdminSurveyStatistic();
  }

  @Watch("searchTerm")
  searchTermChanged(): void {
    this.currentPage = 1;
    setTimeout(() => {
      this.getAdminSurveys();
    }, 100);
  }

  @Watch("sortBy")
  sortByChanged(): void {
    this.getAdminSurveys();
  }
  @Watch("perPage")
  perPageChanged(): void {
    this.getAdminSurveys();
  }

  @Watch("currentOption")
  searchOptionChanged(): void {
    this.getAdminSurveys();
  }

  @Watch('currentPage')
  currentPageChanged(): void {
    this.getAdminSurveys();
  }

  @Watch('options.entryCount')
  entryCount(): void {
    this.currentPage = 1;
    this.getAdminSurveys();
  }

  @Watch('sortDesc')
  sortByDirection(): void {
    this.getAdminSurveys();
  }

  getAdminSurveys() {
    AdminService.getAdminSurveys(
        this.currentPage - 1,
        this.perPage,
        this.sortBy,
        this.sortDesc ? 'DESC' : 'ASC',
        this.searchTerm, this.currentOption)
        .then((res) => {
          this.totalRows = res.totalSize;
          this.totalPages = res.totalPages;
          if (res.empty) {
            this.items = [];
          } else {
            this.items = res.content;
          }
        }).catch((e) => {
      console.log(e)
      this.toast(this.t('admin.surveys.surveysCantLoad'), 'danger');
    });
  }

  getAdminSurveyStatistic() {
    AdminService.getAdminSurveyStatistic().then(
        res => {
          this.totalActive = res.totalActiveSurveys;
          this.totalResponses = res.totalResponses;
        }
    )
  }

  loginAs(item: any): void {
    AuthenticationService.impersonate({
      parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
      childUsername: item.username
    })
        .then(() => {
          this.$router.push('/');
        })
        .catch(this.showNetworkError);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  get surveyUrl(): string {
    return process.env.VUE_APP_SURVEY_URL ?? '';
  }

}
