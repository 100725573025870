






















































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {SurveyForm} from '@/models/SurveyForm';
import {Component, Watch} from 'vue-property-decorator';
import Base from '../Base';
import SurveyGeneral from './components/General.vue';
import SurveySpeakers from './components/Speakers.vue';
import SurveyDates from './components/Dates.vue';
import SurveyParticipation from './components/Participation.vue';
import SurveyNewEmail from './components/Email.vue';
import SurveyPrivacy from './components/Privacy.vue';
import SurveyQuestions from './components/Questions.vue';
import SurveyCertificate from './components/Certificate.vue';
import SurveyDuration from './components/Duration.vue';
import {Validations} from 'vuelidate-property-decorators';
import {helpers, maxLength, maxValue, required, requiredIf, url} from 'vuelidate/lib/validators';
import {Branding} from '@/models/Branding';
import BrandingService from '@/service/BrandingService';
import SurveyService from '@/service/SurveyService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import {validateEmail} from '@/utils/validators';
import {SurveyQuestion} from '@/models/Survey';
import {surveyUrlExists} from "@/utils/formUrls";

@Component({
	components: {
		HeaderLayout,
		SurveyGeneral,
		SurveySpeakers,
		SurveyDates,
		SurveyParticipation,
		SurveyNewEmail,
		SurveyPrivacy,
		SurveyQuestions: SurveyQuestions,
		SurveyCertificate: SurveyCertificate,
		SurveyDuration,
		WHeaderBtn
	}
})
export default class SurveyNew extends Base {
	form: SurveyForm = SurveyService.newSurveyForm();

	branding: Branding | null = null;
	tempFile = '';
	file!: File;

	@Validations()
	validations = {
		form: {
			general: {
				title: { required, maxLength: maxLength(255) },
				subtitle: { maxLength: maxLength(255) },
				url: {
					required,
					noSpecialChars: helpers.regex('alpha', /^[a-zA-Z0-9_.-]*$/),
					maxLength: maxLength(255),
          exists: this.urlExistsValidator
				}
			},
			questions: {
				required,
				$each: {
					title: { required, maxLength: maxLength(255) },
					checkboxQuestionOptions: {
						minOptionsCount: (val: any, model: SurveyQuestion) => model.type === 'CHECKBOX' ? model.checkboxQuestionOptions.length >= 2 : true,
						hasRightAnswer: (val: any, model: SurveyQuestion) => model.type === 'CHECKBOX' && model.points ? model.checkboxQuestionOptions.some(o => o.rightAnswer) : true,
						$each: {
							caption: { required, maxLength: maxLength(255) }
						}
					},
					radioQuestionOptions: {
						minOptionsCount: (val: any, model: SurveyQuestion) => model.type === 'RADIO' ? model.radioQuestionOptions.length >= 2 : true,
						hasRightAnswer: (val: any, model: SurveyQuestion) => model.type === 'RADIO' && model.points ? model.radioQuestionOptions.some(o => o.rightAnswer) : true,
						$each: {
							caption: { required, maxLength: maxLength(255) }
						}
					},
					multipleTextQuestionOptions: {
						required: requiredIf(model => model.type === 'MULTIPLE_TEXT'),
						$each: {
							caption: { required, maxLength: maxLength(255) }
						}
					},
					type: { required }
				}
			},
			mail: {
				subject: {
					maxLength: maxLength(255),
					required: requiredIf((mail) => mail.enabled)
				},
				text: { required: requiredIf((mail) => mail.enabled) },
				replyTo: {
					maxLength: maxLength(255),
					required: requiredIf((mail) => mail.enabled),
					email: validateEmail
				},
				type: { required: requiredIf((mail) => mail.enabled) }
			},
			participation: {
				checkboxes: {
					$each: {
						title: {
							required,
							maxLength: maxLength(4096)
						}
					}
				},
				fields: {
					$each: {
						title: {
							required,
							maxLength: maxLength(4096)
						}
					}
				}
			},
			speakers: {
				$each: {
					name: {
						maxLength: maxLength(255),
						required: requiredIf(val => !!val.image)
					},
					image: {}
				}
			},
			legal: {
				privacyURL: { url, required, maxLength: maxLength(255) },
				imprintName: { required, maxLength: maxLength(255) },
				imprintCompany: { maxLength: maxLength(255) }
			},
			certificateData: {
				multipleChoiceCreditsCondition: { maxValue: maxValue(100) },
				multipleParticipation: {}
			},
			duration: {}
		}
	};

	@Watch('form.general.branding')
	getBranding(): void {
		if (this.form.general.branding !== null) {
			BrandingService.getBranding(+this.form.general.branding)
				.then(b => (this.branding = b))
				.catch(this.showNetworkError);
		} else {
			this.branding = null;
		}
	}

	@Watch('form.mail.enabled')
	mailEnabledChanged(): void {
		this.form.mailEnabled = this.form.mail.enabled;
	}

	get mailEnabled(): boolean {
		return this.form.mail.enabled;
	}

	mounted(): void {
		const { id, brandingId } = this.$route.query;
		if (id) {
			SurveyService.getSurvey(id as string)
				.then(survey => {
					this.form = SurveyService.surveyToSurveyForm(survey);
					this.form.general.url = this.id();
				})
				.catch(this.showNetworkError);
		}
		if (brandingId) {
			setTimeout(() => {
				this.form.general.branding = brandingId as string;
				this.getBranding();
			}, 100);
		}
	}

	submit(): void {
		this.$v.form.$touch();
    if (this.$v.$pending) {
      setTimeout(this.submit, 50);
      return;
    }
		if (!this.$v.form.$invalid) {
			this.baseLoading = true;
			const survey = SurveyService.surveyFormToSurvey(this.form);
			console.log(survey, this.file);
			// TODO upload file here if it exists
			SurveyService.addSurvey(survey)
				.then(async (s) => {
					console.log('created survey');
					const speakers = this.form.speakers.filter(
						(sp) => sp.name !== '' && !!sp.image
					);
					const id = s.id;
					// add images for speakers
					if (speakers.length > 0 && id) {
						Promise.all(
							speakers.map(speaker =>
								SurveyService.addSpeaker(id, speaker).then(sp => {
									const image = speaker.image;
									if (image && image.file && sp.id) {
										return SurveyService.addImage(sp.id as number, id, image.file);
									}
								})
							)
						).catch(this.showNetworkError);
					}
					if (this.form.questions.length > 0 && id) {
						for (const question of this.form.questions) {
							await SurveyService.addQuestion(id, question)
								.then()
								.catch(this.showNetworkError);
						}
					}
					if (this.file && id) {
						SurveyService.addCertificate(id, this.file)
							.then()
							.catch(this.showNetworkError);
					}
				})
				.then(() => {
					console.log('added photos, speakers, certificate');
					this.toast(this.t('forms.formCreated'), 'success');
					this.$router.push('/forms/survey?formUrl=' + this.form.general.url);
				})
				.catch(this.showNetworkError)
				.finally(() => (this.baseLoading = false));
		} else if (this.tempFile === '' && this.form.certificateEnabled) {
			this.toast(this.t('forms.noCertificateError'), 'danger');
		} else {
			this.form.questions.forEach((question) => (question.open = true));
			this.toast(this.t('forms.formHasErrors'), 'danger');
		}
	}

  urlExistsValidator(value: string): Promise<boolean> | boolean {
    const url = this.$v.form.general?.url;
    if (!url?.$dirty) return true;
    return surveyUrlExists(value).then(
        (value) => !value
    )
  }
}
