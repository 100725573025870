






























































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { max, throttle } from 'lodash';
import { EventBus, Events } from '@/EventBus';

@Component
export default class HeaderLayout extends Vue {
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() bread!: any[];
  @Prop({ type: Boolean, default: false }) notFixed!: boolean;
  @Prop() cols!: number;
  @Prop() offset!: number;
  @Prop() md!: number;
  @Prop() offsetMd!: number;
  @Prop() lg!: number;
  @Prop() offsetLg!: number;
  @Prop() xl!: number;
  @Prop() offsetXl!: number;
  @Prop() sm!: number;
  @Prop() xs!: number;
  @Prop({ default: null }) newButton!: string | { name: string; query?: any };
  @Prop({ default: false, type: Boolean }) showNewButton!: boolean;
  @Prop({ type: Function, default: null }) newButtonAction!: () => void;

  headerHeight = '0px';
  throttleFunction = throttle(this.shrinkHeader, 50);
  shrinkClass = false;

  mounted(): void {
    this.updateHeaderHeight();
    window.addEventListener('resize', this.updateHeaderHeight, false);
  }

  updateHeaderHeight() {
    const header = this.$refs['header'] as HTMLElement;
    this.headerHeight = '108px';
    this.shrinkHeader = this.shrinkHeader.bind(this);
    window.addEventListener('scroll', this.throttleFunction, false);
    if (window.innerWidth < 768) {
      this.headerHeight = header.offsetHeight + 12 + 'px' || '0';
    }
    setTimeout(() => {
      const scrollOffset = window.innerWidth < 768 ? window.scrollY : 0;
      EventBus.$emit(Events.HeaderHeightChanged, (max([header.clientHeight - scrollOffset, 0]) || 0) + 65);
    }, 250);
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.throttleFunction, false);
    window.removeEventListener('resize', this.updateHeaderHeight, false);
  }

  shrinkHeader(): void {
    this.shrinkClass = window.scrollY > 50;
    setTimeout(() => {
      const header = this.$refs['header'] as HTMLElement;
      const scrollOffset = window.innerWidth < 768 ? window.scrollY : 0;
      EventBus.$emit(Events.HeaderHeightChanged, (max([header.clientHeight - scrollOffset, 0]) || 0) + 65);
    }, 250);
  }
}
