









































































































































































































































































import {RegistrationDate} from '@/models/Registration';
import {Component, Prop, PropSync} from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import RegistrationService from '@/service/RegistrationService';
import {quillEditor} from 'vue-quill-editor';
import {BFormInput} from 'bootstrap-vue';
import RegistrationDateItem from './DateItem.vue';
import {defaultEditorOptions} from '@/utils/quill';

@Component({
  components: { WSwitch, quillEditor, RegistrationDateItem }
})
export default class RegistrationDates extends Base {
  @Prop() form!: RegistrationDate[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() validation!: any;
  @Prop() regId!: number;
  @PropSync('multiple') multipleSelection!: boolean;
  @PropSync('hideOld') hideOldDates!: boolean;


  extras = false;

  // 10, 20, 30, 45, 60
  lastRegistrations = [
    { value: 10, text: this.$t('forms.nMinutesBeforeStart', { minutes: 10 }) },
    { value: 20, text: this.$t('forms.nMinutesBeforeStart', { minutes: 20 }) },
    { value: 30, text: this.$t('forms.nMinutesBeforeStart', { minutes: 30 }) },
    { value: 45, text: this.$t('forms.nMinutesBeforeStart', { minutes: 45 }) },
    { value: 60, text: this.$t('forms.nMinutesBeforeStart', { minutes: 60 }) }
  ];

  editorOptions = {
    ...defaultEditorOptions,
    placeholder: this.t('forms.dateDescription') + '...'
  };

  get newDateId(): number {
    return this.form[this.form.length - 1].id || -1;
  }

  get open(): boolean {
    return !!this.form.find((date) => date.open);
  }

  toggleAll(): void {
    if (this.open) {
      this.form.forEach((date) => (date.open = false));
    } else {
      this.form.forEach((date) => (date.open = true));
    }
  }

  addDate(): void {
    this.form.push(RegistrationService.newDate(this.form.length + 1));
  }

  deleteDate(id?: number): void {
    const index = this.form.findIndex((date) => date.id === id);
    this.form.splice(index, 1);
  }

  deleteDateParticipants(date: RegistrationDate): void {
    if (date.id && this.regId) {
      this.$bvModal
        .msgBoxConfirm(
          this.$t('modals.deleteDateParticipantsLong.description', { title: date.title }) as string,
          {
            title: this.t('modals.deleteDateParticipantsLong.title'),
            cancelTitle: this.t('common.cancel'),
            centered: true,
            okTitle: this.$t('modals.deleteDateParticipantsLong.ok', { count: date.countRegisteredUser }) as string,
            okVariant: 'danger'
          }
        )
        .then((res) => {
          if (res && date.id) {
            RegistrationService.deleteUsersForDate(this.regId, +date.id)
              .then(() => {
                date.countRegisteredUser = 0;
                date.deleteCustomersAlert = false;
                this.toast(this.t('modals.deleteDateParticipantsLong.deleted'), 'success');
              })
              .catch(this.showNetworkError);
          }
        });
    }
  }

  closeAlert(date: RegistrationDate): void {
    if (date.deleteCustomersAlert) {
      date.deleteCustomersAlert = false;
      this.$forceUpdate();
    }
  }

  validateState(index: number, name: string): boolean | null {
    const validate = this.validation.$each[index][name];
    return validate.$dirty ? !validate.$error : null;
  }

  editDate(date: RegistrationDate): void {
    date.edit = !date.edit;
    this.$forceUpdate();
    setTimeout(() => {
      const speakerRef = 'date-' + date.id;
      const refs = this.$refs[speakerRef] as BFormInput[];
      if (refs.length > 0) {
        refs[0].focus();
      }
    }, 50);
  }

  onTitleBlur(date: RegistrationDate, index: number): void {
    date.edit = false;
    this.$forceUpdate();
  }

  hasLimitChanged(date: RegistrationDate): void {
    if (!date.hasLimit) {
      date.userLimit = 100;
      if (date.countdown && date.countdown > date.userLimit) {
        date.countdown = date.userLimit;
      }
    } else {
      date.userLimit = undefined;
    }
  }

  hasCountdownChanged(date: RegistrationDate): void {
    if (!date.hasCountdown) {
      date.countdown = 0;
    } else {
      date.countdown = undefined;
    }
  }

  isNewDate(date: RegistrationDate): boolean {
    return (
      !!(date.dateCreated && date.dateCreated < Date.now() + 4 * 1000 * 1000) &&
      date.id === this.newDateId
    );
  }

  update(): void {
    this.$forceUpdate();
  }
}
