






























































































































































































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {Component} from 'vue-property-decorator';
import Base from '@/views/Base';
import ImportItem from './ImportItem.vue';
import ContactsService from '@/service/ContactsService';
import {ImportContact, SelectOption} from '@/models/Contact';
import {Group} from '@/models/Group';
import GroupsService from '@/service/GroupsService';
import {readXLSX} from '@/utils/excel';
import {validateEmail} from '@/utils/validators';
import WPagination from '@/components/WPagination.vue';

@Component({
  components: {
    HeaderLayout,
    ImportItem,
    WPagination
  }
})
export default class Import extends Base {
  bread = [
    {
      text: this.t('adressbook.adressbook'),
      to: {name: 'Documents-Adressbook'}
    },
    {text: this.t('common.import'), active: true}
  ];
  encodingOptions = [
    {text: 'ISO-8859-1', value: 'ISO-8859-1'},
    {text: 'UTF-8', value: 'UTF-8'},
    {text: 'ANSI', value: 'ansi_x3.4-1968'}
  ];
  encoding = 'UTF-8';
  parsedData: ImportContact[] | null = null;
  options: SelectOption[] = [];
  fieldNames: { [key: string]: null | number } = {
    name: null,
    telephone: null,
    email: null,
    company: null
  };

  verified = false;
  draggedOver = false;
  loading = false;
  parseLoading = false;

  groups: Group[] = [];
  groupOptions: SelectOption[] = [];
  selectedGroup = '';

  data: string[][] = [];
  validData: ImportContact[] = [];
  dupletteData: ImportContact[] = [];
  invalidData: ImportContact[] = [];
  deletedData: ImportContact[] = [];

  showValid = false;
  importView: 'valid' | 'invalid' | 'deleted' | 'duplette' = 'valid';
  importTitle = '';
  loadingGroups = false;

  entryCount = 10;
  currentPage = 1;

  get selectedData(): ImportContact[] {
    switch (this.importView) {
      case 'valid': {
        this.importTitle = this.t('adressbook.import.validData');
        return this.validData;
      }
      case 'duplette': {
        this.importTitle = this.t('adressbook.import.duplettes');
        return this.dupletteData;
      }
      case 'invalid': {
        this.importTitle = this.t('adressbook.import.invalidData');
        return this.invalidData;
      }
      case 'deleted': {
        this.importTitle = this.t('adressbook.import.deletedData');
        return this.deletedData;
      }
      default:
        return [];
    }
  }

  get filteredSelectedData(): ImportContact[] {
    const end = this.currentPage * this.entryCount;
    const start = end - this.entryCount;
    return this.selectedData.slice(start, end);
  }

  mounted(): void {
    this.getGroups();
  }

  getGroups(): void {
    this.loadingGroups = true;
    GroupsService.getGroups()
        .then((groups) => {
          this.groupOptions = [
            {text: this.t('adressbook.noGroup'), value: ''},
            ...groups.map((group) => ({text: group.name, value: group.name}))
          ];
          this.groups = groups;
          const group = this.$route.query.group;
          if (group) {
            this.selectedGroup =
                this.groupOptions.find((g) => g.value === group)?.text || '';
          }
          this.loadingGroups = false;
        })
        .catch(this.showNetworkError);
  }

  updateData(): void {
    const numbers: string[] = [];
    const emails: string[] = [];
    if (this.parsedData) {
      for (let i = 0; i < this.parsedData.length; i++) {
        const item = this.parsedData[i];
        if (
            numbers.includes(item.telephone) ||
            (emails.includes(item.email) && item.email)
        ) {
          this.dupletteData.push(item);
        } else {
          numbers.push(item.telephone);
          emails.push(item.email);
          if (item.valid) this.validData.push(item);
          if (!item.valid) this.invalidData.push(item);
        }
      }
    }
    this.importView = this.invalidData.length > 0 ? 'invalid' : 'valid';
  }

  fileUploaded(e: Event): void {
    this.parseLoading = true;
    const file = ((e.target as HTMLInputElement).files as FileList)[0];
    if (file.type.includes('csv')) {
      const self = this as any;
      self.$papa.parse(file, {
        encoding: this.encoding,
        delimitersToGuess: [',', ';'],
        complete: (results: { data: string[][] }) => {
          this.draggedOver = false;
          const header: string[] = results.data[0];

          // Map possible columns from csv-sheet and add '-' option to select nothing
          this.options =
              header.length === 1
                  ? header[0].split(';').map((v, i) => ({value: i, text: v}))
                  : header.map((v, i) => ({value: i, text: v}));
          this.setFieldNames(this.options);
          this.options.unshift({value: -1, text: ' '});

          if (!this.checkHeaderForNumber(header)) results.data.shift();
          this.data = results.data.map((entry) =>
              entry.length === 1 ? entry[0].split(';') : entry
          );
          this.data = this.data.filter((entry) => entry.length > 1);
          this.parsedData = [];
          this.parseLoading = false;
        }
      });
    } else if (file.type.includes('sheet')) {
      readXLSX(file).then((strings) => {
        if (strings.length > 0) {
          this.draggedOver = false;

          // Find empty columns
          let nulls: number[] = [];
          for (let i = strings[0].length - 1; i >= 0; i--) {
            if (strings[0][i] == null) {
              nulls.push(i)
            }
          }
          // remove empty columns
          strings = strings.map((row) => {
            for (let i of nulls) {
              row.splice(i, 1);
            }
            return row;
          });


          // Map possible columns from xlsx-sheet and add '-' option to select nothing
          this.options = strings[0].map((v, i) => ({value: i, text: v}));
          this.setFieldNames(this.options);
          this.options.unshift({value: -1, text: ' '});
          this.data = strings.slice(1);
          this.parsedData = [];
        } else {
          {
            this.toast(this.t('adressbook.import.checkExcel'), 'danger');
          }
        }
      }).catch(e=> {
        console.error(e);
        this.toast(this.t('adressbook.excelFileError'), 'danger', 10000);
      }).finally(() => this.parseLoading = false);
    }
  }

  checkHeaderForNumber(header: string[]): boolean {
    let result = false;
    for (let col of header) {
      if (col.replace(/\s/g, '').match(/\d{6,}/gm)) {
        result = true;
        break;
      }
    }
    return result;
  }

  setFieldNames(header: SelectOption[]): void {
    const specialCase = header.find((v) => v.text === 'Alle Teilnehmer_Name');
    let nameIndex: number;
    if (specialCase) {
      nameIndex = header.findIndex((v) => v.text === 'Alle Teilnehmer_Name');
    } else {
      nameIndex = header.findIndex(
				(v) =>
					v.text.toLowerCase().includes('enutzername') ||
					v.text.toLowerCase().includes('name') ||
					v.text.toLowerCase().includes('kontakt')
      );
    }

    // requirement by Torsten to prioritize Telefon1 (geschäftlich) as match for phone numbers to support
    // csv files from the old system. Telefon1 (geschäftlich) is a required field in the old systtem.
    let phoneIndex = header.findIndex((v) =>
			v.text.toLowerCase().includes('telefon1 (gesch')
    );
    if (phoneIndex === -1)
      phoneIndex = header.findIndex(
          (v) =>
					v.text.toLowerCase().includes('nummer') ||
					v.text.toLowerCase().includes('telefon')
      );

    const emailIndex = header.findIndex((v) =>
			v.text.toLowerCase().includes('mail')
    );
    const companyIndex = header.findIndex((v) =>
			v.text.toLowerCase().includes('firma')
    );
    this.fieldNames.name = nameIndex >= 0 ? nameIndex : null;
    this.fieldNames.telephone = phoneIndex >= 0 ? phoneIndex : null;
    this.fieldNames.email = emailIndex >= 0 ? emailIndex : null;
    this.fieldNames.company = companyIndex >= 0 ? companyIndex : null;
  }

  cancel(): void {
    this.data = [];
    this.parsedData = null;
    this.invalidData = [];
    this.validData = [];
    this.deletedData = [];
    this.verified = false;
    this.dupletteData = [];
  }

  deleteContact(invalidIndex: number): void {
    if (this.parsedData) {
      const deletedItem = this.invalidData.splice(invalidIndex, 1);
      this.deletedData = [deletedItem[0], ...this.deletedData];
    }
  }

  submitContact(invalidIndex: number, contactValid?: boolean): void {
    if (this.parsedData && contactValid) {
      const validItem = this.invalidData.splice(invalidIndex, 1);
      this.validData.unshift(validItem[0]);
    }
  }

  verify(): void {
    if (this.parsedData) {
      this.parsedData = this.data.map((item: any) => ({
        id: this.id(),
        name: this.fieldNames.name !== null ? item[this.fieldNames.name] : '',
        telephone:
            this.fieldNames.telephone !== null
                ? item[this.fieldNames.telephone]
                    ? item[this.fieldNames.telephone]
                    : ''
                : '',
        email:
            this.fieldNames.email !== null
                ? item[this.fieldNames.email].text
                    ? item[this.fieldNames.email].text
                    : item[this.fieldNames.email]
                : '',
        company:
            this.fieldNames.company !== null ? item[this.fieldNames.company] : '',
        groups: []
      }));

      this.loading = true;
      const numbers = this.parsedData.map((item) => item.telephone);

      ContactsService.validatePhoneNumbers(numbers)
          .then((validatedNumbers) => {
            this.parsedData?.forEach((item, index) => {
              const validatedNum = validatedNumbers[index];
              this.checkItemValidity(item, validatedNum?.validNumber);
              item.telephone = !validatedNum?.validNumber
                  ? item.telephone
                  : validatedNum?.international;
            });
            this.updateData();
            this.verified = true;
            this.loading = false;
          })
          .catch((err) => {
            this.toast(err.message, 'danger');
          });
    }
  }

  private checkItemValidity(item: any, numberValid: boolean) {
    const nameValid = item.name && item.name !== '';
    // TODO
    const emailValid =
        item.email === '' || (item.email && validateEmail(item.email));
    // const emailValid = item.email && validateEmail(item.email);
    item.valid = nameValid && emailValid && numberValid;
    item.numberValid = numberValid;
  }

  truncateTooltip(text: string): string {
    return text ? (text.length >= 23 ? text : '') : '';
  }

  importData(): void {
    if (this.parsedData) {
      const groups = this.groups.filter((i) => i.name === this.selectedGroup);
      const contacts = this.validData.map((el) => {
        el.groupId = this.selectedGroup && groups ? groups[0].id : '';
        const item: ImportContact = {
          email: el.email,
          name: el.name,
          telephone: el.telephone,
          company: el.company,
          clientSideId: el.id
        };
        if (el.groupId) item.groupId = el.groupId + '';
        return item;
      });

      ContactsService.addContacts(contacts)
          .then((res) => {
            console.log(res);
            this.$router.push({name: 'Documents-Adressbook'});
            setTimeout(() => {
              this.toast(this.t('adressbook.import.dataImported'), 'success');
            });
          })
          .catch(this.showNetworkError);
    }
  }
}
