
























































































import Base from '../../Base';
import ConferenceAgentsField from './ConferenceAgentsField.vue';
import { Component, Prop } from 'vue-property-decorator';
import { OperatorAgent } from '@/models/Operator';
import OperatorService from '@/service/OperatorService';

@Component({
	components: { ConferenceAgentsField }
})
export default class ConferenceAgents extends Base {
	@Prop() form!: OperatorAgent[];
	@Prop() validation!: any;

	open = true;

	toggleOpen(): void {
		this.open = !this.open;
	}

	addAgent(): void {
		this.form.push(OperatorService.newAgent());
	}

	deleteAgent(agent: OperatorAgent): void {
		const idx = this.form.findIndex((a) => a.id === agent.id);
		this.form.splice(idx, 1);
		this.validation.$touch();
	}
}
/*

*/
