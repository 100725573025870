

























































































import {Component, Prop, Watch} from 'vue-property-decorator';
import Base from '../../Base';
import {AdminRegDate} from '@/models/AdminForms';

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: {}
})
export default class RegistrationFormsDay extends Base {
  @Prop() fields!: any[];
  @Prop() datesMap!: AdminRegDate[];
  @Prop() date!: string;

  loadArray = new Int32Array(60 * 24);
  perDay = 0;

  mounted(): void {
    this.calculateLoadArray();
  }

  @Watch('datesMap')
  datesChanged(): void {
    this.calculateLoadArray();
  }

  calculateLoadArray(): void {
    //
    this.datesMap.forEach((date) => {
      // - die ersten 5 Min einer Konferenz = 100% der Teilnehmer
      const minutes = this.minutesFrom(date.start);
      Array.from(Array(5).keys()).forEach((num) => {
        this.loadArray[minutes + num] += date.countRegisteredUsers;
      });
      // - 6-29 Min = 90%
      Array.from(Array(25).keys()).forEach((num) => {
        this.loadArray[minutes + num + 5] += Math.ceil(
            date.countRegisteredUsers * 0.9
        );
      });
      // - 30-59 Min = 70%
      Array.from(Array(29).keys()).forEach((num) => {
        this.loadArray[minutes + num + 30] += Math.ceil(
            date.countRegisteredUsers * 0.7
        );
      });
      // - 60-89 Min = 30%
      Array.from(Array(29).keys()).forEach((num) => {
        this.loadArray[minutes + num + 60] += Math.ceil(
            date.countRegisteredUsers * 0.3
        );
      });
      this.perDay += date.countRegisteredUsers;
      date.perDay = this.perDay;
    });
  }

  minutesFrom(start: number): number {
    const date = new Date(start);
    return date.getHours() * 60 + date.getMinutes();
  }

  systemload(start: number): number {
    return this.loadArray[this.minutesFrom(start)] || 0;
  }

  loginAs(item: any): void {
    this.$emit('loginAs', item);
  }

  openForm(url: string): void {
    this.$emit('openForm', url);
  }
}
