









































import Base from '@/views/Base';
import {Component, Prop} from 'vue-property-decorator';

@Component({})
export default class PlaceholdersModal extends Base {
  @Prop() formType!: string;
  @Prop({type: String, default: 'https://www.csnstart.de'})
  dataConferenceUrl?: string;
  @Prop({type: Boolean}) isReg!: boolean;

  regPlaceholders = [
    {name: 'TITEL', caption: this.$t('modals.placeholder.regTitle')},
    {name: 'UNTERTITEL', caption: this.$t('modals.placeholder.regSubtitle')},
    {name: 'BESCHREIBUNG', caption: this.$t('modals.placeholder.regDescription')},
    {name: 'ANREDE', caption: this.$t('modals.placeholder.salutation')},
    {name: 'VORNAME', caption: this.$t('common.firstname')},
    {name: 'NACHNAME', caption: this.$t('common.lastname')},
    {name: 'NAME', caption: this.$t('common.fullname')},
    {name: 'RUFNUMMER', caption: this.$t('modals.placeholder.numberWithCountryCode')},
    {name: 'EMAIL', caption: this.$t('modals.placeholder.email')},
    {name: 'FIRMA', caption: this.$t('common.company')},
    {name: 'STRASSE', caption: this.$t('common.streetAndNumber')},
    {name: 'STADT', caption: this.$t('common.zipCodeAndCity')},
    {name: 'VV_ID', caption: this.$t('forms.gutBeratenID')},
    {name: 'PARTNER_ID', caption: this.$t('common.partnerID')},
    {name: 'KOMMENTAR', caption: this.$t('common.comment')},
    {name: 'DATUM', caption: this.$t('modals.placeholder.regDateTime')},
    {name: 'PIN', caption: this.$t('modals.placeholder.regPin')},
    {name: 'EINWAHLNUMMERN', caption: this.$t('modals.placeholder.regDialInNumber')},
    {name: 'EINWAHLNUMMERN_INT', caption: this.$t('modals.placeholder.regDialInInternational')},
    {name: 'ACCOUNTINHABER', caption: this.$t('modals.placeholder.owner')},
    {name: 'DOI_LINK', caption: this.$t('modals.placeholder.regDoiLink')},
    {name: 'WEBLINK', caption: this.$t('modals.placeholder.regPresentationLink', {url: this.dataConferenceUrl})},
    {name: 'BUTTON', caption: this.$t('modals.placeholder.regLoginButton', {url: this.dataConferenceUrl})},
  ];

  surveyPlaceholders = [
    {name: 'TITEL', caption: this.$t('modals.placeholder.surveyTitle')},
    {name: 'UNTERTITEL', caption: this.$t('modals.placeholder.surveySubtitle')},
    {name: 'BESCHREIBUNG', caption: this.$t('modals.placeholder.surveyDescription')},
    {name: 'ANREDE', caption: this.$t('modals.placeholder.salutation')},
    {name: 'VORNAME', caption: this.$t('common.firstname')},
    {name: 'NACHNAME', caption: this.$t('common.lastname')},
    {name: 'NAME', caption: this.$t('common.fullname')},
    {name: 'RUFNUMMER', caption: this.$t('modals.placeholder.numberWithCountryCode')},
    {name: 'EMAIL', caption: this.$t('modals.placeholder.email')},
    {name: 'FIRMA', caption: this.$t('common.company')},
    {name: 'STRASSE', caption: this.$t('common.streetAndNumber')},
    {name: 'STADT', caption: this.$t('common.zipCodeAndCity')},
    {name: 'VV_ID', caption: this.$t('forms.gutBeratenID')},
    {name: 'PARTNER_ID', caption: this.$t('common.partnerID')},
    {name: 'KOMMENTAR', caption: this.$t('common.comment')},
    {name: 'DATUM', caption: this.$t('modals.placeholder.surveyDateTime')},
    {name: 'PASSED|Freitext', caption: this.$t('modals.placeholder.surveyPassed')},
    {name: 'FAILED|Freitext', caption: this.$t('modals.placeholder.surveyFailed')},
    {name: 'E_PUNKTE', caption: this.$t('modals.placeholder.surveyReachedPoints')},
    {name: 'G_PUNKTE', caption: this.$t('modals.placeholder.surveyTotalPoints')},
    {name: 'E_PROZENT', caption: this.$t('modals.placeholder.surveyPercent')},
    {name: 'ACCOUNTINHABER', caption: this.$t('modals.placeholder.owner')}
  ];

  surveyCertPlaceholders = [
    {name: 'TITEL', caption: this.$t('modals.placeholder.surveyTitle')},
    {name: 'UNTERTITEL', caption: this.$t('modals.placeholder.surveySubtitle')},
    {name: 'ANREDE', caption: this.$t('modals.placeholder.salutation')},
    {name: 'VORNAME', caption: this.$t('common.firstname')},
    {name: 'NACHNAME', caption: this.$t('common.lastname')},
    {name: 'NAME', caption: this.$t('common.fullname')},
    {name: 'VV_ID', caption: this.$t('forms.gutBeratenID')},
    {name: 'FIRMA', caption: this.$t('common.company')},
    {name: 'PARTNER_ID', caption: this.$t('common.partnerID')},
    {name: 'E_PUNKTE', caption: this.$t('modals.placeholder.surveyReachedPoints')},
    {name: 'G_PUNKTE', caption: this.$t('modals.placeholder.surveyTotalPoints')},
    {name: 'E_PROZENT', caption: this.$t('modals.placeholder.surveyPercent')},
    {name: 'DATUM', caption: this.$t('modals.placeholder.surveyDate')}
  ];

  regCertPlaceholders = [
    {name: 'TITEL', caption: this.$t('modals.placeholder.regTitle')},
    {name: 'UNTERTITEL', caption: this.$t('modals.placeholder.regSubtitle')},
    {name: 'ANREDE', caption: this.$t('modals.placeholder.salutation')},
    {name: 'VORNAME', caption: this.$t('common.firstname')},
    {name: 'NACHNAME', caption: this.$t('common.lastname')},
    {name: 'NAME', caption: this.$t('common.fullname')},
    {name: 'VV_ID', caption: this.$t('forms.gutBeratenID')},
    {name: 'FIRMA', caption: this.$t('common.company')},
    {name: 'PARTNER_ID', caption: this.$t('common.partnerID')},
    {name: 'MINUTEN', caption: this.$t('modals.placeholder.connectionTime')},
    {name: 'DATUM', caption: this.$t('modals.placeholder.surveyDate')}
  ];

  get title(): string {
    switch (this.formType) {
      case 'registration':
        return this.$t('common.regForms') as string;
      case 'survey':
        return this.$t('common.surveyForms') as string;
      default:
        return this.$t('forms.certificates') as string;
    }
  }

  get certPlaceholders() {
    return this.isReg ? this.regCertPlaceholders : this.surveyCertPlaceholders;
  }

  updateConferenceUrl(): void {
    const weblink = this.regPlaceholders.find(p => p.name === 'WEBLINK');
    if (weblink) weblink.caption = this.$t('modals.placeholder.regPresentationLink', {url: this.dataConferenceUrl});
    const button = this.regPlaceholders.find(p => p.name === 'BUTTON');
    if (button) button.caption = this.$t('modals.placeholder.regLoginButton', {url: this.dataConferenceUrl});
  }
}
