









































































import {BookingListItem} from '@/models/Booking';
import Base from '@/views/Base';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {SelectOption} from '@/models/common';
import {RegistrationDate} from '@/models/Registration';
import {dateToDateTimeString, formatDateCreated} from '@/utils/filters';
import RegistrationService from '@/service/RegistrationService';

@Component
export default class SelectRegDateModal extends Base {
  @Prop() bookingItem!: BookingListItem;

  regForms: SelectOption[] = [];

  regFormId: number | null = null;
  regDateId: number | null = null;
  regDateValid: boolean | null = null;
  phoneNotRequiredForms: number[] = [];

  mounted(): void {
    this.getRegistrations();
  }

  getRegistrations(): void {
    RegistrationService.getRegistrations(0, 9999, 'dateCreated', 'DESC')
        .then((forms) => {
          if (!forms.empty) {
            this.regForms = forms.content.map((form) => ({
              text: form.label || form.title,
              value: form.id,
              data: form.regDates
            }));
            this.regForms.unshift({
              text: this.$t('conference.noRegForm') as string,
              value: null,
              data: []
            });
          }
          this.phoneNotRequiredForms = forms.content.filter(f => !(f.phone && f.phoneRequired) && f.id != null).map(f => f.id as number);
        })
        .catch(this.showNetworkError);
  }

  submit(ok: () => void): void {
    if (!this.regFormId) {
      this.deleteBookingFromRegDate(ok);
    } else if (this.regFormId && !this.regDateId) {
      this.regDateValid = false;
    } else {
      if (this.regFormId && this.regDateId) {
        const date: RegistrationDate = this.regDates
            .filter((date) => date.value === this.regDateId)
            .pop()?.data;

        RegistrationService.updateDate(this.regFormId, this.regDateId, {
          ...date,
          bookingId: this.bookingItem?.bookingId
              ? this.bookingItem?.bookingId
              : this.bookingItem.id
        })
            .then(() => this.closeModal(ok))
            .catch(this.showNetworkError);
      }
    }
  }

  closeModal(ok: () => void): void {
    this.$emit('submit', {regId: this.regFormId, dateId: this.regDateId});
    this.resetRegDateModal();
    this.toast(this.$t('forms.formSuccess') as string, 'success');
    ok();
  }

  deleteBookingFromRegDate(ok: () => void): void {
    if (this.bookingItem && this.bookingItem.registrationForm) {
      RegistrationService.deleteBookingFromDate(
          this.bookingItem.registrationForm.regId,
          this.bookingItem.registrationForm.dateId
      ).then(() => this.closeModal(ok));
    }
  }

  resetRegDateModal(): void {
    this.regFormId = null;
    this.regDateId = null;
    this.regDateValid = null;
  }

  @Watch('bookingItem.id')
  itemChanged(): void {
    if (this.bookingItem && this.bookingItem.registrationForm) {
      this.regFormId = this.bookingItem.registrationForm.regId;
      this.regDateId = this.bookingItem.registrationForm.dateId;
      this.regDateValid = true;
    } else {
      this.resetRegDateModal();
    }
  }

  @Watch('regFormId')
  resetRegDate(): void {
    if (this.regFormId !== this.bookingItem?.registrationForm?.regId) {
      this.regDateId = null;
      this.regDateValid = null;
    }
  }

  @Watch('regDateId')
  validateRegDate(): void {
    this.regDateValid = this.regFormId ? this.regDateId !== null : null;
  }

  formatDateCreated = formatDateCreated;

  get regDates(): SelectOption[] {
    let selectOptions = this.regForms.filter(
        (form) => form.value === this.regFormId
    );
    if (selectOptions.length === 1) {
      let regDateOptions = selectOptions
          .pop()
          ?.data.sort(
              (a: RegistrationDate, b: RegistrationDate) => a.start - b.start
          )
          .map((date: RegistrationDate) => ({
            text:
                (date.title || this.$t('forms.noTitle')) +
                ' (' +
                dateToDateTimeString(date.start) +
                ')',
            value: date.id,
            data: date
          }));

      regDateOptions.unshift({
        text: this.$t('forms.noTitle') + '...',
        value: null,
        disabled: true
      });
      return regDateOptions;
    } else {
      return [];
    }
  }

  get dialOutWithNoPhone(): boolean {
    return this.phoneNotRequiredForms.includes(this.regFormId || -1)
        && this.bookingItem.functions != undefined
        && this.bookingItem.functions.dialOut && !this.bookingItem.functions.dialIn
  }

  get saveButtonDisabled(): boolean {
    return (this.regFormId === null ? false : this.regDateId === null) || this.dialOutWithNoPhone;
  }
}
