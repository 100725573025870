




























































































































































import { Component, Watch } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WPagination from '@/components/WPagination.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import { ListOptions } from '@/models/Booking';
import CustomersService from '@/service/CustomersService';
import { Customer } from '@/models/Customer';
import { createXLSX } from '@/utils/excelAdmin';
import { Column } from '@/utils/excel';
import { Permission } from '@/models/Account';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: { HeaderLayout, WListOptions, WPagination, WHeaderBtn }
})
export default class Customers extends Base {
	items: any[] = [];

	fields = [
		{
			key: 'id',
			label: this.t('common.id'),
			sortable: true,
			thStyle: { width: '60px' },
			sortDirection: 'desc'
		},
		{
			key: 'customerNumber',
			label: this.t('admin.customerNumberShort'),
			sortable: true,
			thStyle: { width: '110px' },
			sortDirection: 'desc'
		},
		{ key: 'title', label: this.t('common.company'), sortable: true, sortDirection: 'desc' },
		{ key: 'name', label: this.t('common.name'), sortable: true, sortDirection: 'desc' },
		{
			key: 'userLimit',
			label: this.t('admin.users'),
			sortable: true,
			thStyle: { width: '140px' },
			sortDirection: 'desc'
		},
		{
			key: 'participantLimit',
			label: this.t('admin.participantLimitShort'),
			thStyle: { width: '100px' },
			sortable: true,
			sortDirection: 'desc'
		},
		{
			key: 'dateCreated',
			label: this.t('common.created'),
			thStyle: { width: '120px' },
			sortable: true,
			sortDirection: 'desc'
		},
		{
			key: 'active',
			label: this.t('common.status'),
			thStyle: { width: '120px' },
			sortable: true,
			sortDirection: 'desc'
		},
		{
			key: 'actions',
			label: this.t('common.actions'),
			thStyle: { width: '90px' },
			thClass: 'show-content-md'
		}
	];

	options: ListOptions = {
		entryCount: 100,
		searchTerm: ''
	};

	currentPage = 1;
	totalPages = 1;
	totalRows = 0;

	sortBy = 'dateCreated';
	sortDesc = true;

	mounted(): void {
		this.loadFromLocalStorage();
		this.getCustomers();
	}

	@Watch('options.searchTerm')
	search(): void {
		this.currentPage = 1;
		setTimeout(() => {
			this.getCustomers();
		}, 100);
	}

	@Watch('currentPage')
	currentPageChanged(): void {
		this.getCustomers();
	}

	@Watch('options.entryCount')
	entryCount(): void {
		this.currentPage = 1;
		this.getCustomers();
	}

	@Watch('sortBy')
	sort(): void {
		this.getCustomers();
	}

	@Watch('sortDesc')
	sortByDirection(): void {
		this.getCustomers();
	}

	get perPage(): number {
		return this.options.entryCount;
	}

	set perPage(count: number) {
		this.options.entryCount = count;
	}

	get showPagination(): boolean {
		return this.perPage <= this.totalRows;
	}

	loadFromLocalStorage(): void {
		if (localStorage.getItem('customers')) {
			let customersFilter;
			try {
				const parsedCustomersFilter = localStorage.getItem('customers') as any;
				customersFilter = JSON.parse(parsedCustomersFilter);
			} catch (e) {
				this.clearLocalStorage();
			}
			if (customersFilter) {
				if (customersFilter.entryCount)
					this.options.entryCount = customersFilter.entryCount;
				if (customersFilter.searchTerm)
					this.options.searchTerm = customersFilter.searchTerm;
				if (customersFilter.currentPage)
					this.currentPage = customersFilter.currentPage;
				if (customersFilter.sortBy) this.sortBy = customersFilter.sortBy;
				if (customersFilter.sortDesc) this.sortDesc = customersFilter.sortDesc;
			}
		}
	}

	clearLocalStorage(): void {
		localStorage.removeItem('customers');
	}

	setToLocalStorage(): void {
		localStorage.setItem(
			'customers',
			JSON.stringify({
				entryCount: this.options.entryCount,
				searchTerm: this.options.searchTerm,
				currentPage: this.currentPage,
				sortBy: this.sortBy,
				sortDesc: this.sortDesc
			})
		);
	}

	private getCustomers(): void {
		this.setToLocalStorage();
		CustomersService.getCustomers(
			this.currentPage - 1,
			this.perPage,
			this.sortBy,
			this.sortDesc ? 'DESC' : 'ASC',
			this.options.searchTerm,
			!(this.sortBy === 'id' || this.sortBy === 'customerNumber')
		)
			.then((res) => {
				this.totalRows = res.totalSize;
				this.totalPages = res.totalPages;

				if (!res.empty) {
					this.items = res.content.map((customer) => {
						const contact = customer.contact;
						const nameExists =
							(contact && contact.firstName) || (contact && contact.lastName);
						const name = nameExists
							? (contact.firstName || '') + ' ' + (contact.lastName || '')
							: '';
            const unlimited = this.t('common.unlimited')
						const limit =
							customer.userLimit === -1 ? unlimited : customer.userLimit;
						const limitExceeded =
							limit !== unlimited &&
							(limit || 0) <= (customer.countRegisteredUser || 0);
						return {
							id: customer.id,
							customerNumber: customer.customerNumber,
							title: customer.title || '',
							name: name,
							userLimit: `${limit} (${customer.countRegisteredUser || 0})`,
							participantLimit:
								customer.participantLimit && customer.participantLimit < 0
									? unlimited
									: customer.participantLimit,
							dateCreated: new Date(customer.dateCreated).toLocaleDateString(
								undefined,
								{
									day: '2-digit',
									month: '2-digit',
									year: 'numeric'
								}
							),
							active: customer.active,
							limitExceeded,
							countRegisteredUser: customer.countRegisteredUser
						};
					});
				} else {
					this.items = [];
					this.clearLocalStorage();
				}
			})
			.catch(() => {
				this.toast(this.t('admin.customersCantLoad'), 'danger');
				this.clearLocalStorage();
			});
	}

	rowClicked(item: any): void {
		this.$router.push('/admin/customers/' + item.id);
	}

	createUser(item: any): void {
		this.$router.push('/admin/users/new?customerId=' + item.id);
	}

	deleteCustomer(id: string, title: string): void {
		this.$bvModal
			.msgBoxConfirm(this.$t('admin.deleteCustomerModal.message', {title: title}) as string, {
				centered: true,
				okVariant: 'danger',
				cancelTitle: this.t('common.cancel'),
				okTitle: this.t('admin.deleteCustomerModal.title')
			})
			.then((res) => {
				if (res) {
					CustomersService.deleteCustomer(id)
						.then(() => {
							this.toast(this.t('admin.deleteCustomerModal.deleted'), 'success');
							this.items = this.items.filter((i) => i.id !== id);
						})
						.catch((err) => this.toast(err.response.data.message, 'danger'));
				}
			})
			.catch(this.showNetworkError);
	}

	openUsers(id: number): void {
		this.$router.push({
			name: 'Management-Accounts',
			query: { customerId: id + '' }
		});
	}

	toggleActive(item: any): void {
		if (item.active) {
			CustomersService.deactivateCustomer(item.id)
				.then(() => (item.active = false))
				.catch(() => this.toast(this.t('admin.errorOnDeactivation'), 'danger'));
		} else {
			CustomersService.activateCustomer(item.id)
				.then(() => (item.active = true))
				.catch(() => this.toast(this.t('admin.errorOnActivation'), 'danger'));
		}
	}

	async exportAs(): Promise<void> {
		const filename = this.t('admin.customers');
		const columns: Array<Column> = [
			{ header: this.t('admin.excel.dbId'), key: 'id', width: 10 },
			{ header: this.t('profile.customerNr'), key: 'customerNumber', width: 15 },
			{ header: this.t('admin.participantLimit'), key: 'userLimit', width: 20 },
			{ header: this.t('common.company'), key: 'title', width: 50 },
			{ header: this.t('common.salutation') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.salutation', width: 15 },
			{ header: this.t('common.title') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.title', width: 15 },
			{ header: this.t('common.firstname') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.firstName', width: 20 },
			{ header: this.t('common.lastname') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.lastName', width: 20 },
			{ header: this.t('common.street') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.street', width: 25 },
			{ header: this.t('common.zip') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.zip', width: 20 },
			{ header: this.t('common.city') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.city', width: 20 },
			{ header: this.t('common.country') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.country', width: 20 },
			{ header: this.t('common.phone') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.phone', width: 20 },
			{ header: this.t('common.fax') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.fax', width: 20 },
			{ header: this.t('common.mobile') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.phone2', width: 20 },
			{ header: this.t('common.email') + ' (' + this.t('admin.excel.asp') + ')', key: 'contact.email', width: 30 },
			{ header: this.t('common.salutation') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.salutation', width: 15 },
			{ header: this.t('common.title') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.title', width: 15 },
			{ header: this.t('common.firstname') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.firstName', width: 20 },
			{ header: this.t('common.lastname') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.lastName', width: 20 },
			{ header: this.t('common.street') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.street', width: 25 },
			{ header: this.t('common.zip') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.zip', width: 20 },
			{ header: this.t('common.city') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.city', width: 20 },
			{ header: this.t('common.country') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.country', width: 20 },
			{ header: this.t('common.phone') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.phone', width: 20 },
			{ header: this.t('common.fax') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.fax', width: 20 },
			{ header: this.t('common.mobile') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.phon2', width: 20 },
			{ header: this.t('common.email') + ' (' + this.t('admin.excel.rg') + ')', key: 'billingContact.email', width: 30 },
			{ header: this.t('admin.accountOwner'), key: 'bankAccount.accountHolder', width: 25 },
			{ header: this.t('admin.iban'), key: 'bankAccount.iban', width: 30 },
			{ header: this.t('admin.bic'), key: 'bankAccount.bic', width: 20 },
			{ header: this.t('admin.excel.bankName'), key: 'bankAccount.bankName', width: 25 },
			{ header: this.t('admin.onBill'), key: 'invoicePayment', width: 25 },
			{ header: this.t('common.note'), key: 'info', width: 30 },
			{ header: this.t('admin.excel.lindenbaumId'), key: 'lbId', width: 25 },
			{ header: this.t('admin.excel.lindenbaumUsername'), key: 'lbLogin', width: 25 },
			{ header: this.t('admin.excel.egnShorted'), key: 'anonymizePhonenumbers', width: 20 },
			{
				header: this.t('admin.excel.showContactDetailInAccount'),
				key: 'showCustomerContactInAccounts',
				width: 30
			},
			{ header: this.t('admin.excel.presentationUrl'), key: 'dataConferenceURL', width: 30 },
			{ header: this.t('common.type'), key: 'type', width: 15 },
			{ header: this.t('common.createdAt'), key: 'dateCreated', width: 25 },
			{ header: this.t('admin.active'), key: 'active', width: 15 },
			{ header: this.t('admin.excel.deleted'), key: 'deleted', width: 15 },
			{ header: this.t('common.dialOut') + ' (P)', key: 'permissions.DIAL_OUT', width: 15 },
			{ header: this.t('common.dialInEn') + ' (P)', key: 'permissions.DIAL_IN', width: 15 },
			{ header: this.t('common.recordings') + ' (P)', key: 'permissions.RECORDING', width: 20 },
			{ header: this.t('common.playback') + ' (P)', key: 'permissions.PLAYBACK', width: 15 },
			{ header: this.t('admin.excel.regFormShort') + ' (P)', key: 'permissions.REGISTRATION_FORM', width: 15 },
			{ header: this.t('admin.excel.surveyFormShort') + ' (P)', key: 'permissions.POLL', width: 15 },
			{ header: this.t('common.brandings') + ' (P)', key: 'permissions.BRANDING', width: 15 }
		];
		let customers: Customer[] = [];
		for (let i = 0; i <= this.totalPages; i++) {
			const res = await CustomersService.getCustomers(
				i,
				this.options.entryCount,
				'dateCreated',
				'ASC'
			);
			if (!res.empty) customers = [...customers, ...res.content];
		}
		const entries = customers.map((c: any) => {
			const entry = {} as any;
			columns.forEach((col) => {
				if (!col.key) return;
				const splitKey = (col.key as string).split('.');
				if (splitKey.length === 1) {
					// if string
					const value = c[col.key];
					if (value === true || value === 'BILLING') entry[col.key] = 1;
					else if (value === false || value === 'TEST') entry[col.key] = 0;
					else if (col.key === 'dateCreated')
						entry[col.key] = new Date(value).toLocaleString([], {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit',
							second: '2-digit'
						});
					else entry[col.key] = value;
				} else if (splitKey.length === 2 && splitKey[0] !== 'permissions') {
					// if nested object
					try {
						const value = c[splitKey[0]][splitKey[1]];
						if (value === 'MR') entry[col.key] = this.t('common.mr');
						else if (value === 'MRS') entry[col.key] = this.t('common.mrs');
						else if (value === 'OTHER') entry[col.key] = this.t('common.diverse');
						else entry[col.key] = value;
					} catch (error) {
						entry[col.key] = '';
					}
				} else {
					// if nested permissions array
					try {
						const value = c[splitKey[0]]
							.map((p: Permission) => p.name)
							.includes(splitKey[1]);
						if (value === true) entry[col.key] = 1;
						else entry[col.key] = 0;
					} catch (error) {
						entry[col.key] = 0;
					}
				}
			});
			return entry;
		});
		createXLSX(filename, columns, entries, this.t('admin.customers'));
	}
}
