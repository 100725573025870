





























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class WProgressBar extends Vue {
  @Prop() title!: string;
  @Prop() value!: number;
  @Prop() label!: string;
  @Prop({type: String, default: 'primary'}) variant!: string;
  @Prop({default: true, type: Boolean}) cancel!: boolean;
  @Prop({default: 100, type: Number}) max!: number;
  @Prop({default: false, type: Boolean}) percent!: boolean;
  @Prop({default: false, type: Boolean}) progress!: boolean;
}
