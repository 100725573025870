












































































import {Component, Prop} from 'vue-property-decorator';
import Base from "@/views/Base";
import {CustomField, NewCustomField} from "@/models/CustomField";
import WSwitch from "@/components/WSwitch.vue";
import WExpandableCard from "@/components/WExpandableCard.vue";

@Component({
  components: {WExpandableCard, WCard: WExpandableCard, WSwitch}
})
export default class Edit extends Base {
  @Prop() item!: NewCustomField;
  @Prop() existingItem!: CustomField | null;
  @Prop() validation!: any;

  validateState(name: string): boolean | null {
    const validate = this.validation[name];
    return validate.$dirty ? !validate.$error : null;
  }

  validateBlur(name: string): void {
    const validate = this.validation[name];
    if (this.validation.$dirty) {
      validate.$touch();
    }
  }

  blurCustomFormat(): void {
    this.validation.customFormat.$touch();
  }

  get globalDisabled(): boolean {
    if (this.existingItem == null) {
      return false;
    }
    return this.existingItem.global && !(this.existingItem.usedRegForms.length == 0 && this.existingItem.usedSurveys.length == 0);
  }

  get globalTooltip(): string {
    if(!this.globalDisabled) return '';

    const accounts = Object.keys(this.existingItem?.globalUsedRegForms || []);
    accounts.concat(Object.keys(this.existingItem?.globalUsedSurveys || []));
    const uniq = [...new Set(accounts)];
    return this.t('forms.customFields.cantMakeLocal') + uniq.join(', ');
  }

}
