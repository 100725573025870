





















































import { Component, Prop } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '@/views/Base';
import { ImportContact } from '@/models/Contact';
import { validateEmail } from '@/utils/validators';

@Component
export default class ImportItem extends Base {
	@Prop() item!: ImportContact;

	firstTouch = false;

	@Validations()
	validations = {
		item: {
			name: { required },
			telephone: {
				required,
				noLetters: ContactsService.noLettersValidator,
				format: this.phoneValidation
			},
			email: { email: validateEmail },
			company: {}
		}
	};

	created(): void {
		this.firstTouch = true;
		this.$v.item.$touch();
	}

	focus(): void {
		if (this.firstTouch) this.firstTouch = false;
		this.$v.item.telephone?.$reset();
	}

	submit(): void {
		if (!this.$v.item.$invalid) {
			this.item.valid = true;
			this.$emit('submit');
		}
	}

	phoneValidation(): Promise<boolean> | boolean {
		const telephone = this.$v.item.telephone;
		if (telephone?.$dirty && !this.firstTouch) {
			return ContactsService.validatePhoneNumbers([this.item.telephone])
				.then((validatedNumbers) => {
					const validNumber = validatedNumbers[0];
					const phoneIsValid = validNumber.validNumber;
					if (phoneIsValid) {
						this.item.telephone = validNumber.international;
					}
					return phoneIsValid;
				})
				.catch((err) => {
					this.showNetworkError(err);
					return false;
				});
		}
		return !!this.item.numberValid;
	}

	validateState(val: string): boolean | null {
		const validate: any = this.$v.item[val];
		return validate.$dirty ? !validate.$error : null;
	}
}
