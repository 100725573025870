

































































































































































































































































import Base from '@/views/Base';
import {Component, Prop} from 'vue-property-decorator';
import ListWithHeaderLayout from '@/layouts/nested/ListWithHeaderLayout.vue';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import WIcon from '@/components/WIcon.vue';
import WOperatorConfContext from '@/components/WOperatorConfContext.vue';
import { ConferenceListItem } from '@/models/Conference';
import AdminConsoleService from '@/service/AdminService';
import { RunningConferencesWrapper } from '@/models/AdminConsole';
import AuthenticationService from '@/service/AuthenticationService';
import ConferenceService from '@/service/ConferenceService';
import { formatMillisecondsToHoursMinutes, formatNumberString } from '@/utils/filters';

@Component({
	components: {
		WOperatorConfContext,
		ListWithHeaderLayout,
		HeaderLayout,
		WListOptions,
		WIcon,
		WPagination
	},
  i18n: {
            messages: {
                en: {
                    common: {
                        duration: 'Dur.',
                        company: 'Comp.',
                        customer: 'Cstm.'
                    }
                }
            }
        }
})
export default class RunningConferences extends Base {
	items: ConferenceListItem[] = [];

	runningConferencesInterval!: number;
	fields = [
		{
			key: 'start' as keyof ConferenceListItem,
			label: this.$t('common.start'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { paddingLeft: '1.5rem', width: 'calc(70px + 1.5rem)' }
		},
		{
			key: 'duration' as keyof ConferenceListItem,
			label: this.$t('common.duration'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { width: '75px' }
		},
		{
			key: 'customerNumber' as keyof ConferenceListItem,
			label: this.$t('common.customer'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { width: '75px' }
		},
		{
			key: 'company' as keyof ConferenceListItem,
			label: this.$t('common.company'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { width: '180px' }
		},
		{
			key: 'username' as keyof ConferenceListItem,
			label: this.$t('common.username'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { width: '160px' }
		},
		{
			key: 'participantLimit' as keyof ConferenceListItem,
			label: this.$t('common.limit'),
			sortable: false,
			thStyle: { width: '50px' }
		},
		{
			key: 'pin' as keyof ConferenceListItem,
			label: 'MP/TP',
			sortable: false,
			thStyle: { width: '75px' }
		},
		{
			key: 'activeCount' as keyof ConferenceListItem,
			label: this.$t('common.active'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { width: '105px', paddingRight: '0' }
		},
		{
			key: 'numberOfParticipants' as keyof ConferenceListItem,
			label: this.$t('common.all'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { width: '105px', paddingRight: '0' }
		},
		{
			key: 'rec' as keyof ConferenceListItem,
			label: 'Rec',
			sortable: false,
			sortDirection: 'desc',
			thStyle: { width: '60px' }
		},
		{
			key: 'functions' as keyof ConferenceListItem,
			label: this.$t('common.type'),
			sortable: false,
			sortDirection: 'desc',
			thStyle: { width: '60px' }
		},
		{
			key: 'action' as keyof ConferenceListItem,
			label: this.$t('common.action'),
			sortable: false
		}
	];

	options = {
		entryCount: 10,
		searchTerm: '',
		currentPage: 1,
		totalRows: 0
	};

	sortDesc = true;
	sortBy = 'start';
	loading = false;

	currentPage = 1;

	wrapper: RunningConferencesWrapper = {
		totalConferences: 0,
		currentPhoneParticipants: 0,
		totalPhoneParticipants: 0,
		currentStreamParticipants: 0,
		registeredParticipants: 0,
		conferences: []
	};

	mounted(): void {
		this.getRunningConferences();
		this.runningConferencesInterval = window.setInterval(
			() => this.getRunningConferences(false),
			5000
		);
	}

	beforeDestroy(): void {
		clearInterval(this.runningConferencesInterval);
	}

	getRunningConferences(showLoading = true): void {
		this.loading = showLoading;
		AdminConsoleService.getRunningConferences()
			.then(conferenceWrapper => {
				this.wrapper = conferenceWrapper;
				if (conferenceWrapper.totalConferences > 0) {
					this.items = conferenceWrapper.conferences.map(
						AdminConsoleService.runningConferenceToConferenceItem
					);
				} else {
					this.items = [];
				}
			})
			.catch((error) => {
				this.showNetworkError(error);
				this.sortBy = 'start';
				clearInterval(this.runningConferencesInterval);
			})
			.finally(() => this.loading = false);
	}

	get perPage(): number {
		return this.options.entryCount;
	}

	get totalPages(): number {
		return Math.ceil(this.totalRows / this.perPage);
	}

	get totalRows(): number {
		return this.items.length;
	}

	get showPagination(): boolean {
		return this.perPage <= this.totalRows;
	}

	get currentParticipants(): string {
		return formatNumberString(this.wrapper.currentPhoneParticipants);
	}

	formatNumberString = formatNumberString;

	formatMillisecondsToHoursMinutes = (n: number, b: boolean) => formatMillisecondsToHoursMinutes(n,b, this);

	loginAs(item: any): void {
		AuthenticationService.impersonate({
			parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
			childUsername: item.username
		})
			.then(() => {
				this.$router.push('/');
				localStorage.prevAdmin = 'running';
			})
			.catch(this.showNetworkError);
	}

	endConference(item: any): void {
		this.$bvModal
			.msgBoxConfirm(this.$t('conference.conferenceEndConfirm') as string, {
				title: this.$t('conference.endConference') as string,
				okTitle: this.$t('common.end') as string,
				cancelTitle: this.$t('common.cancel') as string,
				okVariant: 'danger'
			})
			.then((value) => {
				ConferenceService.stopConference(item.id)
					.then(() => {
						this.toast(
							this.$t('conference.conferenceEnded') as string,
							'success'
						);
						this.getRunningConferences();
					})
					.catch(this.showNetworkError);
			});
	}

	editCustomer(item: any): void {
		this.$router.push(`/admin/customers/${item.internalCustomerId}`);
	}

	editUser(item: any): void {
		this.$router.push(`/admin/users/${item.username}`);
	}
}
