
































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import RoomItem from './RoomItem.vue';
import RoomItemSkeleton from './RoomItemSkeleton.vue';
import WPin from '@/components/WPin.vue';
import { BookingListItem } from '@/models/Booking';
import Base from '../Base';

@Component({
	components: { RoomItem, RoomItemSkeleton, WPin }
})
export default class Rooms extends Base {
	@Prop() rooms!: BookingListItem[];
	@Prop() size!: number;
	@Prop() loading!: boolean;
}
