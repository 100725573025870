



















































































































































import { EmailTemplate, RegistrationDOI } from '@/models/Registration';
import RegistrationService from '@/service/RegistrationService';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import PlaceholdersModal from '@/views/forms/components/PlaceholdersModal.vue';
import { Account } from '@/models/Account';

@Component({
	components: { WSwitch, PlaceholdersModal }
})
export default class RegistrationDoubleOptIn extends Base {
	@Prop() form!: RegistrationDOI;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Prop() validation!: any;
	@PropSync('language') lang!: string;
	@Prop({ default: 'registration' }) formType!: 'registration' | 'survey';
	@PropSync('mailEnabled') sendMail!: boolean;

	@Prop() hasParticipants!: boolean;

	open = false;

	templates: EmailTemplate | null = null;
	templateItems: { [key: string]: string | boolean | number }[] = [
		{ text: this.t('forms.components.email.selectMailTemplate'), value: '', disabled: true }
	];
	// selectedTemplate = '';
	loaded = false;

	mounted(): void {
		this.getMailTemplates();

		if (!this.form.replyTo) {
			this.form.replyTo = this.user?.contact?.email || '';
		}

		if (this.form && this.form.type != 'DISABLED') {
			this.open = true;
		}
	}

	getMailTemplates(): void {
		RegistrationService.getDoiEmailTemplates()
			.then((res) => {
				this.templates = res.templates;
				if (this.lang) {
					this.templateItems = [
						this.templateItems[0],
						...this.templates[this.lang.toLowerCase()].map((item) => ({
							text: item.title,
							value: +item.id,
							content: item.text,
							subject: item.subject
						}))
					];
					this.loaded = true;
				}
			})
			.catch(this.showNetworkError);
	}

	@Watch('sendMail')
	setOpen(): void {
		this.open = this.sendMail;
		if (!this.form.replyTo) this.form.replyTo = this.user.contact.email;
	}

	@Watch('lang')
	languageChanged(newLang: string, oldLang: string): void {
		if (newLang !== oldLang) {
			this.getMailTemplates();
		}
	}

	@Watch('form.type')
	typeChanged(newType: string, oldType: string): void {
		if (newType !== oldType) {
			this.form.mailTextTemplateId = newType === 'CONFERENCE' ? 1 : 2;
			this.templateChanged();
		}
		if (newType === 'NEWSLETTER' && !this.form.newsletterText) {
			this.form.newsletterText = this.t('forms.components.doi.newsletterCheckboxDefault');
		}
	}

	templateChanged(): void {
		const template = this.templateItems.find(
			(temp) => temp.value === this.form.mailTextTemplateId
		);
		if (template && template.value !== '') {
			this.form.text = template.content as string;
			this.form.subject = template.subject as string;
			this.form.mailTextTemplateId = template.value as number;
		}
	}

	toggleOpen(): void {
		this.open = !this.open;
	}

	validateState(name: string): boolean | null {
		const validate = this.validation[name];
		return validate.$dirty ? !validate.$error : null;
	}

	get user(): Account {
		return this.$store.getters.account;
	}

	get dataConferenceURL(): string | undefined {
		return this.user?.customerShortDTO?.dataConferenceURL || '';
	}
}
