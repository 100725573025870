





















































































































import Base from "@/views/Base";
import {Component} from "vue-property-decorator";
import WSwitch from "@/components/WSwitch.vue";
import {SelectOption} from "@/models/common";
import SurveyService from "@/service/SurveyService";

@Component({
  components: {WSwitch}
})
export default class AddSurveyModal extends Base {
  surveyType: 'spontaneous' | 'form' = 'spontaneous';
  questionType: 'single' | 'multiple' = 'single';
  showResults = 'pie-chart';
  liveResults = true;

  surveys: SelectOption[] = [{text: this.$t('conference.noSurveyForm') as string, value: null, disabled: true}];
  selectedSurvey: number | null = null;

  answers: { idx: number, text: string, correct: boolean, }[] = [
    {idx: 1, text: '', correct: false,},
    {idx: 2, text: '', correct: false,},
  ];

  showResultsOptions = [
    {value: 'pie-chart', text: this.$t('presentation.survey.pieChartOption')},
    {value: 'other', text: this.$t('common.yes')},
    {value: 'other2', text: this.$t('common.no')},
  ];

  getSurveys(): void {
    SurveyService.getSurveys(0, 9999, 'dateCreated', 'DESC')
      .then(surveys => {
        if (surveys.empty) return;
        this.surveys.push(...surveys.content.map(survey => ({
          text: survey.label || survey.title,
          value: survey.id
        }) as SelectOption));
      })
      .catch(this.showNetworkError);
  }

  addAnswer() {
    const idx = this.answers.map(a => a.idx).reduce((a, b) => Math.max(a, b), 0) + 1;
    this.answers.push({idx, text: '', correct: false});
  }

  deleteAnswer(idx: number) {
    this.answers = this.answers.filter(answer => answer.idx !== idx);
  }

  submit(ok: () => void): void {
    if (this.surveyType === 'spontaneous') {
      // TODO: Change this AI jibberish, but its good enough for now
      const question = this.$el.querySelector('input[name="question-input"]') as HTMLInputElement;
      const description = this.$el.querySelector('textarea[name="question-description-input"]') as HTMLTextAreaElement;
      const answers = this.answers.map(a => a.text).filter(a => a);
      if (!question || !description || !answers.length) return;
      this.$emit('add-spontaneous-survey', {
        question: question.value,
        description: description.value,
        type: this.questionType,
        answers,
        showResults: this.showResults,
        liveResults: this.liveResults,
      });
    } else {
      console.log('ADLKSAJDLASJDLSAJD')
      this.$emit('add-survey-form', this.selectedSurvey);
    }
    ok();
  }

  reset(): void {
    this.surveyType = 'spontaneous';
    this.questionType = 'single';
    this.showResults = 'pie-chart';
    this.liveResults = true;
    this.selectedSurvey = null;
    this.answers = [
      {idx: 1, text: '', correct: false,},
      {idx: 2, text: '', correct: false,},
    ];
  }

  get isSubmitDisabled(): boolean {
    return (this.surveyType === 'form' && !this.selectedSurvey)
      || (this.surveyType === 'spontaneous' && this.answers.some(a => !a.text));
  }

}
