










































































































































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import { Registration, RegistrationDate, RegistrationUserWithData } from '@/models/Registration';
import RegistrationService from '@/service/RegistrationService';
import { Component } from 'vue-property-decorator';
import Base from '../Base';
import WSidebarLink from '@/components/WSidebarLink.vue';
import ItemSection from '../conference/ItemSection.vue';
import RegistrationItemTable from './RegistrationItemTable.vue';
import {
	compareRegUserByCreatedDate,
	dateToDateTimeString,
	formatDateCreated,
	formatDateTimeForFiles
} from '@/utils/filters';
import { Column, createXLSX } from '@/utils/excel';
import SetupModal from './components/SetupModal.vue';
import ImportModal from './components/ImportModal.vue';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import { SetupForm } from '@/models/RegistrationForm';
import AccountsService from '@/service/AccountsService';
import { Permission } from '@/models/Account';
import axios from 'axios';
import store from '@/store';
import { FormMail } from '@/models/Survey';
import CertificateMailModal from '@/components/modals/CertificateMailModal.vue';
import CertificateSetupModal from '@/components/modals/CertificateSetupModal.vue';

@Component({
	components: {
		CertificateSetupModal,
		WHeaderBtn,
		HeaderLayout,
		SidebarLayout,
		WSidebarLink,
		ItemSection,
		RegistrationItemTable,
		SetupModal,
		ImportModal,
		CertificateMailModal
	}
})
export default class RegistrationItem extends Base {
	registration: Registration | null = null;

	selectedDate: RegistrationDate | null = null;

	loadingSimpleParticipants = false;
	loadingParticipants = false;
	loadingSendCertificate = false;
	loadingCertificates = false;
	loadingCertificatesDownload = false;

	hasAnyValidCert = true;

	pollRegInterval!: any;
	sendCertificateInterval!: any;

	mail: FormMail = {
		replyTo: '',
		subject: '',
		text: '',
		attachments: [],
		enabled: true
	};

	mounted(): void {
		this.init();
	}

	beforeDestroy(): void {
		clearInterval(this.pollRegInterval);
		clearInterval(this.sendCertificateInterval);
	}

	get generatedCertPercent(): number {
		if (!this.selectedDate) return 0;
		const availableCerts =
			this.selectedDate.countCertFileUpToDate === undefined
				? this.totalRows
				: this.selectedDate.countCertFileUpToDate;
		return Math.round((availableCerts / this.totalRows) * 100);
	}

	get certificatesGenerating(): boolean {
		if (!this.selectedDate || this.selectedDate.countCertFileUpToDate === undefined) return false;
		return this.selectedDate.countCertFileUpToDate < this.totalRows;
	}

	get isDoiConferenceEnabled(): boolean {
		if (!this.registration || !this.registration.doi) return false;
		return this.registration.doi.type === 'CONFERENCE';
	}

	get totalRows(): number {
		return this.selectedDate?.countRegisteredUser || 0;
	}

	async init(): Promise<void> {
		const { id } = this.$route.params;
		if (!id) return;
		await this.getRegistration(id);
		if (this.totalRows === 0) return;
		if (this.selectedDate?.generateCert) {
			if (this.selectedDate?.mailToAllProgress && !this.certificatesGenerating) {
				this.pollRegFormForCertificateSend(true);
			} else if (this.certificatesGenerating) {
				this.pollRegFormForCertificateDownload();
			}
		}
	}

	private getRegistration(id: string | number) {
		return RegistrationService.getRegistration(id)
			.then(res => {
				this.registration = res;
				if (!this.registration.regDates) return;
				this.registration.regDates = this.registration.regDates.sort((a, b) => a.start - b.start);
				const { date } = this.$route.query;

				if (this.selectedDate) {
					const selectedDate = this.registration.regDates.find(d => d.id === this.selectedDate?.id);
					if (selectedDate) this.selectedDate = selectedDate;
				} else if (date) {
					const selectedDate = this.registration.regDates.find(d => d.id === +date);
					if (selectedDate) this.selectedDate = selectedDate;
				} else {
					this.selectedDate = this.registration.regDates[0];
				}
			})
			.catch(this.showNetworkError);
	}

	setDate(newDate: RegistrationDate): void {
		clearInterval(this.pollRegInterval);
		clearInterval(this.sendCertificateInterval);
		
		this.loadingCertificates = false;
		this.selectedDate = newDate;

		if (this.selectedDate.generateCert && this.availableCerts < this.totalRows) {
			this.pollRegFormForCertificateDownload();
		}
		if (this.selectedDate.mailToAllProgress) {
			this.pollRegFormForCertificateSend(true);
		}
	}

	fillData(): void {
		if (!this.registration) return;
		if (this.registration.regDates.length === 0) return;
		this.selectedDate = this.registration.regDates[0];
	}

	edit(): void {
		this.$router.push(
			'/forms/registration/' +
				this.registration?.id +
				'/edit?mode=' +
				this.$route.query.mode || 'active'
		);
	}

	downloadSimpleParticipants(): void {
		if (
			this.registration &&
			this.registration.id &&
			this.selectedDate &&
			this.selectedDate.id &&
			this.selectedDate.start &&
			this.selectedDate.countRegisteredUser
		) {
			this.loadingSimpleParticipants = true;
			RegistrationService.getRegistrationUsersForDate(
				this.selectedDate.id,
				this.registration.id,
				0,
				this.selectedDate.countRegisteredUser,
				null,
				null
			)
				.then((participants) => {
					if (!participants.empty) {
						const filename = this.$t('forms.simpleRegCsvTitle', {
							date: this.formatDate(this.selectedDate?.start)
						}) as string;

						const columns: Array<Column> = [
							{ header: this.t('common.name'), key: 'name', width: 25 },
							{ header: this.t('common.phoneNumber'), key: 'phone', width: 25 }
						];

						if (this.isDoiConferenceEnabled) {
							columns.push({
								header: this.t('forms.doiVerified'),
								key: 'dateDoi',
								width: 19
							});
						}

						const entries: any[] = [];
						participants.content.forEach((participant) => {
							entries.push({
								name: `${participant.firstname} ${participant.lastname}`,
								phone: participant.phone,
								dateDoi: participant.dateDoi
									? dateToDateTimeString(participant.dateDoi)
									: '-'
							});
						});

						createXLSX(
							filename,
							columns,
							entries,
              this.t('common.participants'),
							this.registration?.doi?.type === 'CONFERENCE'
						);
					} else {
						this.toast(this.t('forms.noParticipants'), 'danger');
					}
				})
				.finally(() => (this.loadingSimpleParticipants = false));
		}
	}

	openMailModal(): void {
		this.$bvModal.show('mailModal');
	}

	downloadParticipants(): void {
		if (!this.registration) return;
		if (!this.registration.id) return;
		if (!this.selectedDate) return;
		if (!this.selectedDate.id) return;
		this.loadingParticipants = true;
		RegistrationService.getRegUsersForDateWithData(this.registration.id, this.selectedDate.id)
			.then(participants => {
        if (!this.selectedDate) return;
				if (participants.length === 0) {
					this.toast(this.t('forms.noParticipants'), 'danger');
					return;
				}
				const filename = this.$t('forms.fullRegCsvTitle', { date: this.formatDate(this.selectedDate?.start) }) as string;
				const columns = RegistrationService.getRegFormColumns(this.registration, participants, this.selectedDate.generateCert, this);
				participants.sort(compareRegUserByCreatedDate);

				const entries = [];
				for (const participant of participants) {
					entries.push(RegistrationService.regUserWithDataToExcelEntryByDate(participant, this.selectedDate.id, this));
				}

				let styleByDateDoi = this.registration?.doi?.type === 'CONFERENCE';
				createXLSX(filename, columns, entries, this.t('common.participants'), styleByDateDoi);
			})
			.catch(this.showNetworkError)
			.finally(() => (this.loadingParticipants = false));
	}

	deleteUsersForDate(dateId: number): void {
		this.$bvModal
			.msgBoxConfirm(this.t('modals.deleteDateParticipants.description'), {
				okVariant: 'danger',
				okTitle: this.t('modals.deleteDateParticipants.ok'),
				cancelTitle: this.t('common.cancel'),
				centered: true,
				title: this.t('modals.deleteDateParticipants.title')
			})
			.then(res => {
				if (res && this.registration && this.registration.id) {
					const id = this.registration.id;
					RegistrationService.deleteUsersForDate(id, dateId)
						.then(() => {
							this.toast(this.t('modals.deleteDateParticipants.deleted'), 'success');
							this.getRegistration(id);
						})
						.catch(this.showNetworkError);
				}
			});
	}

	deleteAllUsers(): void {
		// delete all users for all dates
		this.$bvModal
			.msgBoxConfirm(this.t('modals.deleteAllDatesParticipants.description'), {
				okVariant: 'danger',
				okTitle: this.t('modals.deleteAllDatesParticipants.ok'),
				cancelTitle: this.t('common.cancel'),
				centered: true,
				title: this.t('modals.deleteAllDatesParticipants.title')
			})
			.then(res => {
				if (!res) return;
				if (this.registration && this.registration.id) {
					if (this.registration.regDates.length === 0) return;
					const id = this.registration.id;
					Promise.all(this.registration.regDates.map(date => date.id
								? RegistrationService.deleteUsersForDate(id, +date.id)
								: Promise.resolve()
						))
						.then(() => {
							this.toast(this.t('modals.deleteAllDatesParticipants.deleted'), 'success');
							this.getRegistration(id);
						})
						.catch(this.showNetworkError);
				}
			});
	}

	duplicate(): void {
		if (this.registration && this.registration.id)
			this.$router.push({
				path: '/forms/registration/new',
				query: {
					id: this.registration.id.toString(),
					mode: this.$route.query.mode
				}
			});
	}

	formatDate = formatDateTimeForFiles;

	formatDateSidebar = formatDateCreated;

	downloadFromSetup(setup: SetupForm): void {
		if (
			this.registration &&
			this.registration.id &&
			this.selectedDate &&
			this.selectedDate.id &&
			this.selectedDate.start &&
			this.selectedDate.countRegisteredUser
		) {
			RegistrationService.getRegUsersAndParticipantsForDateWithData(this.registration.id, this.selectedDate.id)
				.then(res => {
				if (res && this.selectedDate) {
					// TODO
					const filename = `Gut_Beraten_${this.formatDate(
						this.selectedDate?.start
					)}`;
					const rows = res
						.filter((p) => {
							const duration = this.calculateDuration(p);
							return p.id && duration / 60 > setup.minConnection;
						})
						.map((p) => {
							const obj = {} as any;
							obj[this.t('common.name')] = p.lastname;
							obj[this.t('common.firstname')] = p.firstname;
							obj[this.t('forms.birthdayOrId')] = '';
							obj['gutBeraten-Id'] = p.vvId || '';
							obj[this.t('forms.educationName')] = setup.name;
							obj[this.t('common.from')] = new Date(
								this.selectedDate?.start || ''
							).toLocaleDateString([], { dateStyle: 'medium' });
							obj[this.t('common.to')] = new Date(
								this.selectedDate?.end || ''
							).toLocaleDateString(['de-DE'], { dateStyle: 'medium' });
							obj[this.t('forms.learningType')] = setup.learningType;
							obj[this.t('forms.learningContent')] = setup.learningContent;
							obj[this.t('forms.educationDuration')] = setup.educationBonus;
							obj[this.t('forms.internalBookingId')] = setup.internalBookingId;
							obj[this.t('forms.contactTitle')] = setup.contactTitle;
							obj[this.t('forms.contactFirstname')] = setup.contactFirstname;
							obj[this.t('forms.contactLastname')] = setup.contactLastname;
							obj[this.t('forms.contactPhone')] = setup.contactPhone;
							obj[this.t('forms.contactEmail')] = setup.contactEmail;

							return obj;
						});

					const self = this as any;
					if (rows.length > 0) {
						const csv = self.$papa.unparse(rows, {
							columns: Object.keys(rows[0]),
							delimiter: ';'
						});
						self.$papa.download(csv, filename);
						return;
					}
				}
				this.toast(this.t('common.noDataFound'), 'danger');
			});
		}
	}

	private calculateDuration(p: RegistrationUserWithData): number {
		let duration = 0;
		p.participants?.forEach(
			(participant) => (duration += participant.duration)
		);
		return duration;
	}

	saveSetup(event: { form: SetupForm; ok: () => void }): void {
		if (
			this.selectedDate !== null &&
			this.registration &&
			this.registration.id !== null
		) {
			const id = this.selectedDate.id;
			const data = JSON.stringify({ setup: event.form });
			RegistrationService.updateDate(this.registration.id, id, {
				...this.selectedDate,
				data
			})
				.then(() => {
					const date = this.registration?.regDates?.find((d) => d.id === id);
					if (date) {
						date.data = data;
						this.$forceUpdate();
					}
					event.ok();
					this.toast(this.t('forms.gutBeratenChanged'), 'success');
					const userSettings = this.user.settings
						? JSON.parse(this.user.settings)
						: {};
					userSettings.setup = event.form;
					this.user.settings = JSON.stringify(userSettings);
					return AccountsService.updateAccount({
						...this.user,
						permissions: (this.user.permissions as Permission[]).map(p => p.name),
						customerId: this.user.customerShortDTO?.id
					});
				})
				.catch(this.showNetworkError);
		}
	}

	addToAddressBook(): void {
		(this.$refs['registration-item-table'] as RegistrationItemTable).addToAddressBook();
	}

	updateTable(): void {
		(this.$refs['registration-item-table'] as RegistrationItemTable).getUsersForDate();
		this.pollRegFormForCertificateDownload();
	}

	// certificates
	get downloadCertificatesUrl(): string {
		const dateId = this.selectedDate?.id;
		if (!dateId) return '';
		return `${axios.defaults.baseURL}/registration/${this.$route.params.id}/date/${dateId}/certificates.zip?access_token=${store.getters.accessToken}`;
	}

	get showCertificateLinks(): boolean {
		if (!this.registration) return false;
		if (!this.selectedDate) return false;
		return this.selectedDate.generateCert && (
			this.isDoiConferenceEnabled
				? this.selectedDate.countDoiUser != undefined && this.selectedDate.countDoiUser > 0
				: this.totalRows > 0
		);
	}

	setAnyUserValidCert(value: boolean): void {
		this.hasAnyValidCert = value;
	}


	async openCertificateModal(): Promise<void> {
    const downloadOK = await this.checkDownloadOK();
    if (downloadOK) {
      this.$bvModal.show('registration-certificate-modal');
    }
	}

	async downloadCertificates(): Promise<void> {
		if (!this.registration) return;
		if (!this.registration.id) return;
		if (!this.selectedDate) return;
		if (!this.selectedDate.id) return;

		this.loadingCertificatesDownload = true;
		const downloadOK = await this.checkDownloadOK();
		if (downloadOK) {
			this.toast(this.t('forms.certificatesDownloading'), 'primary');
			window.location.href = this.downloadCertificatesUrl;
		}
    this.loadingCertificatesDownload = false;
  }

  async checkDownloadOK(): Promise<boolean> {
    if (!this.registration?.id) return false;
    if (!this.selectedDate?.id) return false;
    let downloadOK;
    try {
      downloadOK = await RegistrationService.isCertificatesDownloadOK(this.registration.id, this.selectedDate?.id);
    } catch (e) {
      downloadOK = false;
      this.showNetworkError(e);
    }
    if (!downloadOK) {
      await this.pollRegFormForCertificateDownload();
    }
    return downloadOK
  }


  async pollRegFormForCertificateDownload(): Promise<void> {
		if (!this.selectedDate) return;
		if (!this.selectedDate.generateCert) return;
		const { id } = this.$route.params;
		const date = this.selectedDate.id;
		if (id && date) {
			await this.getRegistration(id);
			// let availableCerts = this.selectedDate.countCertFileUpToDate === undefined
			// 		? this.totalRows
			// 		: this.selectedDate.countCertFileUpToDate;
			if (this.availableCerts < this.totalRows) {
				this.pollRegInterval = setInterval(async () => {
					await this.getRegistration(id);
					if (!this.selectedDate) return;
					// availableCerts = this.selectedDate.countCertFileUpToDate === undefined
					// 		? this.totalRows
					// 		: this.selectedDate.countCertFileUpToDate;
					if (this.availableCerts >= this.totalRows) {
						clearInterval(this.pollRegInterval);
					}
				}, 3000);
			}
		}
	}

	get availableCerts(): number {
		if (!this.selectedDate) return this.totalRows;
		return this.selectedDate.countCertFileUpToDate === undefined
			? this.totalRows
			: this.selectedDate.countCertFileUpToDate;
	}

	async sendCertificateToAllUsers(res: { ok: () => void; mail: FormMail; onlyToNew: boolean; }): Promise<void> {
		if (this.registration && this.registration.id && this.selectedDate) {
			this.loadingSendCertificate = true;
			const onlyToNew = res.onlyToNew;
			await RegistrationService.sendCertificateToAllParticipants(
				this.registration.id,
				this.selectedDate.id,
				{ ...res.mail, onlyToNew }
			);
			this.loadingSendCertificate = false;
			res.ok();
			this.pollRegFormForCertificateSend();
		}
	}

	async pollRegFormForCertificateSend(noStatus?: boolean): Promise<void> {
		const { id } = this.$route.params;
		if (!id) return;
		const date = this.selectedDate?.id;
		if (!date) return;

		this.loadingCertificates = true;
		await this.getRegistration(id);

		if (this.selectedDate !== null && this.selectedDate.mailToAllProgress) {
			if (!noStatus) this.toast(this.t('forms.certDownloadStarted'), 'primary');

			this.sendCertificateInterval = setInterval(async () => {
				await this.getRegistration(id);
				if (this.selectedDate !== null && !this.selectedDate.mailToAllProgress) {
					this.toast(this.t('forms.certificatesSent'), 'success');

					clearInterval(this.sendCertificateInterval);

					const regItemTable = this.$refs['registration-item-table'] as RegistrationItemTable;
					regItemTable.users.forEach(user => {if (user.passed) user.certificateSent = true;});

					this.loadingCertificates = false;
				}
			}, 3000);
		} else {
			this.toast(this.t('forms.certificatesSent'), 'success');
			this.loadingCertificates = false;
		}
	}

	async deleteCertificate(res: { date: RegistrationDate, filename: string }): Promise<void> {
		if (!this.registration) return;
		if (!this.registration.id) return;

		await RegistrationService
			.updateDate(this.registration.id, res.date.id, res.date)
			.catch(this.showNetworkError);

		if (this.selectedDate && this.selectedDate.generateCert) {
			await RegistrationService
				.deleteCertificate(this.registration.id, res.date.id, res.filename)
				.catch(this.showNetworkError);
		}
		this.selectedDate = res.date;
		this.pollRegFormForCertificateDownload();
	}

	async updateCertificate(res: { date: RegistrationDate, file: File | null }): Promise<void> {
		if (!this.registration) return;
		if (!this.registration.id) return;

		await RegistrationService.updateDate(this.registration.id, res.date.id, res.date)
			.catch(this.showNetworkError);

		if (res.file) {
			await RegistrationService
				.deleteCertificate(this.registration.id, res.date.id, 'certificate.pptx')
				.catch(this.showNetworkError);
			await RegistrationService
				.addCertificate(this.registration.id, res.date.id, res.file)
				.catch(this.showNetworkError);
		}
		this.selectedDate = { ...res.date, certificateFileName: 'certificate.pptx' };
		this.pollRegFormForCertificateDownload();
	}
}
