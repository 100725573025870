




















import { Component, Prop, Watch } from 'vue-property-decorator';
import Countries from 'i18n-iso-countries';
import Base from '@/views/Base';

@Component
export default class WCountrySelect extends Base {
	@Prop() value!: string;
	@Prop() disabled!: boolean;
	@Prop() state!: any;

	countries: { value: string; label: string }[] = [];

	mounted(): void {
		this.setLanguages();
	}

	@Watch('user')
	userSet(): void {
		this.setLanguages();
	}

	private setLanguages(): void {
		if (this.user) {
			const list = Countries.getNames(this.user.language);
			this.countries = Object.keys(list).map((key) => ({
				value: key,
				label: list[key]
			}));
		}
	}
}
