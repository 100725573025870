






































































































































































































































































































































































































import { BookingListItem } from '@/models/Booking';
import { Component, Prop } from 'vue-property-decorator';
import WFunctions from '@/components/WFunctions.vue';
import WPin from '@/components/WPin.vue';
import WConfContext from '@/components/WConfContext.vue';
import BookingService from '@/service/BookingService';
import Base from '@/views/Base';
import { BookingRegistration, BookingSurvey } from '@/models/api';
import { dateToDateTimeString } from '@/utils/filters';
import { EventBus, Events } from '@/EventBus';
import { months, days } from '@/utils/date';
@Component({
	components: { WFunctions, WPin, WConfContext }
})
export default class WCard extends Base {
	@Prop() item!: BookingListItem;

	months = months(this);
	days = days(this);

	isSeries = false;

	mounted(): void {
		if (this.item.schedule)
			this.isSeries = BookingService.isSeries(this.item.schedule.type);
	}

	get duration(): string {
		return this.item.duration || '';
	}

	get isRoom(): boolean {
		return this.item.type === 'room';
	}

	get variant(): string {
		return this.item.type === 'past' ? 'gray' : 'primary';
	}

	get beginDate(): Date {
		return this.item?.date;
	}

	get year(): number {
		return this.beginDate.getFullYear();
	}

	get month(): string {
		return this.months[this.beginDate.getMonth()] as string;
	}

	get date(): number {
		return this.beginDate.getDate();
	}

	get day(): string {
		const day = this.beginDate.getDay();
		return this.days[day] as string;
	}

	get time(): string {
		const hours = ('0' + this.beginDate.getHours()).slice(-2);
		const minutes = ('0' + this.beginDate.getMinutes()).slice(-2);
		return hours + ':' + minutes;
	}

	get registration(): BookingRegistration | undefined {
		return this.item.registrationForm;
	}

	get survey(): BookingSurvey | undefined {
		return this.item.survey;
	}

	editConferenceForm(): void {
		EventBus.$emit(Events.EditConferenceForm, this.item);
	}

	editConferenceSurvey(): void {
		EventBus.$emit(Events.EditConferenceSurvey, this.item);
	}

	dateToDateTimeString = dateToDateTimeString;

	openNationalNumbers(): void {
		this.$root.$emit('bv::show::modal', 'nationalNumbers');
	}
}
