





















































































































import { Component, Prop } from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import { ServicesForm } from '@/models/OperatorForm';

@Component({
	components: { WSwitch }
})
export default class ConferenceServices extends Base {
	@Prop() form!: ServicesForm;
	@Prop() validation!: any;

	open = true;

	toggleOpen(): void {
		this.open = !this.open;
	}
}
