export function secureRandom(): number {
	// https://stackoverflow.com/questions/13694626/generating-random-numbers-0-to-1-with-crypto-generatevalues
	const arr = new Uint32Array(1);
	crypto.getRandomValues(arr);
	// keep all 32 bits of the the first, top 20 of the second for 52 random bits
	const mantissa = arr[0] * Math.pow(2, 20) + (arr[1] >>> 12);
	// shift all 52 bits to the right of the decimal point
	return mantissa * Math.pow(2, -52);
}

export function id(): string {
	return secureRandom().toString(36).substr(2);
}
