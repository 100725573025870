




























































import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import { RegFormDateIds, SelectOption } from '@/models/common';
import { RegistrationDate } from '@/models/Registration';
import { dateToDateTimeString } from '@/utils/filters';

@Component
export default class SelectOperatorRegDate extends Base {
	@Prop() value!: RegFormDateIds;
	@Prop() regForms!: SelectOption[];

	value_: RegFormDateIds = { regFormId: '', regDateId: '' };

	submit(ok: () => void): void {
		this.$emit('input', this.value_);
		ok();
		this.value_ = { regFormId: '', regDateId: '' };
	}

	get regDates(): SelectOption[] {
		let selectOptions = this.regForms.filter(
			(form) => form.value === this.value_.regFormId
		);
		if (selectOptions.length === 1) {
			let regDateOptions = selectOptions
				.pop()
				?.data.sort(
					(a: RegistrationDate, b: RegistrationDate) => a.start - b.start
				)
				.map((date: RegistrationDate) => ({
					text: date.title + ' (' + dateToDateTimeString(date.start) + ')',
					value: date.id.toString(),
					data: date,
					disabled: date.start < Date.now()
				}));

			regDateOptions.unshift({
				text: this.t('modals.operatorRegForm.selectDateShort'),
				value: '',
				disabled: true
			});
			return regDateOptions;
		} else {
			return [];
		}
	}

	get saveButtonDisabled(): boolean {
		return this.value_.regFormId === '' ? false : !this.value_.regDateId;
	}

	resetRegDateId(): void {
		this.value_.regDateId = '';
	}
}
