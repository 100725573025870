





























































































































































































import {NationalNumber} from '@/models/api';
import {BookingDialInForm, BookingForm} from '@/models/BookingForm';
import BookingService from '@/service/BookingService';
import Base from '@/views/Base';
import {Component, Prop, PropSync} from 'vue-property-decorator';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import {pinValidator} from "@/utils/validators";

@Component
export default class BookingDialIn extends Base {
	@Prop() dialOut!: boolean;
	@Prop() form!: BookingDialInForm;
	@Prop() bookingForm!: BookingForm;
	@PropSync('valid') syncedValid!: boolean;
	@PropSync('tag') syncedTag!: string;
	@PropSync('dirty') syncedDirty!: boolean;

	@Validations()
	validations = {
		form: {
			moderatorAccessCode: {
				sixIntegers: pinValidator,
				unique: this.uniqueValidator,
				required,
				codeExists: (): any => {
					return this.codeExists('MODERATOR');
				}
			},
			participantAccessCode: {
				sixIntegers: pinValidator,
				unique: this.uniqueValidator,
				required,
				codeExists: (): any => {
					return this.codeExists('PARTICIPANT');
				}
			}
		}
	};

	resetForm!: BookingDialInForm;
	nationalNumbers: NationalNumber[] = [];

	participantCodeExists = false;
	moderatorCodeExists = false;

	get editMode(): boolean {
		return !!this.$route.query.id;
	}

	get numbersCount(): number {
		return this.$store.getters.numbersCount;
	}

	mounted(): void {
		this.resetForm = { ...this.form };
		BookingService.getNationalNumbers()
			.then((numbers) => {
				this.nationalNumbers = numbers
					.filter((num) => num.active)
					.splice(0, this.numbersCount);
			})
			.catch(this.showNetworkError);
	}

	beforeDestroy(): void {
		if (this.$v.form.moderatorAccessCode?.$invalid)
			this.form.moderatorAccessCode = this.resetForm.moderatorAccessCode;
		if (this.$v.form.participantAccessCode?.$invalid)
			this.form.participantAccessCode = this.resetForm.participantAccessCode;
	}

	generate(user: string): void {
		const generatedPin = BookingService.generatePin();
		user === 'mod'
			? (this.form.moderatorAccessCode = generatedPin)
			: (this.form.participantAccessCode = generatedPin);
	}

	reset(user: string): void {
		user === 'mod'
			? (this.form.moderatorAccessCode = '')
			: (this.form.participantAccessCode = '');
	}

	uniqueValidator(): boolean {
		return this.form.moderatorAccessCode !== this.form.participantAccessCode;
	}

	validateState(name: string): boolean | null {
		const validate: any = this.$v.form[name];
		// if (!this.syncedDirty) {
		// 	this.syncedDirty = true;
		// }
		this.syncedValid = !this.$v.form.$invalid;
		return validate.$dirty ? !validate.$error : null;
	}

	openNationalNumbers(): void {
		this.$root.$emit('bv::show::modal', 'nationalNumbers');
	}

	codeExists(type: 'PARTICIPANT' | 'MODERATOR'): any {
		if (this.editMode && !this.resetForm) return true;
		if (this.resetForm) {
			if (
				type === 'MODERATOR' &&
				this.resetForm.moderatorAccessCode === this.form.moderatorAccessCode
			) {
				return true;
			}
			if (
				type === 'PARTICIPANT' &&
				this.resetForm.participantAccessCode === this.form.participantAccessCode
			) {
				return true;
			}
		}
		if (
			pinValidator(this.form.moderatorAccessCode) &&
			pinValidator(this.form.participantAccessCode) &&
			this.uniqueValidator()
		) {
			const booking = BookingService.bookingFormToBooking(
				this.bookingForm,
				this.dialOut,
				true
			);
			return BookingService.testBooking(booking).then((v) => {
				if (type === 'MODERATOR') {
					if (v.errorWithModeratorPin) {
						this.moderatorCodeExists = true;
						return false;
					} else {
						this.moderatorCodeExists = false;
						return true;
					}
				} else {
					if (v.errorWithParticipantPin) {
						this.participantCodeExists = true;
						return false;
					} else {
						this.participantCodeExists = false;
						return true;
					}
				}
			});
		}
		return true;
	}
}
