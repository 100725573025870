
















import Base from "@/views/Base";

export default class LoginOffer extends Base {
}
