import Quill from 'quill';

const Clipboard = Quill.import('modules/clipboard');
const Link = Quill.import('formats/link');
const Delta = Quill.import('delta');
class PlainClipboard extends Clipboard {
	onPaste(e: any) {
		e.preventDefault();
		const range = this.quill.getSelection();
		const text = e.clipboardData.getData('text/plain');
		const delta = new Delta()
			.retain(range.index)
			.delete(range.length)
			.insert(text);
		const index = text.length + range.index;
		const length = 0;
		this.quill.updateContents(delta);
		this.quill.setSelection(index, length, 'silent');
		this.quill.scrollIntoView();
	}
}

// If a user enters an url without protocol, it will be removed by
// our HTMLSanitizer. So we add https:// via CustomLink handler for the quill editor
// https://stackoverflow.com/questions/40956020/how-can-i-prefill-links-with-http-in-a-quill-editor
export class CustomLink extends Link {
	static sanitize(url: string) {
		const value = super.sanitize(url);
		if (value) {
			for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
				if (value.startsWith(this.PROTOCOL_WHITELIST[i])) return value;
			return `https://${value}`;
		}
		return value;
	}
	static create(value: string) {
		const node = super.create(value);
		value = this.sanitize(value);
		node.setAttribute('href', value);
		node.setAttribute('target', 'new');
		return node;
	}
}

export default PlainClipboard;

export const defaultEditorOptions = {
	theme: 'snow',
	placeholder: '',
	modules: {
		toolbar: [
			[{ size: ['small', false, 'large', 'huge'] }],
			['bold', 'italic', 'underline', 'link'],
			[{ list: 'ordered' }, { list: 'bullet' }],
			['clean']
		]
	}
};

export const presentationNotesEditorOptions = {
	theme: 'snow',
	placeholder: '',
	modules: {
		toolbar: [
			[{ size: ['small', false, 'large', 'huge'] }],
			['bold', 'italic', 'underline', 'link'],
			['clean']
		]
	}
};

export const brandingPrivacyEditorOptions = {
	...defaultEditorOptions,
	placeholder: '...'
};
