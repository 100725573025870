<template>
  <div class="speakerCard" :style="{ 'backgroundColor': speakerCard.color }">
    <div class="main">
      <div class="imageBox">
        <div class="imageOverlay" v-if="photoUrl">
          <img :src="photoUrl" alt="profile" class="image"
               ref="speakerCardPhoto" :style="photoStyle"
               @mousemove="mouseMove" @mousedown="mouseDown" @mouseup="mouseUp"
               @touchmove="mouseMove" @touchstart="mouseDown" @touchend="mouseUp"
               draggable="false"
          >
        </div>
        <div v-else class="defaultImage">
          <default-profile-photo />
        </div>
      </div>
      <div class="text">
        <div class="name line-clamp-2" :style="{ 'color': textColorPrimary }">
          {{ speakerCard.name !== '' ? speakerCard.name : $t('presentation.speakerProfiles.firstAndLastname') }}
        </div>
        <div class="position line-clamp-2" :style="{ 'color': textColorSecondary}">
          {{ speakerCard.position !== '' ? speakerCard.position : (!preview ? '' : $t('presentation.speakerProfiles.position'))
          }}
        </div>
      </div>
      <div class="contextMenu" v-if="contextMenu">
        <b-dropdown variant="transparent"
                    no-caret
                    dropleft class="p-0">
          <template #button-content>
            <div>
              <three-dots-icon class="icon" :style="{color: textColorPrimary}" />
            </div>
          </template>
          <b-dropdown-item @click="updateCard">
            {{ $t('presentation.speakerProfiles.editBusinessCard') }}
          </b-dropdown-item>
          <b-dropdown-item @click="duplicateCard">
            {{ $t('presentation.speakerProfiles.duplicateBusinessCard') }}
          </b-dropdown-item>
          <b-dropdown-item @click="deleteCard">
            {{ $t('presentation.speakerProfiles.deleteSpeaker') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="footer">
      <img :src="logoUrl" class="noPointerEvents footerCompany" alt="profile" v-if="logoUrl">
      <div v-else :style="{color: textColorPrimary}" class="line-clamp-2 footerCompany">{{ speakerCard.company }}</div>
      <!-- No preview, url exists or is deactivated -->
      <a v-if="!preview && profileActive" class="profileLink footerLink" :style="{ 'color': textColorPrimary }"
         :href="profileUrl" @click.stop="()=>{}" target="_blank">
        {{ $t('presentation.speakerProfiles.openProfile') }}
        <arrow-right-circle-filled-icon class="icon ml-1" />
      </a>
      <!-- Preview, url exists -->
      <a v-else-if="preview && profileActive && existingUrl" class="profileLink cursor-pointer footerLink" :style="{ 'color': textColorPrimary }"
         :href="existingUrl" target="_blank">
        {{ $t('presentation.speakerProfiles.openProfile') }}
        <arrow-right-circle-filled-icon class="icon ml-1" />
      </a>
    </div>
  </div>
</template>

<script>
import DefaultProfilePhoto from '@/views/presentation/speakerProfiles/default-profile-photo.vue';

export default {
  components: { DefaultProfilePhoto },
  props: {
    speakerCard: {
      type: Object,
      required: true
    },
    initialPhotoX: {
      default: 0,
      type: Number
    },
    initialPhotoY: {
      default: 0,
      type: Number
    },
    photoUrl: {
      default: '',
      type: String
    },
    logoUrl: {
      default: '',
      type: String
    },
    editable: {
      default: false,
      type: Boolean
    },
    profileActive: {
      default: false,
      type: Boolean
    },
    contextMenu: {
      default: false,
      type: Boolean
    },
    preview: {
      default: false,
      type: Boolean
    },
    existingUrl: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      x: 0, // current position of photo (in percent)
      y: 0,
      startX: 0, // start moving photo position in percent
      startY: 0,
      startViewportX: 0, // start moving photo in pixel, mouse click position in viewport
      startViewportY: 0,
      isMoving: false
    };
  },
  mounted() {
    this.x = this.initialPhotoX;
    this.y = this.initialPhotoY;
  },
  computed: {
    isDark: function() {
      if (!this.speakerCard.color.startsWith('#') || this.speakerCard.color.length !== 7) {
        // Fallback for wrong color format
        return true;
      }

      const r = parseInt(this.speakerCard.color.substring(1, 3), 16);
      const g = parseInt(this.speakerCard.color.substring(3, 5), 16);
      const b = parseInt(this.speakerCard.color.substring(5, 7), 16);

      // Luminance calculation, see https://stackoverflow.com/questions/596216/formula-to-determine-perceived-brightness-of-rgb-color
      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luminance < 128;
    },
    textColorPrimary: function() {
      return this.isDark ? '#fff' : '#000';
    },
    textColorSecondary: function() {
      return this.isDark ? '#ccc' : '#444';
    },
    overlayBackground: function() {
      return `radial-gradient(circle at 50% 50%, transparent 50%, white 50%, ${this.speakerCard.color} 52%)`;
    },
    photoStyle: function() {
      return {
        transform: `scale(${this.speakerCard.photoScale}) translate(${this.x}%, ${this.y}%)`,
        cursor: this.isMoving ? 'grabbing' : (this.editable ? 'grab' : 'default')
      };
    },
    profileUrl: function() {
      return process.env.VUE_APP_PROFILE_URL + '/' + this.speakerCard.url;
    }
  },
  methods: {
    getClientCoords(e) {
      if (e && e.touches) {
        const touch = e.touches[0] || e.changedTouches[0];
        return { clientX: touch.clientX, clientY: touch.clientY };
      } else {
        return { clientX: e.clientX, clientY: e.clientY };
      }
    },
    mouseMove(e) {
      if (this.isMoving) {
        if(e && e.touches){
          // Stop scrolling
          e.preventDefault();
        }

        const { clientX, clientY } = this.getClientCoords(e);
        const deltaX = clientX - this.startViewportX;
        const deltaY = clientY - this.startViewportY;
        const parent = this.$refs.speakerCardPhoto.parentElement;
        const width = parent.clientWidth;
        const height = parent.clientHeight;

        this.x = this.startX + (deltaX / width) * 100 / this.speakerCard.photoScale;
        this.y = this.startY + (deltaY / height) * 100 / this.speakerCard.photoScale;
      }
    },
    mouseDown(e) {
      if (!this.editable) return;
      const { clientX, clientY } = this.getClientCoords(e);
      this.startViewportX = clientX;
      this.startViewportY = clientY;
      this.startX = this.x;
      this.startY = this.y;
      this.isMoving = true;
    },
    mouseUp() {
      this.isMoving = false;
      this.$emit('updateXY', this.x, this.y);
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    deleteCard() {
      this.$emit('delete');
    },
    updateCard() {
      this.$emit('update');
    },
    duplicateCard() {
      this.$emit('duplicate');
    }
  }
};
</script>

<style scoped>
.speakerCard {
  aspect-ratio: 16 / 9;
  position: relative;
}

.main {
  height: 75%;
  display: flex;
  align-items: center;
}

.imageBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7%;
  margin-top: 2%;
}

.defaultImage {
  width: 80%;
}

.imageOverlay {
  border-radius: 50%;
  border: 1px solid white;
  overflow: hidden;
  position: relative;
  background-color: #eee;
  width: 80%;
  aspect-ratio: 1;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.noPointerEvents {
  pointer-events: none;
}

.text {
  width: 60%;
  margin-right: 0;
  margin-left: auto;
  padding-left: 8%;
}

.footer {
  height: 25%;
  display: flex;
  padding: 0 20px 20px 20px;
  align-items: center;
  justify-content: space-between;
}

.footer img {
  max-height: 100%;
  max-width: 60%;
}

.footer .profileLink {
  font-size: 0.9rem;
}

.name {
  font-size: 1rem;
  font-weight: bold;
  margin-right: 10%;
}

.position {
  font-size: 0.9rem;
  margin-right: 10%;
}

.contextMenu {
  position: absolute;
  top: 9px;
  right: 5px;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.footerCompany {
  max-width: 65%;
}

</style>