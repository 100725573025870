







import {Component} from 'vue-property-decorator';
import Base from '../../Base';
import {AccountSettings} from '@/models/Account';
import BookingOnePage from './OnePage.vue';
import BookingMultiPage from './MultiPage.vue';
import {Route} from "vue-router";

@Component({
	components: { BookingOnePage, BookingMultiPage }
})
export default class TeleconferenceBooking extends Base {
	onePage = false;

	created(): void {
		if (this.user && this.user.settings) {
			const settings = JSON.parse(this.user.settings) as AccountSettings;
			if (settings) this.onePage = settings.wizard === false;
		}
	}

  beforeRouteEnter(to: Route, from: Route, next: () => void) {
    sessionStorage.setItem('previousRoute', from.path);
    next();
  }

}
