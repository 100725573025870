























import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import { ConferenceListItem } from '@/models/Conference';
// TODO does this do anything?
@Component
export default class WOperatorConfContext extends Base {
	@Prop() item!: ConferenceListItem;
	@Prop() type!: 'scheduled' | 'past' | 'running';

	get isRunning(): boolean {
		return this.type === 'running';
	}

	get isScheduled(): boolean {
		return this.type === 'scheduled';
	}

	get isPast(): boolean {
		return this.type === 'past';
	}
}
