
















































































































import { Component, Prop } from 'vue-property-decorator';
import PlaceholdersModal from '@/views/forms/components/PlaceholdersModal.vue';
import { quillEditor } from 'vue-quill-editor';
import Base from '@/views/Base';
import { required } from 'vuelidate/lib/validators';
import { Validations } from 'vuelidate-property-decorators';
import { FormMail, Survey } from '@/models/Survey';
import { Validation } from 'vuelidate';
import SurveyService from '@/service/SurveyService';
import { replaceNewLinesWithParagraphs } from '@/utils/filters';
import { validateEmail } from '@/utils/validators';
import { Registration, RegistrationDate } from '@/models/Registration';
import RegistrationService from '@/service/RegistrationService';

@Component({
	components: {
		PlaceholdersModal,
		quillEditor
	}
})
export default class CertificateMailModal extends Base {
	@Prop() form!: Survey | Registration;
	@Prop() loading!: boolean;
	@Prop() mail!: FormMail;
	@Prop() modalId!: string;
	@Prop({ type: Boolean, default: false }) single!: boolean;
	@Prop() regDate!: RegistrationDate;

	DEFAULT_SURVEY_TEXT = this.t('forms.defaultSurveyText');

	mailEditorOptions = {
		theme: 'snow',
		placeholder: '',
		modules: {
			toolbar: [['link', 'bold', 'italic', 'underline']]
		}
	};

	@Validations()
	validations = {
		mail: {
			replyTo: { required, email: validateEmail },
			subject: { required },
			text: { required }
		}
	};

	get isReg(): boolean {
		return !!this.regDate;
	}

	validateState(objectName: string, name: string): boolean | null {
		const validate = this.$v[objectName][name] as Validation;
		return validate.$dirty ? !validate.$error : null;
	}

	sendTestCertificate(): void {
		if (!this.form.id) return;
		this.loading = true;
		if (this.isReg) {
			RegistrationService.sendTestCertificate(this.form.id, this.regDate.id, this.mail)
				.then(() => {
					this.toast(this.t('forms.testMailSuccess'), 'success');
					this.regDate.certificateMailPreset = {
						replyTo: this.mail.replyTo,
						subject: this.mail.subject,
						text: this.mail.text,
						enabled: true
					}
				})
				.catch(this.showNetworkError)
				.finally(() => this.loading = false);
		} else {
			SurveyService.sendTestCertificate(this.form.id, this.mail)
				.then(() => {
					this.toast(this.t('forms.testMailSuccess'), 'success');
						this.form.certificateMailPreset = {
							replyTo: this.mail.replyTo,
							subject: this.mail.subject,
							text: this.mail.text,
							enabled: true
						}
				})
				.catch(this.showNetworkError)
				.finally(() => this.loading = false);
		}
	}

	setCertificateMail(): void {
		if (this.isReg && this.regDate && this.regDate.certificateMailPreset) {
			this.mail.replyTo = this.regDate.certificateMailPreset.replyTo;
			this.mail.text = this.regDate.certificateMailPreset.text;
			this.mail.subject = this.regDate.certificateMailPreset.subject;
		} else if (this.form && this.form.certificateMailPreset) {
			this.mail.replyTo = this.form.certificateMailPreset.replyTo;
			this.mail.text = this.form.certificateMailPreset.text;
			this.mail.subject = this.form.certificateMailPreset.subject;
		} else if (this.mail && this.form.mail) {
			this.mail.replyTo = this.mail.replyTo || this.form.mail.replyTo;
			this.mail.text = this.isReg
				? this.t('forms.regCertificate')
				: replaceNewLinesWithParagraphs(this.mail.text || this.form.mail.text);
		} else {
			this.mail.replyTo = this.user.customerContact?.email || this.t('forms.insertResponseMail');
			this.mail.text = this.DEFAULT_SURVEY_TEXT;
		}

		// Replace FAILED string from mail text
		const failedRegEx = /{FAILED\|.*?}/;
		const failedMatch = this.mail.text.match(failedRegEx);
		if (failedMatch && failedMatch[0])
			this.mail.text = this.mail.text.replace(failedMatch[0], '');

		// Replace PASSED string from mail text
		const passedRegEx = /{PASSED\|(?<passedString>.*?)}/;
		const passedMatch = this.mail.text.match(passedRegEx);
		const passedString = passedMatch?.groups?.passedString;

		if (passedString && passedMatch && passedMatch[0]) {
			this.mail.text = this.mail.text.replaceAll('{PASSED|' + passedString + '}', passedString);
		}
		this.mail.subject = this.mail.subject || (this.$t('forms.yourCertificate') as string);
	}

	get modalTitle(): string {
		return this.single
			? (this.$t('forms.certificateToSelected') as string)
			: (this.$t('forms.certificateToAll') as string);
	}
}
