

































































































































































































import { BvTableField } from 'bootstrap-vue';
import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import WFunctions from '@/components/WFunctions.vue';
import WConfContext from '@/components/WConfContext.vue';
import { BookingRegistration } from '@/models/api';
import { BookingListItem } from '@/models/Booking';
import { EventBus, Events } from '@/EventBus';

@Component({
	components: { WFunctions, WConfContext }
})
export default class WTable extends Vue {
	@Prop() items!: any[];
	@Prop() fields!: BvTableField[];
	@Prop({ default: 10 }) perPage!: number;
	@Prop({ default: 1 }) currentPage!: number;
	@Prop() emptyText!: string;

	@PropSync('sortBy', { default: '' }) _sortBy!: string;
	@PropSync('sortDesc', { default: true }) _sortDesc!: boolean;
	@Prop({ default: false, type: Boolean }) serverSide!: boolean;

	@Prop({ default: 'bg-white text-secondary' }) thColorClass?: string;

	get totalRows(): number {
		return this.items.length;
	}

	isPlayback(row: any): boolean {
		return row.item.recording === 'Playback';
	}

	date(milliDate: number): string {
		return new Date(milliDate).toLocaleTimeString(['de'], {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit'
		});
	}

	duration(dur: string): string {
		const durationArr = dur.split(':');
		const minutes = durationArr[0] + 'm';
		const seconds = durationArr[1] + 's';
		return `${minutes} ${seconds}`;
	}

	editConferenceForm(item: BookingListItem): void {
		EventBus.$emit(Events.EditConferenceForm, item);
	}

	editConferenceSurvey(item: BookingListItem): void {
		EventBus.$emit(Events.EditConferenceSurvey, item);
	}
}
