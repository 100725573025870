import {CustomField, NewCustomField, ReorderModel} from "@/models/CustomField";
import axios from "axios";

export default {
    getFields(): Promise<CustomField[]> {
        return axios.get("/validation").then(
            resp => resp.data.map((field: CustomField) => ({
                ...field,
                usedSurveys: field.usedSurveys ?? [],
                usedRegForms: field.usedRegForms ?? [],
            }))
        )
    },
    getField(id: number): Promise<CustomField> {
        return axios.get("/validation/" + id).then(
            resp => ({
                ...resp.data,
                usedSurveys: resp.data.usedSurveys ?? [],
                usedRegForms: resp.data.usedRegForms ?? [],
            })
        )
    },

    createField(customField: NewCustomField): Promise<CustomField> {
        return axios.post("/validation", customField).then(res => res.data)
    },
    updateField(id: number, customField: NewCustomField): Promise<CustomField> {
        return axios.put("/validation/" + id, customField).then(res => res.data)
    },

    deleteField(id: number): Promise<void> {
        return axios.delete("/validation/" + id).then()
    },

    getEmptyField(): NewCustomField {
        return {
            customFormat: "",
            description: "",
            global: false,
            ignoreCase: true,
            maxLength: undefined,
            title: "",
        }
    },

    translate(value: string): Promise<string> {
        return axios.get("/validation/translate", {params: {custom: value}}).then(
            res => res.data
        )
    },

    reorder(model: ReorderModel): Promise<CustomField[]> {
      return axios.put("/validation/reorder", model).then(res=>res.data);
    },

    sortFields<T extends { global: boolean, index: number }>(fields: T[]): T[] {
        return fields.sort((a, b) => {
            if (a.global && !b.global) {
                return -1;
            } else if (!a.global && b.global) {
                return 1;
            } else {
                // Wenn beide global oder beide nicht global sind, sortiere nach dem Index
                return a.index - b.index;
            }
        });
    }
};
