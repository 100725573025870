














































import { BFormInput } from 'bootstrap-vue';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { ValidationRule } from 'vuelidate/lib/validators';

interface Validator {
  [key: string]: [ValidatorRule, string];
}

type ValidatorRule = () => ValidationRule;

@Component
export default class WInlineEdit extends Vue {
  @Prop() value!: string;
  @Prop() placeholder!: string;
  @Prop() content!: string;
  @Prop() validators!: Validator;
  @Prop() id!: string;
  @Prop({ default: null, type: Number }) maxLength!: number;
  @Prop({ default: false, type: Boolean }) dark!: boolean;
  newVal = '';
  errorContent = '';
  errors: { [key: string]: string } = {};
  errorKeys: string[] = [];

  @Validations()
  validations = {
    newVal: {}
  };

  editMode = false;
  loading = false;
  queueSubmit = false;
  queueTab = false;

  @Watch('loading')
  watchLoading(value: boolean): void {
    if (!value) {
      if (this.queueTab) {
        this.queueTab = false;
        this.tab();
      } else if (this.queueSubmit) {
        this.queueSubmit = false;
        this.submit();
      }
    }
  }

  @Watch('newVal')
  watchValue(): void {
    if (this.$v.newVal.$dirty) {
      this.$v.newVal.$reset();
    }
  }

  get hasErrors(): boolean {
    const form = this.$v.newVal;
    return form.$anyError && form.$invalid && form.$dirty;
  }

  get editable(): boolean {
    return !this.activeEditInvalid;
  }

  @Watch('validators')
  validatorsChanged(): void {
    this.errorKeys = Object.keys(this.validators);
    const validatorFns: { [key: string]: ValidatorRule } = {};
    if (this.errorKeys.length > 0) {
      this.errorKeys.forEach((key) => {
        validatorFns[key] = this.validators[key][0];
        this.errors[key] = this.validators[key][1];
      });
    }
    this.validations.newVal = validatorFns;
  }

  enter(): void {
    this.closeOnOutsideClick();
  }

  closeOnOutsideClick(): void {
    this.$v.newVal.$touch();
    this.submit();
  }

  showInput(): void {
    setTimeout(() => {
      if (this.editable) {
        this.newVal = this.value;
        this.editMode = true;
        document.body.addEventListener('mousedown', this.closeOnOutsideClick);
        this.activeInlineEdit = this.id;
        setTimeout(() => {
          (this.$refs['input'] as BFormInput).focus();
        });
      }
    }, 10);
  }

  close(): void {
    if (this.id === this.activeInlineEdit) {
      this.activeInlineEdit = '';
    }
    this.activeEditInvalid = false;
    this.editMode = false;
    document.body.removeEventListener('mousedown', this.closeOnOutsideClick);
  }

  submit(): void {
    if (!this.$v.$invalid && !this.loading) {
      this.$emit('input', this.newVal);
      this.$v.newVal.$reset();
      this.close();
    } else if (this.loading) {
      this.queueSubmit = true;
    }
  }

  tab(e?: KeyboardEvent): void {
    this.$v.newVal.$touch();
    this.submit();
    if (!this.hasErrors) this.$emit('tabbed', e?.shiftKey);
  }

  get validateState(): boolean | null {
    const validate = this.$v.newVal;
    if (validate.$error) {
      this.activeEditInvalid = true;
      this.errorContent = '';

      let filteredErrorKeys = this.errorKeys.filter(key => !validate[key]);
      filteredErrorKeys.forEach((key, index) => this.errorContent += this.errors[key] + (index < filteredErrorKeys.length - 1 ? ', ' : ''));

      setTimeout(() => {
        this.$root.$emit('bv::show::popover', 'edit-errors-trigger');
      }, 50);
    } else {
      this.activeEditInvalid = false;
    }
    return validate.$dirty ? !validate.$error : null;
  }

  // Store Functions
  get activeInlineEdit(): string {
    return this.$store.getters.activeInlineEdit;
  }

  set activeInlineEdit(value: string) {
    if (this.activeInlineEdit !== value) {
      this.$store.commit('setActiveInlineEdit', value);
    }
  }

  get activeEditInvalid(): boolean {
    return this.$store.getters.activeEditInvalid;
  }

  set activeEditInvalid(value: boolean) {
    if (this.activeEditInvalid !== value) {
      this.$store.commit('setActiveEditInvalid', value);
    }
  }
}
