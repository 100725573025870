


























































































































































































































































































import { Component } from 'vue-property-decorator';
import Base from '../Base';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: { HeaderLayout }
})
export default class UserSettings extends Base {
	changeVariable(
		event: InputEvent,
		varNames: string | string[],
		value?: string
	): void {
		const newValue = (event?.target as HTMLInputElement)?.value || value;
		setTimeout(() => {
			if (typeof varNames === 'string') {
				document.documentElement.style.setProperty(
					varNames,
					newValue || '#000'
				);
			} else {
				varNames.forEach((name) => {
					document.documentElement.style.setProperty(name, newValue || '#000');
				});
			}
			if (value) {
				this.toast(this.t("forms.variableChanged"), 'success');
			}
		}, 100);
	}

	toggleBorder(event: InputEvent): void {
		setTimeout(() => {
			const newValue = (event.target as HTMLInputElement).checked;
			if (newValue) {
				document.documentElement.style.setProperty(
					'--w-card-border-width',
					'1px'
				);
				document.documentElement.style.setProperty(
					'--w-wizard-border',
					'#e2e8f0'
				);
			} else {
				document.documentElement.style.setProperty(
					'--w-card-border-width',
					'0px'
				);
				document.documentElement.style.setProperty(
					'--w-wizard-border',
					'var(--w-card)'
				);
			}
		}, 100);
	}
}
