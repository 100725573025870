

















































































































































































import { Component, Watch } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WPagination from '@/components/WPagination.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import { ListOptions } from '@/models/Booking';
import AccountsService from '@/service/AccountsService';
import CustomersService from '@/service/CustomersService';
import AuthenticationService from '@/service/AuthenticationService';
import { createXLSX } from '@/utils/excelAdmin';
import { Account, Permission } from '@/models/Account';
import { Column } from '@/utils/excel';

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: { HeaderLayout, WListOptions, WPagination, WHeaderBtn }
})
export default class Users extends Base {
  items: any[] = [];
  fields = [
    {
      key: 'id',
      label: this.t('common.id'),
      sortable: true,
      thStyle: { width: '60px' },
      sortDirection: 'desc'
    },
    {
      key: 'username',
      label: this.t('common.username'),
      thStyle: { width: '160px' },
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'contact.lastName',
      label: this.t('common.name'),
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'customer',
      label: this.t('common.customer'),
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'customerNr',
      label: this.t('admin.customerNumberShort'),
      thStyle: { width: '108px' },
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'participantLimit',
      label: this.t('admin.participantLimitShort'),
      thStyle: { width: '108px' },
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'dateLastLogin',
      label: this.t('admin.login'),
      thStyle: { width: '120px' },
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'w2MatchingStatus',
      label: this.t('admin.w2MatchingStatus'),
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'active',
      label: this.t('common.status'),
      thStyle: { width: '100px' },
      sortable: true,
      sortDirection: 'desc'
    },
    {
      key: 'actions',
      label: this.t('common.actions'),
      thStyle: { width: '90px' },
      thClass: 'show-content-md'
    }
  ];

  options: ListOptions = {
    entryCount: 10,
    searchTerm: '',
    customer: 0
  };

  currentPage = 1;
  totalRows = 0;
  totalPages = 1;

  sortBy = 'dateCreated';
  sortDesc = true;

  customers: { value: number; text: string }[] = [
    { value: 0, text: this.t('admin.allCustomers') }
  ];

  mounted(): void {
    this.loadFromLocalStorage();
    if (this.$route.query.customerId) {
      this.options.customer = +this.$route.query.customerId;
    }
    this.getAccounts();
    this.getCustomers();
  }

  @Watch('options.searchTerm')
  search(): void {
    this.currentPage = 1;
    setTimeout(() => {
      this.getAccounts();
    }, 100);
  }

  @Watch('currentPage')
  currentPageChanged(): void {
    this.getAccounts();
  }

  @Watch('options.entryCount')
  entryCount(): void {
    this.currentPage = 1;
    this.getAccounts();
  }

  @Watch('options.customer')
  customerChanged(): void {
    this.getAccounts();
  }

  @Watch('sortBy')
  sort(): void {
    const col = this.fields.find((f) => f.key === this.sortBy);
    if (col && col.sortable) {
      this.getAccounts();
    }
  }

  @Watch('sortDesc')
  sortByDirection(): void {
    this.getAccounts();
  }

  loadFromLocalStorage(): void {
    if (localStorage.getItem('users')) {
      const { customerId } = this.$route.query;
      if (customerId) {
        this.clearLocalStorage();
      } else {
        let usersFilter;
        try {
          const parsedUsersFilter = localStorage.getItem('users') as any;
          usersFilter = JSON.parse(parsedUsersFilter);
        } catch (e) {
          this.clearLocalStorage();
        }
        if (usersFilter) {
          if (usersFilter.entryCount)
            this.options.entryCount = usersFilter.entryCount;
          if (usersFilter.searchTerm)
            this.options.searchTerm = usersFilter.searchTerm;
          if (usersFilter.customer)
            this.options.customer = usersFilter.customer;
          if (usersFilter.currentPage)
            this.currentPage = usersFilter.currentPage;
          if (usersFilter.sortBy) this.sortBy = usersFilter.sortBy;
          if (usersFilter.sortDesc) this.sortDesc = usersFilter.sortDesc;
        }
      }
    }
  }

  clearLocalStorage(): void {
    localStorage.removeItem('users');
  }

  setToLocalStorage(): void {
    localStorage.setItem(
      'users',
      JSON.stringify({
        entryCount: this.options.entryCount,
        searchTerm: this.options.searchTerm,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        customer: this.options.customer
      })
    );
  }

  getAccounts(): void {
    this.setToLocalStorage();
    AccountsService.getAccounts(
      this.currentPage - 1,
      this.perPage,
      this.sortBy,
      this.sortDesc ? 'DESC' : 'ASC',
      'USER',
      this.options.searchTerm,
      this.options.customer,
      this.sortBy !== 'id'
    )
      .then((res) => {
        this.totalRows = res.totalSize;
        this.totalPages = res.totalPages;
        if (!res.empty) {
          this.items = res.content.map((item) => {
            return {
              id: item.id,
              username: item.username,
              'contact.lastName':
                item.contact.firstName + ' ' + item.contact.lastName,
              company: item.contact.company,
              telephone: item.contact.phone,
              role: item.role,
              customer: item.customerShortDTO?.title || '-',
              customerNr: item.customerShortDTO?.customerNumber || '-',
              customerId: item.customerShortDTO?.id || 0,
              customerActive: item.customerShortDTO?.active || false,
              participantLimit: item.participantLimit,
              dateLastLogin:
                item.dateLastLogin === -1
                  ? '-'
                  : new Date(item.dateLastLogin).toLocaleDateString(undefined, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  }),
              active: item.active,
              w2MatchingStatus: item.w2MatchingStatus
            };
          });
        } else {
          this.items = [];
          this.clearLocalStorage();
        }
      })
      .catch(() => {
        this.toast(this.t('admin.cantLoad'), 'danger');
        this.clearLocalStorage();
      });
  }

  private getCustomers(): void {
    CustomersService.getCustomers(0, 99999, 'dateCreated', 'DESC', '')
      .then((res) => {
        if (!res.empty)
          this.customers = [
            this.customers[0],
            ...res.content.map((customer) => ({
              text: `(${customer.customerNumber}) ${customer.title} ${
                customer.active ? '' : ' (' + this.t('admin.inactive') + ')'
              }`,
              value: customer.id
            }))
          ];
      })
      .catch(() => {
        this.toast(this.t('admin.customersCantLoad'), 'danger');
      });
  }

  get perPage(): number {
    return this.options.entryCount;
  }

  set perPage(count: number) {
    this.options.entryCount = count;
  }

  get showPagination(): boolean {
    return this.perPage <= this.totalRows;
  }

  get selectedCustomer(): string {
    return (
      this.customers.find(
        (customer) => customer.value === this.options.customer
      )?.text || ''
    );
  }

  rowClicked(item: any): void {
    this.$router.push('/admin/users/' + item.username);
  }

  deleteUser(username: string): void {
    this.$bvModal.msgBoxConfirm( this.$t('modals.deleteAccount.description', {name: username}) as string, {
      okVariant: 'danger',
      okTitle: this.t('modals.deleteAccount.ok'),
      cancelTitle: this.t('common.cancel'),
      centered: true,
      title: this.t('modals.deleteAccount.title')
    }).then((res) => {
      if (res) {
        AccountsService.deleteAccount(username)
          .then(() => {
            this.toast(this.t('admin.userDeleted'), 'success');
            this.items = this.items.filter((i) => i.username !== username);
          })
          .catch(this.showNetworkError);
      }
    });

  }

  toggleStatus(item: any): void {
    if (item.active) {
      AccountsService.deactivateAccount(item.username)
        .then(() => (item.active = false))
        .catch(() => this.toast(this.t('admin.errorOnDeactivation'), 'danger'));
    } else {
      AccountsService.activateAccount(item.username)
        .then(() => (item.active = true))
        .catch(() => this.toast(this.t('admin.errorOnActivation'), 'danger'));
    }
  }

  loginAs(item: any): void {
    AuthenticationService.impersonate({
      parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
      childUsername: item.username
    })
      .then(() => {
        this.$router.push('/');
      })
      .catch(this.showNetworkError);
  }

  async exportAs(): Promise<void> {
    const filename = this.t('admin.users');
    const columns: Array<Column> = [
      { header: this.t('admin.excel.dbId'), key: 'id', width: 10 },
      { header: this.t('admin.excel.dbCustomerId'), key: 'customerShortDTO.id', width: 15 },
      { header: this.t('profile.customerNr'), key: 'customerShortDTO.customerNumber', width: 20 },
      { header: this.t('admin.excel.participantLimit'), key: 'participantLimit', width: 20 },
      { header: this.t('common.username'), key: 'username', width: 25 },
      { header: this.t('common.company'), key: 'contact.company', width: 50 },
      { header: this.t('common.salutation'), key: 'contact.salutation', width: 15 },
      { header: this.t('common.title'), key: 'contact.title', width: 15 },
      { header: this.t('common.firstname'), key: 'contact.firstName', width: 20 },
      { header: this.t('common.lastname'), key: 'contact.lastName', width: 20 },
      { header: this.t('admin.careOfShort'), key: 'contact.careOf', width: 20 },
      { header: this.t('common.street'), key: 'contact.street', width: 25 },
      { header: this.t('common.zip'), key: 'contact.zip', width: 20 },
      { header: this.t('common.city'), key: 'contact.city', width: 20 },
      { header: this.t('common.country'), key: 'contact.country', width: 20 },
      { header: this.t('common.phone'), key: 'contact.phone', width: 20 },
      { header: this.t('common.fax'), key: 'contact.fax', width: 20 },
      { header: this.t('common.mobile'), key: 'contact.phone2', width: 20 },
      { header: this.t('common.email'), key: 'contact.email', width: 30 },
      { header: this.t('common.language'), key: 'language', width: 20 },
      { header: this.t('common.timezone'), key: 'timezone', width: 20 },
      { header: this.t('common.note'), key: 'info', width: 30 },
      { header: this.t('conference.costCenter'), key: 'costCenter', width: 20 },
      {
        header: this.t('admin.excel.dialOutRetryCount'),
        key: 'bookingPreset.dialOutCallRetryCount',
        width: 20
      },
      {
        header: this.t('admin.excel.dialOutRetryDelay'),
        key: 'bookingPreset.dialOutCallRetryDelay',
        width: 20
      },
      { header: this.t('admin.excel.blacklistEmail'), key: 'blacklistMail', width: 20 },
      { header: this.t('admin.excel.blacklistPhone'), key: 'blacklistPhone', width: 20 },
      { header: this.t('admin.excel.passwordPolicy'), key: 'passwordPolicy', width: 20 },
      { header: this.t('admin.excel.role'), key: 'role', width: 15 },
      { header: this.t('admin.excel.accountSettings'), key: 'settings', width: 20 },
      { header: this.t('admin.timeLimit'), key: 'timeLimit', width: 20 },
      { header: this.t('common.createdAt'), key: 'dateCreated', width: 25 },
      { header: this.t('admin.lastLogin'), key: 'dateLastLogin', width: 25 },
      { header: this.t('admin.active'), key: 'active', width: 15 },
      { header: this.t('admin.excel.deactivationReason'), key: 'deactivationReason', width: 20 },
      { header: this.t('admin.excel.failedLogins'), key: 'failedLoginCounter', width: 20 },
      { header: this.t('admin.excel.lindenbaumId'), key: 'lbId', width: 25 },
      { header: this.t('admin.excel.lindenbaumUsername'), key: 'lbLogin', width: 25 },
      {
        header: this.t('admin.excel.lindenbaumLastSync'),
        key: 'dateLastHistorySynchronization',
        width: 30
      }, // TODO
      { header: this.t('common.dialOut') + ' (P)', key: 'permissions.DIAL_OUT', width: 15 },
      { header: this.t('common.dialInEn') + ' (P)', key: 'permissions.DIAL_IN', width: 15 },
      { header: this.t('common.recordings') + ' (P)', key: 'permissions.RECORDING', width: 20 },
      { header: this.t('common.playback') + ' (P)', key: 'permissions.PLAYBACK', width: 15 },
      { header: this.t('admin.excel.regFormShort') + ' (P)', key: 'permissions.REGISTRATION_FORM', width: 15 },
      { header: this.t('admin.excel.surveyFormShort') + ' (P)', key: 'permissions.POLL', width: 15 },
      { header: this.t('common.brandings') + ' (P)', key: 'permissions.BRANDING', width: 15 },
      {
        header: this.t('admin.globalBrandings') + ' (P)',
        key: 'permissions.GLOBAL_BRANDING',
        width: 15
      }
    ];
    let accounts: Account[] = [];
    for (let i = 0; i <= this.totalPages; i++) {
      const res = await AccountsService.getAccounts(
        i,
        this.options.entryCount,
        'dateCreated',
        'ASC'
      );
      if (!res.empty) accounts = [...accounts, ...res.content];
    }
    const entries = accounts.map((c: any) => {
      const entry = {} as any;
      columns.forEach((col) => {
        if (!col.key) return;
        const splitKey = (col.key as string).split('.');
        if (splitKey.length === 1) {
          // if string
          const value = c[col.key];
          if (value === true || value === 'BILLING') entry[col.key] = 1;
          else if (value === false || value === 'TEST') entry[col.key] = 0;
          else if ((col.key as string).startsWith('date'))
            entry[col.key] = value
              ? new Date(value).toLocaleString([], {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              })
              : '';
          else entry[col.key] = value;
        } else if (splitKey.length === 2 && splitKey[0] !== 'permissions') {
          // if nested object
          try {
            const value = c[splitKey[0]][splitKey[1]];
            if (value === 'MR') entry[col.key] = this.t('common.mr');
            else if (value === 'MRS') entry[col.key] = this.t('common.mrs');
            else if (value === 'OTHER') entry[col.key] = this.t('common.diverse');
            else entry[col.key] = value;
          } catch (error) {
            entry[col.key] = '';
          }
        } else {
          // if nested permissions array
          try {
            const value = c[splitKey[0]]
              .map((p: Permission) => p.name)
              .includes(splitKey[1]);
            if (value === true) entry[col.key] = 1;
            else entry[col.key] = 0;
          } catch (error) {
            entry[col.key] = 0;
          }
        }
      });
      return entry;
    });
    createXLSX(filename, columns, entries, this.t('admin.customers'));
  }
}
