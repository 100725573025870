import { Operator, OperatorAgent, OperatorObject } from '@/models/Operator';
import { OperatorForm } from '@/models/OperatorForm';
import axios from 'axios';

/**
 * API calls for Operator-Service
 * @module service/OperatorService
 */
export default {
	// API Calls
	getOperatorServices(
		page: number,
		size: number,
		order: string,
		direction: string,
		year: number,
		month: number,
		search?: string
	): Promise<OperatorObject> {
		const params: any = { page, size, order, direction, year, month };
		if (search && search !== '') params['search'] = search;
		return axios.get(`/operator`, { params }).then((res) => res.data);
	},
	getOperatorService(id: string): Promise<Operator> {
		return axios.get(`/operator/${id}`).then((res) => res.data);
	},
	updateOperatorService(id: number, operator: Operator): Promise<Operator> {
		return axios.put(`/operator/${id}`, operator).then((res) => res.data);
	},
	createOperatorService(operator: Operator): Promise<Operator> {
		return axios.post(`/operator`, operator).then((res) => res.data);
	},
	deleteOperatorService(id: number): Promise<void> {
		return axios.delete(`/operator/${id}`).then();
	},

	// Admin
	getAdminOperatorServices(
		page: number,
		size: number,
		order: string,
		direction: string,
		year: number,
		month: number,
		search?: string
	): Promise<OperatorObject> {
		const params: any = { page, size, order, direction, year, month };
		if (search) params['search'] = search;
		return axios.get(`/admin/operator`, { params }).then((res) => res.data);
	},
	updateAdmins(id: number, admins: { usernames: string[] }): Promise<Operator> {
		return axios
			.put(`/admin/operator/${id}/admins`, admins)
			.then((res) => res.data);
	},
	getStatistics(
		year: number,
		month: number
	): Promise<{ planned: number; passed: number }> {
		return axios
			.get(`/admin/operator/statistic`, { params: { year, month } })
			.then((res) => res.data);
	},
	deleteAdminOperatorService(id: number): Promise<void> {
		return axios.delete(`/admin/operator/${id}`).then();
	},

	// Functions
	newOperatorForm(): OperatorForm {
		return {
			id: 0,
			accountId: 0,
			company: 0,
			customerId: 0,
			planning: {
				bookingId: '',
				surveyId: '',
				registration: {
					regDateId: '',
					regFormId: ''
				}
			},
			date: {
				duration: 45,
				durationPreMeeting: 10,
				expectedNumber: 100,
				start: new Date().getTime()
			},
			type: {
				data: false,
				dialIn: false,
				dialOut: false,
				video: false
			},
			agents: [this.newAgent()],
			admins: [],
			organization: {
				note: '',
				language: '',
				organizerMail: '',
				organizerName: '',
				organizerPhone: '',
				pin: '123456'
			},
			services: {
				serviceGroupManagement: false,
				serviceInit: false,
				servicePreMeeting: false,
				serviceQA: false,
				serviceRecord: false,
				serviceSalutation: false,
				serviceTest: false,
				serviceVideo: false
			}
		};
	},

	newAgent(): OperatorAgent {
		return {
			email: '',
			name: '',
			phone: '',
			id: Math.random()
		};
	},

	operatorToOperatorForm(operator: Operator): OperatorForm {
		return {
			id: operator.id,
			accountId: operator.accountId,
			company: operator.company,
			customerId: operator.customerId,
			planning: {
				bookingId: operator.bookingId ? operator.bookingId.toString() : '',
				surveyId: operator.surveyId ? operator.surveyId.toString() : '',
				registration: {
					regFormId: operator.regFormId ? operator.regFormId.toString() : '',
					regDateId: operator.regDateId ? operator.regDateId.toString() : ''
				}
			},
			date: {
				duration: operator.duration,
				durationPreMeeting: operator.durationPreMeeting,
				expectedNumber: operator.expectedNumber,
				start: new Date(operator.start).getTime()
			},
			type: {
				data: operator.data,
				dialIn: operator.dialIn,
				dialOut: operator.dialOut,
				video: operator.video
			},
			agents: operator.agents || [],
			admins: operator.admins || [],
			organization: {
				note: operator.note,
				language: operator.language.toUpperCase(),
				organizerMail: operator.organizerEmail,
				organizerName: operator.organizerName,
				organizerPhone: operator.organizerPhone,
				pin: operator.pin
			},
			services: {
				serviceGroupManagement: operator.serviceGroupManagement,
				serviceInit: operator.serviceInit,
				servicePreMeeting: operator.servicePreMeeting,
				serviceQA: operator.serviceQA,
				serviceRecord: operator.serviceRecord,
				serviceSalutation: operator.serviceSalutation,
				serviceTest: operator.serviceTest,
				serviceVideo: operator.serviceVideo
			}
		};
	},

	operatorFormToOperator(form: OperatorForm): Operator {
		return {
			id: form.id,
			bookingId: form.planning.bookingId,
			start: new Date(form.date.start).toISOString(),
			duration: form.date.duration,
			durationPreMeeting: form.date.durationPreMeeting,
			expectedNumber: form.date.expectedNumber,
			surveyId: form.planning.surveyId,
			regDateId: form.planning.registration.regDateId,
			regFormId: form.planning.registration.regFormId,

			registeredNumber: 0,
			dateCreated: '',

			//ConferenceType
			dialIn: form.type.dialIn,
			dialOut: form.type.dialOut,
			data: form.type.data,
			video: form.type.video,

			//referenten
			agents: form.agents,
			admins: form.admins,

			//Services
			serviceInit: form.services.serviceInit,
			serviceTest: form.services.serviceTest,
			servicePreMeeting: form.services.servicePreMeeting,
			serviceGroupManagement: form.services.serviceGroupManagement,
			serviceSalutation: form.services.serviceSalutation,
			serviceQA: form.services.serviceQA,
			serviceRecord: form.services.serviceRecord,
			serviceVideo: form.services.serviceVideo,

			//Organisation
			pin: form.organization.pin,
			language: form.organization.language,
			organizerName: form.organization.organizerName,
			organizerPhone: form.organization.organizerPhone,
			organizerEmail: form.organization.organizerMail,
			note: form.organization.note,

			accountId: form.accountId || 0,
			company: form.company || 0,
			customerId: form.customerId || 0
		};
	}
};
