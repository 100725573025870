























































































































































































































import {MultipleChoiceCertificateData, SurveyQuestion} from '@/models/Survey';
import {Component, Prop, PropSync, Watch} from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import SurveyService from '@/service/SurveyService';
import PlaceholdersModal from '@/views/forms/components/PlaceholdersModal.vue';
import RegistrationService from '@/service/RegistrationService';
import {RegistrationCertificateData} from '@/models/RegistrationForm';
import {saveAs} from 'file-saver';

@Component({
	components: { WSwitch, PlaceholdersModal }
})
export default class SurveyCertificate extends Base {
	@PropSync('form') certificateData!:
		| MultipleChoiceCertificateData
		| RegistrationCertificateData;
	@Prop() validation!: any;
	@Prop() questions!: SurveyQuestion[];
	@PropSync('certificateEnabled') generateCert!: boolean;
	@Prop({ default: 'survey' }) type!: 'survey' | 'registration';
	@PropSync('temp') tempFile!: string;

	open = false;
	index = 2;
	file: File = new File([], '');

	multipleChoiceConditionEnabled = false;
	participationDurationEnabled = false;
	minDurationEnabled = false;
	loadingPreview = false;

	@Watch('certificateData.minDuration', { immediate: true })
	minDurationChanged(newValue: number): void {
		this.minDurationEnabled = newValue > 0;
	}

	mounted(): void {
		this.open = this.type === 'survey' || this.$route.path.includes('edit');
	}

	updateMinDuration(event: boolean): void {
		if (event && this.registrationCertificateData.minDuration === 0) {
			this.registrationCertificateData.minDuration = 10;
		}
		if (!event && this.registrationCertificateData.minDuration !== 0) {
			this.registrationCertificateData.minDuration = 0;
		}
	}

	get totalPoints(): number {
		return SurveyService.calculateTotalPoints(this.questions);
	}

	get surveyCertificateData(): MultipleChoiceCertificateData {
		return this.certificateData as MultipleChoiceCertificateData;
	}

	get registrationCertificateData(): RegistrationCertificateData {
		return this.certificateData as RegistrationCertificateData;
	}

	get percentPoints(): number {
		if (!this.surveyCertificateData.multipleChoiceCreditsCondition) return 0;
		return Math.round((this.totalPoints * this.surveyCertificateData.multipleChoiceCreditsCondition) / 100);
	}

	toggleOpen(): void {
		this.open = !this.open;
	}

	downloadPreview(): void {
		if (!this.tempFile) return;
		this.loadingPreview = true;

		const isTemp = this.tempFile.split('/')[0] !== '';
		if (isTemp) {
			SurveyService.downloadTempPreview(this.tempFile)
				.then(res => saveAs(new Blob([res]), this.t('forms.certificatePreview') + '.pdf'))
				.catch(this.showNetworkError)
				.finally(() => this.loadingPreview = false);
		} else {
			const { id } = this.$route.params;
			if (id) {
				SurveyService.downloadCertificatePreview(+id)
					.then(res => saveAs(new Blob([res]), this.t('forms.certificatePreview') + '.pdf'))
					.catch(this.showNetworkError)
					.finally(() => this.loadingPreview = false);
			}
		}
	}

	updatePercentage(value: boolean): void {
		if (value && !this.surveyCertificateData.multipleChoiceCreditsCondition) {
			// If condition is set to true and credits condition is 0%, default value of 60% is set
			this.surveyCertificateData.multipleChoiceCreditsCondition = 60;
		}
	}

	updateDuration(value: boolean): void {
		if (value && !this.surveyCertificateData.participationDurationCondition) {
			// If condition is set to true and duration is 0min, default value of 40min is set
			this.surveyCertificateData.participationDurationCondition = 40;
		}
	}

	uploadTemp(): void {
		RegistrationService.addFile(this.file).then(value => {
			this.tempFile = value;
			this.generateCert = true;
			this.$emit('fileUploaded', this.file);
		});
	}

	deleteFile(): void {
		this.tempFile = '';
		this.generateCert = false;
		this.$emit('fileUploaded', undefined);
	}

	get hasFile(): boolean | null {
		return this.generateCert ? this.tempFile != '' : null;
	}

	@Watch('form.multipleChoiceCreditsCondition', { immediate: true })
	multipleChoiceCreditsConditionChanged(): void {
		this.multipleChoiceConditionEnabled =
			!!this.surveyCertificateData.multipleChoiceCreditsCondition &&
			this.surveyCertificateData.multipleChoiceCreditsCondition > 0;
		this.open = true;
	}

	@Watch('form.participationDurationCondition', { immediate: true })
	participationDurationConditionChanged(): void {
		this.participationDurationEnabled =
			!!this.surveyCertificateData.participationDurationCondition &&
			this.surveyCertificateData.participationDurationCondition > 0;
		this.open = true;
	}

	@Watch('multipleChoiceConditionEnabled')
	multipleChoiceCreditsEnabledChanged(): void {
		this.surveyCertificateData.multipleChoiceCreditsCondition = this
			.multipleChoiceConditionEnabled
			? this.surveyCertificateData.multipleChoiceCreditsCondition
			: 0;
	}

	@Watch('participationDurationEnabled')
	participationDurationEnabledChanged(): void {
		this.surveyCertificateData.participationDurationCondition = this
			.participationDurationEnabled
			? this.surveyCertificateData.participationDurationCondition
			: 0;
	}

	// @Watch('form.multipleParticipation')
	// multipleParticipationChanged(): void {
	//   if (this.certificateData.multipleParticipation) this.open = true;
	// }

	@Watch('generateCert')
	generateCertChanged(): void {
		if (this.generateCert) this.open = true;
	}
}
