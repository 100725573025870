






























import Base from "@/views/Base";
import {Component, Prop, Watch} from "vue-property-decorator";
import {required} from "vuelidate/lib/validators";
import {Validations} from "vuelidate-property-decorators";
import {validateAndNormalizeVideoUrl, validateVideoUrlWithOEmbed} from "@/utils/validators";
import {WebVideoElementForm} from "@/models/Presentation";
import PresentationService from "@/service/PresentationService";

@Component
export default class WebVideoForm extends Base {

  @Prop({required: true}) count!: number;

  form: WebVideoElementForm = {
    videoUrl: '',
    name: '',
    thumbnailUrl: '',
    videoSource: null
  }

  @Validations()
  validations = {
    form: {
      videoUrl: {
        required,
        validateAndNormalizeVideoUrl: validateAndNormalizeVideoUrl,
        validateVideoUrlWithOEmbed: validateVideoUrlWithOEmbed
      }
    }
  };

  normalizeAndUpdateVideoUrl() {
    const normalizedUrl = validateAndNormalizeVideoUrl(this.form.videoUrl);
    if (normalizedUrl) this.form.videoUrl = normalizedUrl;
  }

  @Watch('form.videoUrl')
  onVideoUrlChange(newVal: string) {
    if (!newVal) this.reset();
    else this.normalizeAndUpdateVideoUrl();
  }

  async submit() {
    if (this.$v.$invalid) return;
    const oEmbedResponse = await validateVideoUrlWithOEmbed(this.form.videoUrl);
    if (!oEmbedResponse) return;
    this.form = PresentationService.oEmbedResponseToForm(oEmbedResponse, this.form.videoUrl);
    this.$emit('add-video-element', this.form);
    this.reset();
  }

  reset() {
    this.form = {
      videoUrl: '',
      name: '',
      thumbnailUrl: '',
      videoSource: null
    }
    this.$v.$reset();
  }
}
