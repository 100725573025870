import {
    Email,
    Historical,
    HistoricalMedia,
    HistoricalObject,
    HistoricalParticipant
} from '@/models/api';
import axios from 'axios';
import store from '@/store/index';
import qs from "qs";
import {forEach} from "lodash";

/**
 * API calls for booking
 * @module service/HistoricalService
 */
export default {
    /**
     * Rest Functions bookings
     */
    getHistoricals(
        page: number,
        size: number,
        order: string,
        direction: 'ASC' | 'DESC',
        search?: string,
        ignoreCase?: boolean,
        date?: { minDate: string; maxDate: string }
    ): Promise<HistoricalObject> {
        const params: { [key: string]: any } = {
            page,
            size,
            order,
            direction,
            ignoreCase
        };
        if (search) params['search'] = search;
        if (date) {
            params['minDate'] = date.minDate;
            params['maxDate'] = date.maxDate;
        }
        return axios
            .get('/historical', {params})
            .then((response) => response.data);
    },

    getHistorical(id: string): Promise<Historical> {
        console.log('getHistorical');
        return axios.get('/historical/' + id).then((response) => response.data);
    },

    getHistoricalParticipants(id: string): Promise<Array<HistoricalParticipant>> {
        return axios
            .get('/historical/' + id + '/participants')
            .then((response) => response.data);
    },

    getMediaList(id: string): Promise<HistoricalMedia[]> {
        return axios
            .get(`/historical/${id}/files`)
            .then((response) => response.data);
    },
    getMediaListForIds(ids: string[]): Promise<any> {
        return axios
            .get(`/historical/files`, {
                params: {ids},
                paramsSerializer: params => {
                    return qs.stringify(params, {arrayFormat: 'repeat'})
                }
            })
            .then((response) => {
                return response.data;
            });
    },

    getDeletedMediaList(id: string): Promise<HistoricalMedia[]> {
        return axios
            .get(`/historical/${id}/deletedFiles`)
            .then((response) => response.data);
    },

    getMediaFileUrl(id: string, fileName: string): string {
        return `${axios.defaults.baseURL}/historical/${id}/files/${fileName}?access_token=${store.getters.accessToken}`;
    },

    downloadMediaFile(url: string): Promise<Blob> {
        url += `&?t=${Date.now()}`;
        return axios
            .get(url, {
                responseType: 'blob',
                headers: {Authorization: `Bearer ${store.getters.accessToken}`}
            })
            .then((res) => res.data);
    },

    deleteMediaFile(id: string, fileName: string): Promise<void> {
        return axios.delete(`/historical/${id}/files/${fileName}`).then();
    },

    sendEmailToParticipants(
        id: number,
        mail: Email,
        recipientIds: string[]
    ): Promise<void> {
        return axios
            .post(`/historical/${id}/participants/mail`, {
                ...mail,
                toAll: recipientIds.length === 0,
                recipientIds: recipientIds.map((id) => +id)
            })
            .then();
    },

    sendTestEmailToParticipants(
        id: number,
        mail: Email,
        recipientIds: string[]
    ): Promise<void> {
        return axios
            .post(`/historical/${id}/participants/testMail`, {
                ...mail,
                toAll: recipientIds.length === 0,
                recipientIds: recipientIds.map((id) => +id)
            })
            .then();
    }
};
