











































































import { Component, Watch } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { LiveVideoBackground } from '@/models/liveVideoBackground';
import LiveVideoBackgroundService from '@/service/LiveVideoBackgroundService';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import Base from '@/views/Base';
import { dataURLToBlob } from '@/utils/images';
import { max, min } from 'lodash';
import InlineEdit from '@/views/presentation/liveVideoBackgrounds/InlineEdit.vue';

@Component({
  components: { InlineEdit, WListOptions, HeaderLayout }
})
export default class Index extends Base {
  backgrounds: LiveVideoBackground[] = [];
  selectedBackgrounds: number[] = [];
  currentSelectOption = '';

  loading = false;
  lastSelectedItem: number | null = null;

  options = {
    searchTerm: '',
    sortBy: 'dateCreated',
    sortDirection: 'DESC'
  };

  @Watch('options.searchTerm')
  searchUpdated() {
    this.loadBackgrounds();
  }

  sort() {
    this.loadBackgrounds();
  }

  loadBackgrounds() {
    LiveVideoBackgroundService.getVideobackgrounds(this.options.searchTerm, this.options.sortBy, this.options.sortDirection)
      .then(bg => {
        this.backgrounds = bg;
        this.selectedBackgrounds = this.selectedBackgrounds.filter(id =>
          this.backgrounds.some(bg => bg.id === id)
        );
      })
      .catch(this.showNetworkError);
  }

  async mounted() {
    this.loadBackgrounds();
  }

  photoUrl(bg: LiveVideoBackground): string {
    return LiveVideoBackgroundService.getPhotoUrl(bg.id, bg.filename);
  }

  editBackground(id: number, title: string) {
    LiveVideoBackgroundService.updateVideoBackground(id, { title })
      .then(() => this.loadBackgrounds())
      .catch(this.showNetworkError);
  }

  async deleteBackground(bg: LiveVideoBackground) {
    if (await this.$bvModal.msgBoxConfirm(this.t('presentation.liveVideoBackgrounds.deleteConfirmModalText'), {
      okVariant: 'danger',
      okTitle: this.t('presentation.liveVideoBackgrounds.deleteConfirmModalOk'),
      cancelTitle: this.t('common.cancel'),
      centered: true,
      title: this.t('presentation.liveVideoBackgrounds.deleteConfirmModalTitle')
    })) {
      await LiveVideoBackgroundService.deleteVideoBackground(bg.id);
      this.toast(this.t('presentation.liveVideoBackgrounds.deletedToast'), 'danger');
      await this.loadBackgrounds();
    }
  }

  async deleteSelected() {
    if (await this.$bvModal.msgBoxConfirm(this.t('presentation.liveVideoBackgrounds.deleteMultipleConfirmModalText'), {
      okVariant: 'danger',
      okTitle: this.t('presentation.liveVideoBackgrounds.deleteMultipleConfirmModalOk'),
      cancelTitle: this.t('common.cancel'),
      centered: true,
      title: this.t('presentation.liveVideoBackgrounds.deleteMultipleConfirmModalTitle')
    })) {
      LiveVideoBackgroundService.deleteVideoBackgroundsByIds(this.selectedBackgrounds)
        .then(() => this.loadBackgrounds())
        .catch(this.showNetworkError);
    }
  }

  selected() {
    if (this.currentSelectOption == 'all') {
      this.selectedBackgrounds = this.backgrounds.map(bg => bg.id);
    } else if (this.currentSelectOption == 'none') {
      this.selectedBackgrounds.splice(0, this.selectedBackgrounds.length);
    } else if (this.currentSelectOption == 'invert') {
      const allIds = this.backgrounds.map(bg => bg.id);
      this.selectedBackgrounds = allIds.filter(id => !this.selectedBackgrounds.includes(id));
    }
    this.currentSelectOption = '';
  }

  photoUploaded(files: File[]): void {
    try {
      this.processPhoto(files);
    } catch {
      this.toast('Ein Fehler ist passiert', 'danger');
      this.loading = false;
    }
  }

  processPhoto(files: File[]): void {
    this.loading = true;
    const file: Blob = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const filename = files[0].name.split('.').slice(0, -1).join('.');

    reader.onload = (e) => {
      if (e.target) {
        const img = new Image();
        img.src = e.target.result as string;

        img.onload = () => {
          const width = img.width;
          const height = img.height;

          const targetAspectRatio = 16 / 9;

          let cropHeight = height;
          let cropWidth = width;

          if (width / height > targetAspectRatio) {
            // Width too large
            console.log('Width too large');
            cropWidth = height * targetAspectRatio;
          } else {
            // height too large
            cropHeight = Math.round(width / targetAspectRatio);
          }

          const canvas = document.createElement('canvas');
          canvas.width = cropWidth;
          canvas.height = cropHeight;
          const ctx = canvas.getContext('2d');

          if (ctx) {
            // Bild wird genau zentriert zugeschnitten
            ctx.drawImage(
              img,
              (width - cropWidth) / 2,
              (height - cropHeight) / 2,
              cropWidth,
              cropHeight,
              0,
              0,
              // Workaround for a black bar
              canvas.width + 1,
              canvas.height
            );
            const url = canvas.toDataURL('image/jpeg');
            const blob = dataURLToBlob(url);
            const file = new File([blob], filename + '.jpg');
            this.createBackground(file);
            console.log('Image cropped and uploaded');
          }
        };
        img.onerror = () => {
          this.toast(this.t('presentation.liveVideoBackgrounds.imageUploadFailed'), 'danger');
          this.loading = false;
        };
      }
    };
  }

  selectBackground(id: number, shift = false) {
    if (!shift || this.lastSelectedItem == null) {
      const index = this.selectedBackgrounds.indexOf(id);

      if (index !== -1) {
        this.selectedBackgrounds.splice(index, 1);
        this.lastSelectedItem = null;
      } else {
        this.selectedBackgrounds.push(id);
        this.lastSelectedItem = id;
      }
    } else {
      const firstIndex = this.backgrounds.findIndex(bg => bg.id == this.lastSelectedItem);
      const lastIndex = this.backgrounds.findIndex(bg => bg.id == id);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.selectedBackgrounds = this.backgrounds.slice(min([firstIndex, lastIndex]), max([firstIndex, lastIndex])! + 1).map(bg => bg.id);
    }
  }

  createBackground(file: File) {
    LiveVideoBackgroundService.createVideoBackground(file)
      .then(() => this.loadBackgrounds())
      .catch(e => {
        this.showNetworkError(e);
      }).finally(() => this.loading = false);
  }

}
