import './utils/classComponentHook';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from '@/utils/i18n';

import { initBackendUrl, initDependencies, initIcons, initSessionHandling } from '@/utils/init';

Vue.config.productionTip = false;

initDependencies();
initIcons();
initBackendUrl();
initSessionHandling(router, store);

new Vue({
	i18n,
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
