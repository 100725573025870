
























































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {Component, Watch} from 'vue-property-decorator';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import OperatorService from '@/service/OperatorService';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import {Operator} from '@/models/Operator';
import {abbr, formatNumberString} from '@/utils/filters';
import {SelectOption} from '@/models/common';
import {months} from '@/utils/date';

@Component({
	components: {
		HeaderLayout,
		WHeaderBtn,
		WListOptions,
		WPagination
	},
  i18n: {
    messages: {
      en: {
        common:{
          duration: 'Dur.'
        }
      }
    }
  }
})
export default class Operators extends Base {
	operators: Operator[] = [];

	fields = [
		{
			key: 'start' as keyof Operator,
			label: this.t('common.appointment'),
			sortable: true,
			thStyle: { paddingLeft: '1.75rem', width: '190px' }
		},
		{
			key: 'duration' as keyof Operator,
			label: this.t('common.duration'),
			sortable: true,
			thStyle: { width: '80px' }
		},
		{
			key: 'durationPreMeeting' as keyof Operator,
			label: this.t('operator.durationPreMeeting'),
			thStyle: { width: '90px' }
		},
		{
			key: 'registeredNumber' as keyof Operator,
			label: this.t('common.registered'),
			sortable: true,
			thStyle: { width: '120px' }
		},
		{
			key: 'expectedNumber' as keyof Operator,
			label: this.t('common.expected'),
			sortable: true,
			thStyle: { width: '120px' }
		},
		{
			key: 'admins' as keyof Operator,
			label: this.t('conference.operator'),
			thStyle: { width: '110px' }
		},
		{
			key: 'pin' as keyof Operator,
			label: this.t('conference.pin'),
			thStyle: { width: '85px' }
		},
		{
			key: 'type' as keyof Operator,
			label: this.t('common.type')
		},
		{
			key: 'action' as keyof Operator,
			label: this.t('common.action'),
			thStyle: { width: '110px' }
		}
	];

	get time(): string {
		return `${this.options.month},${this.options.year}`;
	}

	set time(value: string) {
		this.options.month = +value.split(',')[0];
		this.options.year = +value.split(',')[1];
	}

	abbr = abbr;

	options = {
		entryCount: 10,
		searchTerm: '',
		currentPage: 1,
		totalRows: 0,
		year: new Date().getFullYear(),
		month: new Date().getMonth()
	};

	currentPage = 1;
	sortDesc = true;
	sortBy = 'start';

	// calculate +-6 months
	get timeOptions(): SelectOption[] {
		const now = new Date();
		const month = now.getMonth();
		const year = now.getFullYear();
		const options = [];
		const m = months(this);
		for (let i = month - 6; i < month; i++) {
			const newMonth = (i + 12) % 12;
			const newYear = i < 0 ? year - 1 : year;
			options.push({
				text: `${m[newMonth]} ${newYear}`,
				value: newMonth + ',' + newYear
			});
		}
		for (let i = month; i < month + 18; i++) {
			const newMonth = (i + 12) % 12;
			const newYear = i > 11 ? year + 1 : year;
			options.push({
				text: `${m[newMonth]} ${newYear}`,
				value: newMonth + ',' + newYear
			});
		}
		return options;
	}

	get perPage(): number {
		return this.options.entryCount;
	}

	set perPage(count: number) {
		this.options.entryCount = count;
	}

	get totalPages(): number {
		return Math.ceil(this.totalRows / this.perPage);
	}

	get totalRows(): number {
		return this.options.totalRows;
	}

	get showPagination(): boolean {
		return this.perPage <= this.totalRows;
	}

	mailTo(operator: Operator): void {
		const subject = encodeURI(
			`Operator-Service am ${new Date(operator.start).toLocaleString([], {
				dateStyle: 'short',
				timeStyle: 'short'
			})}`
		);
		let mail = 'info@csn.de';
		let body = 'Sehr geehrtes Operator-Team,';
		if (operator.admins && operator.admins.length > 0) {
			const admin = operator.admins[0];
			mail = operator.admins[0].email;
			body = encodeURI(`Sehr geehrte*r ${admin.firstname} ${admin.lastname},`);
		}
		window.open(`mailto:${mail}?subject=${subject}&body=${body}`);
	}

	formatNumberString = formatNumberString;

	@Watch('time')
	timeChanged(): void {
		this.getServices();
	}

	@Watch('options.searchTerm')
	searchChanged(): void {
		this.getServices();
	}

	@Watch('currentPage')
	currentPageChanged(): void {
		this.getServices();
	}

	@Watch('sortBy')
	sort(): void {
		this.getServices();
	}

	@Watch('sortDesc')
	sortByDirection(): void {
		this.getServices();
	}

	@Watch('options.entryCount')
	entryCountChanged(): void {
		this.getServices();
	}

	mounted(): void {
		this.getServices();
	}

	private getServices() {
		OperatorService.getOperatorServices(
			this.currentPage - 1,
			this.perPage,
			this.sortBy,
			this.sortDesc ? 'DESC' : 'ASC',
			this.options.year,
			this.options.month + 1,
			this.options.searchTerm
		)
			.then((obj) => {
				console.log(obj);
				this.operators = !obj.empty ? obj.content : [];
				this.options.totalRows = obj.totalSize;
			})
			.catch(this.showNetworkError);
	}

	deleteService(id: number): void {
		OperatorService.deleteOperatorService(id)
			.then(() => {
				const idx = this.operators.findIndex((op) => op.id === id);
				this.operators.splice(idx, 1);
				this.toast(this.t('operator.cancelled'), 'success');
			})
			.catch(this.showNetworkError);
	}

	iconColorClass(time: number): string {
		return time < Date.now() ? 'text-muted' : 'text-primary';
	}

	registeredColorClass(time: number): string {
		return time < Date.now()
			? 'text-muted border-muted'
			: 'text-primary border-primary';
	}

	bgColorClass(time: number): string {
		return time < Date.now() ? 'bg-muted' : 'bg-primary';
	}

	adminTooltip(item: Operator): string {
		const admins = item.admins;
		return !admins
			? ''
			: `
      ${admins[0].firstname} ${admins[0].lastname} <br>
      ${admins[0].phone} <br>
      ${admins[0].email} <br>
      ${this.t('operator.contactOperator')}
    `;
	}
}
