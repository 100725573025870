







































































































import AccountsService from '@/service/AccountsService';
import ContactsService from '@/service/ContactsService';
import Base from '@/views/Base';
import { Component, Watch } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';

@Component
export default class BlocklistModal extends Base {
	phones = ['0123*', '01721087939', '*4123'];
	emails = ['a@a.de', '*@gmail.de', ''];

	newPhone = '+49 ';
	newEmail = '';

	@Watch('user')
	userChanged(): void {
		this.init();
	}

	@Validations()
	validations = {
		newPhone: {
			// phone: (v: string): boolean =>  /^\+49 ?[0-9]*\*?$/.test(v)
		},
		newEmail: {}
	};

	mounted(): void {
		if (this.user) {
			this.init();
		}
	}

	init(): void {
		this.phones = this.user?.blacklistPhone?.split(',') || [];
		this.emails = this.user?.blacklistMail?.split(',') || [];
	}

	async addPhone(): Promise<void> {
		if (!this.newPhone.includes('*')) {
			const validation = await ContactsService.validatePhoneNumbers([
				this.newPhone
			]);
			if (validation[0].validNumber) {
				this.newPhone = validation[0].international;
			} else {
				this.toast(this.$t('blacklist.invalidNumber') as string, 'danger');
				return;
			}
		}
		this.phones.unshift(this.newPhone);
		this.newPhone = '+49 ';
	}

	addEmail(): void {
		this.emails.unshift(this.newEmail);
		this.newEmail = '';
	}

	deletePhone(index: number): void {
		this.phones.splice(index, 1);
	}

	deleteEmail(index: number): void {
		this.emails.splice(index, 1);
	}

	async save(ok: () => void): Promise<void> {
		const phonesString = this.phones.join(',');
		const emailString = this.emails.join(',');
		this.user.blacklistPhone = phonesString !== '' ? phonesString : undefined;
		this.user.blacklistMail = emailString !== '' ? emailString : undefined;
		await AccountsService.updateAccount({
			...this.user,
			permissions: this.user.permissions.map((p: any) => p.name),
			customerId: this.user.customerShortDTO?.id
		});
		this.toast(this.$t('blacklist.successful') as string, 'success');
		ok();
	}

	reset(): void {
		this.phones = [];
		this.emails = [];
		this.newEmail = '';
		this.newPhone = '+49 ';
	}

	validateState(name: string): boolean | null {
		const validate: any = this.$v[name];
		return validate.$dirty ? !validate.$error : null;
	}
}
