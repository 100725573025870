/* eslint-disable no-mixed-spaces-and-tabs */
import {
    EmailTemplateWrapper,
    FormMail,
    Survey,
    SurveyApiObject,
    SurveyMailType,
    SurveyQuestion,
    SurveyQuestionOption,
    SurveyResponse,
    SurveyResult,
    SurveyResultTableRow,
    SurveySpeaker,
    SurveyStatistic
} from '@/models/Survey';
import {SurveyForm} from '@/models/SurveyForm';
import {id} from '@/utils/secureRandom';
import axios from 'axios';
import {Email} from '@/models/api';
import {getCurrentLanguage} from "@/utils/languages";

export default {
    // GET
    getSurveys(
        page: number,
        size: number,
        order: string,
        direction: 'ASC' | 'DESC',
        search?: string
    ): Promise<SurveyApiObject> {
        const params: { [key: string]: string | number } = {
            page,
            size,
            order,
            direction
        };
        if (search) params['search'] = search;
        return axios.get('/survey', {params}).then((response) => response.data);
    },
    getActiveSurveys(
        page: number,
        size: number,
        order: string,
        direction: 'ASC' | 'DESC',
        search?: string
    ): Promise<SurveyApiObject> {
        const params: { [key: string]: string | number } = {
            page,
            size,
            order,
            direction
        };
        if (search) params['search'] = search;
        return axios
            .get('/survey/listActive', {params})
            .then((response) => response.data);
    },
    getPastSurveys(
        page: number,
        size: number,
        order: string,
        direction: 'ASC' | 'DESC',
        search?: string
    ): Promise<SurveyApiObject> {
        const params: { [key: string]: string | number } = {
            page,
            size,
            order,
            direction
        };
        if (search) params['search'] = search;
        return axios
            .get('/survey/listPast', {params})
            .then((response) => response.data);
    },
    getSurveyStatistics(): Promise<SurveyStatistic> {
        return axios.get('/survey/statistic').then((res) => res.data);
    },
    getSurvey(id: string | number): Promise<Survey> {
        return axios.get('/survey/' + id).then((response) => response.data);
    },
    getEmailTemplates(): Promise<EmailTemplateWrapper> {
        return axios.get(`/mail-text-template`).then((res) => res.data);
    },
    getSpeakerImageUrl(surveyId: number, id: number): string {
		return `${axios.defaults.baseURL}/survey/${surveyId}/speaker/${id}/photo`;
    },
    downloadTempPreview(tmpFile: string): Promise<Blob> {
        return axios
            .get(`/survey/tmp/certificateExample.pdf`, {
                params: {tmpFile},
                responseType: 'blob'
            })
            .then((res) => res.data);
    },
    downloadCertificatePreview(surveyId: number): Promise<Blob> {
        return axios
            .get(`/survey/${surveyId}/certificateExample.pdf`, {
                responseType: 'blob'
            })
            .then((res) => res.data);
    },
    downloadCertificate(surveyId: number, responseId: number): Promise<Blob> {
        return axios
            .get(`/survey/${surveyId}/${responseId}/certificate.pdf`, {
                responseType: 'blob'
            })
            .then((res) => res.data);
    },
    downloadStatistic(surveyId: number, lang: string): Promise<Blob> {
        return axios
			.get(`/survey/${surveyId}/statistic.pdf?v=${Date.now()}`, { responseType: 'blob', params: {lang} })
            .then((res) => res.data);
    },
    isCertificatesDownloadOK(surveyId: number): Promise<boolean> {
        return axios.get(`/survey/${surveyId}/result/isDownloadOK`).then(res => res.data)
    },
    sendCertificate(
        surveyId: number,
        responseId: number | string,
        mail: Email
    ): Promise<void> {
        return axios
            .post(`/survey/${surveyId}/${responseId}/certificate/mail`, {
                ...mail,
                text: mail.text
            })
            .then();
    },
    sendTestCertificate(surveyId: number, mail: Email): Promise<void> {
        return axios.post(`/survey/${surveyId}/testMail`, mail).then();
    },
    sendCertificateToAllParticipants(
        surveyId: number,
        mail: FormMail
    ): Promise<void> {
        return axios.post(`/survey/${surveyId}/mail`, mail).then();
    },
    getResult(surveyId: number, responseId: number): Promise<SurveyResult> {
        return axios
            .get(`/survey/${surveyId}/result/${responseId}`)
            .then((res) => res.data);
    },
    getResults(surveyId: number): Promise<Array<SurveyResult>> {
        return axios.get(`/survey/${surveyId}/result`).then((res) => res.data);
    },
    getResponse(surveyId: number, responseId: number): Promise<SurveyResponse> {
        return axios
            .get(`/survey/${surveyId}/response/${responseId}`)
            .then((res) => res.data);
    },
    getResponses(surveyId: number): Promise<Array<SurveyResponse>> {
        return axios.get(`/survey/${surveyId}/response`).then((res) => res.data);
    },
    getBookings(surveyId: number): Promise<Array<string>> {
        return axios.get(`/survey/${surveyId}/bookings`).then((res) => res.data);
    },

    // UPDATE
    updateGeneral(id: number, survey: Survey): Promise<Survey> {
        return axios.put(`/survey/${id}`, survey).then((res) => res.data);
    },
    updateSpeaker(
        surveyId: number,
        id: number,
        speaker: SurveySpeaker
    ): Promise<SurveySpeaker> {
        return axios
            .put(`/survey/${surveyId}/speaker/${id}`, speaker)
            .then((res) => res.data);
    },
    updateQuestion(
        surveyId: number,
        questionId: number,
        question: SurveyQuestion
    ): Promise<SurveyQuestion> {
        return axios
            .put(`/survey/${surveyId}/question/${questionId}`, question)
            .then((res) => res.data);
    },
    addBooking(surveyId: number, bookingId: string): Promise<Array<string>> {
        return axios
            .post(`/survey/${surveyId}/bookings/${bookingId}`)
            .then((res) => res.data);
    },
    updateResponse(
        surveyId: number,
        responseId: number,
        response: SurveyResponse
    ): Promise<SurveyResponse> {
        return axios
            .put(`/survey/${surveyId}/response/${responseId}`, response)
            .then((res) => res.data);
    },

    // ADD
    addSurvey(survey: Survey): Promise<Survey> {
        return axios.post('/survey', survey).then((response) => response.data);
    },
    addSpeaker(surveyId: number, speaker: SurveySpeaker): Promise<SurveySpeaker> {
        return axios
            .post(`/survey/${surveyId}/speaker`, speaker)
            .then((response) => response.data);
    },
    addImage(speakerId: number, surveyId: number, blob: Blob): Promise<Survey> {
        const form = new FormData();
        form.append('file', blob);
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return axios
            .post(`/survey/${surveyId}/speaker/${speakerId}/image`, form, {headers})
            .then((response) => response.data);
    },
    addQuestion(
        surveyId: number,
        question: SurveyQuestion
    ): Promise<SurveyQuestion> {
        return axios.post(`/survey/${surveyId}/question`, question).then();
    },
    addCertificate(surveyId: number, file: File): Promise<void> {
        const form = new FormData();
        form.append('file', file);
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        return axios.post(`/survey/${surveyId}/certificateTemplate`, form, {
            headers
        });
    },
    duplicateSurvey(surveyId: number): Promise<Survey> {
        return axios.post(`/survey/${surveyId}/duplicate`).then((res) => res.data);
    },

    // DELETE
    deleteSurvey(id: number): Promise<void> {
        return axios.delete('/survey/' + id).then();
    },
    deleteSpeaker(surveyId: number, id: number): Promise<void> {
        return axios.delete(`/survey/${surveyId}/speaker/${id}`).then();
    },
    deleteImage(surveyId: number, id: number): Promise<void> {
        return axios.delete(`/survey/${surveyId}/speaker/${id}/image`).then();
    },
    deleteQuestion(surveyId: number, questionId: number): Promise<void> {
        return axios.delete(`/survey/${surveyId}/question/${questionId}`).then();
    },
    deleteResponse(surveyId: number, responseId: number | string): Promise<void> {
        return axios.delete(`/survey/${surveyId}/response/${responseId}`).then();
    },
    deleteAllResponses(surveyId: number): Promise<void> {
        return axios.delete(`/survey/${surveyId}/response`).then();
    },
    deleteCertificate(surveyId: number, filename: string): Promise<void> {
        return axios
            .delete(`/survey/${surveyId}/certificateTemplate/${filename}`)
            .then();
    },
    deleteBooking(surveyId: number): Promise<void> {
        return axios.delete(`/survey/${surveyId}/bookings`).then();
    },
    deleteAllPastSurveys(): Promise<void> {
        return axios.delete('/survey/pastForms').then();
    },

    activateForm(id: number): Promise<void> {
        return axios.patch(`/survey/${id}/activate`).then();
    },
    deactivateForm(id: number): Promise<void> {
        return axios.patch(`/survey/${id}/deactivate`).then();
    },
    surveyUrlExists(url: string): Promise<boolean> {
        return axios.get('/survey/exists?url=/' + url)
            .then((res) => res.data)
    },

    // FUNCTIONS
    newSurveyForm(): SurveyForm {
        return {
            general: {
                title: '',
                description: '',
                language: getCurrentLanguage(),
                branding: null,
                subtitle: '',
                url: id(),
                label: ''
            },
            mail: {
                replyTo: '',
                subject: '',
                text: '',
                bcc: '',
                enabled: true,
                type: SurveyMailType.None,
                mailTextTemplateId: ''
            },
            participation: {
                address: {
                    enabled: false,
                    required: false
                },
                comment: {
                    enabled: false,
                    required: false
                },
                company: {
                    enabled: false,
                    required: false
                },
                partnerId: {
                    enabled: false,
                    required: false
                },
                salutation: {
                    enabled: true,
                    required: false
                },
                vvId: {
                    enabled: false,
                    required: false
                },
                name: {
                    enabled: true,
                    required: true
                },
                email: {
                    enabled: true,
                    required: true
                },
                phone: {
                    enabled: true,
                    required: true
                },
                fields: [],
                checkboxes: [],
                saveAdditionalParticipantData: true,
                saveParticipantData: true,
                customFields: []
            },
            speakers: [{name: '', idx: 0}],
            legal: {
                imprintCompany: '',
                imprintName: '',
                privacyURL: ''
            },
            questions: [this.newQuestion(1)],
            certificateData: {
                customCertificate: false,
                multipleParticipation: false,
                standardCertificate: false,
                multipleChoiceCreditsCondition: 0,
                participationDurationCondition: 0
            },
            duration: [Date.now(), Date.now() + 1000 * 60 * 60 * 24 * 365 * 2],
            active: true,
            certificateEnabled: false,
            mailEnabled: true,
            hasParticipants: false
        };
    },

    newQuestion(idx: number): SurveyQuestion {
        return {
            id: idx,
            description: '',
            title: '',
            open: true,
            edit: false,
            type: 'CHECKBOX',
            idx: idx,
            required: true,
            points: null,
            checkboxQuestionOptions: [this.newAnswer(1), this.newAnswer(2)],
            radioQuestionOptions: [],
            multipleTextQuestionOptions: [],
            evaluate: false
        };
    },

    newAnswer(idx: number, isRight = false): SurveyQuestionOption {
        return {
            caption: '',
            idx: idx,
            rightAnswer: isRight
        };
    },

    surveyFormToSurvey(form: SurveyForm): Survey {
        const fields = form.participation.fields.filter((f) => f.title !== '');
        const checkboxes = form.participation.checkboxes.filter(
            (f) => f.title !== ''
        );
        const speakers = form.speakers.filter((sp) => !!sp.image);

        return {
            id: null,
            saveParticipantData: form.participation.saveParticipantData,
            saveAdditionalParticipantData:
            form.participation.saveAdditionalParticipantData,
            participantDataToSave: {
                address: {
                    show: !!form.participation.address.enabled,
                    required: !!form.participation.address.required
                },
                comment: {
                    show: !!form.participation.comment.enabled,
                    required: !!form.participation.comment.required
                },
                company: {
                    show: !!form.participation.company.enabled,
                    required: !!form.participation.company.required
                },
                partnerId: {
                    show: !!form.participation.partnerId.enabled,
                    required: !!form.participation.partnerId.required
                },
                salutation: {
                    show: !!form.participation.salutation.enabled,
                    required: !!form.participation.salutation.required
                },
                vvId: {
                    show: !!form.participation.vvId.enabled,
                    required: !!form.participation.vvId.required
                },
                email: {
                    show: !!form.participation.email.enabled,
                    required: !!form.participation.email.required
                },
                name: {
                    show: !!form.participation.name.enabled,
                    required: !!form.participation.name.required
                },
                phone: {
                    show: !!form.participation.phone.enabled,
                    required: !!form.participation.phone.required
                },
                customCheckboxes:
                    checkboxes.map((c) => ({
                        id: c.id ? c.id : undefined,
                        caption: c.title,
                        required: !!c.required
                    })) || [],
                customFields:
                    fields.map((c) => ({
                        id: c.id ? c.id : undefined,
                        caption: c.title,
                        required: !!c.required
                    })) || [],
            },
            customFields: form.participation.customFields.filter(i => i.enabled).map(
                i => {
                    return {
                        id: i.customFieldId,
                        required: i.required,
                        ignoreCase: false,
                        title: "",
                        description: "",
                        maxLength: 0,
                        regex: "",
                        index: 0
                    }
                }
            ),
            label: form.general.label,
            title: form.general.title,
            subTitle: form.general.subtitle,
            description: form.general.description,
            speakers,
            language: form.general.language,
            brandingId: form.general.branding,
            url: '/' + form.general.url,
            surveyLegal: form.legal,

            mail: form.mailEnabled ? form.mail : null,
            sendMail: form.mailEnabled,

            active: form.active,
            bookingIds: form.bookingIds,

            start: form.duration[0],
            end: form.duration[1],
            questions: form.questions.map((question) => ({
                ...question,
                description: question.description
            })),
            certificateData: {
                ...form.certificateData,
                multipleChoiceCreditsCondition:
                    form.certificateData.multipleChoiceCreditsCondition === 0
                        ? undefined
                        : form.certificateData.multipleChoiceCreditsCondition,
                participationDurationCondition:
                    form.certificateData.participationDurationCondition === 0
                        ? undefined
                        : form.certificateData.participationDurationCondition
            },
            generateCert: form.certificateEnabled
        };
    },

    surveyToSurveyForm(survey: Survey): SurveyForm {
        return {
            general: {
                title: survey.title,
                description: survey.description || '',
                language: survey.language.toUpperCase(),
                branding: survey.branding ? survey.branding?.id + '' : null,
                subtitle: survey.subTitle || '',
                url: survey.url.substr(1),
                label: survey.label || ''
            },

            mail: survey.mail
                ? {
                    replyTo: survey.mail.replyTo || '',
                    subject: survey.mail.subject || '',
                    text: survey.mail.text || '',
                    bcc: survey.mail.bcc || '',
                    enabled: survey.sendMail || false,
                    type: survey.mail.type || SurveyMailType.None,
                    mailTextTemplateId: survey.mail.mailTextTemplateId
                }
                : {
                    replyTo: '',
                    subject: '',
                    text: '',
                    bcc: '',
                    enabled: false,
                    type: SurveyMailType.None
                },
            participation: {
                saveAdditionalParticipantData: survey.saveAdditionalParticipantData,
                saveParticipantData: survey.saveParticipantData,
                address: {
                    enabled: survey.participantDataToSave.address.show,
                    required: survey.participantDataToSave.address.required
                },
                comment: {
                    enabled: survey.participantDataToSave.comment.show,
                    required: survey.participantDataToSave.comment.required
                },
                company: {
                    enabled: survey.participantDataToSave.company.show,
                    required: survey.participantDataToSave.company.required
                },
                partnerId: {
                    enabled: survey.participantDataToSave.partnerId.show,
                    required: survey.participantDataToSave.partnerId.required
                },
                salutation: {
                    enabled: survey.participantDataToSave.salutation.show,
                    required: survey.participantDataToSave.salutation.required
                },
                vvId: {
                    enabled: survey.participantDataToSave.vvId.show,
                    required: survey.participantDataToSave.vvId.required
                },
                email: {
                    enabled: survey.participantDataToSave.email.show,
                    required: survey.participantDataToSave.email.required
                },
                phone: {
                    enabled: survey.participantDataToSave.phone.show,
                    required: survey.participantDataToSave.phone.required
                },
                name: {
                    enabled: survey.participantDataToSave.name.show,
                    required: survey.participantDataToSave.name.required
                },
                fields: survey.participantDataToSave.customFields
                    ? survey.participantDataToSave.customFields.map((f) => ({
                        id: f.id,
                        title: f.caption,
                        required: f.required,
                        type: 'TEXT_LINE',
                        idx: 0
                    }))
                    : [],
                checkboxes: survey.participantDataToSave.customCheckboxes
                    ? survey.participantDataToSave.customCheckboxes.map((f) => ({
                        id: f.id,
                        title: f.caption,
                        required: f.required,
                        type: 'TEXT_AREA',
                        idx: 0
                    }))
                    : [],
                customFields: (survey.customFields || []).map(i => {
                    return {
                        customFieldId: i.id,
                        required: i.required,
                        enabled: true
                    }
                })
            },
            speakers: survey.speakers
                ? [
                    ...survey.speakers.map((sp) => ({
                        id: sp.id,
                        name: sp.name,
                        idx: sp.idx
                    })),
                    {name: '', idx: survey.speakers.length}
                ]
                : [{name: '', idx: 0}],
            legal: survey.surveyLegal
                ? {
                    imprintCompany: survey.surveyLegal.imprintCompany || '',
                    imprintName: survey.surveyLegal.imprintName || '',
                    privacyURL: survey.surveyLegal.privacyURL || ''
                }
                : {imprintCompany: '', imprintName: '', privacyURL: ''},
            questions: survey.questions
                ? survey.questions
                    .sort((a, b) => a.idx - b.idx)
                    .map(this.addAllQuestionOptions)
                    .map(this.sortQuestionOptions)
                    .map((q) => ({...q, open: false, points: q.points || null}))
                : [],
            certificateData: {
                ...survey.certificateData,
                multipleChoiceCreditsCondition: survey.certificateData
                    .multipleChoiceCreditsCondition
                    ? survey.certificateData.multipleChoiceCreditsCondition
                    : 0,
                participationDurationCondition: survey.certificateData
                    .participationDurationCondition
                    ? survey.certificateData.participationDurationCondition
                    : 0,
            },
            duration: [survey.start, survey.end],
            active: survey.active,
            certificateEnabled: survey.generateCert,
            mailEnabled: survey.sendMail,
            hasParticipants:
                !!survey.countRegisteredUser && survey.countRegisteredUser > 0,
            bookingIds: survey.bookingIds
        };
    },

    sortQuestionOptions(question: SurveyQuestion): SurveyQuestion {
        question.multipleTextQuestionOptions.sort((a, b) => a.idx - b.idx);
        question.checkboxQuestionOptions.sort((a, b) => a.idx - b.idx);
        question.radioQuestionOptions.sort((a, b) => a.idx - b.idx);
        return question;
    },

    addAllQuestionOptions(question: SurveyQuestion): SurveyQuestion {
        return {
            ...question,
            multipleTextQuestionOptions: question.multipleTextQuestionOptions
                ? question.multipleTextQuestionOptions
                : [],
            checkboxQuestionOptions: question.checkboxQuestionOptions
                ? question.checkboxQuestionOptions
                : [],
            radioQuestionOptions: question.radioQuestionOptions
                ? question.radioQuestionOptions
                : []
        };
    },

    calculateTotalPoints(questions: SurveyQuestion[]): number {
        let total = 0;
        questions.forEach((question) => {
            switch (question.type) {
                case 'RADIO':
                    total += question.points ? question.points : 0;
                    break;
                case 'CHECKBOX':
                    total += question.points
                        ? question.points * question.checkboxQuestionOptions.length
                        : 0;
                    break;
            }
        });
        return total;
    },

    resultToRow(result: SurveyResult, index: number): SurveyResultTableRow {
        return {
            id: result.responseId.toString(),
            index: index + 1,
            selected: false,
            name:
                result.firstname && result.lastname
                    ? `${result.firstname} ${result.lastname}`
                    : '-',
            points: Math.round(
                result.totalPoints > 0
                    ? (result.reachedPoints / result.totalPoints) * 100
                    : 0
            ),
            passed: !!result.passed,
            minutes: result.participationDuration || 0,
            participated: !!result.participated,
            dateCreated: result.dateCreated,
            result,
            response: result.response,
            originalResponse: result.originalResponse,
            certificateSent:
                !!result.response.dateCertMailSent ||
                !!result.response.dateCertMailToAllSent
        };
    }
};
