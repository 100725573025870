




















































































































































































































































import {Component, Prop, PropSync, Watch} from 'vue-property-decorator';
import WSwitch from '@/components/WSwitch.vue';
import WInlineEdit from '@/components/WInlineEdit.vue';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import {BookingDialOutForm, BookingParticipantForm} from '@/models/BookingForm';
import ContactsService from '@/service/ContactsService';
import Base from '@/views/Base';
import WPagination from '@/components/WPagination.vue';
import BookingAddressBook from './Adressbook.vue';
import {AddedContact, TableContact} from '@/models/Contact';
import {validateEmail} from '@/utils/validators';

@Component({
  components: {
    WSwitch,
    WInlineEdit,
    WPagination,
    BookingAddressBook
  },
})
export default class BookingParticipants extends Base {
  @Prop() form!: BookingDialOutForm;
  @Prop() dialOut!: boolean;
  @PropSync('valid') syncedValid!: boolean;
  @PropSync('tag') syncedTag!: string;
  @PropSync('dirty') syncedDirty!: boolean;

  selectedParticipants: string[] = [];

  classForMail(row: any): string {
    if(this.duplicateMails.includes(row.item.email)){
      return 'text-danger'
    }
    return ''
  }

  fields = [
    {key: 'check', thStyle: {width: '60px'}},
    {key: 'number', label: 'Nr.', sortable: true, sortDirection: 'desc', thStyle: {width: '90px'}, class: 'va-middle'},
    {key: 'name', label: this.t('common.name'), sortable: true, sortDirection: 'desc'},
    {key: 'phone', label: this.t('common.phone'), sortable: true},
    {key: 'email', label: this.t('common.email'), sortable: true},
    {key: 'admin', label: this.t('conference.moderator'), thStyle: {width: '140px'}, sortable: true},
    {
      key: 'actions',
      label: this.t('common.settings'),
      thStyle: {width: '120px'},
      class: 'va-middle',
      thClass: 'show-content-md'
    }
  ];

  resetParticipantForm = {
    name: '',
    phone: '',
    email: '',
    company: '',
    admin: false
  };

  required = required;
  email = validateEmail;

  phoneIsValid = false;

  @Validations()
  validations = {
    newParticipantForm: {
      name: {required},
      phone: {
        required,
        noLetters: ContactsService.noLettersValidator,
        format: this.phoneValidation,
        exists: this.phoneExists
      },
      email: {
        email: validateEmail,
        exists: this.emailExists
      },
      admin: {},
      company: {}
    }
  };

  newParticipantForm = {
    name: '',
    phone: '',
    email: '',
    company: '',
    admin: false
  };

  sortBy = '';
  sortDesc = false;
  perPage = 10;
  currentPage = 1;

  loading = false;
  duplicateMails: string[] = []

  mounted(): void {
    if(this.form.participants.length > 0) {
      this.checkDuplicateMails(true)
    }
  }

  checkDuplicateMails(showToast = false): void {
    const mails = this.form.participants.map((item)=> item.email.toLowerCase());
    const set = new Set(mails);
    const duplicates = mails.filter(item => {
        if (set.has(item)) {
            set.delete(item);
        } else {
            return item;
        }
    });
    this.duplicateMails = duplicates

    if(duplicates.length > 0) {
      this.syncedValid = false;
      if(showToast) {
        this.toast(this.t('conference.mailDuplicatesDetected') + ": " + duplicates.join(", "), "danger")
      }
    }
  }

  get addedContacts(): AddedContact[] {
    return this.form.participants.map(p => {
      return ({
        phone: p.phone?.split(' ').join('') || p.phone,
        email: p.email ? p.email.toLowerCase() : undefined
      });
    });
  }

  get totalRows(): number {
    return this.form.participants.length;
  }

  get showPagination(): boolean {
    return this.perPage <= this.totalRows;
  }

  @Watch('sortBy')
  sortByChanged(): void {
    this.closeActiveInlineEdit();
  }

  @Watch('sortDesc')
  sortDescChanged(): void {
    this.closeActiveInlineEdit();
  }

  beforeDestroy(): void {
    this.$store.commit('setActiveInlineEdit', '');
    this.$store.commit('setActiveEditInvalid', false);
  }

  phoneValidation(): boolean | Promise<boolean> {
    const telephone = this.$v.newParticipantForm.phone;
    if (!telephone?.$dirty) return true;
    return ContactsService.validatePhoneNumbers([this.newParticipantForm.phone])
      .then(validatedNumbers => {
        const validNumber = validatedNumbers[0];
        const phoneIsValid = validNumber.validNumber;
        if (phoneIsValid) this.newParticipantForm.phone = validNumber.international;
        return phoneIsValid;
      })
      .catch(e => {
        this.showNetworkError(e);
        return false;
      });
  }

  phoneExists(v: any, m: any): boolean {
    return !(this.form.participants.find(p => p.phone.replaceAll(' ', '') === m.phone.replaceAll(' ', '')));
  }

  emailExists(val: any, model: any): boolean {
    if (model.email === '') return true;
    return !this.form.participants.some(p => p.email.toLowerCase() === model.email.toLowerCase());
  }

  closeActiveInlineEdit(): void {
    if (this.activeInlineEdit !== null && this.activeInlineEdit !== '') {
      (this.$refs['w-edit-' + this.activeInlineEdit] as WInlineEdit).close();
    }
  }

  isEditable(row: any): boolean {
    const editId = this.getEditId(row);
    const refs = Object.keys(this.$refs);
    const index = refs.indexOf('w-edit-' + editId);
    const prevField = this.$refs[refs[index - 1]] as WInlineEdit;
    return (
      (this.activeEditInvalid && this.activeInlineEdit === editId) ||
      this.activeInlineEdit === '' ||
      (prevField?.editMode && !prevField.hasErrors)
    );
  }

  selectAll(value: boolean): void {
    if(value){
      this.selectedParticipants = this.form.participants.map(p=>p.id);
    } else{
      this.selectedParticipants.splice(0, this.selectedParticipants.length);
    }
  }

  invertSelection(){
    this.selectedParticipants = this.form.participants.map(p=>p.id).filter(id=>!this.selectedParticipants.includes(id))
  }

  selectAdmin(){
    this.selectedParticipants = this.form.participants.filter(p=>p.admin).map(p=>p.id)
  }

  selectParticipants(){
    this.selectedParticipants = this.form.participants.filter(p=>!p.admin).map(p=>p.id)
  }

  deleteSelected(){
    const rows = this.form.participants.filter(p=>this.selectedParticipants.includes(p.id));
    if(rows.length > 0){
      for (const row of rows) {
        this.deleteRow({item: row}, false);
      }
      this.selectedParticipants.splice(0, this.selectedParticipants.length);

      this.toast(this.$tc('conference.countParticipantsDeleted', rows.length), 'success');
    }
  }

  getEditId(row: any): string {
    if (!row) return '';
    return row.index + row.field.key;
  }

  deleteRow(row: any, showToast = true): void {
    if (this.activeInlineEdit) {
      const currentOpenField = this.$refs[
      'w-edit-' + this.activeInlineEdit
        ] as WInlineEdit;
      currentOpenField.close();
    }
    this.loading = true;
    let rowIndex = 0;
    for (let i = 0; i < this.form.participants.length; i++) {
      const p = this.form.participants[i];
      if (p.number > row.item.number && i <= this.form.participants.length)
        p.number--;
      if (p.id === row.item.id) {
        rowIndex = i;
      }
    }
    this.form.participants.splice(rowIndex, 1);
    this.loading = false;
    this.syncedTag =
      this.form.participants.length + ' ' + this.t('common.participants');
    if(showToast){
      this.toast(this.t('conference.participantDeleted'), 'success');
    }
  }

  openNextField(row: any, shift: boolean): void {
    const currentFieldId = this.getEditId(row);
    const refKeys = Object.keys(this.$refs);
    const currentFieldIndex = refKeys.indexOf('w-edit-' + currentFieldId);
    const newIndex = !shift ? currentFieldIndex + 1 : currentFieldIndex - 1;
    const nextFieldIndex = refKeys[newIndex];
    const nextField = this.$refs[nextFieldIndex] as WInlineEdit;
    const currentField = this.$refs[refKeys[currentFieldIndex]] as WInlineEdit;
    setTimeout(() => {
      if (!currentField.loading) {
        if (nextField) nextField.showInput();
      }
    }, 100);
  }

  addGroupFromAdressbook(items: TableContact[]): void {
    const phones: string[] = this.form.participants.map(p => p.phone.replaceAll(' ', ''))
    const emails: string[] = this.form.participants.map(p => p.email);

    items.forEach(item => {
      if (!(phones.includes(item.telephone.replaceAll(' ', '')) || emails.includes(item.email) && !!item.email)) {
        this.addFromAdressbook(item);
      }
    });
    this.$bvModal.hide('modal-adressbook');
  }

  addFromAdressbook(item: any): void {
    item.email = (item.email ?? '').toLowerCase()
    if (!item.selected) {
      this.form.participants.unshift({
        id: item.id,
        name: item.name,
        phone: item.telephone,
        email: item.email,
        company: item.company,
        admin: item.admin,
        number: this.form.participants.length
      });
      this.syncedTag = `${this.form.participants.length} ${this.t('common.participants')}`;
      item.added = true;
    }
  }

  addParticipant(): void {
    if (!this.$v.newParticipantForm.$invalid) {
      this.form.participants.unshift({
        ...this.newParticipantForm,
        id: this.id(),
        number: this.form.participants.length,
        email: this.newParticipantForm.email.toLowerCase(),
      });
      this.newParticipantForm = {...this.resetParticipantForm};
      this.syncedTag =
        this.form.participants.length + ' ' + this.t('common.participants');
      this.toast(this.t('conference.participantAdded'), 'success');
      setTimeout(() => {
        this.$v.newParticipantForm.$reset();
      }, 20);
    } else {
      this.$v.newParticipantForm.$touch();
    }
  }

  validateState(name: string): boolean | null {
    const validate: any = this.$v.newParticipantForm[name];
    if (
      this.newParticipantForm.name === '' &&
      this.newParticipantForm.phone === ''
    ) {
      this.syncedDirty = false;
      return null;
    }
    if (!this.syncedDirty) {
      this.syncedDirty = true;
    }
    this.syncedValid = !this.$v.newParticipantForm.$anyDirty;
    return validate.$dirty ? !validate.$error : null;
  }

  inlinePhoneValidation(row: any) {
    return {
      required: [required, this.t('common.requiredInvalid')],
      phone: [this.phoneFormatValidator(row), this.t('common.phoneInvalid')],
      phoneAlreadyExists: [this.alreadyExistsValidator(row, this.form.participants, 'phone'), this.t('common.numberExists')]
    };
  }

  inlineEmailValidation(row: any) {
    return {
      email: [validateEmail, this.t('common.emailInvalid')],
      emailAlreadyExists: [this.alreadyExistsValidator(row, this.form.participants, 'email'), this.t('common.emailExists')]
    };
  }

  phoneFormatValidator(row: any): () => Promise<boolean> | boolean {
    const field = this.$refs['w-edit-' + this.getEditId(row)] as WInlineEdit;
    return function (this: WInlineEdit) {
      const phone = this.$v.newVal;
      if (!phone.$dirty || !phone.required) return false;
      this.loading = true;
      return ContactsService.validatePhoneNumbers([this.newVal])
        .then(validatedNumbers => {
          const validNumber = validatedNumbers[0];
          const phoneIsValid = validNumber.validNumber;
          if (phoneIsValid) {
            this.newVal = validNumber.international;
          }
          return phoneIsValid;
        })
        .catch(err => {
          console.error(err.message);
          return false;
        })
        .finally(() =>
          setTimeout(() => {
            this.loading = false;
            if (field.id !== this.activeInlineEdit) field.submit();
            if (!field.hasErrors) field.closeOnOutsideClick()
          }, 10));
    }.bind(field);
  }

  alreadyExistsValidator(row: any, participants: BookingParticipantForm[], key: string) {
    const field = this.$refs['w-edit-' + this.getEditId(row)] as WInlineEdit;
    return function (this: WInlineEdit) {
      const value = this.$v.newVal;
      if (!value.$dirty || value.required === false) return false;
      if((this.newVal ?? '').replaceAll(" ", "") == '') {
        return true
      }

      row.item.email = row.item.email.toLowerCase();
      return !participants.find((p: any) => {
        let pValue = p[key];
        let newValue = this.newVal;
        if (typeof p[key] === 'string') {
          pValue = pValue.replaceAll(' ', '').toLowerCase();
          newValue = newValue.replaceAll(' ', '').toLowerCase();
          if (value.required === undefined && pValue === '' && newValue === '') return false;
        }

        return pValue === newValue && row.item.id !== p.id;
      });
    }.bind(field);
  }

  // Store Functions
  get activeInlineEdit(): string {
    return this.$store.state.teleconference.activeInlineEdit;
  }

  get activeEditInvalid(): boolean {
    return this.$store.state.teleconference.activeEditInvalid;
  }
}
