import { Conference } from '@/models/Conference';
import axios from 'axios';

/**
 * API calls for booking
 * @module service/ConferenceService
 */
export default {
	/**
	 * Rest Functions bookings
	 */
	getConferences(): Promise<Array<Conference>> {
		return axios.get('/conference').then((response) => response.data);
	},

	stopConference(id: string): Promise<void> {
		return axios.delete('/conference/' + id).then();
	},

	startConference(bookingId: string): Promise<void> {
		return axios.post('/conference', { bookingId }).then();
	}
};
