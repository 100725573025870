














import {Component} from 'vue-property-decorator';
import Base from "@/views/Base";

@Component({})
export default class ReservedCharsModal extends Base {

}
