





































































































































































import HeaderLayout from '../../layouts/nested/HeaderLayout.vue';
import SidebarLayout from '../../layouts/nested/SidebarLayout.vue';
import {Component} from 'vue-property-decorator';
import Base from '@/views/Base';
import ItemSection from './ItemSection.vue';
import PastItemTable from './PastItemTable.vue';
import WFunctions from '@/components/WFunctions.vue';
import WFormSelect from '@/components/WFormSelect.vue';
import WSidebarLink from '@/components/WSidebarLink.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import HistoricalService from '@/service/HistoricalService';
import {Historical, HistoricalMedia, HistoricalParticipant, RecordingMode} from '@/models/api';
import {
  compareRegUserByBeginDate,
  compareRegUserByCreatedDate,
  dateToDateTimeString,
  durationStr,
  durationStrWithSeconds,
  fileSize,
  formatDateTimeForFiles,
  formatStatus,
  summarizeRegUserParticipation
} from '@/utils/filters';
import {createAdvancedConfDetailsWithSurveyXLSX, createXLSX} from '@/utils/excel';
import {saveAs} from 'file-saver';
import PlaybackService from '@/service/PlaybackService';
import {Playback} from '@/models/Playback';
import BookingService from '@/service/BookingService';
import SelectRegDateModal from '@/components/modals/SelectRegDateModal.vue';
import RegistrationService from '@/service/RegistrationService';
import SelectSurveyModal from '@/components/modals/SelectSurveyModal.vue';
import SurveyService from '@/service/SurveyService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';

@Component({
  components: {
    WHeaderBtn,
    SelectSurveyModal,
    SelectRegDateModal,
    HeaderLayout,
    SidebarLayout,
    ItemSection,
    WFunctions,
    WListOptions,
    WSidebarLink,
    PastItemTable,
    WFormSelect
  }
})
export default class ConferencePastItem extends Base {
  play = false;

  conference: Historical | null = null;
  beginDate = '';

  termin: { [key: string]: string } = {};
  options: { [key: string]: string } = {};

  file: HistoricalMedia | null = null;
  fileUrl: string | null = null;
  fileSize = fileSize;

  mediaDeleted = false;
  detailsLoading = false;
  simpleDetailsLoading = false;

  get duration(): string {
    return this.conference ? durationStr(+this.conference.duration) : '';
  }

  playbacks: Playback[] = [];

  mounted(): void {
    const id = this.$route.params.id;
    if (id) this.getBooking(id);
  }

  historicalToConferenceItem = BookingService.historicalToConferenceItem;

  private getBooking(id: string) {
    PlaybackService.getPlaybacks().then((p) => {
      this.playbacks = p;
      HistoricalService.getHistorical(id)
          .then((res) => {
            this.conference = res;
            this.fillData();
          })
          .catch(this.showNetworkError);
    });
  }

  fillData(): void {
    if (this.conference) {
      const playback = this.playbacks.find(
          (p) => p.id === this.conference?.audioId
      );
      const typeStr = playback
          ? this.t('conference.playback') + ' (' + playback.name + ')'
          : this.t('conference.live');
      let regDateStr!: string;
      let surveyStr!: string;
      if (this.conference && this.conference.regDate) {
        const date = this.conference.regDate;
        regDateStr = `${date.title} (${new Date(date.start).toLocaleString(
            undefined,
            {
              dateStyle: 'short',
              timeStyle: 'short'
            }
        )})`;
      }
      if (this.conference && this.conference.survey) {
        const survey = this.conference.survey;
        surveyStr = survey.label || survey.title;
      }

      const value = (key: string) => this.t('conference.sectionValues.' + key);

      this.termin = {
        languageInEmails:
            this.conference?.language?.toLowerCase() === 'de'
                ? this.t('common.german')
                : this.t('common.english'),
        eventType: typeStr,
        costCenter: this.conference?.costCenter || '-',
        pin: `${
            this.conference?.dialIn
                ? this.conference?.moderatorAccessCode || '-'
                : '-'
        } / ${
            this.conference?.dialIn
                ? this.conference?.participantAccessCode || '-'
                : '-'
        }`,
        regForm: regDateStr || '-',
        surveyForm: surveyStr || '-'
      };
      this.options = {
        mode: this.conference?.joinMuted ? value('schooling') : value('talk'),
        waitingRoom: this.conference?.waitingRoom
            ? value('activated')
            : value('deactivated'),
        conferenceEnd: this.conference?.autoClose
            ? value('whenModEnds')
            : value('manual'),
        recording:
            this.conference?.recordingMode === RecordingMode.AUTOMATIC
                ? value('automatic')
                : value('manual'),
        tone: this.conference?.beep ? value('activated') : value('deactivated'),
        confirmation: this.conference?.dialOutCalleeConfirm
            ? value('activated')
            : value('deactivated')
      };
      if (this.conference.start) {
        this.beginDate = new Date(this.conference.start).toLocaleString(
            undefined,
            {dateStyle: 'medium', timeStyle: 'short'}
        );
      }
      if (this.conference.id) {
        HistoricalService.getDeletedMediaList(this.conference.id).then(
            (list) => {
              if (list.length > 0) this.mediaDeleted = true;
            }
        );
        HistoricalService.getMediaList(this.conference.id).then((list) => {
          if (
              list.length > 0 &&
              list[0].filename.includes('mp3') &&
              this.conference?.id
          ) {
            this.file = list[0];
            this.fileUrl = HistoricalService.getMediaFileUrl(
                this.conference.id,
                this.file.filename
            );
          }
        });
      }
    }
  }

  openMailModal(): void {
    this.$bvModal.show('mailModal');
  }

  downloadMedia(): void {
    if (this.conference?.id && this.fileUrl && this.file) {
      HistoricalService.downloadMediaFile(this.fileUrl)
          .then((blob) => saveAs(blob, this.file?.filename))
          .catch((e) => this.showNetworkError(e));
    }
  }

  deleteMedia(): void {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteRecording.description'), {
          centered: true,
          title: this.t('modals.deleteRecording.title'),
          okVariant: 'danger',
          okTitle: this.t('modals.deleteRecording.confirm'),
          cancelTitle: this.t('common.cancel')
        })
        .then((ok) => {
          if (ok && this.conference?.id && this.file) {
            HistoricalService.deleteMediaFile(
                this.conference.id,
                this.file.filename
            )
                .then(() => {
                  this.toast(this.t('modals.deleteRecording.confirmed'), 'success');
                  this.mediaDeleted = true;
                })
                .catch((e) => this.showNetworkError(e));
          }
        });
  }

  repeat(): void {
    this.$router.push(`/conference/booking?copyId=${this.conference?.id}&step=0`);
  }

  downloadParticipants(): void {
    if (this.conference && this.conference.id && !this.simpleDetailsLoading) {
      this.simpleDetailsLoading = true;
      HistoricalService.getHistoricalParticipants(this.conference.id)
          .then((participants) => {
            const filename = 'Details_' + this.formatDate(this.conference?.start);
            const t = (v: string) => this.t('common.' + v);
            const columns = [
              {header: t('name'), key: 'name', width: 21},
              {header: t('phone'), key: 'phone', width: 18},
              {header: t('email'), key: 'email', width: 22},
              {header: t('company'), key: 'company', width: 22},
              {header: t('start'), key: 'start', width: 20},
              {header: t('duration'), key: 'duration', width: 10},
              {header: t('type'), key: 'type', width: 16},
              {header: t('status'), key: 'status', width: 12}
            ];
            participants.sort((a, b) => {
              if (a.status === 'DIALOUT_FAILED' || a.status === 'DIALOUT_BUSY')
                return 1;
              if (b.status === 'DIALOUT_FAILED' || b.status === 'DIALOUT_BUSY')
                return -1;
              return a.beginDate - b.beginDate;
            });
            const entries = [];
            for (const participant of participants) {
              entries.push({
                name: participant.name,
                phone: participant.phone,
                email: participant.email,
                company: participant.company,
                start: participant.status !== 'DIALOUT_FAILED' && participant.status !== 'DIALOUT_BUSY'
                  ? this.startStr(participant)
                  : '-',
                duration: durationStrWithSeconds(participant.duration),
                type:
                    participant.type === 'DIALOUT'
                        ? this.t('conference.dialout')
                        : this.t('conference.dialin'),
                status: formatStatus(participant.status, this)
              });
            }
            createXLSX(filename, columns, entries, this.t('common.participants'));
          })
          .catch((e) => this.showNetworkError(e))
          .finally(() => (this.simpleDetailsLoading = false));
    }
  }

  downloadExtendedParticipants(): void {
    if (!this.conference) return;
    if (!this.conference.regDate) return;
    if (this.detailsLoading) return;
    this.detailsLoading = true;

    const regId = this.conference.regDate.regId;
    const dateId = this.conference.regDate.dateId;
    const regDateStart = this.conference.regDate.start;
    RegistrationService.getRegUsersAndParticipantsForDateWithData(regId, dateId)
        .then(participants => {
          if (participants.length === 0) {
            this.toast(this.t('conference.noParticipantsAvailable'), 'danger');
            return;
          }


          const summarizedParticipants = participants.map(summarizeRegUserParticipation);
          const notMatchedParticipants = summarizedParticipants
              .filter(p => !p.participants)
              .sort(compareRegUserByCreatedDate);
          const matchedParticipants = summarizedParticipants
              .filter(p => p.summarizedParticipant)
              .sort(compareRegUserByBeginDate);

          const filename = `Details_${this.formatDate(regDateStart)} (erweitert)`;

          RegistrationService.getRegistration(regId).then(registration => {
            const hasSurveyResult = summarizedParticipants.find(participant => !!participant.result);
            if (hasSurveyResult && this.conference && this.conference.survey) {
              // const entries = matchedParticipants.concat(notMatchedParticipants);
              SurveyService.getSurvey(this.conference.survey.surveyId).then(survey => {
                if (survey) createAdvancedConfDetailsWithSurveyXLSX(registration, dateId, survey, summarizedParticipants, filename, this);
              });
            } else {
              const regDateGenerateCert = registration.regDates.find(date => date.id === dateId)?.generateCert;
              const entries = matchedParticipants
                  .map(p => RegistrationService.regUserWithDataToExcelEntryByDate(p, dateId, this))
                  .concat(notMatchedParticipants.map(p => RegistrationService.regUserWithDataToExcelEntryByDate(p, dateId, this)));
              const columns = RegistrationService.getExtendedRegFormColumns(registration, participants, regDateGenerateCert, this);
              createXLSX(filename, columns, entries, this.t('common.participants'), registration.doi?.type === 'CONFERENCE');
            }
          });
        })
        .catch(e => this.showNetworkError(e))
        .finally(() => (this.detailsLoading = false));
  }

  formatDate = formatDateTimeForFiles;

  startStr(participant: HistoricalParticipant): string {
    return participant.duration === 0
        ? ''
        : dateToDateTimeString(new Date(participant.beginDate), true);
  }
}
