var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"functions-container d-flex flex-row align-items-center text-muted h-100"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(
			("Dial Out (" + (_vm.functions.dialOut ? _vm.$t('filter.activated') : _vm.$t('filter.deactivated')) + ")")
		),expression:"\n\t\t\t`Dial Out (${\n\t\t\t\tfunctions.dialOut ? $t('filter.activated') : $t('filter.deactivated')\n\t\t\t})`\n\t\t",modifiers:{"bottom":true}}]},[_c('call-out-icon',{staticClass:"icon-lg",class:'icon-lg ' + (_vm.functions.dialOut ? '' : 'inactive')})],1),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(
			("Dial In (" + (_vm.functions.dialIn ? _vm.$t('filter.activated') : _vm.$t('filter.deactivated')) + ")")
		),expression:"\n\t\t\t`Dial In (${\n\t\t\t\tfunctions.dialIn ? $t('filter.activated') : $t('filter.deactivated')\n\t\t\t})`\n\t\t",modifiers:{"bottom":true}}],staticClass:"ml-23"},[_c('call-in-icon',{staticClass:"icon-lg",class:_vm.functions.dialIn ? '' : 'inactive'})],1),(!_vm.noMic)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(
			("Recording (" + (_vm.functions.microphone
					? _vm.$t('filter.activated')
					: _vm.$t('filter.deactivated')) + ")")
		),expression:"\n\t\t\t`Recording (${\n\t\t\t\tfunctions.microphone\n\t\t\t\t\t? $t('filter.activated')\n\t\t\t\t\t: $t('filter.deactivated')\n\t\t\t})`\n\t\t",modifiers:{"bottom":true}}],staticClass:"ml-23"},[_c('microphone-icon',{staticClass:"icon-lg",class:_vm.functions.microphone ? '' : 'inactive'})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }