

































import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";
import {PresentationTableItem} from "@/models/Presentation";

@Component
export default class PresentationCard extends Base {
  @Prop() item!: PresentationTableItem;

  test(): void {
    // console.log('test');
  }
}
