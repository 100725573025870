












import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class WIcon extends Vue {
	@Prop() icon!: string;
	@Prop() height?: number;
	@Prop() width?: number;
	@Prop({ default: false, type: Boolean }) hover!: boolean;
	@Prop({ default: false, type: Boolean }) invert!: boolean;
}
