export interface Booking {
	id?: string;
	topic?: string;
	moderatorAccessCode?: string;
	participantAccessCode?: string;
	costCenter?: string;
	language?: string;
	recordingMode?: RecordingMode;
	schedule?: Schedule;
	participants?: Array<Participant>;
	joinMuted?: boolean;
	waitingRoom?: boolean;
	beep?: boolean;
	dialOutCalleeConfirm?: boolean;
	autoClose?: boolean;
	dialIn: boolean;
	dialOut: boolean;
	start?: number;
	end?: number;
	operatorBooking?: OperatorBooking;
	regDate?: BookingRegistration;
	survey?: BookingSurvey;
	audioId?: number;
}

export interface Pageable {
	content: any;
	empty: boolean;
	numberOfElements: number;
	offset: number;
	pageNumber: number;
	size: number;
	totalPages: number;
	totalSize: number;
}

export interface OperatorBooking {
	id: number;
}

export interface HistoricalObject {
	content: Historical[];
	empty: boolean;
	numberOfElements: number;
	offset: number;
	pageNumber: number;
	size: number;
	totalPages: number;
	totalSize: number;
}

export interface Historical extends Booking {
	conferenceId: string;
	bookingId: string;
	duration: string;
	numberOfParticipants: number;
}

export interface HistoricalParticipant {
	beginDate: number;
	duration: number;
	email: string;
	company: string;
	id: number;
	lineIndex: number;
	name: string;
	phone: string;
	role: 'MODERATOR' | 'PARTICIPANT';
	registrationData: string;
	status:
		| 'UNKNOWN'
		| 'DIALOUT_WAIT'
		| 'FINISHED'
		| 'DIALOUT_ONDEMAND'
		| 'DIALOUT_TIMEOUT'
		| 'DIALOUT_FAILED'
		| 'DISABLED'
		| 'LIMIT_EXCEEDED'
		| 'DIALOUT_BUSY';
	type:
		| 'PRESENTATION'
		| 'DIALIN'
		| 'DIALOUT'
		| 'DIALIN/DIALOUT'
		| 'COLLABORATION'
		| 'AUDIO_STREAMING'
		| 'RECORDING'
		| 'UNKNOWN';
	idx: number;
}

export interface HistoricalMedia {
	filename: string;
	size: number;
}

export enum RecordingMode {
	NONE = 'NONE',
	MANUAL = 'MANUAL',
	AUTOMATIC = 'AUTOMATIC'
}

export interface Schedule {
	type: ScheduleType; // Periodicity
	start: number;
	end?: number;
	mon?: boolean;
	tue?: boolean;
	wed?: boolean;
	thu?: boolean;
	fri?: boolean;
	sat?: boolean;
	sun?: boolean;
	timeInterval?: number;
	ordinalDayOfMonth?: number;
}

export enum ScheduleType {
	ANYTIME = 'ANYTIME',
	IMMEDIATE = 'IMMEDIATE',
	ONETIME = 'ONETIME',
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY'
}

export interface Participant {
	name: string;
	phone: string;
	email?: string;
	company?: string;
	moderator: boolean;
	idx?: number;
	number?: number;
}

export interface NationalNumber {
	active: boolean;
	language: string;
	description: string; // Our description, can be changed

	originDescription: string; // Description from Lindenbaum, can't changed

	number: string;
	displayNumber: string;
	idx: number; // Index for sorting the phone number list

	preferredCountries: string[];
	accessTypes: 'ACCESS_CODE' | 'DIRECT';
	directAccessReservationId: string;
	directAccessRole: 'MODERATOR' | 'PARTICIPANT';
}

export interface BookingRegistration {
	dateId: number;
	regId: number;
	title: string;
	url: string;
	start: number;
	label?: string;
	phoneRequired: boolean;
}

export interface BookingSurvey {
	surveyId: number;
	title: string;
	url: string;
	label?: string;
}

export interface Email {
	pin?: string;
	subject: string;
	text: string;
	replyTo: string;
	bcc?: string;
	type?: 'ANSWERS_AND_PERSONAL_DATA' | 'ANSWERS' | 'PERSONAL_DATA' | 'NONE';
	addOutlookCalendarEntry?: boolean;
	sendReminderMails?: boolean;
	attachments?: string[];
	brandingId?: number | null;
	mailTextTemplateId?: string;
}

export interface TimeRange {
	year: number;
	month: number;
}
