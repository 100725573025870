import 'regenerator-runtime/runtime';
import ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import {Survey, SurveyQuestion, SurveyResponse, SurveyResponseAnswer, SurveyResult} from '@/models/Survey';
import {
    dateToDateTimeString,
    durationStrWithSeconds,
    formatDateCreated,
    formatDateTimeForFiles,
    formatStatus,
    formatType,
    minDurationReached,
    translateSalutation
} from '@/utils/filters';
import {Registration, RegistrationUserWithData} from '@/models/Registration';
import Vue from "vue";


export function createXLSX(filename: string, columns: any[], entries: any[], worksheetName: string, styleByDateDoi = false): void {
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date(Date.now());
    const sheet = workbook.addWorksheet(worksheetName, {views: [{state: 'frozen', ySplit: 1}]});

    sheet.columns = columns;
    sheet.addRows(entries);
    sheet.getRow(1).font = {bold: true};

    if (styleByDateDoi) {
        // if columns contains the key "status", only entries with no status should be greyed out if DOI is not confirmed
        // this is only relevant for downloadExtendedParticipants in PastItem
        if (columns.find(c => c.key === "status")) {
            entries.forEach((e, i) => {
                sheet.getRow(i + 2).font = e.dateDoi === '-' && !e.status ? notConfirmedFont : defaultFontLight;
            })
        } else {
            entries.forEach((e, i) => sheet.getRow(i + 2).font = e.dateDoi === '-' ? notConfirmedFont : defaultFontLight)
        }
    }

    saveXLSX(workbook, `${filename}.xlsx`);
}

export function readXLSX(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const workbook = new ExcelJS.Workbook();
        const result = new Array<Array<string>>();

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer = reader.result as ArrayBuffer;
            workbook.xlsx.load(buffer).then(wb => {
                const sheets = wb.worksheets.filter(sheet => sheet.state === 'visible');
                const sheetId = sheets.shift()?.id
                if (sheetId) {
                    wb.getWorksheet(sheetId).eachRow((row, i) => {
                        if (i == 0) {
                            return;
                        }
                        const values: string[] = [];
                        row.eachCell((cell) => {
                            values.push(cell.text);
                        });
                        // const values = row.values as Array<string>;
                        result.push(values);
                    })
                    resolve(result);
                }
            }).catch(reject)
        }
    })
}

export function createSingleResponseXLSX(survey: Survey, result: SurveyResult, instance: Vue): void {
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date(Date.now());
    const sheet = workbook.addWorksheet(`${result.firstname} ${result.lastname}`);

    sheet.columns = [{width: 8}, {width: 3}, {width: 52}, {width: 3}, {width: 60}, {width: 3}, {width: 8}];
    sheet.properties.showGridLines = false;
    sheet.views = [{showGridLines: false}];

    let rowCount = addParticipantDetails(sheet, survey, result, instance);
    if (result.totalPoints > 0 || survey.generateCert) rowCount = addResultDetails(sheet, survey, result, rowCount, instance);

    survey.questions.sort((a, b) => a.idx - b.idx);
    survey.questions.forEach((question, index) => {
        switch (question.type) {
            case 'TEXT':
                rowCount = addTextQuestionAndAnswer(sheet, question, result.response.answers, index + 1, rowCount, instance);
                break;
            case 'MULTIPLE_TEXT':
                rowCount = addMultipleTextQuestionAndAnswers(sheet, question, result.response.answers, index + 1, rowCount, instance);
                break;
            case "RADIO":
                rowCount = addRadioQuestionAndAnswer(sheet, question, result.response.answers, result, index + 1, rowCount, instance);
                break;
            case "CHECKBOX":
                rowCount = addCheckboxQuestionAndAnswers(sheet, question, result.response.answers, result, index + 1, rowCount, instance);
                break;
        }
    });

    addResponseLegendSheetToXLSX(workbook, instance);
    saveXLSX(workbook, `${instance.$t('forms.individualEvaluationFilename')}_${result.firstname}-${result.lastname}_${formatDateTimeForFiles(result.dateCreated)}.xlsx`);
}

export function createAdvancedConfDetailsWithSurveyXLSX(registration: Registration, dateId: number, survey: Survey, participants: RegistrationUserWithData[], filename: string, instance: Vue): void {
    console.log('registration', registration);
    console.log('participants', participants);
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date(Date.now());
    const sheet = workbook.addWorksheet(instance.$t('common.participants') as string);

    let columnCount = addTotalParticipantsData(sheet, registration, dateId, survey, participants, instance);

    survey.questions.sort((a, b) => a.idx - b.idx);
    const answers = participants.map(participant => participant.result ? participant.result.response.answers : null);
    const results = participants.map(participant => participant.result || null)
    columnCount = addQuestionsAndAnswers(survey, columnCount, sheet, answers, results);

    addResultsColumn(sheet, survey, results, columnCount, instance);
    fillHorizontalStripe(sheet, participants.length + 3, columnCount);

    addResponseLegendSheetToXLSX(workbook, instance, true);
    saveXLSX(workbook, filename)
}

function addQuestionsAndAnswers(survey: Survey, columnCount: number, sheet: ExcelJS.Worksheet, answers: (SurveyResponseAnswer[] | null)[], results: (SurveyResult | null)[]) {
    survey.questions.forEach((question, index) => {
        switch (question.type) {
            case "TEXT":
                columnCount = addTextQuestionAndAnswers(sheet, question, answers, index + 1, columnCount);
                break;
            case "RADIO":
                columnCount = addRadioQuestionAndAnswers(sheet, question, answers, results, index + 1, columnCount);
                break;
            case "CHECKBOX":
                columnCount = addCheckboxQuestionAndAllAnswers(sheet, question, answers, results, index + 1, columnCount);
                break;
            case "MULTIPLE_TEXT":
                columnCount = addMultipleTextQuestionAndAllAnswers(sheet, question, answers, index + 1, columnCount);
                break;
        }
    })
    return columnCount;
}

export function createResponsesXLSX(survey: Survey, results: Array<SurveyResult>, instance: Vue): void {
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date(Date.now());
    const sheet = workbook.addWorksheet(instance.$t('forms.surveyDetails') as string);

    results.sort((a, b) => a.responseId - b.responseId);
    const responses = results.map(result => result.response);

    let columnCount = addSurveyParticipantsData(sheet, survey, responses, instance);

    survey.questions.sort((a, b) => a.idx - b.idx);
    const answers = responses.map(response => response.answers);
    columnCount = addQuestionsAndAnswers(survey, columnCount, sheet, answers, results);

    columnCount = addResultsColumn(sheet, survey, results, columnCount, instance);
    fillHorizontalStripe(sheet, results.length + 3, columnCount);

    addResponseLegendSheetToXLSX(workbook, instance, true);
    saveXLSX(workbook, `${instance.$t('forms.evaluationFilename') as string}_${formatDateTimeForFiles(Date.now())}.xlsx`)
}

function addResultsColumn(sheet: ExcelJS.Worksheet, survey: Survey, results: (SurveyResult | null)[], xPos: number, instance: Vue) {
    const xStart = xPos;
    const hasTotalPoints = results.map(res => res ? res.totalPoints : 0).find(points => points > 0) !== undefined;

    if (hasTotalPoints) {
        results.forEach((result, i) => {
            sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
            sheet.getCell(i + 3, xPos).value = result ? result.reachedPoints : '-';
        })
        xPos = addColumnHeading(sheet, 13, instance.$t('common.points') as string, xPos, results[0] ? results[0].totalPoints : '-', true);

        results.forEach((result, i) => {
            const percentage = result ? `${Math.round((result.reachedPoints / result.totalPoints) * 100)}%` : '-';
            sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
            sheet.getCell(i + 3, xPos).value = percentage;
        })
        xPos = addColumnHeading(sheet, 13, instance.$t('common.percent') as string, xPos, '100%', true);

        if (survey.certificateData.multipleChoiceCreditsCondition) {
            results.forEach((result, i) => {
                sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
                sheet.getCell(i + 3, xPos).value = result ? result.passed ? instance.$t('common.yes') as string : instance.$t('common.no') as string : '-';
            })
            xPos = addColumnHeading(sheet, 18, instance.$t('forms.passedFrom') as string, xPos, survey.certificateData.multipleChoiceCreditsCondition + '%', true);
        }

        if (survey.certificateData.participationDurationCondition) {
            results.forEach((result, i) => {
                sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
                sheet.getCell(i + 3, xPos).value = result ? result.participated ? instance.$t('common.yes') as string : instance.$t('common.no') as string : '-';
            })
            xPos = addColumnHeading(sheet, 20, instance.$t('forms.passedFrom') as string, xPos,
                `${instance.$t('forms.participation') as string} > ${instance.$t('common.nMinutes', {number: survey.certificateData.participationDurationCondition}) as string}`, true);
        }
    }

    if (survey.generateCert) {
        results.forEach((result, i) => {
            sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
            sheet.getCell(i + 3, xPos).value = result ? getDateSent(result.response.dateCertMailSent, result.response.dateCertMailToAllSent, instance) : '-';
        })
        xPos = addColumnHeading(sheet, 25, instance.$t('conference.certificateSentUF') as string, xPos, instance.$t('forms.timestamp') as string, true);
    }

    if (xStart < xPos) {
        colorArea(sheet, {r: 1, c: xStart}, {r: 2, c: xPos - 1}, 'C6EFCE', true);
        createOuterBorder(sheet, {r: 1, c: xStart}, {r: 2, c: xPos - 1});
        createOuterBorder(sheet, {r: 3, c: xStart}, {r: results.length + 2, c: xPos - 1});
        fillVerticalStripe(sheet, xPos, results.length + 3);
    }
    return (xPos + 1);
}

function addQuestionTitleAndBgColor(sheet: ExcelJS.Worksheet, xStart: number, xPos: number, questionNumber: number, question: SurveyQuestion) {
    sheet.mergeCells(1, xStart, 1, xPos - 1);
    sheet.getCell(1, xStart).value = `(${questionNumber}) ${question.title}`;
    sheet.getCell(1, xStart).style.alignment = {horizontal: 'left'};
    colorArea(sheet, {r: 1, c: xStart}, {r: 2, c: xPos - 1}, 'FFF2CC', true);
}

function addCheckboxQuestionAndAllAnswers(sheet: ExcelJS.Worksheet, question: SurveyQuestion, allAnswers: (SurveyResponseAnswer[] | null)[], results: (SurveyResult | null)[], questionNumber: number, xPos: number): number {
    question.checkboxQuestionOptions.sort(((a, b) => a.idx - b.idx));
    const xStart = xPos;

    question.checkboxQuestionOptions.forEach((option, i) => {
        sheet.getColumn(xStart + i).width = Math.min(Math.max(option.caption.length, 15), 35);
        sheet.getColumn(xStart + i).style.alignment = {horizontal: 'center'};
        sheet.getCell(2, xStart + i).value = option.caption;

        if (question.evaluate && option.rightAnswer) sheet.getCell(3, xStart + i).font = correctFont;

        allAnswers.forEach((answers, j) => {
            if (answers) {
                const answer = answers.find(a => a.question.id === question.id);
                if (answer && answer.values && option.id) {
                    if (answer.values.includes(option.id)) {
                        sheet.getCell(j + 3, xStart + i).value = 'X';
                        sheet.getCell(j + 3, xStart + i).font = question.evaluate
                            ? option.rightAnswer ? correctFont : incorrectFont
                            : defaultFont;
                    }
                }
            } else {
                sheet.getCell(j + 3, xStart + i).value = ' ';
            }

        })
        xPos++;
    })
    addQuestionTitleAndBgColor(sheet, xStart, xPos, questionNumber, question);

    if (question.evaluate) {
        // Add green question type if question is evaluated
        sheet.getColumn(xPos).style.alignment = {horizontal: 'center'};
        sheet.getColumn(xPos).width = 4;
        sheet.getCell(1, xPos).value = 'MC';
        sheet.getCell(1, xPos).fill = getFill('C6EFCE');
        sheet.getCell(2, xPos).value = `${question.points}/${question.checkboxQuestionOptions.length * (question.points ? question.points : question.checkboxQuestionOptions.length)}`;
        sheet.getCell(2, xPos).fill = getFill('C6EFCE');
        results.forEach((result, i) => {
            if (result) {
                const questionResult = result.questionResults.find(q => q.questionId === question.id);
                if (questionResult) sheet.getCell(i + 3, xPos).value = questionResult.reachedPoints;
            } else {
                sheet.getCell(i + 3, xPos).value = ' ';
            }
        })
        xPos++;
    }

    createOuterBorder(sheet, {r: 1, c: xStart}, {r: 2, c: xPos - 1});
    createOuterBorder(sheet, {r: 3, c: xStart}, {r: allAnswers.length + 2, c: xPos - 1});
    fillVerticalStripe(sheet, xPos, allAnswers.length + 3);
    return (xPos + 1);
}

function addRadioQuestionAndAnswers(sheet: ExcelJS.Worksheet, question: SurveyQuestion, allAnswers: (SurveyResponseAnswer[] | null)[], results: (SurveyResult | null)[], questionNumber: number, xPos: number): number {
    question.radioQuestionOptions.sort((a, b) => a.idx - b.idx);
    const xStart = xPos;

    question.radioQuestionOptions.forEach((option, i) => {
        sheet.getColumn(xStart + i).width = Math.min(Math.max(option.caption.length, 15), 35);
        sheet.getColumn(xStart + i).style.alignment = {horizontal: 'center'};
        sheet.getCell(2, xStart + i).value = option.caption;

        if (question.evaluate && option.rightAnswer) sheet.getCell(3, xStart + i).font = correctFont;

        allAnswers.forEach((answers, j) => {
            if (answers) {
                const answer = answers.find(a => a.question.id === question.id);
                if (answer && answer.values) {
                    const answerId = answer.values[0];
                    if (option.id === answerId) {
                        sheet.getCell(j + 3, xStart + i).value = 'X';
                        sheet.getCell(j + 3, xStart + i).font = question.evaluate
                            ? option.rightAnswer ? correctFont : incorrectFont
                            : defaultFont;
                    }
                }
            } else {
                sheet.getCell(j + 3, xStart + i).value = ' ';
            }
        })
        xPos++;
    })

    addQuestionTitleAndBgColor(sheet, xStart, xPos, questionNumber, question);

    if (question.evaluate) {
        // Add green question type if question is evaluated
        sheet.getColumn(xPos).style.alignment = {horizontal: 'center'};
        sheet.getColumn(xPos).width = 4;
        sheet.getCell(1, xPos).value = 'SC';
        sheet.getCell(1, xPos).fill = getFill('C6EFCE');
        sheet.getCell(2, xPos).value = question.points;
        sheet.getCell(2, xPos).fill = getFill('C6EFCE');
        results.forEach((result, i) => {
            if (result) {
                const questionResult = result.questionResults.find(q => q.questionId === question.id);
                if (questionResult) sheet.getCell(i + 3, xPos).value = questionResult.reachedPoints;
            } else {
                sheet.getCell(i + 3, xPos).value = ' ';
            }
        })
        xPos++;
    }

    createOuterBorder(sheet, {r: 1, c: xStart}, {r: 2, c: xPos - 1});
    createOuterBorder(sheet, {r: 3, c: xStart}, {r: allAnswers.length + 2, c: xPos - 1});
    fillVerticalStripe(sheet, xPos, allAnswers.length + 3);
    return (xPos + 1);
}

function addMultipleTextQuestionAndAllAnswers(sheet: ExcelJS.Worksheet, question: SurveyQuestion, allAnswers: (SurveyResponseAnswer[] | null)[], questionNumber: number, xPos: number): number {
    question.multipleTextQuestionOptions.sort((a, b) => a.idx - b.idx);

    question.multipleTextQuestionOptions.forEach((option, i) => {
        const colWidth = Math.min(Math.max(option.caption ? option.caption.length : 45, 45), 100);
        const xStart = xPos;

        allAnswers.forEach((answers, j) => {
            if (answers) {
                const answer = answers.find(a => a.question.id === question.id);

                if (answer && option.id) {
                    const value = answer.valueMap ? answer.valueMap[option.id] : '-';
                    sheet.getCell(j + 3, xPos).value = value ? value : '-';
                } else {
                    sheet.getCell(j + 3, xPos).value = '-';
                }
            } else {
                sheet.getCell(j + 3, xPos).value = ' ';
            }
        })
        xPos = addColumnHeading(sheet, colWidth, `(${questionNumber}.${i + 1}) ${question.title}`, xPos, option.caption, false, true);

        colorArea(sheet, {r: 1, c: xStart}, {r: 2, c: xStart}, 'FFF2CC', true);
        createOuterBorder(sheet, {r: 1, c: xStart}, {r: 2, c: xStart});
        createOuterBorder(sheet, {r: 3, c: xStart}, {r: allAnswers.length + 2, c: xStart});
        fillVerticalStripe(sheet, xPos, allAnswers.length + 3);
        xPos++;
    })

    return xPos;
}

function addTextQuestionAndAnswers(sheet: ExcelJS.Worksheet, question: SurveyQuestion, allAnswers: (SurveyResponseAnswer[] | null)[], questionNumber: number, xPos: number): number {

    // columnWidth should be dynamic to text but with a limit
    const colWidth = Math.min(Math.max(question.title.length, 45), 75);
    const xStart = xPos;

    allAnswers.forEach((answers, i) => {
        if (answers) {
            const answer = answers.find(a => a.question.id === question.id);
            sheet.getCell(i + 3, xStart).value = answer ? answer.value : '-';
        } else {
            sheet.getCell(i + 3, xStart).value = '-';
        }
    })
    xPos = addColumnHeading(sheet, colWidth, `(${questionNumber}) ${question.title}`, xPos, '');

    colorArea(sheet, {r: 1, c: xStart}, {r: 2, c: xStart}, 'FFF2CC', true);
    createOuterBorder(sheet, {r: 1, c: xStart}, {r: 2, c: xStart});
    createOuterBorder(sheet, {r: 3, c: xStart}, {r: allAnswers.length + 2, c: xStart})
    fillVerticalStripe(sheet, xPos, allAnswers.length + 3);
    return (xPos + 1);
}

function fillVerticalStripe(sheet: ExcelJS.Worksheet, xPos: number, height: number, width = 3, color = 'FFFFFF'): void {
    sheet.getColumn(xPos).width = width;
    for (let i = 1; i < height; i++) {
        sheet.getCell(i, xPos).fill = getFill(color);
        sheet.getCell(i, xPos).value = ' ';
    }
}

function fillHorizontalStripe(sheet: ExcelJS.Worksheet, yPos: number, width: number, color = 'FFFFFF'): void {
    for (let i = 1; i < width; i++) {
        sheet.getCell(yPos, i).fill = getFill(color);
        sheet.getCell(yPos, i).value = ' ';
    }
}

function addTotalParticipantsData(sheet: ExcelJS.Worksheet, registration: Registration, dateId: number, survey: Survey, participants: RegistrationUserWithData[], instance: Vue): number {
    sheet.getRow(0).border
    sheet.getRow(1).font = {bold: true};
    let xPos = 1;

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.summarizedParticipant?.name || '');
    xPos = addColumnHeading(sheet, 22, instance.$t('common.name') as string, xPos);

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.summarizedParticipant?.phone || participant.phone);
    xPos = addColumnHeading(sheet, 20, instance.$t('common.phoneNumber') as string, xPos);

    participants.forEach((participant, i) => {
        sheet.getCell(i + 3, xPos).value =
            participant.summarizedParticipant?.status === 'DIALOUT_FAILED' || participant.summarizedParticipant?.status === 'DIALOUT_BUSY'
                ? '-'
                : dateToDateTimeString(participant.summarizedParticipant?.beginDate, true)
    });

    xPos = addColumnHeading(sheet, 18, instance.$t('common.startTime') as string, xPos);

    // Fill color & fixate columns and add border
    const fixedColumns = xPos;
    colorArea(sheet, {r: 1, c: 1}, {r: 2, c: fixedColumns - 1}, 'DDEBF7', true);
    createOuterBorder(sheet, {r: 1, c: 1}, {r: 2, c: fixedColumns - 1});
    createOuterBorder(sheet, {r: 3, c: 1}, {r: participants.length + 2, c: fixedColumns - 1});
    sheet.views = [{state: 'frozen', ySplit: 2, xSplit: fixedColumns - 1}];

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = durationStrWithSeconds(participant.summarizedParticipant?.duration));
    xPos = addColumnHeading(sheet, 10, instance.$t('common.duration') as string, xPos);

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = minDurationReached(participant.summarizedParticipant?.duration, instance));
    xPos = addColumnHeading(sheet, 10, '> ' + instance.$t('common.nMinutes', {number: 15}) as string, xPos);

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = formatType(participant.summarizedParticipant?.type, instance));
    xPos = addColumnHeading(sheet, 18, instance.$t('common.type') as string, xPos);

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = formatStatus(participant.summarizedParticipant?.status, instance));
    xPos = addColumnHeading(sheet, 12, instance.$t('common.status') as string, xPos);

    const hasSalutation = participants.filter(p => p.salutation || p.result?.response.salutation).length > 0;
    if (registration.salutation || hasSalutation) {
        participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = translateSalutation(participant.salutation || participant.result?.response.salutation, instance));
        xPos = addColumnHeading(sheet, 8, instance.$t('common.salutation') as string, xPos);
    }

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.firstname);
    xPos = addColumnHeading(sheet, 15, instance.$t('common.firstname') as string, xPos);

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.lastname);
    xPos = addColumnHeading(sheet, 15, instance.$t('common.lastname') as string, xPos);

    const hasAddress = participants.filter(p => p.addressCity || p.result?.response.addressCity).length > 0
        || participants.filter(p => p.addressStreet || p.result?.response.addressStreet).length > 0;
    if (registration.address || hasAddress) {
        participants.forEach((participant, i) => {
            sheet.getCell(i + 3, xPos).value = participant.addressStreet || participant.result?.response.addressStreet || '-';
            sheet.getCell(i + 3, xPos + 1).value = participant.addressCity || participant.result?.response.addressCity || '-';
        });
        xPos = addColumnHeading(sheet, 20, instance.$t('common.streetAndNumber') as string, xPos);
        xPos = addColumnHeading(sheet, 20, instance.$t('common.zipCodeAndCity') as string, xPos);
    }

    const hasCompany = participants.filter(p => p.company || p.result?.response.company).length > 0;
    if (registration.company || hasCompany) {
        participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.company || participant.result?.response.company || '-');
        xPos = addColumnHeading(sheet, 29, instance.$t('common.company') as string, xPos);
    }

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.email || participant.result?.response.email);
    xPos = addColumnHeading(sheet, 40, instance.$t('common.emailAddress') as string, xPos);

    const hasPartnerId = participants.filter(p => p.partnerId || p.result?.response.partnerId).length > 0;
    if (registration.partnerId || hasPartnerId) {
        participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.partnerId || participant.result?.response.partnerId || '-');
        xPos = addColumnHeading(sheet, 15, instance.$t('common.partnerID') as string, xPos);
    }

    const hasVvId = participants.filter(p => p.vvId || p.result?.response.vvId).length > 0;
    if (registration.vvId || hasVvId) {
        participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = participant.vvId || participant.result?.response.vvId || '-');
        xPos = addColumnHeading(sheet, 22, instance.$t('forms.gutBeratenID') as string, xPos);
    }

    if (registration.fields) {
        registration.fields.filter(field => field.type !== "CHECKBOX")
            .sort((a, b) => a.idx - b.idx)
            .forEach((field, i) => {
                participants.forEach((participant, j) => {
                    if (participant.values) {
                        const fieldValue = participant.values.find(fieldValue => fieldValue.fieldId === field.id);
                        sheet.getCell(j + 3, xPos).value = fieldValue && fieldValue.value ? fieldValue.value : '-';
                    } else {
                        sheet.getCell(j + 3, xPos).value = '-';
                    }
                })
                xPos = addColumnHeading(sheet, 30, field.title, xPos, `(${instance.$t('conference.extraFieldAFI', {i: i + 1})})`);
            })
        registration.fields.filter(field => field.type === "CHECKBOX")
            .sort((a, b) => a.idx - b.idx)
            .forEach((field, i) => {
                participants.forEach((participant, j) => {
                    if (participant.values) {
                        const fieldValue = participant.values.find(fieldValue => fieldValue.fieldId === field.id);
                        sheet.getCell(j + 3, xPos).value = fieldValue && fieldValue.value === 'true' ? '✓' : ' ';
                        sheet.getCell(j + 3, xPos).alignment = {horizontal: 'center'};
                    } else {
                        sheet.getCell(j + 3, xPos).value = '-';
                    }
                })
                xPos = addColumnHeading(sheet, 20, field.title, xPos, `(${instance.$t('conference.checkboxAFI', {i: i + 1})})`);
            })
    }
    if (registration.customFields) {
        registration.customFields.forEach((field, i) => {
            participants.forEach((participant, j) => {
                if (participant.customValues) {
                    const fieldValue = participant.customValues.find(fieldValue => fieldValue.fieldId === field.id);
                    sheet.getCell(j + 3, xPos).value = fieldValue && fieldValue.value ? fieldValue.value : '-';
                } else {
                    sheet.getCell(j + 3, xPos).value = '-';
                }
            })
            xPos = addColumnHeading(sheet, 30, field.title, xPos, `(${instance.$t('conference.customFieldAFI', {i: i + 1})})`);
        })
    }


    if (survey.participantDataToSave.customFields) {
        survey.participantDataToSave.customFields.forEach((field, i) => {
            participants.forEach((participant, j) => {
                if (participant.result?.response.customFieldValues) {
                    const value = participant.result.response.customFieldValues.find(value => value.id === field.id);
                    sheet.getCell(j + 3, xPos).value = value && value.value ? value.value : '-';
                } else {
                    sheet.getCell(j + 3, xPos).value = '-';
                }
            });
            xPos = addColumnHeading(sheet, 30, field.caption, xPos, `(${instance.$t('conference.extraFieldUFI', {i: i + 1})})`);
        })
    }
    if (survey.participantDataToSave.customCheckboxes) {
        survey.participantDataToSave.customCheckboxes.forEach((field, i) => {
            participants.forEach((participant, j) => {
                if (participant.result?.response.customCheckboxValues) {
                    const value = participant.result.response.customCheckboxValues.find(value => value.id === field.id);
                    sheet.getCell(j + 3, xPos).value = value && value.value === 'true' ? '✓' : ' ';
                } else {
                    sheet.getCell(j + 3, xPos).value = '-';
                }
            });
            xPos = addColumnHeading(sheet, 30, field.caption, xPos, `(${instance.$t('conference.checkboxUFI', {i: i + 1})})`);
        })
    }

    if (survey.customFields) {
        survey.customFields.forEach((field, i) => {
            participants.forEach((participant, j) => {
                if (participant.result?.response.customValidatedFieldValues) {
                    const value = participant.result.response.customValidatedFieldValues.find(value => value.fieldId === field.id);
                    sheet.getCell(j + 3, xPos).value = value && value.value ? value.value : '-';
                } else {
                    sheet.getCell(j + 3, xPos).value = '-';
                }
            });
            xPos = addColumnHeading(sheet, 30, field.title, xPos, `(${instance.$t('conference.customFieldUFI', {i: i + 1})})`);
        })
    }

    const uniqueUrlParamKeys = Array.from(
      new Set(
        participants
          .flatMap(participant => participant.customUrlParams || [])
          .map(p => p.key)
      )
    )

    uniqueUrlParamKeys.forEach((key, i) => {
        participants.forEach((participant, j) => {
            const param = participant.customUrlParams?.find(p => p.key === key);
            sheet.getCell(j + 3, xPos).value = param?.value || '-';
            sheet.getCell(j + 3, xPos).alignment = {horizontal: 'center'};
        })
        xPos = addColumnHeading(sheet, 25, key, xPos);
    });

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = dateToDateTimeString(participant.dateCreated));
    xPos = addColumnHeading(sheet, 22, instance.$t('conference.registrationTimeAF') as string, xPos);

    participants.forEach((participant, i) => sheet.getCell(i + 3, xPos).value = dateToDateTimeString(participant.result?.dateCreated));
    xPos = addColumnHeading(sheet, 22, instance.$t('conference.registrationTimeUF') as string, xPos);

    const regDateGenerateCert = registration.regDates.find(date => date.id === dateId)?.generateCert;
    console.log('regDateGenerateCert', regDateGenerateCert)
    if (regDateGenerateCert) {
        participants.forEach((participant, i) => {
            let value = '-';
            if (participant.certInfo) {
                const certInfo = participant.certInfo.find(certI => certI.regDateId === dateId);
                if (certInfo) {
                    value = getDateSent(certInfo.dateCertMailSent, certInfo.dateCertMailToAllSent, instance);
                }
            }
            sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
            sheet.getCell(i + 3, xPos).value = value;
        });
        xPos = addColumnHeading(sheet, 25, instance.$t('conference.certificateSentAF') as string, xPos, instance.$t('forms.timestamp') as string, true);
    }

    if (registration.doi && registration.doi.type !== 'DISABLED') {
        participants.forEach((participant, i) => {
            if (registration.doi?.type === 'CONFERENCE') {
                sheet.getRow(i + 3).font = participant.dateDoi ? defaultFontLight : notConfirmedFont;
                colorArea(sheet, {r: i + 3, c: 1}, {r: i + 3, c: xPos}, participant.dateDoi ? undefined : 'D9D9D9');
            }
            sheet.getCell(i + 3, xPos).value = dateToDateTimeString(participant.dateDoi);
        });
        xPos = addColumnHeading(sheet, 22, instance.$t('forms.doiVerified') as string, xPos);
    }

    colorArea(sheet, {r: 1, c: fixedColumns}, {r: 2, c: xPos - 1}, 'DDEBF7', true);
    createOuterBorder(sheet, {r: 3, c: fixedColumns}, {r: participants.length + 2, c: xPos - 1});
    createOuterBorder(sheet, {r: 1, c: fixedColumns}, {r: 2, c: xPos - 1});
    fillVerticalStripe(sheet, xPos, participants.length + 3);
    return (xPos + 1);
}

function addSurveyParticipantsData(sheet: ExcelJS.Worksheet, survey: Survey, responses: Array<SurveyResponse>, instance: Vue): number {
    sheet.getRow(0).border
    sheet.getRow(1).font = {bold: true};
    let xPos = 1;

    responses.forEach((response, i) => sheet.getCell(i + 3, xPos).value = dateToDateTimeString(response.dateCreated));
    xPos = addColumnHeading(sheet, 20, instance.$t('forms.registrationTime') as string, xPos);

    const dataToSave = survey.participantDataToSave;

    // Add all salutations if present in survey
    if (dataToSave.salutation.show) {
        responses.forEach((response, i) => sheet.getCell(i + 3, xPos).value = translateSalutation(response.salutation, instance))
        xPos = addColumnHeading(sheet, 8, instance.$t('common.salutation') as string, xPos);
    }
    // Add names if participant data is saved in survey
    if (survey.saveParticipantData || dataToSave.name.show) {
        responses.forEach((response, i) => {
            sheet.getCell(i + 3, xPos).value = response.firstname ? response.firstname : '-';
            sheet.getCell(i + 3, xPos + 1).value = response.lastname ? response.lastname : '-';
        })
        xPos = addColumnHeading(sheet, 15, instance.$t('common.firstname') as string, xPos);
        xPos = addColumnHeading(sheet, 18, instance.$t('common.lastname') as string, xPos);
    }

    // Fill color & fixate columns and add border
    const fixedColumns = xPos;
    colorArea(sheet, {r: 1, c: 1}, {r: 2, c: fixedColumns - 1}, 'DDEBF7', true);
    createOuterBorder(sheet, {r: 1, c: 1}, {r: 2, c: fixedColumns - 1});
    createOuterBorder(sheet, {r: 3, c: 1}, {r: responses.length + 2, c: fixedColumns - 1});
    sheet.views = [{state: 'frozen', ySplit: 2, xSplit: fixedColumns - 1}];

    // Add address info if visible in survey
    const hasAddress = responses.filter(r => r.addressStreet).length > 0 || responses.filter(r => r.addressCity).length > 0;
    if (dataToSave.address.show || hasAddress) {
        responses.forEach((response, i) => {
            sheet.getCell(i + 3, xPos).value = response.addressStreet ? response.addressStreet : '-';
            sheet.getCell(i + 3, xPos + 1).value = response.addressCity ? response.addressCity : '-';
        })
        xPos = addColumnHeading(sheet, 25, instance.$t('common.streetAndNumber') as string, xPos);
        xPos = addColumnHeading(sheet, 25, instance.$t('common.zipCodeAndCity') as string, xPos);
    }

    const hasCompany = responses.filter(r => r.company).length > 0;
    if (dataToSave.company.show || hasCompany) {
        responses.forEach((response, i) => sheet.getCell(i + 3, xPos).value = response.company ? response.company : '-')
        xPos = addColumnHeading(sheet, 30, instance.$t('common.company') as string, xPos);
    }

    // Add phone info if visible in survey
    const hasPhone = responses.filter(r => r.phone).length > 0;
    if (survey.saveParticipantData || dataToSave.phone.show || hasPhone) {
        responses.forEach((response, i) => {
            sheet.getCell(i + 3, xPos).value = response.phone ? response.phone : '-';
        })
        xPos = addColumnHeading(sheet, 20, instance.$t('common.phoneNumber') as string, xPos);
    }

    // Add mail info if visible in survey
    const hasMail = responses.filter(r => r.email).length > 0;
    if (survey.saveParticipantData || dataToSave.email.show || hasMail) {
        responses.forEach((response, i) => {
            sheet.getCell(i + 3, xPos).value = response.email ? response.email : '-';
        })
        xPos = addColumnHeading(sheet, 25, instance.$t('common.emailAddress') as string, xPos);
    }

    const hasPartnerId = responses.filter(r => r.partnerId).length > 0;
    if (dataToSave.partnerId.show || hasPartnerId) {
        responses.forEach((response, i) => sheet.getCell(i + 3, xPos).value = response.partnerId ? response.partnerId : '-')
        xPos = addColumnHeading(sheet, 12, instance.$t('common.partnerID') as string, xPos);
    }

    const hasVvId = responses.filter(r => r.vvId).length > 0;
    if (dataToSave.vvId.show || hasVvId) {
        responses.forEach((response, i) => sheet.getCell(i + 3, xPos).value = response.vvId ? response.vvId : '-')
        xPos = addColumnHeading(sheet, 20, instance.$t('forms.gutBeratenID') as string, xPos);
    }

    if (dataToSave.customFields) {
        dataToSave.customFields.forEach((field, i) => {
            responses.forEach((response, j) => {
                if (response.customFieldValues) {
                    const fieldValue = response.customFieldValues.find(fieldValue => fieldValue.id === field.id);
                    sheet.getCell(j + 3, xPos).value = fieldValue && fieldValue.value ? fieldValue.value : '-';
                } else {
                    sheet.getCell(j + 3, xPos).value = '-';
                }
            })
            xPos = addColumnHeading(sheet, 25, field.caption, xPos, `(${instance.$t('forms.extraFieldN', {number: i + 1}) as string})`);
        })
    }

    if (dataToSave.customCheckboxes) {
        dataToSave.customCheckboxes.forEach((field, i) => {
            responses.forEach((response, i) => {
                if (response.customCheckboxValues) {
                    const fieldValue = response.customCheckboxValues.find(fieldValue => fieldValue.id === field.id);
                    sheet.getCell(i + 3, xPos).value = fieldValue && fieldValue.value === 'true' ? '✓' : ' ';
                    sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
                } else {
                    sheet.getCell(i + 3, xPos).value = ' ';
                }
            })
            xPos = addColumnHeading(sheet, 25, field.caption, xPos, `(${instance.$t('forms.checkboxN', {number: i + 1})})`);
        })
    }
    if (survey.customFields) {
        survey.customFields.forEach((customField) => {
            responses.forEach((resp, i) => {
                const fieldValue = resp.customValidatedFieldValues?.find(field => field.fieldId === customField.id);
                sheet.getCell(i + 3, xPos).value = fieldValue?.value || '-';
                sheet.getCell(i + 3, xPos).alignment = {horizontal: 'center'};
            })
            xPos = addColumnHeading(sheet, 25, customField.title, xPos, customField.description);
        })
    }

    const hasComment = responses.filter(r => r.comment).length > 0;
    if (dataToSave.comment.show || hasComment) {
        responses.forEach((response, i) => sheet.getCell(i + 3, xPos).value = response.comment ? response.comment : '-')
        xPos = addColumnHeading(sheet, 20, instance.$t('common.notes') as string, xPos);
    }

    const uniqueUrlParamKeys = Array.from(
      new Set(
        responses.flatMap(r => r.customUrlParams || [])
          .map(p => p.key)
      )
    )

    uniqueUrlParamKeys.forEach((key, i) => {
        responses.forEach((response, j) => {
            const param = response.customUrlParams?.find(p => p.key === key);
            sheet.getCell(j + 3, xPos).value = param?.value || '-';
            sheet.getCell(j + 3, xPos).alignment = {horizontal: 'center'};
        })
        xPos = addColumnHeading(sheet, 25, key, xPos);
    });


    colorArea(sheet, {r: 1, c: fixedColumns}, {r: 2, c: xPos - 1}, 'DDEBF7', true);
    createOuterBorder(sheet, {r: 3, c: fixedColumns}, {r: responses.length + 2, c: xPos - 1});
    createOuterBorder(sheet, {r: 1, c: fixedColumns}, {r: 2, c: xPos - 1});
    fillVerticalStripe(sheet, xPos, responses.length + 3);
    return (xPos + 1);
}

function saveXLSX(workbook: ExcelJS.Workbook, filename: string) {
    workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        saveAs(blob, filename);
    });
}

function addColumnHeading(sheet: ExcelJS.Worksheet, width: number | null, title: string, xPos: number, subtitle: string | number = '', centered = false, subtitleBold = false): number {
    sheet.getColumn(xPos).style.alignment = {horizontal: centered ? 'center' : 'left'};
    if (width) sheet.getColumn(xPos).width = width;
    sheet.getCell(1, xPos).value = title;
    if (subtitle) {
        sheet.getCell(2, xPos).value = subtitle;
        sheet.getCell(2, xPos).font = {bold: subtitleBold};
    }
    return (xPos + 1)
}

function addQuestionTitleAndEvaluation(result: SurveyResult, question: SurveyQuestion, yStart: number, sheet: ExcelJS.Worksheet, instance: Vue) {
    if (result.questionResults) {
        const questionResult = result.questionResults.find(result => result.questionId === question.id);

        const resultString = instance.$t('forms.reachedPointsResult', questionResult ? {
            reached: questionResult.reachedPoints,
            total: questionResult.totalPoints
        } : {reached: '?', total: '?'}) as string

        yStart = addRow(sheet, yStart, question.title, resultString, false);
    } else {
        yStart = addRow(sheet, yStart, question.title, ' ', false);
    }
    sheet.getCell(yStart - 1, 5).style.alignment = {vertical: 'top', horizontal: 'center'};
    yStart++;
    return yStart;
}

function addCheckboxQuestionAndAnswers(sheet: ExcelJS.Worksheet, question: SurveyQuestion, answers: Array<SurveyResponseAnswer>, result: SurveyResult, questionNumber: number, yStart: number, instance: Vue): number {
    yStart++;
    const start = yStart + 1;
    addBlockTitle(sheet, yStart, instance.$t('forms.questionMultipleChoice', {questionNumber: questionNumber}) as string);
    yStart += 2;

    question.checkboxQuestionOptions.sort((a, b) => a.idx - b.idx);
    const answer = answers ? answers.find(answer => answer.question.id === question.id) : null;

    sheet.getCell(yStart, 3).font = {bold: true};
    sheet.getCell(yStart, 5).font = {bold: true};

    if (answer && answer.values) {
        yStart = addQuestionTitleAndEvaluation(result, question, yStart, sheet, instance);

        question.checkboxQuestionOptions.forEach((option, index) => {
            if (option.id && answer.values?.includes(option.id)) {
                const pointsString = question.evaluate
                    ? option.rightAnswer ? `+${answer.question.points}` : `-${answer.question.points}`
                    : ' ';
                yStart = addEvaluatedRow(sheet, yStart, option.caption, pointsString, question.evaluate ? option.rightAnswer : null, true, index !== 0);
            } else {
                const pointsString = question.evaluate
                    ? option.rightAnswer ? `-${answer.question.points}` : `+${answer.question.points}`
                    : ' ';
                yStart = addEvaluatedRow(sheet, yStart, option.caption, pointsString, question.evaluate ? option.rightAnswer : null, false, index !== 0
                );
            }
        })
    } else {
        yStart = addRow(sheet, yStart, question.title, ' ', false);
        yStart++;

        question.checkboxQuestionOptions.forEach((option, index) => {
            yStart = addEvaluatedRow(sheet, yStart, option.caption, ' ', question.evaluate ? option.rightAnswer : null, false, index !== 0);
        })
    }

    colorArea(sheet, {r: start, c: 1}, {r: yStart, c: 6}, 'FFF2CC');
    createOuterBorder(sheet, {r: start, c: 1}, {r: yStart, c: 6});
    return (yStart + 1);
}

function addRadioQuestionAndAnswer(sheet: ExcelJS.Worksheet, question: SurveyQuestion, answers: Array<SurveyResponseAnswer>, result: SurveyResult, questionNumber: number, yStart: number, instance: Vue): number {
    yStart++;
    const start = yStart + 1;
    addBlockTitle(sheet, yStart, instance.$t('forms.questionSingleChoice', {questionNumber: questionNumber}) as string);
    yStart += 2;

    question.radioQuestionOptions.sort((a, b) => a.idx - b.idx);
    const answer = answers ? answers.find(answer => answer.question.id === question.id) : null;

    sheet.getCell(yStart, 3).font = {bold: true};
    sheet.getCell(yStart, 5).font = {bold: true};

    if (answer && answer.values) {
        yStart = addQuestionTitleAndEvaluation(result, question, yStart, sheet, instance);

        const answerId = answer.values[0];
        question.radioQuestionOptions.forEach((option, index) => {
            if (option.id === answerId) {
                yStart = addEvaluatedRow(sheet, yStart, option.caption,
                    question.evaluate ? (option.rightAnswer ? answer.question.points : '0') : ' ',
                    question.evaluate ? option.rightAnswer : null, true, index !== 0);
            } else {
                yStart = addEvaluatedRow(sheet, yStart, option.caption, ' ',
                    question.evaluate ? option.rightAnswer : null, false, index !== 0);
            }
        })
    } else {
        yStart = addRow(sheet, yStart, question.title, ' ', false);
        yStart++;

        question.radioQuestionOptions.forEach((option, index) => {
            yStart = addEvaluatedRow(sheet, yStart, option.caption, ' ', question.evaluate ? option.rightAnswer : null, false, index !== 0);
        })
    }

    colorArea(sheet, {r: start, c: 1}, {r: yStart, c: 6}, 'FFF2CC');
    createOuterBorder(sheet, {r: start, c: 1}, {r: yStart, c: 6});
    return (yStart + 1);
}

function addMultipleTextQuestionAndAnswers(sheet: ExcelJS.Worksheet, question: SurveyQuestion, answers: SurveyResponseAnswer[], questionNumber: number, yStart: number, instance: Vue): number {
    yStart++;
    const start = yStart + 1;
    addBlockTitle(sheet, yStart, instance.$t('forms.questionMultipleTextAndAnswer', {questionNumber: questionNumber}) as string);
    yStart += 2;

    sheet.getCell(yStart, 3).font = {bold: true};
    yStart = addRow(sheet, yStart, question.title, ' ', false);
    yStart++;

    question.multipleTextQuestionOptions.sort((a, b) => a.idx - b.idx);
    const answer = answers ? answers.find(answer => answer.question.id === question.id) : null;

    if (answer) {
        question.multipleTextQuestionOptions.forEach((option, index) => {
            if (option.id) {
                const value = answer.valueMap ? answer.valueMap[option.id] : '-';
                yStart = addRow(sheet, yStart, option.caption, value, index !== 0, 35);
            }
        })
    } else {
        question.multipleTextQuestionOptions.forEach((option, index) => {
            yStart = addRow(sheet, yStart, option.caption, '-', index !== 0, 35);
        })
    }

    colorArea(sheet, {r: start, c: 1}, {r: yStart, c: 6}, 'FFF2CC');
    createOuterBorder(sheet, {r: start, c: 1}, {r: yStart, c: 6});
    return (yStart + 1);
}

function addTextQuestionAndAnswer(sheet: ExcelJS.Worksheet, question: SurveyQuestion, answers: SurveyResponseAnswer[], questionNumber: number, yStart: number, instance: Vue): number {
    yStart++;
    const start = yStart + 1;
    addBlockTitle(sheet, yStart, instance.$t('forms.questionNWithBox', {questionNumber: questionNumber}) as string);
    yStart += 2;

    sheet.getCell(yStart, 3).font = {bold: true};

    // Check if the participant has answered this question. Our persistence does not return answered questions
    const answer = answers ? answers.find(answer => answer.question.id === question.id) : null;

    if (answer) {
        yStart = addRow(sheet, yStart, answer.question.title, answer.value, false, 50);
    } else {
        yStart = addRow(sheet, yStart, question.title, '-', false, 30);
    }

    colorArea(sheet, {r: start, c: 1}, {r: yStart, c: 6}, 'FFF2CC');
    createOuterBorder(sheet, {r: start, c: 1}, {r: yStart, c: 6});
    return (yStart + 1);
}

function getDateSent(dateA: number | undefined, dateB: number | undefined, instance?: Vue) {
    return (dateA || dateB)
        ? formatDateCreated(Math.max(dateA || 0, dateB || 0), instance)
        : '-';
}

function addResultDetails(sheet: ExcelJS.Worksheet, survey: Survey, result: SurveyResult, yStart: number, instance: Vue): number {
    yStart++;
    const start = yStart + 1;
    addBlockTitle(sheet, yStart, instance.$t('forms.evaluation') as string);
    yStart += 2;

    if (result.totalPoints > 0) {
        yStart = addRow(sheet, yStart, instance.$t('forms.reachedPoints') as string, instance.$t('forms.points', {
            reached: result.reachedPoints,
            achieved: result.totalPoints
        }) as string, false);
        yStart = addRow(sheet, yStart, instance.$t('forms.questionsCorrect') as string, `${Math.round(result.totalPoints > 0 ? (result.reachedPoints / result.totalPoints) * 100 : 0)}%`);
        if (survey.certificateData.multipleChoiceCreditsCondition) {
            yStart = addRow(sheet, yStart, instance.$t('forms.passed') as string + ' (' + instance.$t('forms.fromNPercent', {percent: survey.certificateData.multipleChoiceCreditsCondition}) as string + ')', result.passed ? instance.$t('common.yes') as string : instance.$t('common.no') as string);
        }

        if (survey.certificateData.participationDurationCondition) {
            yStart = addRow(sheet, yStart, `${instance.$t('forms.passed') as string} (${instance.$t('forms.participation') as string} > ${instance.$t('common.nMinutes', {number: survey.certificateData.participationDurationCondition}) as string})`, result.participated ? instance.$t('common.yes') as string : instance.$t('common.no') as string);
        }
    }

    if (survey.generateCert) {
        yStart = addRow(sheet, yStart, instance.$t('forms.certificateSentShort') as string, getDateSent(result.response.dateCertMailSent, result.response.dateCertMailToAllSent, instance), yStart - start !== 1);
    }

    colorArea(sheet, {r: start, c: 1}, {r: yStart, c: 6}, 'DDEBF7');
    createOuterBorder(sheet, {r: start, c: 1}, {r: yStart, c: 6});
    return (yStart + 1);
}

function addBlockTitle(sheet: ExcelJS.Worksheet, yPos: number, title: string) {
    sheet.getCell(yPos, 3).value = title;
    sheet.getCell(yPos, 3).style = {alignment: {vertical: 'middle'}};
    sheet.getCell(yPos, 3).font = {bold: true};
    sheet.getRow(yPos).height = 20;
}

function addRow(
    sheet: ExcelJS.Worksheet,
    yPos: number,
    key: string,
    value: string | number | undefined | null,
    borderDivider = true,
    rowHeight = 15
): number {
    sheet.getRow(yPos).height = rowHeight;
    sheet.getCell(yPos, 3).value = key;
    sheet.getCell(yPos, 3).style.alignment = {wrapText: true, vertical: 'top', horizontal: 'left'};
    sheet.getCell(yPos, 5).value = value ? value : '-';
    sheet.getCell(yPos, 5).style.alignment = {wrapText: true, vertical: 'top', horizontal: 'left'};
    if (borderDivider) {
        const border: ExcelJS.Border = {
            style: 'dotted',
            color: {argb: 'FF000000'}
        };
        sheet.getCell(yPos, 3).border = {top: border};
        sheet.getCell(yPos, 4).border = {top: border};
        sheet.getCell(yPos, 5).border = {top: border};
    }
    return (yPos + 1);
}

function addEvaluatedRow(
    sheet: ExcelJS.Worksheet,
    yPos: number,
    key: string,
    value: string | number | undefined | null,
    isCorrect: boolean | null,
    hasX: boolean,
    borderDivider = true,
    rowHeight = 15
): number {

    addRow(sheet, yPos, key, value, borderDivider, rowHeight);
    sheet.getCell(yPos, 4).style.alignment = {horizontal: 'center'};
    sheet.getCell(yPos, 5).style.alignment = {horizontal: 'center'};
    if (isCorrect === true) {
        sheet.getCell(yPos, 3).font = correctFont;
        sheet.getCell(yPos, 4).font = correctFont;
    } else if (isCorrect === false) {
        sheet.getCell(yPos, 4).font = incorrectFont;
    } else {
        sheet.getCell(yPos, 4).font = defaultFont;
    }
    sheet.getCell(yPos, 4).value = hasX ? 'X' : '';
    return (yPos + 1)
}

function addParticipantDetails(sheet: ExcelJS.Worksheet, survey: Survey, result: SurveyResult, instance: Vue): number {
    addBlockTitle(sheet, 3, instance.$t('forms.participantDetails') as string);
    let height = 5;

    height = addRow(sheet, height, instance.$t('forms.registrationTime') as string, formatDateCreated(result.dateCreated), false);
    const dataToSave = survey.participantDataToSave;
    const response = result.response;
    if (dataToSave.salutation.show) height = addRow(sheet, height, instance.$t('common.salutation') as string, translateSalutation(response.salutation, instance));
    if (survey.saveParticipantData || dataToSave.name.show || !!response.firstname) {
        height = addRow(sheet, height, instance.$t('common.firstname') as string, response.firstname);
    }
    if (survey.saveParticipantData || dataToSave.name.show || !!response.lastname) {
        height = addRow(sheet, height, instance.$t('common.lastname') as string, response.lastname);
    }
    if (dataToSave.company.show || !!response.company) height = addRow(sheet, height, instance.$t('common.company') as string, response.company);
    if (dataToSave.address.show || !!response.addressStreet || !!response.addressCity) {
        height = addRow(sheet, height, instance.$t('common.streetAndNumber') as string, response.addressStreet);
        height = addRow(sheet, height, instance.$t('common.zipCodeAndCity') as string, response.addressCity);
    }
    if (survey.saveParticipantData || dataToSave.email.show || !!response.email) {
        height = addRow(sheet, height, instance.$t('common.emailAddress') as string, response.email);
    }
    if (survey.saveParticipantData || dataToSave.phone.show || !!response.phone) {
        height = addRow(sheet, height, instance.$t('common.callNumber') as string, response.phone);
    }
    if (dataToSave.partnerId.show || !!response.partnerId) {
        height = addRow(sheet, height, instance.$t('common.partnerID') as string, response.partnerId);
    }
    if (dataToSave.vvId.show || !!response.vvId) {
        height = addRow(sheet, height, instance.$t('forms.gutBeratenID') as string, response.vvId);
    }
    if (dataToSave.comment.show || !!response.comment) {
        height = addRow(sheet, height, instance.$t('common.notes') as string, response.comment);
    }

    if (dataToSave.customFields) {
        dataToSave.customFields.forEach((field, i) => {
            if (response.customFieldValues) {
                const fieldValue = response.customFieldValues.find(fieldValue => fieldValue.id === field.id);
                height = addRow(sheet, height, `${instance.$t('forms.extraField') as string} ${i + 1}: ${field.caption}`, (fieldValue && fieldValue.value) ? fieldValue.value : '-');
            } else {
                height = addRow(sheet, height, `${instance.$t('forms.extraField') as string} ${i + 1}: ${field.caption}`, '-');
            }
        })
    }
    if (survey.customFields) {
        survey.customFields.forEach((field, i) => {
            if (response.customValidatedFieldValues) {
                const fieldValue = response.customValidatedFieldValues.find(fieldValue => fieldValue.fieldId === field.id);
                height = addRow(sheet, height, field.title, (fieldValue && fieldValue.value) ? fieldValue.value : '-');
            } else {
                height = addRow(sheet, height, field.title, '-');
            }
        })
    }
    if (dataToSave.customCheckboxes) {
        dataToSave.customCheckboxes.forEach((checkbox, i) => {
            if (response.customCheckboxValues) {
                const checkboxValue = response.customCheckboxValues.find(checkboxValue => checkboxValue.id === checkbox.id);
                height = addRow(sheet, height, `${instance.$t('forms.checkbox') as string} ${i + 1}: ${checkbox.caption}`, (checkboxValue && checkboxValue.value) === 'true' ? '✓' : ' ')
            } else {
                height = addRow(sheet, height, `${instance.$t('forms.checkbox') as string} ${i + 1}: ${checkbox.caption}`, ' ');
            }
        })
    }

    colorArea(sheet, {r: 4, c: 1}, {r: height, c: 6}, 'DDEBF7');
    createOuterBorder(sheet, {r: 4, c: 1}, {r: height, c: 6});
    return (height + 1);
}

function addExampleGradesTable(legendSheet: ExcelJS.Worksheet, instance: Vue) {

    // Merge cells and add text above and below table
    legendSheet.mergeCells(26, 3, 26, 7);
    legendSheet.mergeCells(41, 3, 41, 7);

    legendSheet.getCell(26, 3).value = instance.$t('forms.excelLegend.example') as string;
    legendSheet.getCell(26, 3).font = {bold: true};

    legendSheet.getCell(41, 3).value = instance.$t('forms.excelLegend.multipleChoiceDescriptionExample') as string;
    legendSheet.getCell(41, 3).font = {italic: true};

    // Table Header
    fillRow(
        legendSheet,
        [{r: 27, c: 3}, {r: 27, c: 4}, {r: 27, c: 5}, {r: 27, c: 6}, {r: 27, c: 7}],
        [instance.$t('forms.excelLegend.marked') as string, instance.$t('forms.excelLegend.notMarked') as string, instance.$t('forms.excelLegend.correct') as string, instance.$t('forms.excelLegend.incorrect') as string, instance.$t('forms.evaluation') as string],
        true,
        '5B9BD5',
        'FFFFFF');

    const and = instance.$t('common.and') as string;
    // Table content as array
    const exampleTableValues = [
        ['C, E', 'A, B, D', 5, 0, '5 - 0 = 5'],
        ['C, E ' + and + ' A', 'B, D', 4, 1, '4 - 1 = 3'],
        ['C, E ' + and + ' B', 'A, D', 4, 1, '4 - 1 = 3'],
        ['C, E ' + and + ' D', 'A, B', 4, 1, '4 - 1 = 3'],
        ['C', 'A, B, D, E', 4, 1, '4 - 1 = 3'],
        ['E', 'A, B, C, D', 4, 1, '4 - 1 = 3'],
        ['C ' + and + ' A', 'B, D, E', 3, 2, '3 - 2 = 1'],
        ['C ' + and + ' B', 'A, D, E', 3, 2, '3 - 2 = 1'],
        ['C ' + and + ' D', 'A, B, E', 3, 2, '3 - 2 = 1'],
        ['E ' + and + ' A', 'B, C, D', 3, 2, '3 - 2 = 1'],
        ['E ' + and + ' B', 'A, C, D', 3, 2, '3 - 2 = 1'],
        ['E ' + and + ' D', 'A, B, C', 3, 2, '3 - 2 = 1'],
        [instance.$t('forms.excelLegend.else_') as string, instance.$t('forms.excelLegend.else_') as string, '< 3', '> 2', 0],
    ];

    // Fill rows of example table
    exampleTableValues.forEach((values, i) => {
        i += 28;
        const cells: CellPosition[] = [{r: i, c: 3}, {r: i, c: 4}, {r: i, c: 5}, {r: i, c: 6}, {r: i, c: 7}]
        fillRow(
            legendSheet,
            cells,
            values,
            false,
            i % 2 == 0 ? 'FFFFFF' : 'DDEBF7');
    });
}

function addDescriptionText(legendSheet: ExcelJS.Worksheet, instance: Vue) {
    legendSheet.mergeCells(3, 3, 3, 7);
    legendSheet.getCell(3, 3).value = instance.$t('forms.excelLegend.singleChoiceTitle') as string;
    legendSheet.getCell(3, 3).font = {bold: true};

    legendSheet.mergeCells(5, 3, 8, 7);
    legendSheet.getCell(5, 3).value = instance.$t('forms.excelLegend.singleChoiceDescription') as string;
    legendSheet.getCell(5, 3).style.alignment = {horizontal: 'left', vertical: 'top', wrapText: true};

    legendSheet.mergeCells(10, 3, 10, 7);
    legendSheet.getCell(10, 3).value = instance.$t('forms.excelLegend.multipleChoiceTitle') as string;
    legendSheet.getCell(10, 3).font = {bold: true};

    legendSheet.mergeCells(12, 3, 18, 7);
    legendSheet.getCell(12, 3).value = instance.$t('forms.excelLegend.multipleChoiceDescription1') as string;
    legendSheet.getCell(12, 3).style.alignment = {horizontal: 'left', vertical: 'top', wrapText: true};

    legendSheet.mergeCells(20, 3, 21, 7);
    legendSheet.getCell(20, 3).value = instance.$t('forms.excelLegend.multipleChoiceDescription2') as string;
    legendSheet.getCell(20, 3).style.alignment = {horizontal: 'left', vertical: 'top', wrapText: true};

    legendSheet.mergeCells(23, 3, 24, 7);
    legendSheet.getCell(23, 3).value = instance.$t('forms.excelLegend.multipleChoiceDescription3') as string;
    legendSheet.getCell(23, 3).style.alignment = {horizontal: 'left', vertical: 'top', wrapText: true};
}

function addResponseLegendSheetToXLSX(workbook: ExcelJS.Workbook, instance: Vue, withAdditionalLegend = false): void {
    const legendSheet = workbook.addWorksheet(instance.$t('forms.excelLegend.evaluationAndLegend') as string);
    legendSheet.columns = [{width: 3}, {width: 3}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 18}, {width: 3}];
    legendSheet.properties.showGridLines = false;
    legendSheet.views = [{showGridLines: false}];

    createOuterBorder(legendSheet, {r: 2, c: 2}, {r: 42, c: 8});
    createOuterBorder(legendSheet, {r: 27, c: 3}, {r: 40, c: 7}, 'medium', '5B9BD5');

    addDescriptionText(legendSheet, instance);
    addExampleGradesTable(legendSheet, instance);

    if (withAdditionalLegend) addAdditionalLegend(legendSheet, instance);
}

function addAdditionalLegend(legendSheet: ExcelJS.Worksheet, instance: Vue): void {
    legendSheet.getColumn(10).width = 3;
    legendSheet.getColumn(11).width = 5;
    for (let i = 12; i < 18; i++) legendSheet.getColumn(i).width = 13;

    legendSheet.mergeCells(3, 11, 3, 18);
    legendSheet.getCell(3, 11).value = instance.$t('forms.excelLegend.legend') as string;
    legendSheet.getCell(3, 11).style.font = {bold: true};

    legendSheet.mergeCells(5, 11, 5, 18);
    legendSheet.getCell(5, 11).value = instance.$t('forms.excelLegend.abbSingleChoice') as string;

    legendSheet.mergeCells(6, 11, 6, 18);
    legendSheet.getCell(6, 11).value = instance.$t('forms.excelLegend.abbMultipleChoice') as string;

    legendSheet.mergeCells(8, 11, 8, 18);
    legendSheet.getCell(8, 11).value = instance.$t('forms.excelLegend.descriptionHead') as string;
    legendSheet.getCell(8, 11).style.font = {bold: true};

    legendSheet.getCell(9, 11).value = ' - ';
    legendSheet.getCell(9, 11).style.alignment = {horizontal: 'center'};
    legendSheet.mergeCells(9, 12, 9, 18);
    legendSheet.getCell(9, 12).value = instance.$t('forms.excelLegend.scFullPoints') as string;

    legendSheet.getCell(10, 11).value = ' - ';
    legendSheet.getCell(10, 11).style.alignment = {horizontal: 'center'};
    legendSheet.mergeCells(10, 12, 10, 18);
    legendSheet.getCell(10, 12).value = instance.$t('forms.excelLegend.mcTwoValues') as string;

    legendSheet.mergeCells(11, 12, 11, 18);
    legendSheet.getCell(11, 12).value = instance.$t('forms.excelLegend.rightMaxPoints') as string;

    legendSheet.mergeCells(13, 11, 13, 18);
    legendSheet.getCell(13, 11).value = instance.$t('forms.excelLegend.evaluationOnEnd') as string;
    legendSheet.getCell(13, 11).style.font = {bold: true};

    legendSheet.getCell(14, 11).value = ' - ';
    legendSheet.getCell(14, 11).style.alignment = {horizontal: 'center'};
    legendSheet.mergeCells(14, 12, 14, 18);
    legendSheet.getCell(14, 12).value = instance.$t('forms.excelLegend.tableHeadAllPoints') as string;

    legendSheet.mergeCells(15, 12, 15, 18);
    legendSheet.getCell(15, 12).value = instance.$t('forms.excelLegend.valuePassedFrom') as string;

    legendSheet.getCell(16, 11).value = ' - ';
    legendSheet.getCell(16, 11).style.alignment = {horizontal: 'center'};
    legendSheet.mergeCells(16, 12, 16, 18);
    legendSheet.getCell(16, 12).value = instance.$t('forms.excelLegend.resultPerParticipant') as string;

    createOuterBorder(legendSheet, {r: 2, c: 10}, {r: 42, c: 18});
}

function createOuterBorder(
    worksheet: ExcelJS.Worksheet,
    start: CellPosition,
    end: CellPosition,
    borderWidth: ExcelJS.BorderStyle = 'medium',
    borderColor = '000000'
): void {
    const border: ExcelJS.Border = {
        style: borderWidth,
        color: {argb: `FF${borderColor}`}
    };
    for (let i = start.r; i <= end.r; i++) {
        const leftBorderCell = worksheet.getCell(i, start.c);
        const rightBorderCell = worksheet.getCell(i, end.c)
        leftBorderCell.border = {
            ...leftBorderCell.border,
            left: border
        };
        rightBorderCell.border = {
            ...rightBorderCell.border,
            right: border
        };
    }

    for (let i = start.c; i <= end.c; i++) {
        const topBorderCell = worksheet.getCell(start.r, i);
        const bottomBorderCell = worksheet.getCell(end.r, i);
        topBorderCell.border = {
            ...topBorderCell.border,
            top: border
        };
        bottomBorderCell.border = {
            ...bottomBorderCell.border,
            bottom: border
        };
    }
}

const fillRow = (
    sheet: ExcelJS.Worksheet,
    cells: CellPosition[],
    values?: Array<string | number>,
    bold = false,
    bgColor = 'FFFFFF',
    fontColor = '000000'
) => {
    cells.map((key, index) => {
        sheet.getCell(key.r, key.c).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: `FF${bgColor}`}
        };
        sheet.getCell(key.r, key.c).font = {
            bold: bold,
            color: {argb: `FF${fontColor}`}
        };
        if (values) {
            sheet.getCell(key.r, key.c).value = values[index];
            sheet.getCell(key.r, key.c).style.alignment = {horizontal: 'left'};
        }
    })
}

function getNoBorders(color = 'FFFFFE'): Partial<ExcelJS.Borders> {
    return {
        top: {color: {argb: `FF${color}`}, style: 'thin'},
        bottom: {color: {argb: `FF${color}`}, style: 'thin'},
        left: {color: {argb: `FF${color}`}, style: 'thin'},
        right: {color: {argb: `FF${color}`}, style: 'thin'}
    };
}

function getFill(color = 'FFFFFE'): ExcelJS.Fill {
    return {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: `FF${color}`}
    };
}

function colorArea(
    sheet: ExcelJS.Worksheet,
    start: CellPosition,
    end: CellPosition,
    color = 'FFFFFF',
    removeGridlines = false
): void {
    for (let i = start.r; i <= end.r; i++) {
        for (let j = start.c; j <= end.c; j++) {
            sheet.getCell(i, j).fill = getFill(color);
            if (removeGridlines) sheet.getCell(i, j).style.border = getNoBorders(color)
        }
    }
}

const correctFont = {color: {argb: 'FF70AD47'}, bold: true};
const incorrectFont = {color: {argb: 'FFFF0000'}, bold: true};
const defaultFont = {color: {argb: 'FF000000'}, bold: true};
const defaultFontLight = {color: {argb: 'FF000000'}, bold: false};
const notConfirmedFont = {color: {argb: 'FF7F7F7F'}, bold: false};

export interface Column {
    header?: string,
    key?: string | number,
    width: number,
    alignment?: ExcelJS.Alignment
}

interface CellPosition {
    r: number,
    c: number
}
