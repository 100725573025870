












































import {Component, Prop} from 'vue-property-decorator';
import Base from './Base';

@Component({
	components: { }
})
export default class LoginParticipantMask extends Base {
    @Prop() validation!: any;
}
