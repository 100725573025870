












import {Component} from 'vue-property-decorator';
import AppLayout from './layouts/top/AppLayout.vue';
import LoginLayout from './layouts/top/LoginLayout.vue';
import AccountsService from './service/AccountsService';
import {Theme} from '@/models/Theme';
import Base from './views/Base';

const defaultLayout = 'app';

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: {
    'app-layout': AppLayout,
    'login-layout': LoginLayout
  }
})
export default class App extends Base {

  themeLoading = true;

  get layout(): string {
    return (
        (this.$store.getters.layout ||
            this.$route?.meta?.layout ||
            defaultLayout) + '-layout'
    );
  }

  async created(): Promise<void> {
    console.log("Start feature level", this.$store.getters.featureLevel);

    try {
      await this.setTheme();
    } catch (error) {
      console.error(error)
    } finally {
      this.themeLoading = false;
    }

  }

  setTheme(): Promise<void> {
    return AccountsService.getThemes(this.prod)
        .then(res => {
          const isArray = Array.isArray(res);
          let theme!: Theme | undefined;
          let host = window.location.hostname;

          if (typeof res === 'string') {
            this.toast('Themes enthält ein Fehler', 'danger');
            return;
          }

          if (isArray) {
            this.$store.commit("setAllThemes", res as Theme[])
            const themes = res as Theme[];
            theme = themes.find(th => {
              host = host.replaceAll(/.csn(?:dev)?.de/gm, '');
              return host === th.subdomain;
            });
            if (!theme) {
              theme = themes.find(th => th.subdomain.includes('default'));
              if (!theme) return;
            }
          } else {
            theme = res as Theme;
            if (!host.includes(theme.subdomain) && !theme.subdomain.includes('default')) {
              return;
            }
          }
          if (theme) {
            this.setI18n(theme);
            this.$store.commit("setBrand", this.setBrand(theme));
          }
        });
  }

  setBrand(theme: Theme): any {
    return {
      title: theme.subdomain,
      theme,
      css: !theme.color ? '' : `
				:root {
					${theme.color.primary ? '--w-primary:' + theme.color.primary + ';' : ''};
					${theme.color.primaryHover ? '--w-primary-hover:' + theme.color.primaryHover + ';' : ''}
					${theme.color.header ? '--w-header:' + theme.color.header + ';' : ''}
					${theme.color.primaryHeader ? '--w-primaryHeader:' + theme.color.primaryHeader + ';' : ''}
				}

				#login .login-tab,
				#login .login-tab .login-label,
				#login .login-tab:hover {
					color: ${theme.color.startTab ? theme.color.startTab : 'var(--w-header)'} !important;
				}

				.login-start {
					background-color: ${theme.color.start ? theme.color.start : 'var(--w-header)'} !important;
					border-color: ${theme.color.start ? theme.color.start : 'var(--w-header)'} !important;
				}

				.w-nav-item:active a.nav-link,
				.w-nav-item.active a.nav-link,
				.w-nav-item[active='true'] a.nav-link {
					color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'};
				}

				.w-nav-item:hover a.nav-link,
				.w-nav-item:active a.nav-link:hover,
				.w-nav-item.active a.nav-link:hover,
				.w-nav-item[active='true'] a.nav-link:hover  {
					color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'} !important;
				}

				.switch-btn.btn-primary {
          background-color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'} !important;
          border-color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'} !important;
        }

        .switch-btn.btn-primary:hover {
          background-color: ${theme.color.primaryHeaderHover ? theme.color.primaryHeaderHover : '#208a9d'} !important;
          border-color: ${theme.color.primaryHeaderHover ? theme.color.primaryHeaderHover : '#208a9d'} !important;
        }

				.notification {
					background-color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'};
				}

				.login-start:hover {
					background-color: ${theme.color.startHover ? theme.color.startHover : 'var(--w-header)'} !important;
					border-color: ${theme.color.startHover ? theme.color.startHover : 'var(--w-header)'} !important;
				}

				.navbar-dark .navbar-nav .nav-link:focus {
          color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'};
        }

        .w-dropdown-item a.dropdown-item .w-dropdown-item-icon {
          color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'};
        }

        .w-dropdown-item a.dropdown-item:active .w-dropdown-item-icon {
          color: #FFFFFF !important;
        }

        .w-dropdown-item a.dropdown-item.active,
        .w-dropdown-item a.dropdown-item:active {
          background-color: ${theme.color.primaryHeader ? theme.color.primaryHeader : '#27A6BD'} !important;
        }

				.text-start {
					color: ${theme.color.start ? theme.color.start : 'var(--w-primary)'} !important;
					border-color: ${theme.color.start ? theme.color.start : ''} !important;
				}

				.text-start:hover {
					color: ${theme.color.startHover ? theme.color.startHover : 'var(--w-primary)'} !important;
					border-color: ${theme.color.startHover ? theme.color.startHover : ''} !important;
				}

				.bg-primary {
					background-color: var(--w-primary) !important;
				}

				.text-primary {
					color: var(--w-primary) !important;
				}

				a, .btn-link, .btn-link:hover {
					color: var(--w-primary);
				}

				.btn-primary {
					${theme.color.primary ? 'background-color: var(--w-primary) !important;' : ''}
					${theme.color.primary ? 'border-color: var(--w-primary) !important;' : ''}
				}

				.btn-primary:hover {
				  ${theme.color.primaryHover ? 'background-color: var(--w-primary-hover) !important;' : ''}
				  ${theme.color.primaryHover ? 'border-color: var(--w-primary-hover) !important;' : ''}
				}

				.btn-header {
					background-color: var(--w-header) !important;
					border-color: var(--w-header) !important;
				}

				.badge-header {
					background-color: var(--w-header) !important;
				}

				.badge-primary {
					background-color: var(--w-primary) !important;
				}

				.custom-control-input:checked ~ .custom-control-label::before {
					background-color: var(--w-primary) !important;
					border-color: var(--w-primary) !important;
				}

				.list-group-item.active {
					background-color: var(--w-primary);
					border-color: var(--w-primary);
				}
			`
    }
  }

  setI18n(theme: Theme): void {
    if (theme.textbox) {
      const textboxDE = this.filterEmptyAttributes(theme.textbox);
      this.$i18n.mergeLocaleMessage('de', {textbox: textboxDE})
    }
    if (theme.textboxEN) {
      const textboxEN = this.filterEmptyAttributes(theme.textboxEN);
      this.$i18n.mergeLocaleMessage('en', {textbox: textboxEN})
    }
    if (theme.weblink) {
      const weblinkDE = this.filterEmptyAttributes(theme.weblink);
      this.$i18n.mergeLocaleMessage('de', {weblink: weblinkDE})
    }
    if (theme.weblinkEN) {
      const weblinkEN = this.filterEmptyAttributes(theme.weblinkEN);
      this.$i18n.mergeLocaleMessage('en', {weblink: weblinkEN})
    }
    if (theme.participantNamePlaceholder) {
      this.$i18n.mergeLocaleMessage('de', {login: {namePlaceholder: theme.participantNamePlaceholder}})
    }
    if (theme.participantNamePlaceholderEN) {
      this.$i18n.mergeLocaleMessage('en', {login: {namePlaceholder: theme.participantNamePlaceholderEN}})
    }
    if (!localStorage.language) this.$i18n.locale = theme.language ? theme.language : 'de';
  }

  filterEmptyAttributes(object: any): any {
    const keys = Object.keys(object)
    const result: any = {};
    keys.forEach(key => {
      const value = object[key];
      if (value && value !== '')
        result[key] = value;
    })
    return result;

  }

}
