






















































































































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import {Component, Watch} from 'vue-property-decorator';
import Base from '@/views/Base';
import NewContact from './NewContact.vue';
import EditableTable from './EditableTable.vue';
import GroupItem from './GroupItem.vue';
import WPagination from '@/components/WPagination.vue';
import {Validations} from 'vuelidate-property-decorators';
import {maxLength, required} from 'vuelidate/lib/validators';
import GroupsService, {GroupDeleteType} from '@/service/GroupsService';
import {Contact, GroupContact, SelectOption, TableContact} from '@/models/Contact';
import {Group} from '@/models/Group';
import ContactsService from '@/service/ContactsService';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import {ListOptions} from '@/models/Booking';
import {debounce} from 'lodash';
import {formatDateForFiles} from '@/utils/filters';

@Component({
  components: {
    HeaderLayout,
    SidebarLayout,
    NewContact,
    WPagination,
    EditableTable,
    GroupItem,
    WListOptions
  }
})
export default class AddressBook extends Base {
  newContactGroup = '';
  selectedGroup = '';
  groups: Group[] = [];
  newGroup = '';
  showGroupForm = false;
  currentGroupPage = 1;
  groupsPerPage = 10;
  toDeleteGroup: Group | null = null;
  visibilities = {
    mail: true,
    company: true
  };

  loadingGroups = false;
  loadingContacts = false;

  totalContacts = 0;
  totalRows = 0;
  totalPages = 1;
  currentTablePage = 1;
  order = 'dateCreated';
  directionDesc = false;

  tableOptions: ListOptions = {
    entryCount: 10,
    searchTerm: ''
  };

  rows: TableContact[] = [];
  selectedIds: string[] = [];

  @Validations()
  validations = {
    newGroup: {required, maxLength: maxLength(255)}
  };

  debounceGetContacts = debounce(this.getContacts, 200);

  get rowsLength(): number {
    return this.rows?.length || 0;
  }

  get selectedGroupString(): string {
    return this.selectedGroup ? this.$t('adressbook.inGroup', {group: this.selectedGroup}) as string : ''
  }

  get pagedGroups(): Group[] {
    return this.groups.filter(
        (v, i) =>
            i < this.groupsPerPage * this.currentGroupPage &&
            i >= this.groupsPerPage * (this.currentGroupPage - 1)
    );
  }

  get showPagination(): boolean {
    return this.tableOptions.entryCount <= this.totalRows;
  }

  get groupOptions(): SelectOption[] {
    const options = this.groups.map((g) => ({value: g.name, text: g.name}));
    return [{value: '', text: this.t('adressbook.noGroup')}, ...options];
  }

  get editMode(): boolean {
    return this.selectedIds.length > 0;
  }

  @Watch('currentTablePage')
  currentTablePageChanged(): void {
    if (!this.loadingContacts) {
      setTimeout(() => this.debounceGetContacts());
    }
  }

  @Watch('tableOptions.searchTerm')
  searchChanged(): void {
    this.debounceGetContacts();
  }

  @Watch('tableOptions.entryCount')
  entryCountChanged(): void {
    setTimeout(() => {
      this.currentTablePage = 1;
      this.debounceGetContacts();
    });
  }

  mounted(): void {
    this.init();
  }

  init(): void {
    this.getGroups();
    this.loadSessionStorage();
    this.debounceGetContacts();
  }

  setOrder(order: string): void {
    this.order = order;
    this.debounceGetContacts();
  }

  setDirection(directionDesc: boolean): void {
    this.directionDesc = directionDesc;
    this.debounceGetContacts();
  }

  updateItem(obj: {
    type: 'name' | 'telephone' | 'email';
    item: TableContact;
    newValue: string;
  }): void {
    const oldValue: string = obj.item[obj.type] as string;
    if (!obj.item.id || obj.newValue === oldValue) return;
    this.loadingContacts = true;

    const newItem = {...obj.item};
    newItem[obj.type] = obj.newValue;

    ContactsService.updateContact(obj.item.id, newItem)
        .then(() => obj.item[obj.type] = obj.newValue)
        .catch(e => {
          if (obj.type === 'telephone') this.toast(this.t('common.phoneInvalid'), 'danger');
          else this.showNetworkError(e);
        })
        .finally(() => this.loadingContacts = false);
  }

  addGroupToContact(obj: { newId: string; item: TableContact }): void {
    if (!obj.item.id || !obj.newId) return;
    this.loadingContacts = true;
    ContactsService.updateContactGroup(obj.item.id, obj.newId)
        .then(updatedItem => {
          if (!obj.item.groupIds?.includes(obj.newId)) obj.item.groupIds?.push(obj.newId);

          obj.item = {
            ...obj.item,
            ...updatedItem
          };
          this.getGroups();
        })
        .catch(this.showNetworkError)
        .finally(() => this.loadingContacts = false);
  }

  toggleContactGroups(value: boolean, groupId: string): void {
    const items =
        this.selectedIds.map((id) => this.rows.find((item) => item.id === id)) ||
        [];
    Promise.all(
        items.map((item: TableContact | undefined) => {
          if (item && item.id) {
            const itemGroupIds = item.groupIds || [];
            if (value && !itemGroupIds.includes(groupId)) {
              return ContactsService.updateContactGroup(item.id, groupId).then(
                  (updatedItem: Contact) => {
                    if (!itemGroupIds?.includes(groupId)) {
                      item?.groupIds?.push(groupId);
                    }
                    item = {...item, ...updatedItem};
                  }
              );
            }
            if (!value && itemGroupIds.includes(groupId)) {
              return ContactsService.deleteContactGroup(item.id, groupId).then(
                  (updatedItem: Contact) => {
                    if (itemGroupIds?.includes(groupId)) {
                      const index = itemGroupIds.findIndex((id) => id === groupId);
                      item?.groupIds?.splice(index, 1);
                    }
                    item = {...item, ...updatedItem};
                  }
              );
            }
          }
          return Promise.resolve();
        })
    )
        .then(() => this.getGroups())
        .catch(this.showNetworkError);
  }

  removeGroupFromContact(obj: { oldId: string; item: TableContact }): void {
    if (obj.item.id && obj.oldId) {
      this.loadingContacts = true;
      ContactsService.deleteContactGroup(obj.item.id, obj.oldId)
          .then((updatedItem: Contact) => {
            if (obj.item.groupIds?.includes(obj.oldId)) {
              obj.item.groupIds = obj.item.groupIds?.filter(
                  (groupId) => groupId !== obj.oldId
              );
            }
            obj.item = {
              ...obj.item,
              ...updatedItem
            };
            const group = this.groups.find((g) => g.id === obj.oldId);
            if (group && group.numberOfContact) group.numberOfContact--;
            this.loadingContacts = false;
          })
          .catch(this.showNetworkError);
    }
  }

  deleteSelectedIds(): void {
    this.$bvModal
        .msgBoxConfirm(this.t('modals.deleteSelectedContacts.description'), {
          title: this.t('modals.deleteSelectedContacts.title'),
          centered: true,
          okVariant: 'danger',
          okTitle: this.t('modals.deleteSelectedContacts.ok'),
          cancelTitle: this.t('common.cancel')
        })
        .then((value) => {
          if (value) {
            this.loadingContacts = true;
            const selectedLength = this.selectedIds.length;
            Promise.allSettled(
                this.selectedIds.map((id) => ContactsService.deleteContact(id))
            ).then(() => {
              this.toast(this.t('adressbook.contactsDeleted'), 'success');
              this.init();
              this.resetSelectedIds();
              this.totalContacts = this.totalContacts - selectedLength;
            });
          }
        })
        .catch(this.showNetworkError);
  }

  resetSelectedIds(): void {
    this.rows.forEach(row => (row.selected = false));
    this.selectedIds = [];
  }

  loadSessionStorage(): void {
    if (sessionStorage.getItem('isMailVisible')) {
      this.visibilities.mail =
          sessionStorage.getItem('isMailVisible') === 'true';
    } else {
      sessionStorage.setItem(
          'isMailVisible',
          this.visibilities.mail.toString()
      );
    }
    if (sessionStorage.getItem('isCompanyVisible')) {
      this.visibilities.company =
          sessionStorage.getItem('isCompanyVisible') === 'true';
    } else {
      sessionStorage.setItem(
          'isCompanyVisible',
          this.visibilities.company.toString()
      );
    }
  }

  updateMailVisibility(): void {
    this.visibilities.mail = !this.visibilities.mail;
    sessionStorage.setItem('isMailVisible', this.visibilities.mail.toString());
  }

  updateCompanyVisibility(): void {
    this.visibilities.company = !this.visibilities.company;
    sessionStorage.setItem(
        'isCompanyVisible',
        this.visibilities.company.toString()
    );
  }

  getGroups(): void {
    this.loadingGroups = true;

    GroupsService.getGroups()
        .then(groups => {
          groups.sort((a, b) => a.idx - b.idx)
              .forEach(g => g.numberOfContact = g.numberOfContact ? g.numberOfContact : 0);
          this.groups = groups;
          this.loadingGroups = false;
        })
        .catch(this.showNetworkError);
  }

  getContacts(): void {
    this.loadingContacts = true;
    if (this.order === '') this.order = 'dateCreated';
    this.resetSelectedIds();

    ContactsService.getContacts(
        this.currentTablePage - 1,
        this.tableOptions.entryCount,
        this.order,
        this.directionDesc ? 'DESC' : 'ASC',
        this.tableOptions.searchTerm,
        this.selectedGroup
    )
        .then(wrapper => {
          this.currentTablePage = wrapper.pageNumber + 1;
          this.totalRows = wrapper.totalSize;
          if (this.totalContacts === 0) this.totalContacts = this.totalRows;
          this.totalPages = wrapper.totalPages;

          if (!wrapper.empty) this.contactsToRows(wrapper.content);
          else this.rows = [];

          this.loadingContacts = false;
        })
        .catch(this.showNetworkError);
  }

  contactsToRows(contacts: Contact[]): void {
    this.rows = contacts.map((contact) => ({
      ...contact,
      id: contact.id + '',
      selected: contact.id ? this.selectedIds.includes(contact.id + '') : false,
      groupIds: contact.groups ? contact.groups.map((group) => group.id) : [],
      groups: contact.groups || []
    }));
  }

  async deleteContacts(ok: () => void): Promise<void> {
    this.loadingContacts = true;
    await ContactsService.deleteAllContacts().catch(this.showNetworkError);
    this.toast(this.t('adressbook.allContactsDeleted'), 'success');
    this.totalContacts = 0;
    const promises = this.groups.map(g => {
      if (g.id) {
        return GroupsService.deleteGroup(g.id, 'onlyGroup').catch(
            this.showNetworkError
        );
      }
    });
    await Promise.all(promises);
    this.toast(this.t('adressbook.allGroupsDeleted'), 'success');
    this.init();
    ok();
  }

  deleteContactsWithoutGroup(ok: () => void): void {
    this.loadingContacts = true;
    ContactsService.deleteAllContactsWithoutGroup()
        .then(() => {
          this.toast(this.t('adressbook.allContactsDeleted'), 'success');
          this.totalContacts = 0;
          this.init();
          ok();
        })
        .catch(this.showNetworkError);
  }

  selectGroup(group: string): void {
    if (group !== this.selectedGroup) {
      this.selectedGroup = group;
      this.newContactGroup = group;
      this.currentTablePage = 1;
      this.debounceGetContacts();
    }
  }

  calculatePositionInList(group: Group): number {
    if (this.groups.length < 2) return -2;
    const maxIdx = this.groups.reduce((a, b) => (a.idx > b.idx ? a : b), {
      idx: 1
    }).idx;
    const minIdx = this.groups.reduce((a, b) => (a.idx > b.idx ? b : a), {
      idx: maxIdx
    }).idx;
    if (group.idx === maxIdx) return -1;
    else if (group.idx === minIdx) return 1;
    else return 0;
  }

  updateGroupIndex(direction: number, group: Group): void {
    const swap = this.groups.findIndex(g => g.id === group.id) + direction;
    const groupToSwap = this.groups[swap];
    this.swapGroupIndex(groupToSwap, group);
  }

  addContact(newRow: TableContact): void {
    const group = this.groups.find(group => group.name === this.newContactGroup);
    const newContact: GroupContact = {
      name: newRow.name,
      telephone: newRow.telephone,
      email: newRow.email,
      company: newRow.company,
      groupId: group ? group.id : undefined
    };

    ContactsService.addContact(newContact)
        .then(contact => {
          this.rows.unshift({
            ...contact,
            id: `${contact.id}`,
            selected: false,
            groupIds: contact.groups
                ? contact.groups.map((group) => group.id)
                : undefined
          });

          if (this.rows.length > this.tableOptions.entryCount)
            this.rows.splice(-1, 1);
          if (contact.groups && contact.groups[0]) {
            let id = contact.groups[0].id;
            this.groups.map((group) => {
              if (group.id === id)
                group.numberOfContact
                    ? group.numberOfContact++
                    : (group.numberOfContact = 1);
            });
          }

          this.totalRows++;
          this.totalContacts++;

          this.toast(this.t('adressbook.contactAdded'), 'success');
        })
        .catch(this.showNetworkError);
  }

  addGroup(): void {
    if (this.groups.find(g => g.name === this.newGroup)) {
      this.toast(this.t('adressbook.groupExists'), 'danger');
    } else {
      const idx =
          this.groups.reduce((a, b) => (a.idx > b.idx ? a : b), {idx: 1}).idx +
          1;
      const group: Group = {idx: idx, name: this.newGroup};
      this.loadingGroups = true;

      GroupsService.addGroup(group)
          .then(res => {
            if (!res.numberOfContact) res.numberOfContact = 0;
            this.groups.unshift(res);
            this.loadingGroups = false;
            this.toast(this.t('adressbook.groupAdded'), 'success');
            this.newGroup = '';
            this.$v.newGroup.$reset();
            this.getGroups();
          })
          .catch(this.showNetworkError);
    }
  }

  deleteGroupOpen(group: Group): void {
    this.loadingGroups = true;
    this.toDeleteGroup = group;
    this.$bvModal.show('group-delete-modal');
  }

  deleteGroup(deleteType: GroupDeleteType): void {
    const group = this.toDeleteGroup;
    if (group && group.id) {
      GroupsService.deleteGroup(group.id, deleteType)
          .then(() => {
            this.loadingGroups = false;
            if (group?.name === this.selectedGroup) {
              this.selectedGroup = '';
            }
            this.toDeleteGroup = null;
            this.$bvModal.hide('group-delete-modal');
            this.toast(this.t('adressbook.groupDeleted'), 'success');
            this.init();
            if (group.numberOfContact) {
              this.totalContacts = this.totalContacts - +group.numberOfContact;
            }
          })
          .catch(this.showNetworkError);
    }
  }

  editGroupName(newValue: string, group: Group): void {
    if (group.id) {
      this.loadingGroups = true;
      GroupsService.updateGroup(group.id, {idx: group.idx, name: newValue})
          .then(newGroup => {
            const oldName = group.name;
            group.name = newGroup.name;
            if (this.selectedGroup === oldName) this.selectedGroup = newGroup.name;

            this.toast(this.t('adressbook.groupNameChanged'), 'success');
          })
          .catch(this.showNetworkError);
    }
  }

  swapGroupIndex(groupA: Group, groupB: Group): void {
    if (!groupA.id) return;
    this.loadingGroups = true;
    GroupsService.updateGroup(groupA.id, {
      idx: groupB.idx,
      name: groupA.name
    })
        .then(() => {
          if (!groupB.id) return;
          GroupsService.updateGroup(groupB.id, {
            idx: groupA.idx,
            name: groupB.name
          })
              .then(this.getGroups)
              .catch(this.showNetworkError);
        })
        .catch(this.showNetworkError);
  }

  deleteRow(item: TableContact): void {
    const name = item.name;
    this.$bvModal
        .msgBoxConfirm(
            this.$t('modals.deleteContact.description', {name}) as string,
            {
              title: this.t('modals.deleteContact.title'),
              centered: true,
              okVariant: 'danger',
              okTitle: this.t('modals.deleteContact.ok'),
              cancelTitle: this.t('common.cancel')
            }
        )
        .then(value => {
          if (value && item.id) {
            this.loadingContacts = true;
            ContactsService.deleteContact(item.id)
                .then(() => {
                  this.toast(this.t('adressbook.contactDeleted'), 'success');
                  this.totalContacts--;
                  this.init();
                })
                .catch(this.showNetworkError);
          }
        });
  }

  toggleGroupForm(): void {
    this.showGroupForm = !this.showGroupForm;
  }

  download(): void {
    if (this.totalContacts > 0) {
      const self = this as any;
      ContactsService.getContacts(
          0,
          this.totalContacts,
          'dateCreated',
          'ASC',
          this.tableOptions.searchTerm,
          this.selectedGroup
      )
          .then((wrapper) => {
            const rows = wrapper.content;
            if (rows) {
              let modRows: string[][] = [[this.t('common.name'), this.t('common.phoneNumber'), this.t('common.emailAddress'), this.t('common.company')]];
              rows.forEach((row) => {
                modRows.push([
                  row.name,
                  row.telephone,
                  row.email,
                  row.company
                ]);
              });
              const csv = self.$papa.unparse(modRows, {
                columns: [this.t('common.name'), this.t('common.phoneNumber'), this.t('common.emailAddress'), this.t('common.company')],
                delimiter: ';',
              });
              self.$papa.download(
                  csv,
                  this.t('adressbook.adressbookFileName') +
                  '_' +
                  this.exportName +
                  '_' +
                  formatDateForFiles(Date.now())
              );
            } else {
              this.toast(this.t('common.noDataFound'), 'danger');
            }
          })
          .catch(this.showNetworkError);
    } else {
      this.toast(this.t('adressbook.noContactsToExport'), 'danger');
    }
  }

  validateState(): boolean | null {
    return this.$v.newGroup.$dirty ? !this.$v.newGroup.$error : null;
  }

  get exportName(): string {
    return this.selectedGroup ? this.selectedGroup : this.user.username;
  }
}
