













































import { BookingListItem } from '@/models/Booking';
import Base from '@/views/Base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { SelectOption } from '@/models/common';
import SurveyService from '@/service/SurveyService';
import { formatDateCreated } from '@/utils/filters';

@Component
export default class SelectSurveyModal extends Base {
	@Prop() bookingItem!: BookingListItem;

	surveys: SelectOption[] = [];
	selectedSurvey: number | null = null;

	mounted(): void {
		this.getSurveys();
	}

	getSurveys(): void {
		SurveyService.getSurveys(0, 9999, 'dateCreated', 'DESC')
			.then((surveys) => {
				if (!surveys.empty) {
					this.surveys = surveys.content
						.sort((a, b) => (a.bookingIds ? 1 : b.bookingIds ? -1 : 0))
						.map((survey) => ({
							text:
								(survey.bookingIds && this.selectedSurvey !== survey.id
									? `- ${this.$t('forms.assignedElsewhere')} - `
									: '') + (survey.label || survey.title),
							value: survey.id,
							data: survey.label || survey.title,
							disabled: !!survey.bookingIds && this.selectedSurvey !== survey.id
						}));
				}
				this.surveys.unshift({
					text: this.$t('conference.noSurveyForm') as string,
					value: null,
					data: []
				});
			})
			.catch(this.showNetworkError);
	}

	formatDateCreated = formatDateCreated;

	submit(ok: () => void): void {
		if (this.selectedSurvey && this.bookingItem) {
			SurveyService.addBooking(
				this.selectedSurvey,
				this.bookingItem.bookingId
					? this.bookingItem.bookingId
					: this.bookingItem.id
			)
				.then(() => this.closeModal(ok))
				.catch(this.showNetworkError);
		} else {
			this.deleteBookingFromSurvey(ok);
		}
	}

	closeModal(ok: () => void): void {
		ok();
		this.$emit('submit', this.selectedSurvey);
		this.selectedSurvey = null;
		this.toast(this.$t('forms.formSuccess') as string, 'success');
		this.getSurveys();
	}

	deleteBookingFromSurvey(ok: () => void): boolean {
		if (this.bookingItem && this.bookingItem.survey) {
			SurveyService.deleteBooking(this.bookingItem.survey.surveyId)
				.then(() => this.closeModal(ok))
				.catch(this.showNetworkError);
			return true;
		}
		return false;
	}

	@Watch('bookingItem.id')
	itemChanged(): void {
		if (this.bookingItem && this.bookingItem.survey) {
			this.selectedSurvey = this.bookingItem.survey.surveyId;
			let selectedIdx = this.surveys.findIndex(
				(s) => s.value === this.selectedSurvey
			);
			this.surveys[selectedIdx] = {
				...this.surveys[selectedIdx],
				disabled: false,
				text: this.surveys[selectedIdx].text.replaceAll(
					`- ${this.$t('forms.assignedElsewhere')} - `,
					''
				)
			};
		} else {
			this.selectedSurvey = null;
		}
	}
}
