





































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class WPin extends Vue {
	@Prop() pin!: { moderator: number; participant: number };
	@Prop({ default: false, type: Boolean }) room!: boolean;
	@Prop({ default: false, type: Boolean }) noBg!: boolean;
}
