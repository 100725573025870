import { Branding, CountryCode } from '@/models/Branding';
import axios from 'axios';
import countryCodes from '@/utils/countryCodes';

/**
 * API calls for branding
 * @module service/BrandingService
 */
export default {
	getBrandings(): Promise<Branding[]> {
		return axios.get('/branding').then((response) => response.data);
	},
	getBranding(id: number): Promise<Branding> {
		return axios.get(`/branding/${id}`).then((response) => response.data);
	},
	getLogoUrl(id: number): string {
		return `${axios.defaults.baseURL}/branding-resources/${id}/logo.png`;
	},
	getBannerUrl(id: number): string {
		return `${axios.defaults.baseURL}/branding-resources/${id}/banner.jpg`;
	},

	createBranding(branding: Branding): Promise<Branding> {
		return axios
			.post('/branding', { ...branding, data: JSON.stringify(branding.data) })
			.then((res) => res.data);
	},
	addLogo(id: number, file: Blob): Promise<Branding> {
		const form = new FormData();
		form.append('file', file);
		const headers = {
			'Content-Type': 'multipart/form-data'
		};

		return axios
			.post(`/branding/${id}/upload/logo`, form, { headers })
			.then((res) => res.data);
	},
	addBanner(id: number, file: Blob): Promise<Branding> {
		const form = new FormData();
		form.append('file', file);
		const headers = {
			'Content-Type': 'multipart/form-data'
		};
		return axios
			.post(`/branding/${id}/upload/banner`, form, { headers })
			.then((res) => res.data);
	},

	updateBranding(id: number, branding: Branding): Promise<Branding> {
		return axios
			.put(`/branding/${id}`, {
				...branding,
				data: JSON.stringify(branding.data)
			})
			.then((res) => res.data);
	},

	deleteBranding(id: number): Promise<Branding> {
		return axios.delete(`/branding/${id}`).then();
	},

	deleteBanner(id: number): Promise<Branding> {
		return axios.delete(`/branding/${id}/upload/banner`).then();
	},

	deleteLogo(id: number): Promise<Branding> {
		return axios.delete(`/branding/${id}/upload/logo`).then();
	},

	// Functions
	newBranding(): Branding {
		return {
			countryISO: 'DE',
			data: {
				headerBgColor: '#27A6BD',
				headerTextColor: '#FFFFFF',
				logoPosition: 'left',
				primaryBgColor: '#27A6BD',
				primaryTextColor: '#FFFFFF'
			},
			id: Date.now(),
      accountId: -1,
			title: '',
			version: 1.0,
			bannerFileExists: false,
			logoFileExists: false,
			footer: '',
			footerEnabled: true,
			countRegForms: 0,
			countSurveys: 0,
			countRegFormsForCurrentAccount: 0,
			countSurveysForCurrentAccount: 0,
			global: false,
			dateCreated: Date.now()
		};
	},

	getCountryCodes(): CountryCode[] {
		return countryCodes;
	}
};
