

































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import WExpandableCard from '@/components/WExpandableCard.vue';
import Base from '@/views/Base';
import { CreateSpeakerCard, ImageData } from '@/models/SpeakerCard';
import { dataURLToBlob } from '@/utils/images';

@Component({
  components: { WExpandableCard }
})
export default class BuisnessCard extends Base {
  @Prop() validation!: any;
  @Prop() speakerCard!: CreateSpeakerCard;
  @Prop() photoData!: ImageData;
  @Prop() logoData!: ImageData;
  color = '#FFFFFF';

  mounted() {
    this.color = this.speakerCard.color;
  }

  photoUploaded(files: File[]): void {
    const file: Blob = files[0];
    const logoData = this.photoData;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      if (e.target) {
        const img = new Image()
        img.onload = function () {
          const width = img.width;
          const height = img.height;

          let newWidth = width;
          let newHeight = height;

          const maxWidth = 1920;
          const maxHeight = 1080;

          if(width > 1920){
            newWidth = 1920;
            newHeight = Math.floor((height*maxWidth) / width);
          }
          if(height > 1080){
            newHeight = 1080;
            newWidth = Math.floor((width*maxHeight) / height);
          }

          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;

          const ctx = canvas.getContext('2d');

          if(ctx){
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            logoData.url = canvas.toDataURL('image/png');
            logoData.file = new File([dataURLToBlob(logoData.url)], "img.png");

            console.log("logo finished");
          }
        }
        img.src = e.target.result as string
        console.log('Logo uploaded');

        this.speakerCard.photoScale = 1.5;
        this.speakerCard.photoX = 0;
        this.speakerCard.photoY = 0;
        console.log('Image uploaded');
      }
    };
  }

  logoUploaded(files: File[]): void {
    const file: Blob = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      if (e.target) {
        this.logoData.url = e.target.result as string;
        this.logoData.file = files[0];
        console.log('Logo uploaded');
      }
    };
  }

  deletePhoto() {
    console.log('Delete');
    this.photoData.file = null;
    this.photoData.url = null;
  }

  deleteLogo() {
    this.logoData.file = null;
    this.logoData.url = null;
  }


  @Watch('color')
  colorChanged(newValue: any, oldValue: any) {
    this.speakerCard.color = newValue.hex;
  }
}
