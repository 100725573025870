



































import {Component} from 'vue-property-decorator';
import Base from '../Base';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import AccountsService from '@/service/AccountsService';
import {ServiceLink} from '@/models/ServiceLink';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: { HeaderLayout }
})
export default class UserExtras extends Base {
	itemsLeft: ServiceLink[] = [];
	itemsRight: ServiceLink[] = [];

	async mounted(): Promise<void> {
		const extras = await AccountsService.getExtras(this.$i18n.locale, this.prod).catch(
			this.showNetworkError
		);
		if (extras) {
			this.itemsLeft = extras.filter((item) => item.position === 'left');
			this.itemsRight = extras.filter((item) => item.position === 'right');
		}
	}
}
