








































































import { Component, Prop, PropSync } from 'vue-property-decorator';
import Base from '../Base';

@Component
export default class ProfileSection extends Base {
	@Prop() title!: string;
	@Prop() data!: { [key: string]: string };
	@PropSync('anyActive') editActive!: boolean;
	@Prop({ default: false, type: Boolean }) noAutoClose!: boolean;

	editMode = false;

	get dataKeys(): string[] {
		return Object.keys(this.data);
	}

	keyName(key: string): string {
		const translation = this.$t('common.' + key) as string;
		if (!translation.includes('.')) {
			return translation;
		}
		return key;
	}

	edit(): void {
		this.editMode = true;
		this.editActive = true;
	}

	submit(): void {
		if (!this.noAutoClose) this.editMode = false;
		this.$emit('submit');
	}

	cancel(): void {
		this.editMode = false;
		this.$emit('cancel');
	}

	close(): void {
		this.editMode = false;
	}
}
