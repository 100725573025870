

























import Base from "@/views/Base";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class FileForm extends Base {

  @Prop({type: String, required: true}) heading!: string;
  @Prop({type: String}) description!: string;
  @Prop({type: String}) accept!: string;
  @Prop({type: Boolean, default: false}) responsive!: boolean;
  @Prop({type: Boolean, default: false}) disabled!: boolean;
  @Prop({type: Number}) fileSizeLimit!: number; // in MB

  // TODO: Accept multiple files

  file: File | null = null;

  submit() {
    if (!this.file) return;
    if (this.fileSizeLimit) {
      if (this.file.size > this.fileSizeLimit * 1024 * 1024) {
        this.toast(this.$t('common.fileTooLarge', {size: this.fileSizeLimit}) as string, 'danger');
        return;
      }
    }

    this.$emit('files-selected', this.file);
    this.file = null;
  }

  get colProps() {
    return this.responsive ? { cols: 12, lg: 4, xl: 3 } : {};
  }
}
