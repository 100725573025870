




































































































































































import { BookingForm, BookingParticipantForm } from '@/models/BookingForm';
import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import BookingSection from './BookingSection.vue';
import {NationalNumber, ScheduleType} from '@/models/api';
import BookingService from '@/service/BookingService';
import { Playback } from '@/models/Playback';

@Component({
	components: { BookingSection }
})
export default class BookingPreview extends Base {
	@Prop() form!: BookingForm;
	@Prop() dialInActive!: boolean;
	@Prop() dialOut!: boolean;
	@Prop() playbacks!: Playback[];

	event: { [key: string]: string } = {};
	dialin: { [key: string]: string } = {};
	dialout: { [key: string]: string } = {};
	options: { [key: string]: string } = {};

	sortedList: BookingParticipantForm[] = [];

  nationalNumbers: NationalNumber[] = []

	get date(): string {
		if (this.form.date.schedule.type === ScheduleType.IMMEDIATE) {
			return this.t('conference.immediate');
		}
		return new Date(this.form.date.schedule.start).toLocaleString(undefined, {
			dateStyle: 'long',
			timeStyle: 'short'
		});
	}

	mounted(): void {
		this.init();
    if (this.dialInActive) {
      BookingService.getNationalNumbers().then((numbers) => {
      this.nationalNumbers = numbers;
			const number = numbers.filter((n) => n.active)[0];
			if (number) {
				this.dialin['dialNumbers'] = `${
					number.displayNumber || number.number
				} ${number.description ? '(' + number.description + ')' : ''}`;
			}
		});
    }
	}

	init(): void {
		if (this.form) {
			const playback = this.playbacks.find(
				(p) => p.id === this.form.date.playbackId
			);
			const typeStr =
				playback && this.form.date.type === 'PLAYBACK'
					? this.t('conference.playback') + ' (' + playback.name + ')'
					: this.t('conference.live');

			const value = (key: string) => this.t('conference.sectionValues.' + key);

			this.event = {
				eventType: typeStr,
				language: this.form.date.language === 'en' ? this.t('common.english') : this.t('common.german'),
				costCenter: this.form.date.costCenter.split('|')[0]
			};
      console.log(JSON.stringify(this.dialInActive))
			this.dialin = {
				modPin: this.dialInActive ? this.form.dialIn.moderatorAccessCode : '-',
				partPin: this.dialInActive ? this.form.dialIn.participantAccessCode : '-',
				dialNumbers: this.dialInActive ? '' : '-'
			};
			this.options = {
				mode: this.form.options.schooling ? value('schooling') : value('talk'),
				waitingRoom: this.form.options.waitingRoom
					? value('activated')
					: value('deactivated'),
				conferenceEnd: this.form.options.endConference
					? value('whenModEnds')
					: value('manual'),
				recording: this.form.options.recordingMode
					? value('automatic')
					: value('manual'),
				tone: this.form.options.beep
					? value('activated')
					: value('deactivated'),
				confirmation: this.form.options.dialOutCalleeConfirm
					? value('activated')
					: value('deactivated')
			};
			this.sortedList = this.form.dialOut.participants.sort((a, b) =>
				a.number > b.number ? 1 : -1
			);
		}
	}

	edit(step: number): void {
		this.$emit('cancel', step);
	}

	submit(): void {
		this.$emit('submit');
	}

	openNationalNumbers(): void {
		this.$root.$emit('bv::show::modal', 'nationalNumbers');
	}

	keyName(key: string): string {
		const translation = this.$t('conference.sections.' + key) as string;
		if (!translation.includes('.')) {
			return translation;
		}
		return key;
	}
}
