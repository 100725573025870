var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header-layout',{attrs:{"title":_vm.t('nav.supportCenter')}},[_c('b-container',{staticClass:"pt-45"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"9"}},[_c('h4',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.t('profile.videoTutorials')))]),_c('b-row',{staticClass:"pr-2"},_vm._l((_vm.videos),function(video){return _c('b-col',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
							_vm.videoUnavailable(video) ? _vm.t('common.inWork') : ''
						),expression:"\n\t\t\t\t\t\t\tvideoUnavailable(video) ? t('common.inWork') : ''\n\t\t\t\t\t\t",modifiers:{"hover":true}}],key:video.name,staticClass:"pb-34 px-2",attrs:{"cols":"6","lg":"3"}},[_c('b-link',{attrs:{"href":video.videoUrl,"disabled":_vm.videoUnavailable(video),"target":"_blank"}},[_c('b-card',{staticClass:"h-100",class:{ 'bg-transparent': _vm.videoUnavailable(video) },attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"d-flex flex-column align-items-center flex-grow-1"},[_c('div',{staticClass:"pt-2 pb-12 d-flex align-self-center",class:{
											'text-muted': _vm.videoUnavailable(video),
											'text-primary': !_vm.videoUnavailable(video)
										}},[_c(video.icon + '-icon',{tag:"component",staticClass:"icon-lg"})],1),_c('div',{staticClass:"pt-1",class:{
											'text-muted': _vm.videoUnavailable(video),
											'text-dark': !_vm.videoUnavailable(video)
										}},[_vm._v(" "+_vm._s(video.name)+" ")]),(video.duration)?_c('b-button',{staticClass:"durationBtn py-0 px-1",attrs:{"variant":"outline-muted","size":"sm"}},[_vm._v(" "+_vm._s(video.duration)+" ")]):_vm._e()],1)],1)],1)],1)}),1)],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('h4',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.t('profile.handbooks')))]),_vm._l((_vm.links),function(link){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.handbookUnavailable(link) ? _vm.t('common.inWork') : ''),expression:"handbookUnavailable(link) ? t('common.inWork') : ''"}],key:link.name},[(!_vm.handbookUnavailable(link))?_c('b-link',{attrs:{"href":link.handbookUrl,"target":"_blank"}},[_c('w-sidebar-link',{staticClass:"mb-23",attrs:{"icon":link.icon}},[_vm._v(" "+_vm._s(link.name)+" ")])],1):_c('w-sidebar-link',{staticClass:"mb-23",attrs:{"text":"","icon":link.icon}},[_vm._v(" "+_vm._s(link.name)+" ")])],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }