






































import { Registration, RegistrationDate } from '@/models/Registration';
import { Component, Prop } from 'vue-property-decorator';
import Base from '../Base';

@Component({})
export default class RegistrationLink extends Base {
	@Prop() regDate!: RegistrationDate;
	@Prop() reg!: Registration;
	@Prop() formMode!: 'active' | 'past';
}
