import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from '@/i18n/en';
import de from '@/i18n/de';

const messages = {
	en: { ...en },
	de: { ...de }
};

Vue.use(VueI18n);

function getDefaultLocale(): string{
	const urlLangParam = new URLSearchParams(window.location.search).get("lang");
	if(urlLangParam && Object.keys(messages).some(lang => lang.toLowerCase() === urlLangParam.toLowerCase())) {
		console.log("Using url language parameter");
		localStorage.setItem("language", urlLangParam);
		return urlLangParam;
	}
	return localStorage.getItem('language') || navigator.languages[0]
}

// Create VueI18n instance with options
export default new VueI18n({
	locale: getDefaultLocale(), // set locale
	fallbackLocale: 'de',
	messages // set locale messages
});

export class MimeTypes {
	private static mimeToI18nKeyMap: { [key: string]: string } = {
		// Document types
		"application/pdf": "fileType.pdf",
		"application/msword": "fileType.word",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fileType.word",
		"application/vnd.ms-excel": "fileType.excel",
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fileType.excel",
		"application/vnd.ms-powerpoint": "fileType.powerpoint",
		"application/vnd.openxmlformats-officedocument.presentationml.presentation": "fileType.powerpoint",
		"application/vnd.oasis.opendocument.text": "fileType.odt",
		"application/vnd.oasis.opendocument.spreadsheet": "fileType.ods",
		"application/vnd.oasis.opendocument.presentation": "fileType.odp",
		"application/rtf": "fileType.rtf",

		// Image types
		"image/jpeg": "fileType.image",
		"image/png": "fileType.image",
		"image/gif": "fileType.image",
		"image/bmp": "fileType.image",
		"image/svg+xml": "fileType.vectorImage",
		"image/tiff": "fileType.image",
		"image/webp": "fileType.image",

		// Audio types
		"audio/mpeg": "fileType.audio",
		"audio/wav": "fileType.audio",
		"audio/aac": "fileType.audio",
		"audio/ogg": "fileType.audio",
		"audio/flac": "fileType.audio",

		// Video types
		"video/mp4": "fileType.video",
		"video/x-msvideo": "fileType.video",
		"video/x-matroska": "fileType.video",
		"video/quicktime": "fileType.video",
		"video/x-flv": "fileType.video",
		"video/ogg": "fileType.video",

		// Archive types
		"application/zip": "fileType.archive",
		"application/x-tar": "fileType.archive",
		"application/x-rar-compressed": "fileType.archive",
		"application/x-7z-compressed": "fileType.archive",
		"application/gzip": "fileType.archive",

		// Text types
		"text/plain": "fileType.text",
		"text/csv": "fileType.csv",
		"text/html": "fileType.html",
		"text/css": "fileType.css",
		"application/json": "fileType.json",
		"application/javascript": "fileType.javascript",
		"application/xml": "fileType.xml",
		"application/sql": "fileType.sql",

		// Font types
		"font/ttf": "fileType.font",
		"font/otf": "fileType.font",
		"font/woff": "fileType.font",
		"font/woff2": "fileType.font",

		// Default fallback
		"application/octet-stream": "fileType.binary",
		"application/x-msdownload": "fileType.binary",
		"application/x-sh": "fileType.script",
		"application/x-httpd-php": "fileType.phpScript",
		"text/x-python": "fileType.pythonScript",

		// Unknown file type
		"default": "fileType.unknown"
	};

	/**
	 * Get the i18n key for the given MIME type.
	 * @param mimeType The MIME type string to be translated.
	 * @returns The i18n key corresponding to the MIME type.
	 */
	public static getI18nKeyForMimeType(mimeType: string): string {
		return this.mimeToI18nKeyMap[mimeType] || "fileType.unknown";
	}
}
