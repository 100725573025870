


































































































































































































































































































































































































































import HeaderLayout from '../../layouts/nested/HeaderLayout.vue';
import SidebarLayout from '../../layouts/nested/SidebarLayout.vue';
import { Component, Watch } from 'vue-property-decorator';
import Base from '@/views/Base';
import ItemSection from './ItemSection.vue';
import WSidebarLink from '@/components/WSidebarLink.vue';
import WFormSelect from '@/components/WFormSelect.vue';
import BookingService from '@/service/BookingService';
import { Booking, Email, NationalNumber, RecordingMode, ScheduleType } from '@/models/api';
import ConferenceService from '@/service/ConferenceService';
import { Playback } from '@/models/Playback';
import PlaybackService from '@/service/PlaybackService';
import SelectRegDateModal from '@/components/modals/SelectRegDateModal.vue';
import { downloadConfirmation, StringMap } from '@/utils/pdf';
import SelectSurveyModal from '@/components/modals/SelectSurveyModal.vue';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import BrandingService from '@/service/BrandingService';
import { SelectOption } from '@/models/common';
import { Validation } from 'vuelidate';
import { formatNames } from '@/utils/filters';
import RegistrationService from '@/service/RegistrationService';
import { validateEmail } from '@/utils/validators';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import { Route } from 'vue-router';

@Component({
  components: {
    WHeaderBtn,
    SelectSurveyModal,
    SelectRegDateModal,
    HeaderLayout,
    SidebarLayout,
    ItemSection,
    WSidebarLink,
    WFormSelect
  }
})
export default class ConferenceScheduledItem extends Base {
  booking: Booking | null = null;

  termin: { [key: string]: string } = {};
  options: { [key: string]: string } = {};
  dialIn: { [key: string]: string } = {};
  nationalNumbers: NationalNumber[] = [];

  playbacks: Playback[] = [];

  beginDate = '';

  files: File[] = [];
  mail: Email = {
    replyTo: '',
    subject: '',
    text: '',
    attachments: [],
    brandingId: null
  };
  mailTested = false;
  sendingMail = false;
  sendingTestMail = false;
  brandingOptions: SelectOption[] = [];
  previousRoute: Route | null = null;
  openRegTimeout: any;

  @Validations()
  validations = {
    mail: {
      replyTo: { required, email: validateEmail },
      subject: { required },
      text: { required }
    }
  };

  mounted(): void {
    const { id } = this.$route.params;
    if (id) this.getBooking(id);

    if (this.booking?.dialIn) {
      BookingService.getNationalNumbers().then(
        (numbers) => (this.nationalNumbers = numbers)
      );
    }

    PlaybackService.getPlaybacks().then((p) => (this.playbacks = p));
    this.getBrandings();
  }

  getBrandings(): void {
    BrandingService.getBrandings().then((res) => {
      this.brandingOptions = [];
      res.map((branding) => {
        this.brandingOptions.push({
          text: branding.title,
          value: branding.id
        });
      });
      this.brandingOptions.unshift({
        text: this.t('forms.noBranding'),
        value: null
      });
    });
  }

  bookingToBookingListItem = BookingService.bookingToConferenceItem;

  @Watch('booking')
  bookingChanged(newValue: Booking | null, oldValue: Booking | null): void {
    if (oldValue === null) {
      const { openReg } = this.$route.query;
      if (openReg) {
        this.openRegTimeout = setTimeout(() => {
          this.$bvModal.show('select-reg-date-modal');
          history.pushState({}, '', this.$route.path);
        }, 2000);
      }
    }
  }

  getBooking(id: string): void {
    BookingService.getBooking(id)
      .then((booking) => {
        this.booking = booking;
        this.fillData();
        return BookingService.getNationalNumbers();
      })
      .then((numbers) => {
        const number = numbers.filter((n) => n.active)[0];
        if (number) {
          this.dialIn = {
            ...this.dialIn,
            dialNumbers: `${number.displayNumber || number.number} ${
              number.description ? '(' + number.description + ')' : ''
            }`
          };
        }
      })
      .catch(this.showNetworkError);
  }

  openMailModal(): void {
    this.$bvModal.show('mailModal');
  }

  sendTestMail(): void {
    if (!this.$v.mail.$invalid) {
      this.sendingTestMail = true;
      this.uploadMailFiles().then(() => this.sendTestEmailToParticipants());
    }
  }

  private sendTestEmailToParticipants() {
    if (this.booking && this.booking.id) {
      BookingService.sendTestEmailToParticipants(this.booking.id, this.mail)
        .then(() => {
          this.sendingTestMail = false;
          this.toast(this.t('forms.testMailSuccess'), 'success');
          this.mailTested = true;
        })
        .catch(this.showNetworkError);
    }
  }

  sendMail(ok: () => void): void {
    this.sendingMail = true;
    if (!this.$v.mail.$invalid) {
      this.uploadMailFiles().then(() => {
        this.sendEmailToParticipants(ok);
      });
    }
  }

  private sendEmailToParticipants(ok: () => void) {
    if (this.booking && this.booking.id) {
      BookingService.sendEmailToParticipants(this.booking.id, this.mail)
        .then(() => {
          this.toast(this.t('forms.sendMailSuccess'), 'success');
          this.resetMail();
          this.sendingMail = false;
          ok();
        })
        .catch(this.showNetworkError);
    }
  }

  private uploadMailFiles(): Promise<void | void[]> {
    this.mail.attachments = [];
    return Promise.all(
      this.files.map((file) =>
        RegistrationService.addFile(file)
          .then((value) => {
            if (this.mail.attachments) this.mail.attachments.push(value);
          })
          .catch((err) => console.log(file.name, err.message))
      )
    ).catch(() => {
      this.toast(this.t('forms.fileNotUploaded'));
      this.mail.attachments = [];
      this.files = [];
    });
  }

  fillData(): void {
    const playback = this.playbacks.find((p) => p.id === this.booking?.audioId);
    const typeStr = playback
      ? this.t('conference.playback') + ' (' + playback.name + ')'
      : this.t('conference.live');
    let regDateStr = '';
    let surveyStr = '';
    if (this.booking && this.booking.regDate) {
      const date = this.booking.regDate;
      regDateStr = `${date.title} (${new Date(date.start).toLocaleString(
        undefined,
        {
          dateStyle: 'short',
          timeStyle: 'short'
        }
      )})`;
    }
    if (this.booking && this.booking.survey) {
      const survey = this.booking.survey;
      surveyStr = survey.label || survey.title;
    }

    const value = (key: string) => this.t('conference.sectionValues.' + key);
    this.termin = {
      languageInEmails:
        this.booking?.language?.toLowerCase() === 'de' ? this.t('common.german') : this.t('common.english'),
      eventType: typeStr,
      costCenter: this.booking?.costCenter || '-'
    };
    if (!this.isRoom) {
      this.termin['regForm'] = regDateStr || '-';
      this.termin['surveyForm'] = surveyStr || '-';
    }
    this.options = {
      mode: this.booking?.joinMuted ? value('schooling') : value('talk'),
      waitingRoom: this.booking?.waitingRoom
        ? value('activated')
        : value('deactivated'),
      conferenceEnd: this.booking?.autoClose
        ? value('whenModEnds')
        : value('manual'),
      recording:
        this.booking?.recordingMode === RecordingMode.AUTOMATIC
          ? value('automatic')
          : value('manual'),
      tone: this.booking?.beep ? value('activated') : value('deactivated'),
      confirmation: this.booking?.dialOutCalleeConfirm
        ? value('activated')
        : value('deactivated')
    };
    this.dialIn = {
      modPin: this.booking?.moderatorAccessCode || '',
      partPin: this.booking?.participantAccessCode || ''
    };
    if (this.booking?.start) {
      if (this.booking.schedule?.type === ScheduleType.IMMEDIATE)
        this.beginDate = this.t('conference.immediate');
      else
        this.beginDate = new Date(this.booking?.start).toLocaleString(
          undefined,
          { dateStyle: 'medium', timeStyle: 'short' }
        );
      this.beginDate = this.beginDate + ', ';
    }
  }

  edit(wizardStep: number): void {
    if (this.booking?.schedule?.type === ScheduleType.ANYTIME) {
      this.$router.push(`/conference/booking-room?id=${this.booking?.id}`);
    } else {
      this.$router.push(`/conference/booking?id=${this.booking?.id}&step=${wizardStep}`);
    }
  }

  copy(): void {
    if (this.booking?.schedule?.type === ScheduleType.ANYTIME) {
      this.$router.push(`/conference/booking-room?copyId=${this.booking?.id}`);
    } else {
      this.$router.push(`/conference/booking?copyId=${this.booking?.id}&step=0`);
    }
  }

  back() {
    if (this.openRegTimeout) clearTimeout(this.openRegTimeout);
    let route = sessionStorage.getItem('previousRoute');
    if (route) {
      this.$router.push(route);
      sessionStorage.removeItem('previousRoute');
    } else {
      this.$router.back();
    }
  }

  downloadConfirmation(
    dialIn: StringMap,
    termin: StringMap,
    options: StringMap,
    nationalNumbers: NationalNumber[],
    beginDate: string,
    booking: Booking | null
  ): void {
    const dialInKeys = Object.keys(dialIn);
    const localisedDialIn: StringMap = {};
    dialInKeys.forEach((key) => {
      localisedDialIn[this.keyName(key)] = dialIn[key];
    });

    const terminKeys = Object.keys(termin);
    const localisedTermin: StringMap = {};
    terminKeys.forEach((key) => {
      localisedTermin[this.keyName(key)] = termin[key];
    });

    const optionsKeys = Object.keys(options);
    const localisedOptions: StringMap = {};
    optionsKeys.forEach((key) => {
      localisedOptions[this.keyName(key)] = options[key];
    });

    downloadConfirmation(
      localisedDialIn,
      localisedTermin,
      localisedOptions,
      nationalNumbers,
      beginDate,
      booking,
      this
    );
  }

  deleteItem(): void {
    this.$bvModal
      .msgBoxConfirm(this.t('modals.deleteConference.description'), {
        title: this.t('modals.deleteConference.title'),
        okTitle: this.t('common.delete'),
        cancelTitle: this.t('common.cancel'),
        okVariant: 'danger'
      })
      .then((value) => {
        if (value && this.booking && this.booking.id) {
          BookingService.deleteBooking(this.booking.id)
            .then(() => {
              this.toast(
                this.t('modals.deleteConference.confirmed'),
                'success'
              );
              this.$router.push({ name: 'Home' });
            })
            .catch(this.showNetworkError);
        }
      });
  }

  deleteSeries(): void {
    this.$bvModal
      .msgBoxConfirm(this.t('modals.deleteSeries.description'), {
        title: this.t('modals.deleteSeries.title'),
        okTitle: this.t('common.delete'),
        cancelTitle: this.t('common.cancel'),
        okVariant: 'danger'
      })
      .then((value) => {
        if (value && this.booking && this.booking.id) {
          BookingService.deleteSeries(this.booking.id)
            .then(() => {
              this.toast(this.t('modals.deleteSeries.confirmed'), 'success');
              this.$router.push({ name: 'Home' });
            })
            .catch(this.showNetworkError);
        }
      });
  }

  startConference(): void {
    if (this.booking && this.booking.id) {
      ConferenceService.startConference(this.booking.id)
        .then(() => {
          this.toast(this.t('conference.conferenceStarted'), 'success');
          this.$router.push('/');
        })
        .catch(this.showNetworkError);
    }
  }

  get hasMailableParticipants(): boolean {
    if (this.booking && this.booking.participants) {
      let find = this.booking.participants
        .map((p) => (p.email ? true : undefined))
        .find((b) => b === true);
      if (find) return true;
    }
    return false;
  }

  openNationalNumbers(): void {
    this.$root.$emit('bv::show::modal', 'nationalNumbers');
  }

  scheduleType(type?: ScheduleType): string {
    switch (type) {
      case ScheduleType.ANYTIME:
        return this.t('conference.anytime');
      case ScheduleType.IMMEDIATE:
      case ScheduleType.ONETIME:
        return this.t('conference.onetime');
      case ScheduleType.WEEKLY:
      case ScheduleType.MONTHLY:
      case ScheduleType.DAILY:
        return this.t('conference.repeated');
      default:
        return '';
    }
  }

  validateState(objectName: string, name: string): boolean | null {
    const validate = this.$v[objectName][name] as Validation;
    return validate.$dirty ? !validate.$error : null;
  }

  formatNames = formatNames;

  resetMail(): void {
    this.mail = {
      replyTo: '',
      subject: '',
      text: '',
      attachments: [],
      brandingId: null
    };
    this.files = [];
    this.$v.mail.$reset();
  }

  get isSeries(): boolean {
    if (!this.booking || !this.booking.schedule) return false;
    return (
      this.booking.schedule &&
      BookingService.isSeries(this.booking.schedule.type)
    );
  }

  get conferenceDateString(): string {
    return (
      this.beginDate +
      ' ' +
      (this.booking && this.booking?.schedule
        ? this.scheduleType(this.booking.schedule.type)
        : '')
    );
  }

  get isRoom(): boolean {
    return this.$route.fullPath.includes('room');
  }

  get isDialOut(): boolean {
    return this.booking?.dialOut || false;
  }

  keyName(key: string): string {
    const translation = this.$t('conference.sections.' + key) as string;
    if (!translation.includes('.')) {
      return translation;
    }
    return key;
  }
}
