

























































export default {
	name: 'sidebar-layout',
	props: {
		title: String,
		loading: {
			type: Boolean,
			default: false
		},
		sticky: {
			type: Boolean,
			default: false
		},
		cols: {
			type: Number,
			default: null
		},
		offset: {
			type: Number,
			default: null
		},
		md: {
			type: Number,
			default: null
		},
		offsetMd: {
			type: Number,
			default: null
		},
		position: {
			type: String,
			default: 'right'
		}
	}
};
