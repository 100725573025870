





































import Base from '@/views/Base';
import { Component, Prop } from 'vue-property-decorator';
import { SelectOption } from '@/models/common';
import SurveyService from '@/service/SurveyService';

@Component
export default class SelectOperatorSurvey extends Base {
	@Prop() value!: string;
	value_ = '';

	surveyOptions: SelectOption[] = [];

	mounted(): void {
		this.getSurveys();
	}

	submit(ok: () => void): void {
		this.$emit('input', this.value_);
		ok();
		this.value_ = '';
	}

	getSurveys(): void {
		SurveyService.getSurveys(0, 9999, 'dateCreated', 'DESC')
			.then((surveys) => {
				if (!surveys.empty) {
					this.surveyOptions = surveys.content.map((survey) => ({
						text: survey.label || survey.title,
						value: survey.id ? survey.id : ''
					}));
				}
				this.surveyOptions.unshift({
					text: this.t('conference.noSurveyForm'),
					value: ''
				});
			})
			.catch(this.showNetworkError);
	}
}
