



















































































import Base from "@/views/Base";
import {Component, Prop, Watch} from "vue-property-decorator";
import PresentationElementList from "@/views/presentation/components/ElementList.vue";
import PresentationElementTable from "@/views/presentation/components/ElementTable.vue";
import WSwitch from "@/components/WSwitch.vue";
import PresentationDownloadList from "@/views/presentation/components/DownloadList.vue";
import PresentationDownloadTable from "@/views/presentation/components/DownloadTable.vue";
import FileForm from "@/views/presentation/components/FileForm.vue";
import {DownloadListItem, IndexForm, PresentationForm} from "@/models/Presentation";
import PresentationService from "@/service/PresentationService";
import {saveAs} from "file-saver";
import BatchEdit from "@/views/presentation/components/BatchEdit.vue";
import WProgressBar from "@/components/WProgressBar.vue";

@Component({
  components: {
    WProgressBar,
    BatchEdit,
    FileForm,
    PresentationDownloadList,
    PresentationDownloadTable,
    WSwitch,
    PresentationElementTable,
    PresentationElementList
  }
})
export default class PresentationDownloads extends Base {
  @Prop() form!: PresentationForm;

  columns: string | number = 3;
  editIds: number[] = [];
  allSelected = false;

  items: DownloadListItem[] = [];
  currentUploadingFile: File | null = null;
  uploadProgress = 0;
  abortController = new AbortController();

  mounted() {
    PresentationService.getDownloads(this.form.id)
      .then(res => this.items = res.map(PresentationService.downloadToListItem))
      .catch(this.showNetworkError);
  }

  updateIndexes(): void {
    let indexList = this.items.map((item, idx) => ({id: item.id, idx: idx + 1}) as IndexForm);
    PresentationService
      .updateDownloadIndexes(this.form.id, indexList)
      .then(res => this.items = res.map(PresentationService.downloadToListItem))
      .catch(this.showNetworkError);
  }

  download(item: DownloadListItem): void {
    PresentationService.download(this.form.id, item.uuid)
      .then(res => saveAs(new Blob([res]), item.fileName))
      .catch(this.showNetworkError);
  }

  deleteDownload(item: DownloadListItem): void {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('modals.deleteDownload.description', {name: item.fileName}) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteDownload.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteDownload.title')
        }
      )
      .then(res => {
        if (!res) return;
        PresentationService.deleteDownload(this.form.id, item.id)
          .then(() => {
            this.items = this.items.filter(i => i.id !== item.id);
            this.$emit('update');
            this.toast(this.$t('modals.deleteDownload.deleted') as string, 'success');
          })
          .catch(this.showNetworkError);
      });
  }

  deleteSelectedIds() {
    this.$bvModal
      .msgBoxConfirm(
        this.$tc('modals.deleteDownloads.description', this.editIds.length, {count: this.editIds.length}) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteDownloads.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteDownloads.title')
        }
      )
      .then(res => {
        if (!res) return;
        PresentationService.deleteSelectedDownloads(this.form.id, this.editIds)
          .then(() => {
            this.editIds = [];
            this.items = this.items.filter(item => !item.selected);
            this.$emit('update');
            this.toast(this.$t('modals.deleteDownloads.deleted', {count: this.editIds.length}) as string, 'success');
          })
          .catch(this.showNetworkError);
      })
  }

  uploadFiles(file: File): void {
    this.currentUploadingFile = file;
    const updateProgress = (progressEvent: ProgressEvent) => this.uploadProgress = progressEvent.loaded / progressEvent.total * 100;
    PresentationService.uploadFiles(this.form.id, file, this.abortController, updateProgress)
      .then(res => {
        this.items.push(PresentationService.downloadToListItem(res));
        this.$emit('update');
        this.toast(this.$t('presentation.download.added') as string, 'success');
      })
      .catch(this.showNetworkError)
      .finally(() => {
        this.uploadProgress = 0;
        this.currentUploadingFile = null;
      });
  }

  abortUpload(): void {
    this.abortController.abort();
    this.uploadProgress = 0;
    this.currentUploadingFile = null;
    this.abortController = new AbortController();
  }

  get isUploading(): boolean {
    return this.currentUploadingFile !== null;
  }

  @Watch('editIds', {deep: true})
  onEditIdsChanged() {
    this.allSelected = this.editIds.length === this.items.length && this.items.length > 0;
  }

  updateSelection(value: string | null) {
    if (!value) return;
    switch (value) {
      case 'invert':
        this.items.forEach(item => this.rowSelected(item, false));
        this.allSelected = this.items.every(item => item.selected);
        break;
      case 'all':
        this.allSelected = false;
        this.selectAll();
        break;
      case 'none':
        this.allSelected = true;
        this.selectAll();
        break;
    }
  }

  selectAll(): void {
    this.allSelected = !this.allSelected;
    this.items.forEach(item => {
      if (this.allSelected) {
        if (!item.selected) this.rowSelected(item);
      } else {
        if (item.selected) this.rowSelected(item);
      }
    });
  }

  rowSelected(item: DownloadListItem, shiftKey = false): void {
    item.selected = !item.selected;
    if (item.selected) this.editIds.push(item.id);
    else this.editIds = this.editIds.filter(id => id !== item.id);
    if (shiftKey) this.selectBetween(item, item.selected);
  }

  selectBetween(item: DownloadListItem, value: boolean) {
    const index = this.items.findIndex(i => i.id === item.id);
    const selectedIndex = this.items.findIndex(i => i.selected === value && i.id !== item.id);
    let startIndex: number, endIndex: number;
    if (selectedIndex === -1) {
      startIndex = endIndex = index;
    } else {
      startIndex = Math.min(index, selectedIndex);
      endIndex = Math.max(index, selectedIndex);
    }
    this.items.slice(startIndex, endIndex + 1).forEach(i => {
      if (i.selected !== value) this.rowSelected(i, false);
    });
  }

  get editMode(): boolean {
    return this.editIds.length > 0;
  }
}
