import RegistrationService from "@/service/RegistrationService";
import SurveyService from "@/service/SurveyService";

export function registrationUrlExists(url: string): Promise<boolean> {
    return RegistrationService.registrationUrlExists(url)
        .then((res)=> {
            return res
        });

}


export function surveyUrlExists(url: string): Promise<boolean> {
    return SurveyService.surveyUrlExists(url)
        .then((res)=> {
            return res
        });

}
