

























































































































































































































































import Base from '@/views/Base';
import {Component, Watch} from 'vue-property-decorator';
import ListWithHeaderLayout from '@/layouts/nested/ListWithHeaderLayout.vue';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import WPagination from '@/components/WPagination.vue';
import WOperatorConfContext from '@/components/WOperatorConfContext.vue';
import {ConferenceListItem} from '@/models/Conference';
import AdminConsoleService from '@/service/AdminService';
import {
  PastConferenceStatistics,
  PastConferenceWrapper
} from '@/models/AdminConsole';
import AuthenticationService from '@/service/AuthenticationService';
import {durationStr, formatNumberString} from '@/utils/filters';
import {SelectOption} from '@/models/common';

@Component({
  components: {
    WOperatorConfContext,
    ListWithHeaderLayout,
    HeaderLayout,
    WListOptions,
    WPagination
  },
  i18n: {
    messages: {
      en: {
        common: {
          duration: 'Dur.',
          customer: 'Custm.'
        }
      }
    }
  }
})
export default class PastConferences extends Base {
  items: ConferenceListItem[] = [];

  fields = [
    {
      key: 'start' as keyof ConferenceListItem,
      label: this.$t('common.start'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {paddingLeft: '1.5rem', width: 'calc(160px + 1.5rem)'}
    },
    {
      key: 'duration' as keyof ConferenceListItem,
      label: this.$t('common.duration'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '75px'}
    },
    {
      key: 'customerNumber' as keyof ConferenceListItem,
      label: this.$t('common.customer'),
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '75px'}
    },
    {
      key: 'company' as keyof ConferenceListItem,
      label: this.$t('common.company'),
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '180px'}
    },
    {
      key: 'username' as keyof ConferenceListItem,
      label: this.$t('common.username'),
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '160px'}
    },
    {
      key: 'participantLimit' as keyof ConferenceListItem,
      label: this.$t('common.limit'),
      sortable: false,
      thStyle: {width: '50px'}
    },
    {
      key: 'pin' as keyof ConferenceListItem,
      label: this.t('conference.pinShort2'),
      sortable: false,
      thStyle: {width: '75px'}
    },
    {
      key: 'numberOfParticipants' as keyof ConferenceListItem,
      label: this.t('conference.participantShort') + '.',
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '105px'}
    },
    {
      key: 'rec' as keyof ConferenceListItem,
      label: this.t('conference.rec'),
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '55px'}
    },
    {
      key: 'functions' as keyof ConferenceListItem,
      label: this.$t('common.type'),
      sortable: false,
      sortDirection: 'desc',
      thStyle: {width: '75px'}
    },
    {
      key: 'action' as keyof ConferenceListItem,
      label: this.$t('common.action'),
      sortable: false
    }
  ];

  options = {
    entryCount: 10,
    searchTerm: '',
    currentPage: 1,
    totalRows: 0,
    timeRange: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    }
  };

  statistics: PastConferenceStatistics = {
    totalConferences: 0,
    totalParticipants: 0
  };

  sortDesc = true;
  sortBy = 'start';
  loading = false;

  wrapper: PastConferenceWrapper = {
    empty: true,
    numberOfElements: 0,
    offset: 0,
    content: [],
    pageNumber: 0,
    size: 0,
    totalPages: 0,
    totalSize: 0
  };

  mounted(): void {
    this.getPastConfs();
  }

  loginAs(item: any, showConference: boolean): void {
    AuthenticationService.impersonate({
      parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
      childUsername: item.username
    })
        .then(() => {
          if (showConference) this.$router.push(`/conference/past/${item.id}`);
          else this.$router.push('/');
        })
        .catch(this.showNetworkError);
  }

  private getPastConfs() {
    this.loading = true;
    AdminConsoleService.getPastConferences(
        this.options.timeRange,
        this.options.currentPage - 1,
        this.options.entryCount,
        this.sortBy,
        this.sortDesc ? 'DESC' : 'ASC',
        this.options.searchTerm,
        false
    )
        .then((conferencesWrapper) => {
          if (conferencesWrapper.content) {
            this.items = conferencesWrapper.content.map(
                AdminConsoleService.pastConferenceToConferenceItem
            );
            this.wrapper = conferencesWrapper;
          } else {
            this.items = [];
            this.wrapper = {
              empty: true,
              numberOfElements: 0,
              offset: 0,
              content: [],
              pageNumber: 0,
              size: 0,
              totalPages: 0,
              totalSize: 0
            };
          }
        })
        .catch((e) => this.showNetworkError(e))
        .finally(() => (this.loading = false));
    AdminConsoleService.getPastConferencesStatistics(
        this.options.timeRange,
        this.options.searchTerm
    ).then((res) => (this.statistics = res));
  }

  get perPage(): number {
    return this.options.entryCount;
  }

  set perPage(count: number) {
    this.options.entryCount = count;
  }

  get totalPages(): number {
    return Math.ceil(this.totalRows / this.perPage);
  }

  get totalRows(): number {
    return this.items.length;
  }

  get showPagination(): boolean {
    return this.perPage <= this.wrapper.totalSize;
  }

  get totalConferencesString(): string {
    return this.$tc('conference.countPastConferences', this.statistics.totalConferences) as string
  }

  get totalParticipantsString(): string {
    return `${this.statistics.totalParticipants} ${this.$t(
        'common.participants'
    )}`;
  }

  get timeRangeOptions(): SelectOption[] {
    const date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const options: SelectOption[] = [];
    for (let i = 0; i < 6; i++) {
      options.unshift({
        value: {year: date.getFullYear(), month: date.getMonth() + 1},
        text: date.toLocaleDateString([], {
          month: 'long',
          year: 'numeric'
        })
      });
      date.setMonth(date.getMonth() - 1);
    }
    return options.reverse();
  }

  formatNumberString = formatNumberString;

  durationString = durationStr;

  formatDate(t: number): string {
    return new Date(t).toLocaleString([], {
      dateStyle: 'short',
      timeStyle: 'short'
    });
  }

  editCustomer(item: any): void {
    this.$router.push(`/admin/customers/${item.internalCustomerId}`);
  }

  editUser(item: any): void {
    this.$router.push(`/admin/users/${item.username}`);
  }

  @Watch('options.searchTerm')
  search(): void {
    this.options.currentPage = 1;
    this.getPastConfs();
  }

  @Watch('sortBy')
  sort(): void {
    this.getPastConfs();
  }

  @Watch('sortDesc')
  sortByDirection(): void {
    this.getPastConfs();
  }

  @Watch('options.currentPage')
  currentPageChanged(): void {
    this.getPastConfs();
  }

  @Watch('options.timeRange')
  timeRangeChanged(): void {
    this.options.currentPage = 1;
    this.getPastConfs();
  }

  @Watch('options.entryCount')
  entryCountChanged(): void {
    this.options.currentPage = 1;
    this.getPastConfs();
  }
}
