




























































































































































































import { Component } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import Base from '@/views/Base';
import {
  CreateSpeakerCard,
  ImageData,
  SpeakerCardButton,
  SpeakerCardModel,
  SpeakerCardSocialLink
} from '@/models/SpeakerCard';
import SpeakerCardService from '@/service/SpeakerCardService';
import { Validations } from 'vuelidate-property-decorators';
import { maxLength, required, requiredIf, url } from 'vuelidate/lib/validators';
import { validateEmail, validateNoSpecialChars } from '@/utils/validators';
import BuisnessCard from '@/views/presentation/speakerProfiles/BuisnessCard.vue';
import WSwitch from '@/components/WSwitch.vue';
import WExpandableCard from '@/components/WExpandableCard.vue';
import Contact from '@/views/presentation/speakerProfiles/Contact.vue';
import SocialLinks from '@/views/presentation/speakerProfiles/SocialLinks.vue';
import CustomButtons from '@/views/presentation/speakerProfiles/CustomButtons.vue';
import Infobox from '@/views/presentation/speakerProfiles/Infobox.vue';
import SpeakerCard from '@/views/presentation/speakerProfiles/SpeakerCard.vue';
import SpeakerProfile from '@/views/presentation/speakerProfiles/SpeakerProfile.vue';
import { EventBus, Events } from '@/EventBus';
import ContactsService from '@/service/ContactsService';

@Component({
  components: {
    SpeakerProfile,
    SpeakerCard,
    Infobox,
    CustomButtons,
    SocialLinks,
    Contact,
    WExpandableCard,
    WSwitch,
    BuisnessCard,
    WHeaderBtn,
    HeaderLayout
  }
})
export default class Edit extends Base {
  speakerCard: CreateSpeakerCard = SpeakerCardService.getEmptySpeakerCard();
  existingCard: SpeakerCardModel | null = null;

  photoData: ImageData = {
    file: null, url: null
  };
  logoData: ImageData = {
    file: null, url: null
  };

  originalUrl: string | null = null;

  get existingUrl(): string | null {
    if (this.originalUrl && this.originalUrl == this.speakerCard.url) {
      return this.url + '/' + this.originalUrl;
    }
    return null;
  }

  @Validations()
  validations = {
    speakerCard: {
      name: { required },
      url: {
        required: requiredIf((vm) => vm.profileActive),
        exists: this.urlExistsValidator,
        noSpecialChars: validateNoSpecialChars
      },
      speakerProfile: {
        email: { email: validateEmail },
        phone: { phone: this.phoneValidation },
        homepageUrl: { url: url },
        infobox: { length: maxLength(5000) }
      }
    }
  };

  async urlExistsValidator(url: string): Promise<boolean> {
    if (!url || (this.existingCard && url == this.existingCard.url)) {
      return true;
    }
    return !await SpeakerCardService.urlExists(url);
  }

  phoneValidation(): boolean | Promise<boolean> {
    const telephone = this.$v.speakerCard.speakerProfile?.phone;
    if (!telephone?.$dirty) return true;
    const phone = telephone.$model;
    if (phone == '') return true;
    return ContactsService.validatePhoneNumbers([phone])
      .then(validatedNumbers => {
        const validNumber = validatedNumbers[0];
        const phoneIsValid = validNumber.validNumber;
        if (phoneIsValid) (this.speakerCard.speakerProfile || { phone: '' }).phone = validNumber.international;
        return phoneIsValid;
      })
      .catch(e => {
        this.showNetworkError(e);
        return false;
      });
  }

  get url(): string {
    return process.env.VUE_APP_PROFILE_URL || '';
  }

  mounted() {
    const id = this.$route.params.id;
    if (id == 'new') {
      this.speakerCard.speakerProfile?.socialLinks?.push({ url: '', title: '' });
      this.speakerCard.speakerProfile?.buttons?.push({ url: '', title: '' });
      return;
    }
    SpeakerCardService.getSpeakerCard(id).then(card => {
      this.existingCard = card;
      this.speakerCard.profileActive = !!card.speakerProfile;
      if (!this.speakerCard.profileActive) {
        this.collapseAll(true);
      }
      const speakerCard: CreateSpeakerCard = {
        profileActive: !!card.speakerProfile,
        ...card, photoScale: card.photoScale || 1.5,
        speakerProfile: {
          ...card.speakerProfile,
          // Keep references of the empty object
          socialLinks: this.speakerCard.speakerProfile?.socialLinks,
          buttons: this.speakerCard.speakerProfile?.buttons
        }
      };
      if (!speakerCard.speakerProfile?.email) {
        (speakerCard.speakerProfile || { email: '' }).email = '';
      }
      if (!speakerCard.speakerProfile?.homepageUrl) {
        (speakerCard.speakerProfile || { homepageUrl: '' }).homepageUrl = '';
      }
      if (!speakerCard.speakerProfile?.phone) {
        (speakerCard.speakerProfile || { phone: '' }).phone = '';
      }
      if (speakerCard.speakerProfile?.address) {
        speakerCard.speakerProfile.address = speakerCard.speakerProfile.address.replace(/\n/g, '|');
      }

      this.originalUrl = this.existingCard.url || null;

      speakerCard.speakerProfile?.socialLinks?.splice(0, speakerCard.speakerProfile.socialLinks.length);
      card.speakerProfile?.socialLinks?.forEach(i => speakerCard.speakerProfile?.socialLinks?.push(i));
      if (speakerCard.speakerProfile?.socialLinks?.length == 0) {
        speakerCard.speakerProfile?.socialLinks?.push({ title: '', url: '' });
      }
      speakerCard.speakerProfile?.buttons?.splice(0, speakerCard.speakerProfile.buttons.length);
      card.speakerProfile?.buttons?.forEach(i => speakerCard.speakerProfile?.buttons?.push(i));
      if (speakerCard.speakerProfile?.buttons?.length == 0) {
        speakerCard.speakerProfile?.buttons?.push({ title: '', url: '' });
      }

      if (this.existingCard.photoFileName) {
        this.photoData.url = SpeakerCardService.getSpeakerPhotoUrl(this.existingCard.id, this.existingCard.photoFileName);
      }
      if (this.existingCard.logoPath) {
        this.logoData.url = SpeakerCardService.getSpeakerPhotoUrl(this.existingCard.id, this.existingCard.logoPath);
      }
      if (speakerCard.photoX && speakerCard.photoY) {
        this.updateXYPosition(speakerCard.photoX, speakerCard.photoY);
      }
      this.speakerCard = speakerCard;
      this.$forceUpdate();
    });
  }

  editSocialLink(link: SpeakerCardSocialLink) {
    (this.$refs.socialLink as any)?.editLink(link);
    const el: any = this.$refs.socialLink;
    if (el) {
      el.$el.scrollIntoView();
      setTimeout(() => {
        // Scrollt weiter nach oben, zum Beispiel 50px mehr
        window.scrollBy({
          top: -100  // Negative Werte scrollen nach oben
        });
      });
    }
  }

  editButton(button: SpeakerCardButton) {
    (this.$refs.button as any)?.editButton(button);
    const el: any = this.$refs.button;
    if (el) {
      el.$el.scrollIntoView();
      setTimeout(() => {
        // Scrollt weiter nach oben, zum Beispiel 50px mehr
        window.scrollBy({
          top: -100  // Negative Werte scrollen nach oben
        });
      });
    }
  }

  updateXYPosition(x: number, y: number) {
    (this.$refs.card as any)?.setPosition(x, y);
  }

  xyUpdated(x: number, y: number) {
    this.speakerCard.photoX = x;
    this.speakerCard.photoY = y;
    (this.$refs.profile as any)?.setPosition(this.speakerCard.photoX, this.speakerCard.photoY);
  }

  collapseAll(val: any) {
    if (val) {
      EventBus.$emit(Events.CollapseAll);
    }
  }

  copyLink(): void {
    window.navigator.clipboard.writeText(`${this.url}/${this.speakerCard.url}`);
    this.toast(this.t('forms.urlCopied'), 'dark');
  }

  async submit() {
    this.$v.speakerCard.$touch();
    if (this.$v.speakerCard.$pending) {
      setTimeout(this.submit, 50);
      return;
    }

    const createCard: CreateSpeakerCard = {...this.speakerCard}

    if (this.$v.speakerCard.$invalid) {
      this.toast(this.t('forms.formHasErrors'), 'danger');
      return;
    }

    if (!this.speakerCard.profileActive) {
      createCard.speakerProfile = undefined;
    }

    if (createCard.speakerProfile) {
      createCard.speakerProfile.socialLinks = createCard.speakerProfile.socialLinks?.filter(link => link.title != '');
      createCard.speakerProfile.buttons = createCard.speakerProfile.buttons?.filter(link => link.title != '');

      if(createCard.speakerProfile.address){
        createCard.speakerProfile.address = createCard.speakerProfile.address.replace(/\|/g, '\n')
      }
    }

    let speakerCard: SpeakerCardModel;
    try {
      if (this.existingCard != null) {
        speakerCard = await SpeakerCardService.updateSpeakerCard(this.existingCard.id, createCard);
      } else {
        speakerCard = await SpeakerCardService.createSpeakerCard(createCard);
      }
    } catch (e) {
      this.showNetworkError(e);
      return;
    }

    if (this.photoData.file != null) {
      await SpeakerCardService.uploadSpeakerPhoto(speakerCard.id, this.photoData.file).catch(this.showNetworkError);
    } else if (this.existingCard != null && this.existingCard.photoFileName != undefined && this.photoData.file == null && this.photoData.url == null) {
      await SpeakerCardService.deletePhoto(this.existingCard.id).catch(this.showNetworkError);
    }

    if (this.logoData.file != null) {
      await SpeakerCardService.uploadCompanyLogo(speakerCard.id, this.logoData.file).catch(this.showNetworkError);
    } else if (this.existingCard != null && this.existingCard.logoPath != undefined && this.logoData.file == null && this.logoData.url == null) {
      await SpeakerCardService.deleteLogo(this.existingCard.id).catch(this.showNetworkError);
    }

    this.$router.push('/presentation/speaker-profile');
  }

}
