import {CreatePinReservation, PinReservation} from '@/models/PinReservation';
import axios from 'axios';
import {secureRandom} from "@/utils/secureRandom";

export default {
  createReservation(reservation: CreatePinReservation): Promise<PinReservation>{
    return axios.post(`/pin`, reservation).then(resp=>resp.data);
  },
  getReservations(): Promise<PinReservation[]>{
    return axios.get('/pin').then(resp=>resp.data);
  },
  deleteReservation(id: number): Promise<void> {
    return axios.delete("/pin/" + id).then()
  },
  renew(id: number): Promise<PinReservation>{
    return axios.post('/pin/' + id + '/renew').then(resp=>resp.data);
  },
  deleteReservationsByIds(ids: number[]): Promise<void>{
    const queryParams = ids.map(id => `ids=${id}`).join('&');
    const url = `?${queryParams}`;

    return axios.delete('/pin/' + url).then((response) => response.data);
  },

  generatePin(): string {
    return Math.floor(100000 + secureRandom() * 900000).toString();
  },
}
