






















































































import { Component, Prop } from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import { Group } from '@/models/Group';
import Base from '@/views/Base';

@Component
export default class GroupItem extends Base {
	@Prop() group!: Group;
	@Prop() active!: boolean;

	// -2 = only one item in list, -1 = last, 0 = inbetween, 1 = first
	@Prop() positionInList!: number;

	editMode = false;
	newGroupValue = '';

	get _group(): string {
		return this.group.name;
	}

	set _group(value: string) {
		this.newGroupValue = value;
	}

	toggleEdit(value: boolean): void {
		this.editMode = value;
		if (value) {
			setTimeout(() => {
				const input = this.$refs['edit'] as BFormInput;
				input.focus();
			});
		} else {
			this.newGroupValue = '';
		}
	}

	changeGroup(): void {
		if (this.newGroupValue !== '') {
			this.$emit('edit', this.newGroupValue);
			this.editMode = false;
			this.newGroupValue = '';
		} else {
			this.editMode = false;
		}
	}

	changeIndex(direction: number): void {
		if (direction) {
			this.$emit('editIdx', direction);
		}
	}

	get isOnlyItem(): boolean {
		return this.positionInList === -2;
	}

	get isFirstItem(): boolean {
		return this.positionInList === 1;
	}

	get isLastItem(): boolean {
		return this.positionInList === -1;
	}
}
