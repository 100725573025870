











































































































































































































































































































































































































































































































































































































































































































































import {Component, Watch} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import AdminSection from './AdminSection.vue';
import {PermissionName} from '@/models/Account';
import {Validations} from 'vuelidate-property-decorators';
import {maxValue, required, url} from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WInlineEdit from '@/components/WInlineEdit.vue';
import {Customer} from '@/models/Customer';
import CustomersService from '@/service/CustomersService';
import WCountrySelect from '@/components/WCountrySelect.vue';
import {validateEmail} from '@/utils/validators';
import {productPermList} from "@/utils/permissionLists";

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: {
    HeaderLayout,
    SidebarLayout,
    WSwitch,
    AdminSection,
    WHeaderBtn,
    WInlineEdit,
    WCountrySelect
  }
})
export default class CustomersNew extends Base {
  customer: Customer = {
    dateCreated: '',
    id: 0,
    title: '',
    customerNumber: 0,
    type: 'BILLING',
    anonymizePhonenumbers: true,
    bankAccount: {
      accountHolder: '',
      iban: '',
      bic: '',
      bankName: ''
    },
    createdBy: '',
    showCustomerContactInAccounts: false,
    contact: {
      phone: '',
      city: '',
      country: 'DE',
      street: '',
      zip: '',
      salutation: '',
      title: '',
      company: '',
      firstName: '',
      lastName: '',
      phone2: '',
      addressSuffix: '',
      email: '',
      fax: ''
    },
    billingContact: {
      phone: '',
      city: '',
      country: '',
      street: '',
      zip: '',
      salutation: '',
      title: '',
      company: '',
      firstName: '',
      lastName: '',
      phone2: '',
      addressSuffix: '',
      email: '',
      fax: '',
      careOf: ''
    },
    lindenbaumId: '',
    participantLimit: 0,
    permissions: [],
    userLimit: 0,
    invoicePayment: false,
    dataConferenceURL: ''
  };

  salutations = [
    {text: this.t('admin.selectSalutation'), value: '', disabled: true},
    {
      text: this.t('common.mr'),
      value: 'MR'
    },
    {text: this.t('common.mrs'), value: 'MRS'},
    {text: this.t('common.diverse'), value: 'OTHER'}
  ];
  types = [
    {text: this.t('admin.billingCustomer'), value: 'BILLING'},
    {text: this.t('admin.testCustomer'), value: 'TEST'}
  ];
  egn = [
    {text: this.t('admin.numberShorted'), value: true},
    {text: this.t('admin.unshorted'), value: false}
  ];

  loadingPhone1 = false;
  loadingPhone2 = false;

  info = '';

  asContact = false;
  billingClosed = false;

  participantLimit = -1;
  userLimit = -1;
  permissionNames: PermissionName[] = [];

  // TODO: Remove this map, when PRESENTATION permission should be changable on prod
  get productPermList() {
    return productPermList.map(perm => perm.key === 'PRESENTATION' ? { ...perm, disabled: !this.dev } : perm);
  }

  @Watch('asContact')
  asContactChanged(newVal: boolean): void {
    if (newVal) {
      this.customer.billingContact = {
        ...this.customer.billingContact,
        ...this.customer.contact,
        company: this.customer.title
      };
      this.billingClosed = true;
    } else {
      this.billingClosed = false;
    }
  }

  @Watch('billingClosed')
  billingClosedChanged(): void {
    if (this.asContact) {
      this.customer.billingContact = {
        ...this.customer.billingContact,
        ...this.customer.contact,
        company: this.customer.title
      };
    }
  }

  @Validations()
  validations = {
    customer: {
      contact: {
        salutation: {required},
        firstName: {required},
        lastName: {required},
        phone: {
          required,
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.contact.phone')
        },
        phone2: {
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.contact.phone2')
        },
        email: {email: validateEmail, required},
        street: {},
        zip: {},
        city: {},
        country: {},
        title: {},
        company: {}
      },
      billingContact: {
        salutation: {required},
        firstName: {},
        lastName: {},
        phone: {
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.billingContact.phone'),
          required
        },
        phone2: {
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('customer.billingContact.phone2')
        },
        email: {email: validateEmail, required},
        street: {},
        zip: {},
        city: {},
        country: {},
        title: {},
        company: {},
        careOf: {}
      },
      id: {},
      title: {required},
      customerNumber: {},
      participantLimit: {maxValue: maxValue(4000)},
      permissions: {},
      userLimit: {maxValue: maxValue(4000)},
      type: {required},
      bankAccount: {
        accountHolder: {},
        iban: {
          iban: this.ibanValidator
        },
        bic: {},
        bankName: {}
      },
      anonymizePhonenumbers: {},
      invoicePayment: {},
      dataConferenceURL: {
        login: this.presentationUrlValidator,
        url: url
      }
    }
  };

  mounted(): void {
    CustomersService.getGeneratedCustomerId().then((newId) => {
      this.customer.customerNumber = newId;
    });
  }

  setUserLimit(limit: number): void {
    this.customer.userLimit = this.userLimit === -1 ? 0 : +limit;
    this.$v.customer.userLimit?.$touch();
  }

  setParticipantLimit(limit: number): void {
    this.customer.participantLimit = this.participantLimit === -1 ? 0 : +limit;
    this.$v.customer.participantLimit?.$touch();
  }

  setDataConferenceURL(url: string): void {
    this.customer.dataConferenceURL = url ? url : undefined;
    this.$v.customer.dataConferenceURL?.$touch();
  }

  save(): void {
    if (this.asContact) {
      this.customer.billingContact = {...this.customer.contact};
    }

    this.$v.customer.$touch();
    setTimeout(() => {
      if (!this.$v.customer.$anyError) {
        this.customer.userLimit =
            this.userLimit === -1 ? -1 : this.customer.userLimit;
        this.customer.participantLimit =
            this.participantLimit === -1 ? -1 : this.customer.participantLimit;
        this.addCustomer();
      } else {
        this.toast(
            this.t('admin.formContainsErrors'),
            'danger'
        );
      }
    }, 500);
  }

  private addCustomer(): void {
    CustomersService.addCustomer(this.customer)
        .then((customer) => {
          if (this.info) {
            CustomersService.updateInfo(customer.id + '', this.info).then(() => {
              this.toast(
                  this.t('admin.customerAndDescriptionAdded'),
                  'success'
              );
              this.redirect();
            });
          } else {
            this.toast(this.t('admin.customerAdded'), 'success');
            this.redirect();
          }
        })
        .catch(this.showNetworkError);
  }

  redirect(): void {
    this.$router.push('/admin/customers');
  }

  togglePermission(permission: PermissionName): void {
    if (this.customer.permissions) {
      const permissions = this.customer.permissions.map((p) => p.name);
      if (permissions.includes(permission)) {
        this.customer.permissions = this.customer.permissions.filter(
            (p) => p.name !== permission
        );
      } else {
        this.customer.permissions.push({name: permission, additional: ''});
      }
    }
  }

  saveInfo(): void {
    if (this.info && this.customer.id) {
      CustomersService.updateInfo(this.customer.id + '', this.info)
          .then(() =>
              this.toast(this.t('admin.descriptionChangedSuccessfully'), 'success')
          )
          .catch(this.showNetworkError);
    }
  }

  validateState(name: string): boolean | null {
    let validate: any = this.$v.customer;
    const nameArr = name.split('.');
    if (nameArr.length === 1) validate = validate[name];
    else nameArr.forEach((n) => (validate = validate[n]));
    return validate.$dirty && validate.$error ? false : null;
  }

  phoneFormatValidator(
      phoneStr: string
  ): (value: string) => boolean | Promise<boolean> {
    // return Vuelidate validate function that uses phoneStr
    // phoneStr has an 'object.attribute.attribute' structure
    return (value) => {
      const phoneArr: any[] = phoneStr.split('.');
      let phoneForm: any = this.$v; // form
      phoneArr.forEach((p) => (phoneForm = phoneForm[p]));
      if (!phoneForm.required && value === '') return true;
      if (phoneForm?.$dirty && this.customer) {
        // validate
        return ContactsService.validatePhoneNumbers([value])
            .then((validatedNumbers) => {
              const validNumber = validatedNumbers[0];
              const phoneIsValid = validNumber.validNumber;
              if (phoneIsValid) {
                // replace with international
                switch (phoneArr.length) {
                  case 2:
                    (this.customer as any)[phoneArr[1]] =
                        validNumber.international;
                    break;
                  case 3:
                    (this.customer as any)[phoneArr[1]][phoneArr[2]] =
                        validNumber.international;
                    break;
                }
              }
              return phoneIsValid;
            })
            .catch(() => false);
      }
      return false;
    };
  }

  ibanValidator(value: string): boolean | Promise<boolean> {
    const ibanForm = this.$v.customer.bankAccount?.iban;
    if (value === '') {
      this.customer.bankAccount.bic = '';
      this.customer.bankAccount.bankName = '';
      return true;
    }
    if (ibanForm?.$dirty && this.customer) {
      return CustomersService.validateIban(value)
          .then((validation) => {
            if (!validation.valid) return false;
            this.customer.bankAccount.bic = validation.bic;
            this.customer.bankAccount.bankName = validation.name;
            return true;
          })
          .catch(() => {
            this.customer.bankAccount.bic = '';
            this.customer.bankAccount.bankName = '';
            return false;
          });
    }
    return false;
  }
  dataConferenceURLBlur(url: string){
    this.customer.dataConferenceURL = this.customer.dataConferenceURL?.replace(/\/+$/, '');
    this.$v.customer.dataConferenceURL?.$touch()
  }
  presentationUrlValidator(url: string): boolean {
    url = url.replace(/\/+$/, '');
    return !url.endsWith("login")
  }
}
