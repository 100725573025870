




































































































































































import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import WSwitch from '@/components/WSwitch.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import { BookingDateForm } from '@/models/BookingForm';
import { ScheduleType } from '@/models/api';
import { Validations } from 'vuelidate-property-decorators';
import BookingService from '@/service/BookingService';
import AuthenticationService from '@/service/AuthenticationService';
import Base from '@/views/Base';
import BookingDateModal from './DateModal.vue';
import { Playback } from '@/models/Playback';
import PlaybackInputGroup from './PlaybackInputGroup.vue';
import {days} from "@/utils/date";
import {getSelectLanguages} from "@/utils/languages";

@Component({
	components: { WSwitch, WCostCenter, BookingDateModal, PlaybackInputGroup },
	filters: {
		dayInitial(i: number, days: string[]) {
			return days[i].substring(0, 2);
		}
	}
})
export default class BookingDate extends Base {
	@Prop() dialOut!: boolean;
	@Prop() form!: BookingDateForm;
	@Prop() playbacks!: Playback[];
	@PropSync('valid') syncedValid!: boolean;
	@PropSync('tag') syncedTag!: string;
	@PropSync('dirty') syncedDirty!: boolean;

	ScheduleType = ScheduleType;

	get selectedPlayback(): Playback | undefined {
		return this.playbacks.find((p) => p.id === this.form.playbackId);
	}

	languages = getSelectLanguages(this);
	daysList = days(this);

	@Validations()
	validations = {
		form: {
			topic: {},
			language: {},
			costCenter: {},
			recordingMode: {},
			schedule: {
				type: {},
				start: {},
				end: {},
				duration: {},
				isSeries: {},
				allowedWeekdays: {},
				timeInterval: {},
				ordinalDayOfMonth: {},
				periodicity: {},
				periodicityStr: {}
			}
		}
	};

	resetIsSeries = false;
	resetFormSchedule = {
		periodicity: 'DAILY',
		periodicityStr: 'DAILY',
		ordinalDayOfMonth: 1,
		end: new Date(Date.now() + 1000 * 60 * 60 * 24 * 2),
		allowedWeekdays: [true, true, false, false, false, false, false]
	};

	dateOptions = [
		{ value: ScheduleType.DAILY, text: this.t('conference.daily') },
		{ value: ScheduleType.WEEKLY + ',1', text: this.t('conference.weekly') },
		{ value: ScheduleType.WEEKLY + ',2', text: this.$t('conference.everyNWeeks', {n: 2}) },
		{ value: ScheduleType.WEEKLY + ',3', text: this.$t('conference.everyNWeeks', {n: 3}) },
		{ value: ScheduleType.WEEKLY + ',4', text: this.$t('conference.everyNWeeks', {n: 4}) },
		{ value: ScheduleType.MONTHLY, text: this.t('conference.monthly') }
	];
	monthlyOptions = [
		{ value: 1, text: this.$t('conference.everyFirst')},
		{ value: 2, text: this.$t('conference.everySecond') },
		{ value: 3, text: this.$t('conference.everyThird') },
		{ value: 4, text: this.$t('conference.everyFourth') },
		{ value: -1, text: this.$t('conference.everyLast') }
	];

	get selectedTime(): string {
		const date = this.form.schedule.start;
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		return `${hours}:${minutes}`;
	}

	set selectedTime(newTime: string) {
		const hours = +newTime.split(':')[0];
		const minutes = +newTime.split(':')[1];
		const date = this.form.schedule.start;
		if (!isNaN(hours)) date.setHours(hours);
		if (!isNaN(minutes)) date.setMinutes(minutes);
		date.setSeconds(0);
		this.updateTag();
	}

	get selectedDate(): string {
		const date = this.form.schedule.start;
		const month = ('0' + (date.getMonth() + 1)).slice(-2);
		const day = ('0' + date.getDate()).slice(-2);
		return `${date.getFullYear()}-${month}-${day}`;
	}

	set selectedDate(date: string) {
		const year = +date.split('-')[0];
		const month = +date.split('-')[1];
		const day = +date.split('-')[2];
		const beginDate = this.form.schedule.start;
		if (!isNaN(year)) beginDate.setFullYear(year);
		if (!isNaN(month)) beginDate.setMonth(month - 1);
		if (!isNaN(day)) beginDate.setDate(day);
		this.updateTag();
	}

	get seriesToString(): string {
		if (!this.isReccurance) return '';

		const schedule = this.form.schedule;
		const repType = this.dateOptions.find(o => o.value.includes(schedule.periodicityStr))?.text;
		const monthly = this.monthlyOptions.find(o => o.value === schedule.ordinalDayOfMonth)?.text;
		const days = schedule.allowedWeekdays.map((d, i) => d ? this.daysList[i] : '').filter(d => d !== '');

		const endDate = this.form.schedule.end;
		const end = endDate.toLocaleString().split(',')[0];
		const isMonthly = schedule.periodicity === 'MONTHLY';

		return `${repType}, ${isMonthly ? monthly + ', ' : ''} ${repType === 'täglich' ? '' : days.join(', ') + ','} bis zum ${end}`;
	}

	@Watch('dialOut')
	dialOutChanged(): void {
		if (this.form.schedule.type === ScheduleType.ANYTIME) {
			this.form.schedule.type = ScheduleType.IMMEDIATE;
		}
	}

	@Watch('form.schedule.type')
	scheduleTypeChanged(): void {
		this.updateTag();
	}

	@Watch('form.type')
	typeChanged(newVal: 'LIVE' | 'PLAYBACK'): void {
		if (newVal === 'PLAYBACK' && this.form.schedule.type === ScheduleType.IMMEDIATE)
			this.form.schedule.type = ScheduleType.ONETIME;
	}

	updateTag(): void {
		const date = new Date(this.form.schedule.start);
		const localeDate = date.toLocaleString([], {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit'
		});
		this.syncedTag = this.form.schedule.type === ScheduleType.IMMEDIATE ? 'Sofort' : localeDate;
	}

	get isReccurance(): boolean {
		return BookingService.isRecurrence(this.form.schedule.type);
	}

	validateState(name: string, depth?: number): boolean | null {
		if (!this.syncedDirty) this.syncedDirty = true;
		return null;
	}

	openModal(event: boolean): void {
		if (!event) return;
		this.$bvModal.show('seriesModal');
		this.resetFormSchedule = {
			periodicity: this.form.schedule.periodicity,
			periodicityStr: this.form.schedule.periodicityStr,
			ordinalDayOfMonth: this.form.schedule.ordinalDayOfMonth,
			end: this.form.schedule.end,
			allowedWeekdays: [...this.form.schedule.allowedWeekdays]
		};
		if (!this.form.schedule.isSeries) this.form.schedule.isSeries = true;
	}

	handleSeriesOk(): void {
		if (!this.form.schedule.isSeries) this.form.schedule.isSeries = true;
		this.resetIsSeries = true;
	}

	handleSeriesEnter(): void {
		this.handleSeriesOk();
		this.$bvModal.hide('seriesModal');
	}

	handleSeriesCancel(): void {
		this.form.schedule.isSeries = this.resetIsSeries;
		this.form.schedule = {
			...this.form.schedule,
			periodicity: this.resetFormSchedule.periodicity as
				| 'DAILY'
				| 'MONTHLY'
				| 'WEEKLY',
			periodicityStr: this.resetFormSchedule.periodicityStr,
			ordinalDayOfMonth: this.resetFormSchedule.ordinalDayOfMonth,
			end: this.resetFormSchedule.end,
			allowedWeekdays: [...this.resetFormSchedule.allowedWeekdays]
		};
	}

	saveCostCenters(): void {
		const account = {
			...this.user,
			costCenter: this.form.costCenter,
			customerId: this.user.customerShortDTO?.id
		};
		AuthenticationService.updateMyAccount(account).then().catch();
	}
}
