var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header-layout',{attrs:{"title":_vm.t('common.brandings')},scopedSlots:_vm._u([{key:"header-cols",fn:function(){return [_c('b-col',{staticClass:"mt-1",attrs:{"cols":"3","offset-md":"5","md":"2","offset-lg":"3","lg":"3"}},[(_vm.hasPermission('BRANDING'))?_c('w-header-btn',{attrs:{"to":{ name: 'Forms-New-Branding' },"title":_vm.t('brandings.createBranding'),"text":_vm.t('brandings.newBranding'),"icon":"plus"}}):_vm._e()],1)]},proxy:true}])},[(_vm.brandings.length > 0)?_c('b-container',{staticClass:"pt-45"},[_c('b-row',_vm._l((_vm.brandings),function(branding){return _c('b-col',{key:branding.id,staticClass:"mb-4",attrs:{"cols":"12","lg":"4"}},[_c('b-card',[_c('b-card-text',[_c('a',{on:{"click":function($event){_vm.editable(branding)
									? _vm.$router.push(("/forms/branding/" + (branding.id)))
									: ''}}},[_c('div',{staticClass:"branding-preview position-relative",class:{ 'cursor-pointer': _vm.editable(branding) }},[_c('div',{staticClass:"d-flex",class:_vm.logoFlex},[(branding.logo)?_c('div',{staticClass:"preview-logo d-flex",class:_vm.logoFlex},[_c('img',{attrs:{"src":branding.logo.clientSrc
													? branding.logo.clientSrc
													: branding.logo.backendUrl}})]):_c('div',{staticClass:"preview-logo d-flex align-items-center justify-content-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('brandings.noLogo'))+" ")])]),(branding.banner)?_c('div',{staticClass:"preview-banner mt-23"},[_c('img',{attrs:{"src":branding.banner.clientSrc
												? branding.banner.clientSrc
												: branding.banner.backendUrl}})]):_c('div',{staticClass:"preview-banner empty d-flex align-items-center justify-content-center text-muted mt-23"},[_vm._v(" "+_vm._s(_vm.t('brandings.noBanner'))+" ")]),_c('div',{staticClass:"preview-colors d-flex"},[_c('div',{staticClass:"font-weight-medium d-flex align-items-center justify-content-center text-sm",style:({
											backgroundColor: _vm.brandingData(branding).primaryBgColor,
											color: _vm.brandingData(branding).primaryTextColor
										})},[_vm._v(" "+_vm._s(_vm.t('brandings.accentColor'))+" ")]),_c('div',{staticClass:"font-weight-medium d-flex align-items-center justify-content-center text-sm",style:({
											backgroundColor: _vm.brandingData(branding).headerBgColor,
											color: _vm.brandingData(branding).headerTextColor
										})},[_vm._v(" "+_vm._s(_vm.t('brandings.headerColors'))+" ")])])])]),_c('div',[_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"cols":"10"}},[(_vm.editable(branding))?_c('b-link',{attrs:{"to":("/forms/branding/" + (branding.id))}},[_c('h4',{staticClass:"mb-12"},[_vm._v(_vm._s(branding.title))])]):_c('div',[_c('h4',{staticClass:"mb-12"},[_vm._v(_vm._s(branding.title))])])],1),_c('b-col',{staticClass:"d-flex align-items-start justify-content-end",attrs:{"cols":"2"}},[_c('b-dropdown',{attrs:{"size":"sm","variant":"link","toggle-class":"actionBtn text-secondary","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('three-dots-icon',{staticClass:"icon text-secondary"})]},proxy:true}],null,true)},[(_vm.editable(branding))?_c('b-dropdown-item',{attrs:{"to":("/forms/branding/" + (branding.id))}},[_vm._v(" "+_vm._s(_vm.t('brandings.editBranding'))+" ")]):_vm._e(),(_vm.editable(branding))?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
												branding.countRegForms > 0
													? _vm.t('brandings.brandingUsed')
													: ''
											),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\tbranding.countRegForms > 0\n\t\t\t\t\t\t\t\t\t\t\t\t\t? t('brandings.brandingUsed')\n\t\t\t\t\t\t\t\t\t\t\t\t\t: ''\n\t\t\t\t\t\t\t\t\t\t\t",modifiers:{"hover":true}}]},[_c('b-dropdown-item',{attrs:{"disabled":branding.countRegForms > 0},on:{"click":function($event){return _vm.deleteBranding(branding.id)}}},[_vm._v(" "+_vm._s(_vm.t('brandings.deleteBranding'))+" ")])],1):_vm._e(),(_vm.editable(branding))?_c('b-dropdown-divider'):_vm._e(),_c('b-dropdown-item',{attrs:{"to":("/forms/registration/new?brandingId=" + (branding.id))}},[_vm._v(" "+_vm._s(_vm.t('forms.newRegForm'))+" ")]),_c('b-dropdown-item',{attrs:{"to":("/forms/survey/new?brandingId=" + (branding.id))}},[_vm._v(" "+_vm._s(_vm.t('forms.newSurveyForm'))+" ")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-muted d-flex align-items-center"},[(branding.global)?_c('globe-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(_vm.t('brandings.globalBranding')),expression:"t('brandings.globalBranding')",modifiers:{"bottom":true}}],staticClass:"icon mr-2",class:_vm.editable(branding) ? 'text-primary' : 'text-muted'}):_vm._e(),_vm._v(" "+_vm._s(_vm.$tc('brandings.usedInForms', _vm.getCountForms(branding)))+" ")],1)])],1)],1)])],1)],1)}),1)],1):(
			!_vm.hasPermission('BRANDING') && !_vm.hasPermission('CUSTOMER_BRANDING')
		)?_c('b-container',{staticClass:"pt-45"},[_c('b-card',{staticClass:"p-4"},[_vm._v(" "+_vm._s(_vm.t('brandings.noAccess'))+" "),_c('br'),_c('br'),_c('a',{attrs:{"href":"https://umfrage.csn.de/ci-anpassung","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.t('brandings.bookAccess'))+" ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }