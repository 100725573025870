













































































import Component from 'vue-class-component';
import Base from '../Base';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';

import HomeForms from './Forms.vue';
import HomeLists from './Lists.vue';
import HomeRooms from './Rooms.vue';

import WHeaderBtn from '@/components/WHeaderBtn.vue';
import {BookingListItem} from '@/models/Booking';
import BookingService from '@/service/BookingService';
import {ScheduleType} from '@/models/api';
import {EventBus, Events} from '@/EventBus';
import {Conference} from '@/models/Conference';
import ConferenceService from '@/service/ConferenceService';
import HistoricalService from '@/service/HistoricalService';
import {Watch} from 'vue-property-decorator';
import RegistrationService from '@/service/RegistrationService';
import {RegistrationStatistic} from '@/models/Registration';
import SurveyService from '@/service/SurveyService';
import {SurveyStatistic} from '@/models/Survey';

@Component({
	components: {
		HeaderLayout,
		HomeForms,
		HomeLists,
		HomeRooms,
		WHeaderBtn
	}
})
export default class Home extends Base {
	scheduledItems: BookingListItem[] = [];
	rooms: BookingListItem[] = [];
	pastItems: BookingListItem[] = [];
	runningItems: BookingListItem[] = [];

	loading = false;
	listsLoading = {
		past: false,
		scheduled: false,
		running: false,
		rooms: false
	};

	scheduledSize = 0;
	pastSize = 0;
	roomsSize = 0;

	formsStats: RegistrationStatistic = {
		activeDates: 0,
		activeForms: 0,
		activeUser: 0,
		activeDoiConferenceUser: 0,
		activeDoiNewsletterUser: 0,
		pastForms: 0,
		pastUser: 0,
		brandings: 0
	};

	surveyStats: SurveyStatistic = {
		activeSurveys: 0,
		activeUser: 0,
		brandings: 0,
		pastSurveys: 0
	};

	toDeleteBooking: BookingListItem | null = null;

	mounted(): void {
		setTimeout(() => {
			if (this.isUser()) {
				this.initBookings();
				this.initConferences();
				this.initHistorical();
				setTimeout(() => {
					this.initHistorical();
				}, 20000);
				this.initFormStatistics();

				// Eventbus
				EventBus.$on(Events.DeleteConference, (item: BookingListItem) => {
					this.toDeleteBooking = item;

					if (item.schedule && BookingService.isSeries(item.schedule.type)) {
						this.$bvModal.show('series-delete-modal');
					} else {
						this.deleteBooking();
					}
				});
				EventBus.$on(Events.EditConference, (item: BookingListItem) => {
					if (item.type === 'room') {
						this.$router.push(`/conference/booking-room?id=${item.id}`);
					} else {
						this.$router.push(`/conference/booking?id=${item.id}&step=0`);
					}
				});
				EventBus.$on(Events.RepeatConference, (item: BookingListItem) => {
					if (item.type === 'room') {
						this.$router.push(`/conference/booking-room?copyId=${item?.id}&step=0`);
					} else {
						this.$router.push(`/conference/booking?copyId=${item?.id}&step=0`);
					}
				});
				EventBus.$on(Events.ConferenceDetail, (booking: BookingListItem) => {
					switch (booking.type) {
						case 'room':
						case 'scheduled':
							this.$router.push('/conference/scheduled/' + booking.id);
							break;
						case 'past':
							this.$router.push('/conference/past/' + booking.id);
							break;
						case 'running':
							this.$router.push('/conference/running/' + booking.id);
							break;
					}
				});
				EventBus.$on(Events.StartConference, (bookingId: string) => {
					ConferenceService.startConference(bookingId).then(() => {
						this.toast(this.t('conference.conferenceStarted'), 'success');
						this.scheduledItems = this.scheduledItems.filter(
							(item) => item.id !== bookingId
						);
					});
					this.scheduledSize--;
				});
				EventBus.$on(Events.EndConference, (bookingId: string) => {
					const id = this.conferences.find(
						(conf) => conf.bookingId === bookingId
					)?.id;
					if (id)
						ConferenceService.stopConference(id).then(() => {
							this.toast(this.t('conference.conferenceEnded'), 'success');
						});
				});
			}
		}, 200);
	}

	beforeDestroy(): void {
		if (this.isUser()) {
			EventBus.$off(Events.RepeatConference);
			EventBus.$off(Events.DeleteConference);
			EventBus.$off(Events.StartConference);
			EventBus.$off(Events.EditConference);
			EventBus.$off(Events.ConferenceDetail);
			EventBus.$off(Events.EndConference);
		}
	}

	get conferences(): Conference[] {
		return this.$store.getters.conferences;
	}

	@Watch('conferences')
	conferencesChanged(newValue: Conference[], oldValue: Conference[]): void {
		if (newValue.length > 0) {
			this.initConferences();
		} else {
			this.runningItems = [];
		}
		if (newValue.length < oldValue.length) {
			setTimeout(() => {
				this.initHistorical();
			}, 20000);
		}
	}

	initBookings(): void {
		this.listsLoading.scheduled = true;
		this.listsLoading.rooms = true;
		BookingService.getBookings()
			.then((bookings) => {
				const scheduledBookings = bookings.filter(
					(bookings) => bookings.schedule?.type !== ScheduleType.ANYTIME
				);
				this.scheduledSize = scheduledBookings.length;
				this.scheduledItems = scheduledBookings
					.slice(0, 2)
					.map((booking) => BookingService.bookingToConferenceItem(booking));

				const rooms = bookings.filter(
					(bookings) => bookings.schedule?.type === ScheduleType.ANYTIME
				);
				this.roomsSize = rooms.length;
				this.rooms = rooms
					.slice(0, 3)
					.map((booking) => BookingService.bookingToConferenceItem(booking));

				this.listsLoading.scheduled = false;
				this.listsLoading.rooms = false;
				this.checkLoading();
			})
			.catch(this.showNetworkError);
	}

	initConferences(): void {
		this.listsLoading.running = true;
		const conferences = this.conferences;
		if (conferences.length > 0) {
			console.log(conferences);
			this.runningItems = conferences.map((conf) => ({
				...BookingService.bookingToConferenceItem(conf.booking),
				type: 'running',
				participants: conf.currentPhoneParticipants || 0
			}));
		}
	}

	initHistorical(): void {
		this.listsLoading.past = true;
		HistoricalService.getHistoricals(0, 3, 'start', 'DESC', undefined, false)
			.then((res) => {
				if (res.content) {
					this.pastItems = res.content
						.reverse()
						.map((historical) =>
							BookingService.historicalToConferenceItem(historical)
						)
						.map((item) => {
							HistoricalService.getMediaList(item.id).then((list) => {
								if (list.length > 0 && list[0].filename.includes('mp3')) {
									item.file = list[0];
									item.fileUrl = HistoricalService.getMediaFileUrl(
										item.id,
										item.file.filename
									);
								}
							});
							return item;
						})
						.reverse();
					this.pastSize = res.totalSize;
				}
				this.checkLoading();
			})
			.catch(this.showNetworkError)
			.finally(() => (this.listsLoading.past = false));
	}

	initFormStatistics(): void {
		RegistrationService.getRegistrationStatistics()
			.then((stats) => {
				this.formsStats = { ...stats };
				this.checkLoading();
			})
			.catch(this.showNetworkError);
		SurveyService.getSurveyStatistics().then((stats) => {
			this.surveyStats = { ...stats };
			this.checkLoading();
		});
	}

	deleteBooking(ok?: () => void): void {
		const item = this.toDeleteBooking;
		this.toDeleteBooking = null;
		if (ok) ok();
		if (item && item.id) {
			BookingService.deleteBooking(item.id)
				.then(() =>
					this.toast(this.t('conference.conferenceDeleted'), 'success')
				)
				.catch(this.showNetworkError)
				.then(() => this.initBookings());
		}
	}

	deleteSeries(ok: () => void): void {
		const item = this.toDeleteBooking;
		this.toDeleteBooking = null;
		ok();
		if (item && item.id) {
			BookingService.deleteSeries(item.id)
				.then(() => this.toast(this.t('conference.seriesDeleted'), 'success'))
				.catch(this.showNetworkError)
				.then(() => this.initBookings());
		}
	}

	checkLoading(): boolean {
		let allLoaded = true;
		for (const prop in this.listsLoading) {
			if (Object.prototype.hasOwnProperty.call(this.listsLoading, prop)) {
				if (!prop) allLoaded = false;
			}
		}
		return allLoaded;
	}
}
