


















































































































































































































































































































































































































import {Component} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import AdminSection from './AdminSection.vue';
import {Account, Permission, PermissionName} from '@/models/Account';
import {Validations} from 'vuelidate-property-decorators';
import {required} from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '../Base';
import AccountsService from '@/service/AccountsService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import WCountrySelect from '@/components/WCountrySelect.vue';
import {Customer} from '@/models/Customer';
import {validateEmail} from '@/utils/validators';
import {getLanguages} from "@/utils/languages";
import {AdminSectionField} from "@/models/AdminForms";

/**
 * Some documented component
 *
 * @component
 */
@Component({
  components: {
    HeaderLayout,
    SidebarLayout,
    WSwitch,
    AdminSection,
    WHeaderBtn,
    WCostCenter,
    WCountrySelect
  }
})
export default class AdminsItem extends Base {
  account: Account = {
    role: 'ADMIN',
    timezone: '',
    username: '',
    dateCreated: 0,
    id: 0,
    language: 'DE',
    permissions: [],
    contact: {
      lastName: '',
      email: '',
      firstName: '',
      phone: '',
      city: '',
      country: '',
      street: '',
      zip: '',
      company: '',
      salutation: 'MR',
      title: '',
      phone2: ''
    },
    costCenter: '',
    passwordPlain: null,
    active: true,
    dateLastLogin: Date.now(),
    deactivationReason: 'NONE',
    failedLoginCounter: 0,
    participantLimit: 0,
    passwordPolicy: 'NONE',
    timeLimit: -1,
    customerId: 0,
    bookingPreset: {
      dialOutCallRetryCount: 2,
      dialOutCallRetryDelay: 60
    }
  };

  nameEdit = false;
  mailEdit = false;
  oldLastName: string | undefined = '';
  oldFirstName: string | undefined = '';
  oldMail = '';

  userAccount: AdminSectionField[] = [];
  contact: AdminSectionField[] = [];

  languages = getLanguages(this);

  timezones!: { value: string; text: string }[];
  salutations = [
    {text: this.t('common.mr'), value: 'MR'},
    {text: this.t('common.mrs'), value: 'MRS'},
    {text: this.t('common.diverse'), value: 'OTHER'}
  ];

  passwordPolicies = [
    {
      text: this.t('admin.passwordPolicyChangeAfterLogin'),
      value: 'CHANGE_BY_FIRST_LOGIN'
    },
    {text: this.t('admin.none'), value: 'NONE'}
  ];

  editActive = false;
  loadingPhone1 = false;
  loadingPhone2 = false;

  info = '';
  userActive = false;
  userActiveHover = false;

  permissionNames: PermissionName[] = [];

  limit = false;
  timeLimitStr = '';

  participantLimit = -1;
  participantLimitNum = 0;

  customer: Customer | null = null;
  customerPermissionNames: PermissionName[] = [];

  oldUsername = '';

  get userAccountValid(): boolean {
    return (
        (this.$v.account.$anyDirty &&
            !this.$v.account.username?.$invalid &&
            !this.$v.account.passwordPlain?.$invalid) ||
        false
    );
  }

  get contactValid(): boolean {
    return (
        (this.$v.account.$anyDirty &&
            !this.$v.account.contact?.phone?.$invalid &&
            !this.$v.account.contact?.phone?.$invalid) ||
        false
    );
  }

  get dateCreated(): Date {
    return new Date(this.account.dateCreated);
  }

  get lastLogin(): Date {
    return new Date(this.account.dateLastLogin);
  }

  @Validations()
  validations = {
    account: {
      contact: {
        salutation: {},
        firstName: {required},
        lastName: {required},
        phone: {
          required,
          noLetters: ContactsService.noLettersValidator,
          format: this.phoneFormatValidator('account.contact.phone')
        },
        phone2: {},
        email: {required, email: validateEmail},
        street: {},
        zip: {},
        city: {},
        country: {},
        title: {},
        company: {}
      },
      language: {},
      timezone: {},
      costCenter: {},
      username: {required},
      passwordPlain: {},
      passwordPolicy: {},
      timeLimit: {}
    }
  };

  mounted(): void {
    this.getAccount();
  }

  toggleStatus(): void {
    if (this.account.active) {
      AccountsService.deactivateAccount(this.account.username)
          .then((account) => this.setAccount(account))
          .catch(() => this.toast(this.t('admin.errorOnDeactivation'), 'danger'));
    } else {
      AccountsService.activateAccount(this.account.username)
          .then((account) => this.setAccount(account))
          .catch(() => this.toast(this.t('admin.errorOnActivation'), 'danger'));
    }
    this.userActiveHover = false;
  }

  getAccount(): void {
    const {id} = this.$route.params;
    if (id) {
      AccountsService.getAccount(id)
          .then((account) => {
            this.setAccount(account);
            this.oldUsername = account.username;
            if (this.isSAdmin()) {
              AccountsService.getInfo(account.username).then(
                  (info) => (this.info = info)
              );
            }
          })
          .catch(this.showNetworkError);
    }
  }

  submitAfterTouch(submitFtn: () => void, path: string): void {
    this.$v.account[path]?.$touch();
    setTimeout(() => {
      if (!this.$v.account[path]?.$anyError && this.$v.account[path]?.$anyDirty)
        submitFtn();
    }, 250);
  }

  save(): void {
    this.editActive = false;
    this.account.timeLimit = this.limit
        ? new Date(this.timeLimitStr).getTime()
        : -1;
    this.updateAccount();
  }

  saveInfo(): void {
    AccountsService.updateInfo(this.account.username, this.info).then(() =>
        this.toast(this.t('admin.descriptionChangedSuccessfully'), 'success')
    );
  }

  editName(): void {
    this.nameEdit = true;
    this.oldFirstName = this.account.contact.firstName;
    this.oldLastName = this.account.contact.lastName;
  }

  editMail(): void {
    this.mailEdit = true;
    this.oldMail = this.account.contact.email;
  }

  cancelEdit(): void {
    if (this.nameEdit) {
      this.nameEdit = false;
      this.account.contact.firstName = this.oldFirstName;
      this.account.contact.lastName = this.oldLastName;
    }
    if (this.mailEdit) {
      this.mailEdit = false;
      this.account.contact.email = this.oldMail;
    }
  }

  saveNameAndMail(): void {
    this.$v.account['contact']?.$touch();
    if (
        !this.$v.account['contact']?.$anyError &&
        this.$v.account['contact']?.$anyDirty
    ) {
      this.save();
      this.mailEdit = false;
      this.nameEdit = false;
    } else {
      this.toast(this.t('common.verifyRedFields'), 'danger');
    }
  }

  togglePermission(permission: PermissionName): void {
    const permissions = this.account.permissions.map(
        (p) => (p as Permission).name
    );
    if (permissions.includes(permission)) {
      this.account.permissions = (
          this.account.permissions as Permission[]
      ).filter((p: Permission) => p.name !== permission);
    } else {
      (this.account.permissions as Permission[]).push({
        name: permission,
        additional: ''
      });
    }
    AccountsService.updatePermissions(
        this.account.username,
        this.account.permissions as Permission[]
    ).then(() => {
      this.toast(this.t('admin.permissionChanged'), 'success');
      if (this.account.permissions)
        this.permissionNames = this.account.permissions.map(
            (permission) => (permission as Permission).name
        );
    });
  }

  private updateAccount() {
    const acc = this.account;
    acc.permissions = acc.permissions.map((p) => (p as Permission).name);
    AccountsService.updateAccount(acc, this.oldUsername)
        .then((account) => {
          this.setAccount(account);

          this.toast(this.t('admin.adminUpdated'), 'success');
          this.$v.account.$reset();
          if (this.oldUsername && this.oldUsername !== account.username) {
            this.$router.push('/admin/admins/' + account.username);
          }
        })
        .catch(() => {
          if (
              this.$v.account.passwordPlain &&
              this.$v.account.passwordPlain.$dirty
          ) {
            this.toast(this.t('admin.passwordNotFollowingGuidelines'), 'danger');
          } else {
            this.toast(
                this.t('common.anErrorOccurredTryAgain'),
                'danger'
            );
          }
          this.$v.account.$reset();
        });
  }

  cancel(): void {
    this.editActive = false;
    this.$v.account.$reset();
    this.getAccount();
  }

  private setAccount(account: Account): void {
    this.account = {
      ...this.account,
      ...account
    };
    this.userAccount = [
      {text: this.t('common.username') + '*', value: this.account.username},
      {text: this.t('common.password'), value: '******'},
      {
        text: this.t('admin.passwordPolicy'), value: this.account.passwordPolicy === 'CHANGE_BY_FIRST_LOGIN'
            ? this.t('admin.passwordPolicyChangeAfterLogin')
            : this.t('admin.none')
      },
      {text: this.t('admin.timeLimit'), value: this.account.timeLimit && this.account.timeLimit !== -1
              ? this.t('admin.to') + ' ' + new Date(this.account.timeLimit).toLocaleDateString()
              : this.t('admin.none')}
    ]
    this.contact = [
      {text: this.t('common.phone') + '*', value: this.account.contact.phone}
    ]

    if (this.account.permissions)
      this.permissionNames = this.account.permissions.map(
          (permission) => (permission as Permission).name
      );
  }

  validateState(name: string): boolean | null {
    let validate: any = this.$v.account;
    const nameArr = name.split('.');
    if (nameArr.length === 1) validate = validate[name];
    else nameArr.forEach((n) => (validate = validate[n]));
    return validate.$dirty && validate.$error ? false : null;
  }

  phoneFormatValidator(phoneStr: string): (value: string) => boolean | Promise<boolean> {
    // return Vuelidate validate function that uses phoneStr
    // phoneStr has an 'object.attribute.attribute' structure
    return (value) => {
      const phoneArr: any[] = phoneStr.split('.');
      let phoneForm: any = this.$v; // form
      phoneArr.forEach((p) => (phoneForm = phoneForm[p]));
      if (
          (!phoneForm.required && value === '') ||
          (!phoneForm.required && !value)
      )
        return true;
      if (phoneForm?.$dirty && this.account) {
        // validate
        return ContactsService.validatePhoneNumbers([value])
            .then((validatedNumbers) => {
              const validNumber = validatedNumbers[0];
              const phoneIsValid = validNumber.validNumber;
              if (phoneIsValid) {
                // replace with international
                switch (phoneArr.length) {
                  case 2:
                    (this.account as any)[phoneArr[1]] =
                        validNumber.international;
                    break;
                  case 3:
                    (this.account as any)[phoneArr[1]][phoneArr[2]] =
                        validNumber.international;
                    break;
                }
              }
              return phoneIsValid;
            })
            .catch(() => false);
      }
      return false;
    };
  }
}
