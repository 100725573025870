

































































































import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import Base from '@/views/Base';

@Component
export default class BookingSection extends Base {
	@Prop() title!: string;
	@Prop() subtitle!: string;
	@Prop() data!: { [key: string]: string };
	@PropSync('anyActive') editActive!: boolean;
	@Prop({ default: false, type: Boolean }) noAutoClose!: boolean;
	@Prop({ default: false, type: Boolean }) valid!: boolean;
	@Prop({ default: false, type: Boolean }) editOnly!: boolean;
	@Prop({ default: false, type: Boolean }) togglable!: boolean;
	@Prop({ default: false, type: Boolean }) noSubmit!: boolean;
	@PropSync('closed') closedSync!: boolean;

	editMode = false;

	get dataKeys(): string[] {
		return Object.keys(this.data);
	}

	mounted(): void {
		if (this.editOnly) {
			this.edit();
		}
	}

	keyName(key: string): string {
		const translation = this.$t('conference.sections.' + key) as string;
		if (!translation.includes('.')) {
			return translation;
		}
		return key;
	}

	@Watch('editOnly')
	editOnlyChanged(newValue: boolean): void {
		if (newValue) {
			this.edit();
		}
	}

	edit(): void {
		this.editMode = true;
		this.editActive = true;
		this.$emit('edit');
	}

	submit(): void {
		if (!this.noAutoClose) this.editMode = false;
		this.$emit('submit');
	}

	cancel(): void {
		this.editMode = false;
		this.$emit('cancel');
	}

	close(): void {
		this.editMode = false;
	}
}
