import { Playback } from '@/models/Playback';
import axios from 'axios';
import store from '@/store/index';

/**
 * API calls for playbacks
 * @module service/PlaybackService
 */
export default {
	getPlaybacks(): Promise<Playback[]> {
		return axios.get('/audio').then((res) => res.data);
	},
	downloadPlayback(id: number, name: string): Promise<Blob> {
		return axios
			.get(`/audio/${id}/${name}`, { responseType: 'blob' })
			.then((res) => res.data);
	},
	getPlaybackUrl(playback: Playback): string {
		return `${axios.defaults.baseURL}/audio/${playback.id}/${playback.name}?access_token=${store.getters.accessToken}`;
	},
	uploadPlayback(name: string, blob: Blob): Promise<void> {
		const form = new FormData();
		form.append('file', blob);
		const headers = {
			'Content-Type': 'multipart/form-data'
		};
		return axios.post(`/audio/upload/${name}`, form, { headers }).then();
	},
	deletePlayback(id: number): Promise<void> {
		return axios.delete(`/audio/${id}`).then();
	}
};
