<template>
  <div class="speakerProfile">
    <div class="speakerCard">
      <div class="scTopBox" :style="{backgroundColor: speakerCard.color}">
        <div class="nameBox" :style="{color: textColorPrimary}">
          <div class="name line-clamp-2" :style="{ 'color': textColorPrimary }">
            {{ speakerCard.name !== '' ? speakerCard.name : $t('presentation.speakerProfiles.firstAndLastname') }}
          </div>
          <div class="position truncate" :style="{ 'color': textColorSecondary}">
            {{ speakerCard.position !== '' ? speakerCard.position : $t('presentation.speakerProfiles.position') }}
          </div>
        </div>
      </div>
      <div class="scBottomBox"></div>
      <div class="imageBox">
        <div class="imageOverlay defaultImage" v-if="photoUrl">
          <img :src="photoUrl" alt="profile" class="image"
               ref="speakerCardPhoto" :style="photoStyle"
          >
        </div>
        <div v-else class="defaultImage">
          <default-profile-photo />
        </div>
      </div>
      <!-- Use d-none, so the element exists and attributes can be manipulated. See mounted() -->
      <div class="qr" :class="{'d-none': !existingUrl}">
        <qrcode-vue :value="existingUrl || ''" size="29"
                    class="qr-code" level="M" render-as="svg" />
      </div>
    </div>
    <div class="speakerData" :style="socialVisible ? 'padding-bottom: 1.8rem;': ''">
      <div class="speakerDataField truncate" v-if="speakerCard.speakerProfile.email">
        <mail-icon class="icon mailIcon" />
        <a :href="'mailto:' + speakerCard.speakerProfile.email" class="truncate">{{ speakerCard.speakerProfile.email }}</a>
      </div>
      <div class="speakerDataField truncate" v-if="speakerCard.speakerProfile.phone">
        <phone-call-icon class="icon" />
        <span class="truncate">{{ speakerCard.speakerProfile.phone }}</span>
      </div>
      <div class="speakerDataField addressField" v-if="addressText">
        <map-pin-icon class="icon" />
        <span style="white-space: pre-line">{{ addressText }}</span>
      </div>
      <div class="speakerDataField truncate" v-if="speakerCard.speakerProfile.homepageUrl">
        <globe-icon class="icon" />
        <a :href="speakerCard.speakerProfile.homepageUrl" class="truncate">{{ speakerCard.speakerProfile.homepageUrl }}</a>
      </div>
    </div>
    <div class="socialLinks border-bottom" v-if="socialVisible">
      <div v-for="(socialLink, i) in speakerCard.speakerProfile.socialLinks" :key="socialLink.url">
        <div v-if="socialLink.title" class="socialLink border-top">
          <div class="socialLinkInfo">
            <component :is="socialLink.title + '-icon'" class="socialIcon" />
            <a class="socialText" :href="socialLink.url" target="_blank">{{ getSocialName(socialLink.title) }}</a>
          </div>
          <div @click="$emit('editLink', socialLink)" v-if="editable" class="cursor-pointer">
            <pencil-icon class="icon" />
          </div>
          <div @click="removeSocialLink(i)" v-if="editable" class="cursor-pointer">
            <trash-icon class="icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="socialSpacer" v-if="!buttonsVisible && !speakerCard.speakerProfile.infobox"/>

    <div class="buttons" v-if="buttonsVisible" :style="'margin-bottom: ' + (speakerCard.speakerProfile.infobox ? '0.3rem' : '1.8rem')">
      <div v-for="(button, i) in speakerCard.speakerProfile.buttons" :key="button.url">
        <div v-if="button.title" class="buttonBox">
          <div :style="{backgroundColor: speakerCard.color, color: textColorPrimary}"
               class="btn button border" @click="openButton(button.url)">
            <span></span>
            {{ button.title }}
            <span class="d-flex">
              <div v-if="editable" @click.stop="$emit('editButton', button)" class="pr-3 cursor-pointer">
                <pencil-icon class="icon" />
              </div>
              <div v-if="editable" @click.stop="removeButton(i)" class="cursor-pointer">
                <trash-icon class="icon" />
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="infoBox" v-html="infoboxSanitized" v-if="speakerCard.speakerProfile.infobox" />

    <div class="footer" :style="{backgroundColor: speakerCard.color}">
      <a v-if="speakerCard.speakerProfile.homepageUrl && (logoUrl || speakerCard.company)" class="footerCompany"
         :href="speakerCard.speakerProfile.homepageUrl" target="_blank">
        <img :src="logoUrl" class="noPointerEvents" alt="profile" v-if="logoUrl">
        <div v-else :style="{color: textColorPrimary}" class="line-clamp-2">{{ speakerCard.company }}</div>
      </a>
      <div v-else class="footerCompany">
        <img :src="logoUrl" class="noPointerEvents" alt="profile" v-if="logoUrl">
        <div v-else :style="{color: textColorPrimary}" class="line-clamp-2">{{ speakerCard.company }}</div>
      </div>
      <div class="footerLinks">
        <a class="footerButton border" :href="existingUrl ? contactHref: undefined" :download="existingUrl ? contactFileName: undefined"
          v-b-tooltip="existingUrl ? undefined : $t('presentation.speakerProfiles.saveToTest')" :class="{'cursor-pointer': existingUrl}"
        >
          <user-plus-icon class="btnIcon" />
        </a>
        <a class="footerButton border" @click="share" v-if="canShare()"
           v-b-tooltip="existingUrl ? undefined : $t('presentation.speakerProfiles.saveToTest')"
           :class="{'cursor-pointer': existingUrl}"
        >
          <share-icon class="btnIcon" />
        </a>
        <a class="footerButton border" :href="existingUrl ? shareMailToLink : undefined" v-else target="_blank"
           v-b-tooltip="existingUrl ? undefined : $t('presentation.speakerProfiles.saveToTest')"
           :class="{'cursor-pointer': existingUrl}"
        >
          <share-icon class="btnIcon" />
        </a>
      </div>
    </div>
  </div>
</template>


<script>
import DefaultProfilePhoto from '@/views/presentation/speakerProfiles/default-profile-photo.vue';
import DOMPurify from 'dompurify';

export default {
  components: { DefaultProfilePhoto },
  props: {
    speakerCard: {
      type: Object,
      required: true
    },
    logoUrl: {
      type: String,
      default: ''
    },
    photoUrl: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    existingUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {};
  },
  mounted() {
    if (this.speakerCard.speakerProfile === undefined) {
      this.speakerCard.speakerProfile = {};
    }
    this.$nextTick(() => {
      const svgElement = this.$el.querySelector('.qr-code > svg');
      if (svgElement) {
        svgElement.removeAttribute('width');
        svgElement.removeAttribute('height');
        svgElement.style.width = '100%';
        svgElement.style.height = '100%';
      }
    });
  },
  computed: {
    photoStyle() {
      return {
        transform: `scale(${this.speakerCard.photoScale}) translate(${this.speakerCard.photoX}%, ${this.speakerCard.photoY}%)`
      };
    },
    buttonsVisible() {
      return (this.speakerCard.speakerProfile?.buttons?.filter(b => b.title).length || 0) > 0;
    },
    socialVisible() {
      return (this.speakerCard.speakerProfile?.socialLinks?.filter(b => b.title).length || 0) > 0;
    },
    color() {
      return this.speakerCard.color || '#FFFFFF';
    },
    textColorPrimary() {
      return this.isDark ? '#fff' : '#000';
    },
    textColorSecondary() {
      return this.isDark ? '#ddd' : '#111';
    },
    isDark() {
      if (!this.color.startsWith('#') || this.color.length !== 7) {
        return true;
      }

      const r = parseInt(this.color.substring(1, 3), 16);
      const g = parseInt(this.color.substring(3, 5), 16);
      const b = parseInt(this.color.substring(5, 7), 16);

      const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return luminance < 128;
    },
    contactHref() {
      const card = this.speakerCard;
      const profile = this.speakerCard.speakerProfile;
      const [firstName, ...lastNameParts] = card.name.split(' ');
      const lastName = lastNameParts.join(' ');

      let vCardData = `BEGIN:VCARD\nVERSION:3.0\nFN:${(card.name)}\n`;

      vCardData += `N:${lastName};${firstName};;;\n`;

      if (profile?.phone) {
        vCardData += `TEL;TYPE=WORK,VOICE:${(profile?.phone)}\n`;
      }
      if (profile?.email) {
        vCardData += `EMAIL;TYPE=WORK:${(profile?.email)}\n`;
      }
      if (profile?.homepageUrl) {
        vCardData += `URL:${(profile?.homepageUrl)}\n`;
      }
      if (card.company) {
        vCardData += `ORG:${(card.company)}\n`;
      }
      if (profile?.address) {
        vCardData += `ADR;TYPE=WORK:;;${(profile?.address)}\n`;
      }

      vCardData += 'END:VCARD';

      const blob = new Blob([vCardData.trim()], { type: 'text/vcard' });

      return URL.createObjectURL(blob);
    },
    contactFileName() {
      return this.speakerCard.name.replace(' ', '-') + '.vcf';
    },
    shareMailToLink() {
      const subject = 'Visitenkarte von ' + this.speakerCard.name;
      const body = `${this.url()}/${this.speakerCard.url}`;
      return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    },
    infoboxSanitized(){
      return DOMPurify.sanitize(this.speakerCard.speakerProfile.infobox)
    },
    addressText(){
      return this.speakerCard.speakerProfile.address.replace(/\|/g, '\n');
    }
  },
  methods: {
    removeSocialLink(i) {
      this.speakerCard.speakerProfile?.socialLinks?.splice(i, 1);
      if (this.speakerCard.speakerProfile?.socialLinks?.length === 0) {
        this.speakerCard.speakerProfile.socialLinks.push({ title: '', url: '' });
      }
    },
    removeButton(i) {
      this.speakerCard.speakerProfile?.buttons?.splice(i, 1);
      if (this.speakerCard.speakerProfile?.buttons?.length === 0) {
        this.speakerCard.speakerProfile.buttons.push({ title: '', url: '' });
      }
    },
    openButton(url) {
      window.open(url, '_blank');
    },
    setPosition(x, y) {
      const scale = this.speakerCard.photoScale;
      this.speakerCard.photoScale = (this.speakerCard.photoScale || 1.5) + 0.1;
      setTimeout(() => {
        this.speakerCard.photoScale = scale;
      }, 10);
    },
    getSocialName(name) {
      return {
        'facebook': 'Facebook',
        'instagram': 'Instagram',
        'linkedin': 'LinkedIn',
        'vimeo': 'Vimeo',
        'x': 'X',
        'xing': 'Xing',
        'youtube': 'YouTube'
      }[name] || '';
    },
    url() {
      return process.env.VUE_APP_PROFILE_URL;
    },
    canShare() {
      return !!navigator.share;
    },
    share() {
      if(!this.existingUrl){
        return;
      }
      if (navigator.share) {
        navigator.share({
          title: 'Visitenkarte von ' + this.speakerCard.name,
          text: '',
          url: this.url() + '/' + this.speakerCard.url
        });
      }
    }
  }
};
</script>


<style scoped lang="scss">

.speakerProfile {
  background-color: #fff;
}

.speakerCard {
  aspect-ratio: 21/9;
  position: relative;

  .scTopBox {
    height: 60%;
    width: 100%;
    align-items: center;
    display: flex;
  }

  .scBottomBox {
    height: 40%;
    width: 100%;

  }

  .nameBox {
    width: 60%;
    margin-right: 0;
    margin-left: auto;
    padding-left: 2%;
  }

  .name {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10%;
  }

  .position {
    font-size: 0.9rem;
    margin-right: 10%;
  }

  .imageBox {
    position: absolute;
    top: 4.7%;
    left: 3%;
    width: 40%;
    display: flex;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
  }

  .defaultImage {
    width: 80%;
  }

  .imageOverlay {
    border-radius: 50%;
    border: 1px solid white;
    aspect-ratio: 1;
    overflow: hidden;
    position: relative;
    background-color: #eee;
    width: 80%;
  }

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .qr {
    position: absolute;
    border: 1px solid gray;
    padding: 5px;
    right: 5%;
    bottom: 15%;
    background-color: #fff;
    border-radius: 5%;
    width: 15%;
    height: auto;
    aspect-ratio: 1;
  }
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.speakerData {
  .addressField{
    word-wrap: anywhere;
    max-width: 90%;
  }
  .speakerDataField {
    display: flex;
    padding: 0.2em 10%;
    max-width: 100%;
    align-items: center;

    span, a {
      margin-left: 5%;
    }

    .mailIcon {
      transform: translateY(2px);
    }
  }
}

.socialLinks {
  .socialLink {
    align-items: center;
    display: flex;
    padding-left: 10%;
    padding-right: 8%;
    height: 4em;
    justify-content: space-between;
    background-color: var(--w-bg);

    .socialLinkInfo {
      display: flex;
      align-items: center;
      width: 80%;


      .socialText {
        margin-left: 5%;
      }
    }

  }
}

.buttons {
  margin-top: 1.8rem;

  .buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 9;

    .button {
      width: 90%;
      aspect-ratio: 24;
      font-size: 0.9rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        height: 90%;
      }
    }
  }
}

.infoBox {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.footerCompany img {
  max-width: 100%;
  max-height: 100%;
}

.footer {
  aspect-ratio: 17/4;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footerCompany {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 5%;
    width: 60%;
    margin-right: 5%;
    height: 50%;
  }

  .footerLinks {
    margin-right: 3%;
    width: 35%;
    height: 55%;
    justify-content: space-around;
    display: flex;

    .footerButton {
      border-radius: 50%;
      aspect-ratio: 1;
      background-color: #fff;
      color: #000;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .btnIcon {
        height: 50%;
      }
    }
  }
}

.border {
  border: 1px solid #dee2e6;
}

.socialSpacer {
  height: 2.5rem;
}

</style>
