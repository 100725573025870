import {parseInt} from 'lodash';
import {RegistrationUserWithData} from '@/models/Registration';
import Vue from "vue";

export function abbr(value: string): string {
	return !value ? '-' : value.charAt(0);
}

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
export function fileSize(b: number): string {
	let u = 0;
	const s = 1024;
	while (b >= s || -b >= s) {
		b /= s;
		u++;
	}
	return (u ? b.toFixed(1) + ' ' : b) + ' KMGTPEZY'[u] + 'B';
}

export function formatStatus(status: string | undefined, instance: Vue): string {
	if (!status) return '';
	switch (status) {
		case 'DIALOUT_BUSY':
			return instance.$t('conference.busy') as string;
		case 'DIALOUT_FAILED':
			return instance.$t('conference.notReached') as string;
		default:
			return instance.$t('conference.ended') as string;
	}
}

export function formatType(type: string | undefined, instance: Vue): string {
	if (!type) return '';
	switch (type) {
		case 'DIALIN':
			return instance.$t('common.dialInEn') as string;
		case 'DIALOUT':
			return instance.$t('common.dialOut') as string;
		case 'DIALIN/DIALOUT':
			return instance.$t('common.dialInEn') as string + ' / ' + instance.$t('common.dialOut') as string;
		case 'PRESENTATION':
			return instance.$t('nav.presentation') as string;
		case 'COLLABORATION':
			return instance.$t('conference.collaboration') as string;
		case 'RECORDING':
			return instance.$t('common.recording') as string;
		case 'AUDIO_STREAMING':
			return instance.$t('conference.audioStreaming') as string;
		case 'UNKNOWN':
		default:
			return instance.$t('conference.unknown') as string;
	}
}

export function durationStr(b: number | undefined): string {
	if (!b) return '-';
	const minutes = String(Math.floor(b / 60)).padStart(2, '0');
	const seconds = String(b % 60).padStart(2, '0');
	return `${minutes}:${seconds}`;
}

export function durationStrWithSeconds(b: number | undefined): string {
	if (!b) return '-';
	const hours = String(Math.floor(b / 3600)).padStart(2, '0');
	const minutes = String(Math.floor(b / 60) % 60).padStart(2, '0');
	const seconds = String(b % 60).padStart(2, '0');
	return `${hours}:${minutes}:${seconds}`;
}

export function dateToDateString(date: Date): string {
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const day = date.getDate().toString().padStart(2, '0');
	return `${date.getFullYear()}-${month}-${day}`;
}

export function trimString(str: string, count: number): string {
	if (str.length <= count) return str;
	return str.trim().substring(0, count) + '...';
}

export function formatNames(files: File[]): string {
	return files.length === 1 ? files[0].name : `${files.length} files selected`;
}

export function dateToDateTimeString(
	date: Date | number | undefined,
	withSeconds = false
): string {
	if (!date) return '-';
	if (typeof date === 'number') date = new Date(date);
	const timeOptions: Intl.DateTimeFormatOptions = {
		hour: '2-digit',
		minute: '2-digit'
	};
	if (withSeconds) {
		timeOptions.second = '2-digit';
	}
	return `${date.toLocaleDateString([], {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric'
	})}, ${date.toLocaleTimeString([], timeOptions)}`;
}

export function convertBoolString(string: string): string {
	if (string.toLowerCase() === 'true' || string.toLowerCase() === 'ja')
		return '✓';
	if (string.toLowerCase() === 'false' || string.toLowerCase() === 'nein')
		return ' ';
	else return string;
}

export function minDurationReached(duration: number | undefined, instance: Vue): string {
	if (!duration) return '';
	return duration > 900 ? instance.$t('common.yes') as string : instance.$t('common.no') as string;
}

export function formatDateTimeForFiles(
	start: string | number | undefined
): string {
	if (!start) return '-';
	const date = new Date(typeof start === 'string' ? parseInt(start) : start);
	return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
		2,
		'0'
	)}-${String(date.getDate()).padStart(2, '0')}_${String(
		date.getHours()
	).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(
		date.getSeconds()
	).padStart(2, '0')}`;
}

export function formatDateForFiles(start: string | number | undefined): string {
	if (!start) return '-';
	const date = new Date(typeof start === 'string' ? parseInt(start) : start);
	return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
		2,
		'0'
	)}-${String(date.getDate()).padStart(2, '0')}`;
}

export function formatMillisecondsToHoursMinutes(
	time: number,
	detailed: boolean,
	instance: Vue
): string {
	let hours = Math.floor(time / 3600000)
		.toString()
		.padStart(2, '0');
	let minutes = Math.floor((time / 60000) % 60)
		.toString()
		.padStart(2, '0');
	if (detailed) {
		hours = hours === '00' ? '' : hours + ' ' + instance.$t('common.hours') as string + ' ';
		minutes += ' ' + instance.$t('common.minutesLong') as string;
		return `${hours} ${minutes}`;
	}
	return `${hours}:${minutes}`;
}

/*
 * Date time to (german) readable String
 * ex.: 123456789 -> "01.01.1970 um 01:00 Uhr"
 */
export function formatDateCreated(start: string | number | undefined, component?: Vue): string {
	if (!start) return '-';
	const date = new Date(typeof start === 'string' ? parseInt(start) : start);
	const at = component?.$t('common.at') || 'um';
	return `${date.toLocaleDateString([], {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric'
	})} ${at} ${date.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit'
	})} Uhr`;
}

/*
 * Format a number with a "." for each thousand, i.e. 1000 -> 1.000
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 */
export function formatNumberString(str: string | number): string {
	return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const compareRegUserByBeginDate = (
	a: RegistrationUserWithData,
	b: RegistrationUserWithData
): number => {
	if (a.summarizedParticipant && b.summarizedParticipant) {
		if (a.summarizedParticipant.status === 'DIALOUT_FAILED' || a.summarizedParticipant.status === 'DIALOUT_BUSY') return 1;
		if (b.summarizedParticipant.status === 'DIALOUT_FAILED' || b.summarizedParticipant.status === 'DIALOUT_BUSY') return -1;
		return (
			a.summarizedParticipant.beginDate - b.summarizedParticipant.beginDate
		);
	}
	return 0;
};

export const compareRegUserByCreatedDate = (
	a: RegistrationUserWithData,
	b: RegistrationUserWithData
): number => {
	return a.dateCreated && b.dateCreated ? a.dateCreated - b.dateCreated : 0;
};

export function formatFileSize(bytes: number): string {
	if (bytes === 0) return '0 Bytes';
	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function summarizeRegUserParticipation(
	regUsers: RegistrationUserWithData
): RegistrationUserWithData {
	if (regUsers.participants && regUsers.participants.length > 0) {
		regUsers.summarizedParticipant = regUsers.participants[0];
		if (regUsers.participants.length !== 1) {
			regUsers.summarizedParticipant.duration = regUsers.participants
				.map((p) => p.duration)
				.reduce((a, b) => a + b, 0);

			const participantsWithDuration = regUsers.participants.filter(
				(p) => p.duration > 0
			);
			if (participantsWithDuration.length > 0) {
				regUsers.summarizedParticipant.beginDate = participantsWithDuration
					.map((p) => p.beginDate)
					.reduce((a, b) => Math.min(a, b));
			} else {
				regUsers.summarizedParticipant.beginDate = regUsers.participants
					.map((p) => p.beginDate)
					.reduce((a, b) => Math.min(a, b));
			}

			const isDialIn =
				regUsers.participants
					.map((p) => p.type)
					.find((type) => type === 'DIALIN') !== undefined;
			const isDialOut =
				regUsers.participants
					.map((p) => p.type)
					.find((type) => type === 'DIALOUT') !== undefined;
			if (isDialIn && isDialOut)
				regUsers.summarizedParticipant.type = 'DIALIN/DIALOUT';
			const isNotFailed = regUsers.participants
				.map((p) => p.status)
				.find(
					(status) => status !== 'DIALOUT_FAILED' && status !== 'DIALOUT_BUSY'
				);
			if (isNotFailed) regUsers.summarizedParticipant.status = isNotFailed;
		}
	}
	return regUsers;
}

export function translateSalutation(
	salutation: string | undefined | null, instance: Vue
): string {
	if (!salutation) return '-';
	switch (salutation.toLowerCase()) {
		case 'male':
		case 'mr':
			return instance.$t('common.mr') as string;
		case 'female':
		case 'mrs':
			return instance.$t('common.mrs') as string;
		case 'divers':
			return instance.$t('common.divers') as string;
		default:
			return '-';
	}
}

export function replaceNewLinesWithParagraphs(text: string | null): string {
	if (!text) return '';
	const regex = /(.*)\n?/gm;
	let match;
	let output = '';

	while ((match = regex.exec(text)) !== null) {
		// This is necessary to avoid infinite loops with zero-width matches
		if (match.index === regex.lastIndex) {
			regex.lastIndex++;
		}

		// The result can be accessed through the `m`-variable.
		match.forEach((match, groupIndex) => {
			if (groupIndex === 1) output += '<p>' + match + '</p>';
		});
	}

	output = output.replaceAll('<p></p>', '<p><br></p>');
	return output;
}
