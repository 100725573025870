







































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import {SurveyForm} from '@/models/SurveyForm';
import {Component, Watch} from 'vue-property-decorator';
import Base from '../Base';
import SurveyGeneral from './components/General.vue';
import SurveySpeakers from './components/Speakers.vue';
import SurveyDates from './components/Dates.vue';
import SurveyParticipation from './components/Participation.vue';
import SurveyNewEmail from './components/Email.vue';
import SurveyPrivacy from './components/Privacy.vue';
import SurveyQuestions from './components/Questions.vue';
import SurveyCertificate from './components/Certificate.vue';
import SurveyDuration from './components/Duration.vue';
import {Validations} from 'vuelidate-property-decorators';
import {helpers, maxLength, maxValue, required, requiredIf, url} from 'vuelidate/lib/validators';
import {Branding} from '@/models/Branding';
import BrandingService from '@/service/BrandingService';
import SurveyService from '@/service/SurveyService';
import {Survey, SurveyQuestion} from '@/models/Survey';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import {AxiosError} from 'axios';
import {validateEmail} from '@/utils/validators';
import {surveyUrlExists} from "@/utils/formUrls";

@Component({
	components: {
		HeaderLayout,
		SurveyGeneral,
		SurveySpeakers,
		SurveyDates,
		SurveyParticipation,
		SurveyNewEmail,
		SurveyPrivacy,
		SurveyQuestions: SurveyQuestions,
		SurveyCertificate: SurveyCertificate,
		SurveyDuration,
		WHeaderBtn
	}
})
export default class SurveyEdit extends Base {
	form: SurveyForm = SurveyService.newSurveyForm();
	survey: Survey | null = null;
  oldUrl: string|null = null;

	branding: Branding | null = null;

	surveyId = 0;
	tempFile = '';
	file!: File;

	isEdit = false;

	@Validations()
	validations = {
		form: {
			general: {
				title: { required, maxLength: maxLength(255) },
				subtitle: { maxLength: maxLength(255) },
				url: {
					required,
					noSpecialChars: helpers.regex('alpha', /^[a-zA-Z0-9_.-]*$/),
					maxLength: maxLength(255),
          exists: this.urlExistsValidator
				}
			},
			questions: {
				required,
				$each: {
					title: { required, maxLength: maxLength(255) },
					checkboxQuestionOptions: {
						// minOptionsCount: (val: any, model: SurveyQuestion) => model.type === 'CHECKBOX' ? model.checkboxQuestionOptions.length >= 2 : true,
						hasRightAnswer: (val: any, model: SurveyQuestion) => model.type === 'CHECKBOX' && model.points ? model.checkboxQuestionOptions.some(o => o.rightAnswer) : true,
						$each: {
							caption: { required, maxLength: maxLength(255) }
						}
					},
					radioQuestionOptions: {
						// minOptionsCount: (val: any, model: SurveyQuestion) => model.type === 'RADIO' ? model.radioQuestionOptions.length >= 2 : true,
						hasRightAnswer: (val: any, model: SurveyQuestion) => model.type === 'RADIO' && model.points ? model.radioQuestionOptions.some(o => o.rightAnswer) : true,
						$each: {
							caption: { required, maxLength: maxLength(255) }
						}
					},
					multipleTextQuestionOptions: {
						required: requiredIf((model) => model.type === 'MULTIPLE_TEXT'),
						$each: {
							caption: { required, maxLength: maxLength(255) }
						}
					},
					type: { required }
				}
			},
			mail: {
				subject: {
					maxLength: maxLength(255),
					required: requiredIf((mail) => mail.enabled)
				},
				text: { required: requiredIf((mail) => mail.enabled) },
				replyTo: {
					maxLength: maxLength(255),
					required: requiredIf((mail) => mail.enabled),
					email: validateEmail
				},
				type: { required: requiredIf((mail) => mail.enabled) }
			},
			participation: {
				checkboxes: {
					$each: {
						title: {
							required,
							maxLength: maxLength(4096)
						}
					}
				},
				fields: {
					$each: {
						title: {
							required,
							maxLength: maxLength(4096)
						}
					}
				}
			},
			speakers: {
				$each: {
					name: {
						maxLength: maxLength(255),
						required: requiredIf(val => !!val.image)
					},
					image: {}
				}
			},
			legal: {
				privacyURL: { url, required, maxLength: maxLength(255) },
				imprintName: { required, maxLength: maxLength(255) },
				imprintCompany: { maxLength: maxLength(255) }
			},
			certificateData: {
				multipleChoiceCreditsCondition: { maxValue: maxValue(100) },
				multipleParticipation: {}
			},
			duration: {}
		}
	};

	@Watch('form.general.branding')
	getBranding(): void {
		if (this.form.general.branding !== null) {
			BrandingService.getBranding(+this.form.general.branding)
				.then((b) => (this.branding = b))
				.catch(this.showNetworkError);
		} else {
			this.branding = null;
		}
	}

	@Watch('form.mail.enabled')
	mailEnabledChanged(): void {
		this.form.mailEnabled = this.form.mail.enabled;
	}

	get mailEnabled(): boolean {
		return this.form.mail.enabled;
	}

	mounted(): void {
		this.init();
	}

	init(): void {
		const { id } = this.$route.params;
		if (id) {
			this.surveyId = +id;
			SurveyService.getSurvey(id)
				.then((survey) => {
					if (survey.speakers)
						survey.speakers = survey.speakers.sort(
							(a, b) => (a.idx || 0) - (b.idx || 0)
						);
					this.survey = survey;
          this.oldUrl = survey.url.replace("/", "");
					this.form = SurveyService.surveyToSurveyForm(survey);
					if (survey.certificateData.certificateTemplateFilename) {
						this.tempFile =
							'/' + survey.certificateData.certificateTemplateFilename;
					}
					if (survey.speakers && survey.speakers.length > 0) {
						this.form.speakers.forEach((speaker) => {
							if (speaker.id) {
								const imageUrl = SurveyService.getSpeakerImageUrl(
									this.surveyId,
									speaker.id as number
								);
								speaker.image = { src: imageUrl };
							}
						});
					}
					this.getBranding();
					this.isEdit = true;
					if (survey.title === survey.label) {
						this.form.general.label = '';
					}
				})
				.catch(this.showNetworkError);
		} else {
			this.$router.push({ name: 'Forms-New-Survey' });
		}
	}

	errorHandler(err: AxiosError): void {
		this.init();
		this.showNetworkError(err);
	}

	async submit(): Promise<void> {
		this.$v.form.$touch();
    if (this.$v.$pending) {
      setTimeout(this.submit, 50);
      return;
    }
		if (this.$v.form.$invalid) {
			this.form.questions.forEach((question) => (question.open = true));
			this.toast(this.t('forms.formHasErrors'), 'danger');
		} else if (this.tempFile === '' && this.form.certificateEnabled) {
			this.toast(this.t('forms.noCertificate'), 'danger');
		} else {
			this.baseLoading = true;
			const newSurvey = SurveyService.surveyFormToSurvey(this.form);
			const oldSurvey = this.survey;

			if (newSurvey.label === '') {
				newSurvey.label = newSurvey.title;
			}
			if (oldSurvey !== null) {
				// TODO Reihenfolge updaten
				if (oldSurvey.id) {
					await SurveyService.updateGeneral(oldSurvey.id, newSurvey).catch(this.errorHandler);
				}

				// compare speakers and speaker images
				const oldSpeakers = oldSurvey?.speakers;
				const newSpeakers = newSurvey.speakers;
				if (oldSpeakers && newSpeakers) {
					for (const element of oldSpeakers) {
						const oldSpeaker = element;
						const newSpeaker = newSpeakers.find(sp => sp.id === oldSpeaker.id);
						if (newSpeaker) {
							// compare speaker names
							if (oldSpeaker.name !== newSpeaker.name || oldSpeaker.idx !== newSpeaker.idx) {
								if (oldSurvey.id && oldSpeaker.id) {
									await SurveyService.updateSpeaker(oldSurvey.id, oldSpeaker.id, newSpeaker)
										.catch(this.errorHandler);
								}
							}
						} else {
							// remove speaker
							if (oldSurvey.id && oldSpeaker.id) {
								await SurveyService.deleteSpeaker(oldSurvey.id, oldSpeaker.id as number)
									.catch(this.errorHandler);
							}
						}
					}
				}
				// add new speakers
				const oldSpeakerIds = oldSpeakers?.map((oldSp) => oldSp.id) || [];
				for (const newSpeaker of newSpeakers.filter(speaker => !oldSpeakerIds.includes(speaker.id))) {
					if (oldSurvey.id) {
						await SurveyService.addSpeaker(oldSurvey.id, newSpeaker)
							.then(resSp => {
								console.log('addedSpeaker');
								if (resSp.id && oldSurvey.id) {
									if (newSpeaker.image?.file) {
										return SurveyService.addImage(resSp.id, oldSurvey.id, newSpeaker.image.file).then(() => console.log('added image and speaker'));
									}
								}
							})
							.catch(this.errorHandler);
					}
				}

				// compare questions
				const oldQuestions = oldSurvey.questions || [];
				const newQuestions = newSurvey.questions;
				if (oldQuestions && newQuestions) {
					for (const element of oldQuestions) {
						const oldQuestion = element;
						const newQuestion = newQuestions.find(question => question.id === oldQuestion.id);
						if (newQuestion) {
							// update old date with new one
							if (oldSurvey.id && newQuestion.id) {
								newQuestion.evaluate = !!newQuestion.points;
								await SurveyService.updateQuestion(oldSurvey.id, newQuestion.id, newQuestion)
									.then(() => console.log('updated question'))
									.catch(this.errorHandler);
							}
						} else {
							// delete
							if (oldSurvey.id && oldQuestion.id) {
								await SurveyService.deleteQuestion(oldSurvey.id, oldQuestion.id).catch(this.errorHandler);
							}
						}
					}
				}

				// add new nonexistent questions
				if (newQuestions) {
					const oldQuestionIds = oldQuestions.map((oldQuestion) => oldQuestion.id) || [];
					for (const newQuestion of newQuestions.filter(q => !oldQuestionIds.includes(q.id))) {
						if (oldSurvey.id) {
							newQuestion.evaluate = !!newQuestion.points;
							await SurveyService.addQuestion(oldSurvey.id, newQuestion).catch(this.errorHandler);
						}
					}
				}

				const oldFilename = this.form.certificateData.certificateTemplateFilename;
				if (oldFilename) {
					// old file exists, check if tempFile is different
					if (this.tempFile === '' || this.tempFile && !this.tempFile.includes(oldFilename)) {
						// tempFile is different: Delete old file
						await SurveyService.deleteCertificate(this.surveyId, oldFilename).catch(this.errorHandler);

						if (this.tempFile && this.tempFile !== '') {
							// new tempFile exists, upload
							await SurveyService.addCertificate(this.surveyId, this.file).catch(this.errorHandler);
						}
					}
				} else if (this.tempFile) {
					// old file doesn't exist, upload tempFile
					await SurveyService.addCertificate(this.surveyId, this.file).catch(
						this.errorHandler
					);
				}
			}

			this.baseLoading = false;
			this.toast(this.t('forms.formSaved'), 'success');
			await this.$router.push(
				'/forms/survey?mode=' +
					(this.$route.query.mode || 'active') +
					`&formUrl=${this.form.general.url}`
			);
		}
	}

  urlExistsValidator(value: string): Promise<boolean> | boolean {
    const url = this.$v.form.general?.url;
    if(value === this.oldUrl) return true;
    if (!url?.$dirty) return true;
    return surveyUrlExists(value).then(
        (value) => !value
    )
  }
}
