






































































































































import { Component, Watch } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WPagination from '@/components/WPagination.vue';
import WListOptions from '@/components/listlayout/WListOptions.vue';
import { ListOptions } from '@/models/Booking';
import AccountsService from '@/service/AccountsService';
import AuthenticationService from '@/service/AuthenticationService';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: { HeaderLayout, WListOptions, WPagination, WHeaderBtn }
})
export default class Admins extends Base {
	items: any[] = [];

	fields = [
		{
			key: 'id',
			label: this.t('common.id'),
			sortable: true,
			thStyle: { width: '80px' },
			sortDirection: 'desc'
		},
		{ key: 'name', label: this.t('common.name'), sortable: true, thStyle: { width: '250px' }, sortDirection: 'desc' },
		{
			key: 'username',
			label: this.t('common.username'),
			sortable: true,
      thStyle: { width: '250px' },
			sortDirection: 'desc'
		},
		{
			key: 'dateCreated',
			label: this.t('common.created'),
			thStyle: { width: '120px' },
			sortable: true,
			sortDirection: 'desc'
		},
		{
			key: 'dateLastLogin',
			label: this.t('admin.login'),
			thStyle: { width: '170px' },
			sortable: true,
			sortDirection: 'desc'
		},
		{
			key: 'active',
			label: this.t('common.status'),
			thStyle: { width: '120px' },
			sortable: true,
			sortDirection: 'desc'
		},
		{
			key: 'actions',
			label: this.t('common.actions'),
			thStyle: { width: '90px' },
			thClass: 'show-content-md'
		}
	];

	options: ListOptions = {
		entryCount: 100,
		searchTerm: ''
	};

	currentPage = 1;
	totalRows = 0;
	totalPages = 1;

	sortBy = 'dateCreated';
	sortDesc = true;

	mounted(): void {
		this.loadFromLocalStorage();
		this.getAdminAccounts();
	}

	@Watch('options.searchTerm')
	search(): void {
		this.currentPage = 1;
		setTimeout(() => {
			this.getAdminAccounts();
		}, 100);
	}

	@Watch('currentPage')
	currentPageChanged(): void {
		this.getAdminAccounts();
	}

	@Watch('options.entryCount')
	entryCount(): void {
		this.currentPage = 1;
		this.getAdminAccounts();
	}

	@Watch('sortBy')
	sort(): void {
		this.getAdminAccounts();
	}

	@Watch('sortDesc')
	sortByDirection(): void {
		this.getAdminAccounts();
	}

	get perPage(): number {
		return this.options.entryCount;
	}

	set perPage(count: number) {
		this.options.entryCount = count;
	}

	get showPagination(): boolean {
		return this.perPage <= this.totalRows;
	}

	loadFromLocalStorage(): void {
		if (localStorage.getItem('admins')) {
			let adminsFilter;
			try {
				const parsedAdminsFilter = localStorage.getItem('admins') as any;
				adminsFilter = JSON.parse(parsedAdminsFilter);
			} catch (e) {
				this.clearLocalStorage();
			}
			if (adminsFilter) {
				if (adminsFilter.entryCount)
					this.options.entryCount = adminsFilter.entryCount;
				if (adminsFilter.searchTerm)
					this.options.searchTerm = adminsFilter.searchTerm;
				if (adminsFilter.currentPage)
					this.currentPage = adminsFilter.currentPage;
				if (adminsFilter.sortBy) this.sortBy = adminsFilter.sortBy;
				if (adminsFilter.sortDesc) this.sortDesc = adminsFilter.sortDesc;
			}
		}
	}

	clearLocalStorage(): void {
		localStorage.removeItem('admins');
	}

	setToLocalStorage(): void {
		localStorage.setItem(
			'admins',
			JSON.stringify({
				entryCount: this.options.entryCount,
				searchTerm: this.options.searchTerm,
				currentPage: this.currentPage,
				sortBy: this.sortBy,
				sortDesc: this.sortDesc
			})
		);
	}

	private getAdminAccounts(): void {
		this.setToLocalStorage();
		AccountsService.getAccounts(
			this.currentPage - 1,
			this.perPage,
			this.sortBy,
			this.sortDesc ? 'DESC' : 'ASC',
			'ADMIN',
			this.options.searchTerm,
			undefined,
			this.sortBy !== 'id'
		)
			.then((res) => {
				this.totalRows = res.totalSize;
				this.totalPages = res.totalPages;

				if (!res.empty) {
					this.items = res.content.map((item) => {
						return {
							id: item.id,
							username: item.username,
							name: `${item.contact.firstName} ${item.contact.lastName}`,
							company: item.contact.company ? item.contact.company : '-',
							telephone: item.contact.phone,
							role: item.role,
							customer: item.customerShortDTO?.title || '-',
							customerNr: item.customerShortDTO?.customerNumber || '-',
							customerId: item.customerShortDTO?.id || 0,
							customerActive: item.customerShortDTO?.active || false,
							participantLimit: item.participantLimit,
							dateCreated: new Date(item.dateCreated).toLocaleDateString(
								undefined,
								{
									day: '2-digit',
									month: '2-digit',
									year: 'numeric'
								}
							),
							dateLastLogin:
								item.dateLastLogin === -1
									? '-'
									: `${new Date(item.dateLastLogin).toLocaleDateString('de-DE', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                  })}, ${new Date(item.dateLastLogin).toLocaleTimeString(
											undefined,
											{
												hour: '2-digit',
												minute: '2-digit'
											}
                    )} Uhr`,
							active: item.active
						};
					});
				} else {
					this.items = [];
					this.clearLocalStorage();
				}
			})
			.catch(() => {
				this.toast(this.t('admin.customersCantLoad'), 'danger');
				this.clearLocalStorage();
			});
	}

	rowClicked(item: any): void {
		this.$router.push(`/admin/admins/${item.username}`);
	}

	createAdmin(): void {
		this.$router.push('/admin/admins/new');
	}

	loginAs(item: any): void {
		AuthenticationService.impersonate({
			parentUsername: this.isAdmin() ? 'admin' : 'sadmin',
			childUsername: item.username
		})
			.then(() => {
				this.$router.push('/admin/customers');
			})
			.catch(this.showNetworkError);
	}

	deleteAdmin(item: any): void {
		this.$bvModal
			.msgBoxConfirm(
				this.$t('admin.deleteAdminModal.message', {name: item.name, username: item.username}) as string,
				{
					centered: true,
					title: this.t('admin.deleteAdminModal.title'),
					okVariant: 'danger',
					cancelTitle: 'Abbrechen',
					okTitle: this.t('admin.deleteAdminModal.title')
				}
			)
			.then((res) => {
				if (res) {
					AccountsService.deleteAccount(item.username)
						.then(() => {
							this.toast(this.t('admin.deleteAdminModal.deleted'), 'success');
							this.items = this.items.filter(
								(i) => i.username !== item.username
							);
						})
						.catch((err) => this.toast(err.response.data.message, 'danger'));
				}
			})
			.catch(this.showNetworkError);
	}

	openUsers(id: number): void {
		this.$router.push({
			name: 'Management-Accounts',
			query: { customerId: id + '' }
		});
	}

	toggleActive(item: any): void {
		if (item.active) {
			AccountsService.deactivateAccount(item.username)
				.then(() => (item.active = false))
				.catch(() => this.toast(this.t('admin.errorOnDeactivation'), 'danger'));
		} else {
			AccountsService.activateAccount(item.username)
				.then(() => (item.active = true))
				.catch(() => this.toast(this.t('admin.errorOnDeactivation'), 'danger'));
		}
	}
}
