



































import {Component, Prop} from 'vue-property-decorator';
import WExpandableCard from "@/components/WExpandableCard.vue";
import Base from "@/views/Base";
import {NewCustomField} from "@/models/CustomField";


@Component({
  components: {WExpandableCard, WCard: WExpandableCard}
})
export default class CustomFieldTry extends Base {
  @Prop() regex!: string;
  @Prop() item!: NewCustomField;

  text = "";
  valid: boolean | null = null;

  validateRegex(): void {
    if(this.buttonDisabled){
      return;
    }
    if(!this.regex || this.regex == ""){
      this.valid = true;
      return;
    }
    const re = new RegExp("^" + this.regex + "$", this.item.ignoreCase ? "i": "");
    this.valid = re.test(this.text);
  }

  get buttonDisabled(): boolean{
    return (this.regex==null || (this.regex == '' && this.item.maxLength==null));
  }

  get textTooLong(): boolean {
    return this.item.maxLength != undefined && this.item.maxLength > 0 && this.text.length > this.item.maxLength;
  }

}
