










































































import {RegistrationSpeaker} from '@/models/Registration';
import {Component, Prop, PropSync} from 'vue-property-decorator';
import Base from '../../Base';
import draggable from 'vuedraggable';
import {BFormInput} from 'bootstrap-vue';

@Component({
	components: { draggable }
})
export default class RegistrationSpeakers extends Base {
	@PropSync('form') speakers!: RegistrationSpeaker[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Prop() validation!: any;

	open = true;

	toggleOpen(): void {
		this.open = !this.open;
	}

	addSpeaker(): void {
		this.speakers.push({ name: '', id: +this.id(), idx: this.speakers.length });
	}

	deleteSpeaker(index: number): void {
		this.speakers.splice(index, 1);
		this.speakers.forEach((sp, i) => (sp.idx = i));
	}

	pictureUploaded(speaker: RegistrationSpeaker, files: File[]): void {
		const file: Blob = files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (e) => {
			if (e.target) speaker.image = { src: e.target.result as string, file };
			this.addSpeaker();
		};
	}

	validateState(index: number): boolean | null {
		const validate = this.validation.$each[index].name;
		return validate.$dirty ? !validate.$error : null;
	}

	editSpeaker(speaker: RegistrationSpeaker): void {
		speaker.edit = !speaker.edit;
		this.$forceUpdate();
		setTimeout(() => {
			const speakerRef = 'speaker-' + speaker.id;
			const refs = this.$refs[speakerRef] as BFormInput[];
			if (refs && refs.length > 0) {
				refs[0].focus();
			}
		}, 50);
	}

	onDragEnd(): void {
		this.speakers.forEach((sp, i) => (sp.idx = i));
	}
}
