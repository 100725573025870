



























































































































































































































































































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { Component } from 'vue-property-decorator';
import Base from '../../Base';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WSwitch from '@/components/WSwitch.vue';
import { Branding, BrandingData } from '@/models/Branding';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import BrandingService from '@/service/BrandingService';
import BrandingPreview from './Preview.vue';
import { quillEditor } from 'vue-quill-editor';
import { SelectOption } from '@/models/common';
import { brandingPrivacyEditorOptions } from '@/utils/quill';

@Component({
	components: {
		HeaderLayout,
		WHeaderBtn,
		BrandingPreview,
		quillEditor,
		WSwitch
	}
})
export default class FormsBrandingForm extends Base {
	branding: Branding = BrandingService.newBranding();

	editMode = false;
	countryCodes: SelectOption[] = [];
	showValidation = false;

	editorOptions = brandingPrivacyEditorOptions;

	pendingLogoDelete = false;
	pendingBannerDelete = false;

	@Validations()
	validations = {
		branding: {
			title: { required },
			data: {
				headerBgColor: {},
				headerTextColor: {},
				logoPosition: {},
				primaryBgColor: {},
				primaryTextColor: {}
			},
			countryISO: {}
		}
	};

	get brandingData(): BrandingData | undefined {
		return this.branding ? (this.branding.data as BrandingData) : undefined;
	}

	mounted(): void {
		const { id } = this.$route.params;
		if (id) {
			BrandingService.getBranding(+id)
				.then((b) => {
					console.log(b.footerEnabled);
					b.data = JSON.parse(b.data as string);
					this.branding = b;
					this.editMode = true;
					if (b.logoFileExists) {
						this.branding.logo = {
							backendUrl:
								BrandingService.getLogoUrl(b.id) + `?v=${this.branding.version}`
						};
					}
					if (b.bannerFileExists) {
						this.branding.banner = {
							backendUrl:
								BrandingService.getBannerUrl(b.id) +
								`?v=${this.branding.version}`
						};
					}
				})
				.catch(this.showNetworkError);
		}
		this.countryCodes = BrandingService.getCountryCodes().map((code) => ({
			value: code.isoCode,
			text: `${code.name} (${code.dialCode})`
		}));
	}

	logoUploaded(files: File[]): void {
		const file: Blob = files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (e) => {
			if (e.target) {
				this.branding = {
					...this.branding,
					logo: {
						...this.branding.logo,
						clientSrc: e.target.result as string,
						file
					}
				};
			}
		};
	}

	bannerUploaded(files: File[]): void {
		const file: Blob = files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (e) => {
			if (e.target) {
				this.branding = {
					...this.branding,
					banner: {
						...this.branding.banner,
						clientSrc: e.target.result as string,
						file
					}
				};
			}
		};
	}

	removeLogo(): void {
		if (this.branding.logoFileExists) {
			this.pendingLogoDelete = true;
		}
		this.branding = {
			...this.branding,
			logo: undefined
		};
	}

	removeBanner(): void {
		if (this.branding.bannerFileExists) {
			this.pendingBannerDelete = true;
		}
		this.branding = {
			...this.branding,
			banner: undefined
		};
	}

	async save(): Promise<void> {
		if (this.$v.$invalid) {
			this.showValidation = true;
		} else {
			if (!this.editMode) {
				const b = await BrandingService.createBranding(this.branding).catch(
					this.showNetworkError
				);
				if (b) {
					if (this.branding.logo && this.branding.logo.file) {
						await BrandingService.addLogo(b.id, this.branding.logo.file).then(
							() => {
								this.toast(this.t('brandings.logoSaved'), 'success');
							}
						);
					}
					if (this.branding.banner && this.branding.banner.file) {
						await BrandingService.addBanner(
							b.id,
							this.branding.banner.file
						).then(() => {
							this.toast(this.t('brandings.bannerSaved'), 'success');
						});
					}
					this.toast(this.t('brandings.brandingSaved'), 'success');
					this.$router.push('/forms/branding');
				}
			} else {
				// save
				try {
					this.baseLoading = true;
					const b = await BrandingService.updateBranding(
						this.branding.id,
						this.branding
					);
					if (b) {
						// if initial no new logo and logo true -> remove
						if (
							(this.branding.logoFileExists && !this.branding.logo) ||
							this.pendingLogoDelete
						) {
							await BrandingService.deleteLogo(b.id);
							console.log('Altes Logo gelöscht!');
						}

						// if initial no new banner and banner true -> remove
						if (
							(this.branding.bannerFileExists && !this.branding.banner) ||
							this.pendingBannerDelete
						) {
							await BrandingService.deleteBanner(b.id);
							console.log('Alte Banner gelöscht!');
						}
						// if new logo -> add
						if (this.branding.logo && this.branding.logo.file) {
							await BrandingService.addLogo(b.id, this.branding.logo.file);
							console.log('Neues Logo gespeichert!');
						}
						// if new banner -> add
						if (this.branding.banner && this.branding.banner.file) {
							await BrandingService.addBanner(b.id, this.branding.banner.file);
							console.log('Neue Banner gespeichert!');
						}

						this.toast(this.t('brandings.brandingSaved'), 'success');
					}
				} catch (error: any) {
					this.showNetworkError(error);
				}
				this.baseLoading = false;
				this.$router.push('/forms/branding');
			}
		}
	}
}
