






























































































import {Component, Watch} from "vue-property-decorator";
import HeaderLayout from "@/layouts/nested/HeaderLayout.vue";
import Base from "@/views/Base";
import WHeaderBtn from "@/components/WHeaderBtn.vue";
import ListWithHeaderLayout from "@/layouts/nested/ListWithHeaderLayout.vue";
import {PresentationTableItem} from "@/models/Presentation";
import PresentationService from "@/service/PresentationService";
import WListOptions from "@/components/listlayout/WListOptions.vue";
import PresentationCard from "@/views/presentation/components/PresentationCard.vue";
import {SelectOption} from "@/models/common";
import PresentationTable from "@/views/presentation/components/PresentationTable.vue";

@Component({
  components: {PresentationTable, PresentationCard, WListOptions, ListWithHeaderLayout, WHeaderBtn, HeaderLayout}
})
export default class Presentation extends Base {
  items: PresentationTableItem[] = [];
  fields = [
    {
      key: 'selected',
      sortable: false,
      thStyle: {width: '35px'},
      class: ' va-middle pl-3 pr-1'
    },
    {
      key: 'thumbnailUrl',
      sortable: false,
      thStyle: {width: '60px'},
      thClass: 'show-content-md'
    },
    {
      key: 'title',
      label: this.t('presentation.title'),
      sortable: true,
      sortDirection: 'asc',
    },
    {
      key: 'dateUpdated',
      label: this.t('presentation.lastModified'),
      sortable: true,
      sortDirection: 'desc',
      thStyle: {width: '170px'},
    },
    {
      key: 'isGlobal',
      label: this.t('presentation.global'),
      sortable: false,
      thStyle: {width: '70px'}
    },
    {
      key: 'elementCount',
      label: this.t('presentation.elements'),
      sortable: true,
      thStyle: {width: '95px'},
    },
    {
      key: 'hasAnimations',
      label: this.t('presentation.animations'),
      sortable: false,
      thStyle: {width: '105px'},
    },
    {
      key: 'actions',
      thClass: 'show-content-md',
      thStyle: {width: '85px'},
    }
  ];
  options = {
    mode: 'table',
    entryCount: 9999,
    searchTerm: '',
    currentPage: 1,
    totalRows: 0,
    sortBy: 'dateUpdated',
    sortDirection: 'DESC' as 'DESC' | 'ASC'
  };

  baseLoading = false;
  isSortDesc = this.options.sortDirection === 'DESC';
  allSelected = false;
  editIds: number[] = [];

  editOptions: SelectOption[] = [
    {value: 'action', text: this.$t('common.changeSelection') as string, disabled: true},
    {value: 'invert', text: this.$t('common.invertSelection') as string},
    {value: 'all', text: this.$t('common.selectAll') as string},
    {value: 'none', text: this.$t('common.deselectAll') as string}
  ]
  selectedEditOption: 'invert' | 'all' | 'none' | 'action' = 'action';

  mounted() {
    this.getPresentations();
  }

  get editMode(): boolean {
    return this.editIds.length > 0;
  }

  get isCards(): boolean {
    return this.options.mode === 'cards';
  }

  @Watch('options', {deep: true})
  onOptionsChanged() {
    this.getPresentations();
    this.isSortDesc = this.options.sortDirection === 'DESC';
  }

  @Watch('isSortDesc')
  onSortDescChanged() {
    this.options.sortDirection = this.isSortDesc ? 'DESC' : 'ASC';
  }

  @Watch('editIds', {deep: true})
  onEditIdsChanged() {
    this.allSelected = this.editIds.length === this.items.length && this.items.length > 0;
  }

  getPresentations() {
    this.baseLoading = true;
    this.editIds = [];
    this.allSelected = false;
    PresentationService
      .getPresentations(
        this.options.currentPage - 1,
        this.options.entryCount,
        this.options.sortBy || 'dateUpdated',
        this.options.sortDirection,
        this.options.searchTerm
      )
      .then(res => {
        this.items = res.content
          ? res.content.map(PresentationService.presentationToTableItem)
          : [];
      })
      .catch(this.showNetworkError)
      .finally(() => this.baseLoading = false)
  }

  editPresentation(item: PresentationTableItem) {
    this.$router.push(`/presentation/${item.id}`);
  }

  deletePresentation(item: PresentationTableItem) {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('modals.deletePresentation.description', {name: item.title}) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deletePresentation.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deletePresentation.title')
        }
      )
      .then(res => {
        if (!res) return;
        this.baseLoading = true;
        PresentationService
          .deletePresentation(item.id)
          .then(() => {
            this.getPresentations();
            this.toast(this.t('modals.deletePresentation.deleted'), 'success');
          })
          .catch(this.showNetworkError)
          .finally(() => this.baseLoading = false)
      })
  }

  deleteSelectedIds() {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('modals.deletePresentations.description', {
          count: this.editIds.length
        }) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deletePresentations.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deletePresentations.title')
        }
      )
      .then(res => {
        if (!res) return;
        this.baseLoading = true;
        PresentationService
          .deleteSelectedPresentations(this.editIds)
          .then(() => {
            this.editIds = [];
            this.getPresentations();
            this.toast(this.t('modals.deletePresentations.deleted'), 'success');
          })
          .catch(this.showNetworkError)
          .finally(() => this.baseLoading = false)
      })
  }

  rowSelected(item: PresentationTableItem, shiftKey = false) {
    item.selected = !item.selected;
    if (item.selected) this.editIds.push(item.id);
    else this.editIds = this.editIds.filter(id => id !== item.id);
    if (shiftKey) this.selectBetween(item, item.selected);
    this.selectedEditOption = 'action';
  }

  selectAll(): void {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.items.forEach(item => {
        if (!item.selected) this.rowSelected(item);
      });
    } else {
      this.items.forEach(item => {
        if (item.selected) this.rowSelected(item);
      });
    }
  }

  selectBetween(item: PresentationTableItem, value: boolean) {
    const index = this.items.findIndex(i => i.id === item.id);
    const selectedIndex = this.items.findIndex(i => i.selected === value && i.id !== item.id);
    let startIndex: number, endIndex: number;
    if (selectedIndex === -1) {
      startIndex = endIndex = index;
    } else {
      startIndex = Math.min(index, selectedIndex);
      endIndex = Math.max(index, selectedIndex);
    }
    this.items.slice(startIndex, endIndex + 1).forEach(i => {
      if (i.selected !== value) this.rowSelected(i, false);
    });
  }

  updateSelection(value: string | null) {
    if (!value) return;
    this.$forceUpdate();
    switch (value) {
      case 'invert':
        this.items.forEach(item => this.rowSelected(item, false));
        break;
      case 'all':
        this.allSelected = false;
        this.selectAll();
        break;
      case 'none':
        this.allSelected = true;
        this.selectAll();
        break;
    }
  }

  async createNewPresentation() {
    this.baseLoading = true;
    try {
      const response = await PresentationService.createPresentation();
      const newId = response.id;
      await this.$router.push({path: `/presentation/${newId}`, query: {new: 'true'}});
    } catch (error) {
      console.error('Error creating new presentation: ', error);
    } finally {
      this.baseLoading = false;
    }
  }
}
