import { BankValidation, Customer, CustomerWrapper } from '@/models/Customer';
import axios from 'axios';

/**
 * API calls for booking
 * @module service/CustomerService
 */
export default {
	/**
	 * Rest Functions customers
	 */
	getCustomers(
		page: number,
		size: number,
		order: string,
		direction: 'ASC' | 'DESC',
		search?: string,
		ignoreCase?: boolean
	): Promise<CustomerWrapper> {
		const params: { [key: string]: any } = {
			page,
			size,
			order,
			direction,
			ignoreCase
		};
		if (search) params['search'] = search;
		return axios.get('/customer', { params }).then((response) => response.data);
	},

	getCustomer(id: string): Promise<Customer> {
		return axios.get('/customer/' + id).then((res) => res.data);
	},

	updateCustomer(id: number, customer: Customer): Promise<Customer> {
		return axios.put(`/customer/${id}`, customer).then((res) => res.data);
	},

	getInfo(id: number): Promise<string> {
		return axios.get(`/customer/${id}/info`).then((res) => res.data.info || '');
	},

	updateInfo(id: string, info: string): Promise<string> {
		return axios.put(`/customer/${id}/info`, { id: 2, info }).then();
	},

	addCustomer(customer: Customer): Promise<Customer> {
		return axios.post(`/customer`, customer).then((res) => res.data);
	},

	deleteCustomer(id: string): Promise<void> {
		return axios.delete(`/customer/${id}`);
	},

	getGeneratedCustomerId(): Promise<number> {
		return axios.get(`/customer/maxNumber`).then((res) => res.data + 1);
	},

	validateIban(iban: string): Promise<BankValidation> {
		return axios
			.get(`/validator/iban`, { params: { iban } })
			.then((res) => res.data);
	},

	updatePermissionsAndLimits(
		id: number,
		customer: Customer
	): Promise<Customer> {
		const participantLimit = customer.participantLimit;
		const userLimit = customer.userLimit;
		const permissions = customer.permissions;
		return axios
			.put(`/customer/${id}/admin`, {
				participantLimit,
				userLimit,
				permissions
			})
			.then((res) => res.data);
	},

	activateCustomer(id: number): Promise<void> {
		return axios.put(`/customer/${id}/activate`).then();
	},

	deactivateCustomer(id: number): Promise<void> {
		return axios.put(`/customer/${id}/deactivate`).then();
	}
};
