




































































































import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import { Branding, BrandingData } from '@/models/Branding';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Base from '../../Base';

@Component({
	components: { HeaderLayout }
})
export default class FormsBrandingPreview extends Base {
	@Prop() branding!: Branding;

	@Watch('branding.logo')
	logoChanged(): void {
		this.$forceUpdate();
	}

	@Watch('branding.banner')
	bannerChanged(): void {
		this.$forceUpdate();
	}

	get brandingData(): BrandingData {
		return this.branding.data as BrandingData;
	}

	get logoFlex(): string {
		const data = this.brandingData;
		const align =
			data.logoPosition === 'right'
				? 'end'
				: data.logoPosition === 'middle'
				? 'center'
				: 'start';
		return 'justify-content-' + align;
	}

	get primaryStyle(): { [key: string]: string } {
		return {
			backgroundColor: this.brandingData.primaryBgColor,
			color: this.brandingData.primaryTextColor
		};
	}
	get headerStyle(): { [key: string]: string } {
		return {
			backgroundColor: this.brandingData.headerBgColor,
			color: this.brandingData.headerTextColor
		};
	}
}
