



































































































































































































































































































































































































































































import {Component} from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import SidebarLayout from '@/layouts/nested/SidebarLayout.vue';
import WSwitch from '@/components/WSwitch.vue';
import AdminSection from './AdminSection.vue';
import {Account, PasswordPolicy, Permission, PermissionName} from '@/models/Account';
import {Validations} from 'vuelidate-property-decorators';
import {minValue, required} from 'vuelidate/lib/validators';
import ContactsService from '@/service/ContactsService';
import Base from '../Base';
import AccountsService from '@/service/AccountsService';
import WHeaderBtn from '@/components/WHeaderBtn.vue';
import WCostCenter from '@/components/WCostCenter.vue';
import CustomersService from '@/service/CustomersService';
import WCountrySelect from '@/components/WCountrySelect.vue';
import {Customer} from '@/models/Customer';
import {validateEmail} from '@/utils/validators';
import {getLanguages} from "@/utils/languages";
import {managementPermList, productPermList} from "@/utils/permissionLists";

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: {
		HeaderLayout,
		SidebarLayout,
		WSwitch,
		AdminSection,
		WHeaderBtn,
		WCostCenter,
		WCountrySelect
	}
})
export default class UsersItem extends Base {
	account: Account = {
		role: 'USER',
		timezone: 'Europe/Berlin',
		username: '',
		dateCreated: 0,
		id: 0,
		language: 'DE',
		permissions: [],
		contact: {
			lastName: '',
			email: '',
			firstName: '',
			phone: '',
			city: '',
			country: '',
			street: '',
			zip: '',
			company: '',
			salutation: '',
			title: '',
			phone2: ''
		},
		costCenter: '',
		passwordPlain: '',
		customerId: -1,
		active: true,
		dateLastLogin: Date.now(),
		deactivationReason: 'NONE',
		failedLoginCounter: 0,
		participantLimit: -1,
		passwordPolicy: 'CHANGE_BY_FIRST_LOGIN',
		timeLimit: -1,
		bookingPreset: {
			dialOutCallRetryCount: 2,
			dialOutCallRetryDelay: 60
		}
	};

  // TODO: Remove this map, when PRESENTATION permission should be changable on prod
  get productPermList() {
    return productPermList.map(perm => perm.key === 'PRESENTATION' ? { ...perm, disabled: !this.dev } : perm);
  }
  managementPermList = managementPermList;

  languages = getLanguages(this)
	timezones!: { value: string; text: string }[];
	salutations = [
		{ text: this.t('admin.selectSalutation'), value: '', disabled: true },
		{ text: this.t('common.mr'), value: 'MR' },
		{ text: this.t('common.mrs'), value: 'MRS' },
		{ text: this.t('common.diverse'), value: 'OTHER' }
	];

	newCostCenter = '';

	costCenters = [{ text: this.t('conference.noCostCenter'), value: '', undeletable: true }];
	passwordPolicies: { text: string; value: PasswordPolicy }[] = this.dev
		? [
			{ text: this.t('admin.passwordPolicyChangeAfterLogin'), value: 'CHANGE_BY_FIRST_LOGIN' },
			{ text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 30}) as string, value: 'CHANGE_BY_FIRST_LOGIN_AND_30_DAYS' },
			{ text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 60}) as string, value: 'CHANGE_BY_FIRST_LOGIN_AND_60_DAYS' },
			{ text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 90}) as string, value: 'CHANGE_BY_FIRST_LOGIN_AND_90_DAYS' },
			{ text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 180}) as string, value: 'CHANGE_BY_FIRST_LOGIN_AND_180_DAYS' },
			{ text: this.$t('admin.passwordPolicyChangeAfterLoginAndNDays', {n: 360}) as string, value: 'CHANGE_BY_FIRST_LOGIN_AND_360_DAYS' },
			{ text: this.t('admin.none'), value: 'NONE' }
		]
		: [
			{ text: this.t('admin.passwordPolicyChangeAfterLogin'), value: 'CHANGE_BY_FIRST_LOGIN' },
			{ text: this.t('admin.none'), value: 'NONE' }
		];

	loadingPhone1 = false;
	loadingPhone2 = false;

	info = '';

	permissionNames: PermissionName[] = [];

	customer: Customer | null = null;
	customerPermissionNames: PermissionName[] = [];

	customerOptions: {
		[key: string]: number | Customer | string | null | boolean;
	}[] = [
		{ value: -1, text: this.t('admin.selectCustomer') + '...', customer: null, disabled: true }
	];

	limit = false;
	timeLimitStr = '';

	participantLimit = -1;
	participantLimitNum = 0;

	@Validations()
	validations = {
		account: {
			contact: {
				salutation: { required },
				firstName: { required },
				lastName: { required },
				phone: {
					required,
					noLetters: ContactsService.noLettersValidator,
					format: this.phoneFormatValidator('account.contact.phone')
				},
				phone2: {
					noLetters: ContactsService.noLettersValidator,
					format: this.phoneFormatValidator('account.contact.phone2')
				},
				email: { required, email: validateEmail },
				street: { required },
				zip: { required },
				city: { required },
				country: { required },
				title: {},
				company: {}
			},
			language: { required },
			timezone: { required },
			costCenter: {},
			username: { required },
			passwordPlain: { required },
			customerId: { required, minValue: minValue(0) },
			passwordPolicy: {},
			timeLimit: {}
		}
	};

	mounted(): void {
		if (this.$route.query.customerId) {
			this.account.customerId = +this.$route.query.customerId;
		}
		this.setTimezones();
		this.getCustomers();
	}

	save(): void {
		this.account.timeLimit = this.limit
			? new Date(this.timeLimitStr).getTime()
			: -1;
		this.account.participantLimit =
			this.participantLimit < 0 ? -1 : +this.participantLimitNum;

		this.$v.account.$touch();
		setTimeout(() => {
			if (!this.$v.account.$anyError) {
				this.addAccount();
			} else {
				this.toast(
					this.t('admin.formContainsErrors'),
					'danger'
				);
			}
		}, 250);
	}

	private addAccount(): void {
		const acc = { ...this.account };
		acc.permissions = this.account.permissions.map(
			(p) => (p as Permission).name
		);
		AccountsService.addAccount(acc)
			.then((account) => {
				const requests = [];
				if (this.info) {
					requests.push(
						AccountsService.updateInfo(account.username, this.info)
					);
				}
				// workround because permissions not being saved immediately
				if (acc.permissions.length > 0) {
					requests.push(
						AccountsService.updatePermissions(
							account.username,
							this.account.permissions as Permission[]
						)
					);
				}

				if (requests.length > 0) {
					return Promise.all(requests as any[]);
				} else {
					this.toast(this.t('admin.userAndDescriptionCreated'), 'success');
					this.redirect();
				}
			})
			.then(() => {
				this.toast(this.t('admin.userAndDescriptionCreated'), 'success');
				this.redirect();
			})
			.catch((err) => {
				if (
					err.response.data._embedded.errors[0].message.includes(
						'invalid passwordHash'
					)
				) {
					this.toast(
						this.t('admin.passwordRequirements2'),
						'danger'
					);
				} else if (err.response.data.message.includes('already exists')) {
					this.toast(this.t('admin.usernameExists'), 'danger');
				} else {
					this.showNetworkError(err);
				}
			});
	}

	redirect(): void {
		this.$router.push('/admin/users');
	}

	copyCustomer(): void {
		if (this.account.customerId) {
			const customerOption = this.customerOptions.find(
				(c) => c.value === this.account.customerId
			);
			if (customerOption) {
				const customer = customerOption.customer as Customer;
				if (customer) {
					this.account.contact = {
						...this.account.contact,
						...customer.contact
					};
				}
			}
		}
	}

	togglePermission(permission: PermissionName): void {
		const permissions = this.account.permissions.map(
			(p) => (p as Permission).name
		);
		if (permissions.includes(permission)) {
			this.account.permissions = (
				this.account.permissions as Permission[]
			).filter((p: Permission) => p.name !== permission);
		} else {
			(this.account.permissions as Permission[]).push({
				name: permission,
				additional: ''
			});
		}
	}

	private getCustomers(): void {
		CustomersService.getCustomers(0, 10000, 'title', 'DESC')
			.then((customers) => {
				this.customerOptions = [
					this.customerOptions[0],
					...customers.content.map((customer) => {
						const disabled =
							customer.userLimit !== -1 &&
							(customer.userLimit || 0) <= (customer.countRegisteredUser || 0);
						const title = disabled
							? customer.title + ' (' + this.t('admin.userLimitExceededShort') + ')'
							: customer.title;
						return {
							text: `(${customer.customerNumber}) ${title}`,
							value: customer.id,
							customer,
							disabled
						};
					})
				];
				if (this.account.customerId) {
					const option = this.customerOptions.find(
						(option) => option.value === this.account.customerId
					);
					if (option) this.setFromCustomer(option.value as number);
				}
			})
			.catch(this.showNetworkError);
	}

	setFromCustomer(optionId: number): void {
		const customer = this.customerOptions.find(
			(option) => option.value === optionId
		);
		if (customer && customer.customer !== null) {
			this.customer = customer.customer as Customer;
			this.account.contact.company = this.customer.title;
			if (this.customer.contact && this.customer.contact.country)
				this.account.contact.country = this.customer.contact.country;
			this.customerPermissionNames = [];
			this.account.permissions = [];
			this.permissionNames = [];
			if (this.customer.permissions) {
				this.customerPermissionNames = this.customer.permissions.map(p => p.name);
				this.customerPermissionNames.forEach((permission) => {
					(this.account.permissions as Permission[]).push({
						name: permission,
						additional: ''
					});
				});
				this.permissionNames = [...this.customerPermissionNames];
			}
		}
	}

	setTimezones(): void {
		const tzList: { [key: string]: string } = {
			'Etc/GMT+12': '(GMT-12:00) International Date Line West',
			'Pacific/Midway': '(GMT-11:00) Midway Island, Samoa',
			'Pacific/Honolulu': '(GMT-10:00) Hawaii',
			'US/Alaska': '(GMT-09:00) Alaska',
			'America/Los_Angeles': '(GMT-08:00) Pacific Time (US & Canada)',
			'US/Arizona': '(GMT-07:00) Arizona',
			'America/Managua': '(GMT-06:00) Central America',
			'US/Central': '(GMT-06:00) Central Time (US & Canada)',
			'America/Bogota': '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
			'US/Eastern': '(GMT-05:00) Eastern Time (US & Canada)',
			'Canada/Atlantic': '(GMT-04:00) Atlantic Time (Canada)',
			'America/Argentina/Buenos_Aires': '(GMT-03:00) Buenos Aires, Georgetown',
			'America/Noronha': '(GMT-02:00) Mid-Atlantic',
			'Atlantic/Azores': '(GMT-01:00) Azores',
			'Etc/Greenwich':
				'(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
			'Europe/Berlin':
				'(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
			'Europe/Helsinki':
				'(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
			'Europe/Moscow': '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
			'Asia/Tehran': '(GMT+03:30) Tehran',
			'Asia/Yerevan': '(GMT+04:00) Yerevan',
			'Asia/Kabul': '(GMT+04:30) Kabul',
			'Asia/Yekaterinburg': '(GMT+05:00) Yekaterinburg',
			'Asia/Karachi': '(GMT+05:00) Islamabad, Karachi, Tashkent',
			'Asia/Calcutta': '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
			'Asia/Katmandu': '(GMT+05:45) Kathmandu',
			'Asia/Dhaka': '(GMT+06:00) Astana, Dhaka',
			'Asia/Rangoon': '(GMT+06:30) Yangon (Rangoon)',
			'Asia/Bangkok': '(GMT+07:00) Bangkok, Hanoi, Jakarta',
			'Asia/Hong_Kong': '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
			'Asia/Seoul': '(GMT+09:00) Seoul',
			'Australia/Adelaide': '(GMT+09:30) Adelaide',
			'Australia/Canberra': '(GMT+10:00) Canberra, Melbourne, Sydney',
			'Asia/Magadan': '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
			'Pacific/Auckland': '(GMT+12:00) Auckland, Wellington',
			'Pacific/Tongatapu': "(GMT+13:00) Nuku'alofa"
		};

		this.timezones = Object.keys(tzList).map((key: string) => ({
			value: key,
			text: tzList[key]
		}));
	}

	validateState(name: string): boolean | null {
		let validate: any = this.$v.account;
		const nameArr = name.split('.');
		if (nameArr.length === 1) validate = validate[name];
		else nameArr.forEach((n) => (validate = validate[n]));
		return validate.$dirty && validate.$error ? false : null;
	}

	phoneFormatValidator(
		phoneStr: string
	): (value: string) => boolean | Promise<boolean> {
		// return Vuelidate validate function that uses phoneStr
		// phoneStr has an 'object.attribute.attribute' structure
		return (value) => {
			const phoneArr: any[] = phoneStr.split('.');
			let phoneForm: any = this.$v; // form
			phoneArr.forEach((p) => (phoneForm = phoneForm[p]));
			if (!phoneForm.required && value === '') return true;
			if (phoneForm?.$dirty && this.account) {
				// validate
				return ContactsService.validatePhoneNumbers([value])
					.then((validatedNumbers) => {
						const validNumber = validatedNumbers[0];
						const phoneIsValid = validNumber.validNumber;
						if (phoneIsValid) {
							// replace with international
							switch (phoneArr.length) {
								case 2:
									(this.account as any)[phoneArr[1]] =
										validNumber.international;
									break;
								case 3:
									(this.account as any)[phoneArr[1]][phoneArr[2]] =
										validNumber.international;
									break;
							}
						}
						return phoneIsValid;
					})
					.catch(() => false);
			}
			return false;
		};
	}
}
