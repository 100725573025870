








































































































































































































































































import {Component, Prop} from 'vue-property-decorator';
import Base from '../../Base';
import WSwitch from '@/components/WSwitch.vue';
import {quillEditor} from 'vue-quill-editor';
import {BFormInput} from 'bootstrap-vue';
import {SurveyQuestion, SurveyQuestionOption} from '@/models/Survey';
import SurveyService from '@/service/SurveyService';
import QuestionField from './QuestionField.vue';
import {SelectOption} from '@/models/common';

@Component({
	components: { WSwitch, quillEditor, QuestionField }
})
export default class SurveyQuestions extends Base {
	@Prop() form!: SurveyQuestion[];
	@Prop() validation!: any;
	@Prop() hasParticipants!: boolean;

	editorOptions = {
		theme: 'snow',
		placeholder: this.t('forms.components.questions.explanation') + '...',
		modules: {}
	};

	pointsOptions = [
		{ value: null, text: this.t('forms.components.questions.noEvaluation') },
		{ value: 1, text: '1' },
		{ value: 2, text: '2' },
		{ value: 3, text: '3' },
		{ value: 4, text: '4' },
		{ value: 5, text: '5' }
	];

	selectedRadio = '';

	questionTypeChanged(question: SurveyQuestion, validation: any): void {
		validation.checkboxQuestionOptions.$reset();
		validation.radioQuestionOptions.$reset();
		validation.multipleTextQuestionOptions.$reset();
		question['multipleTextQuestionOptions'] = [];
		question['radioQuestionOptions'] = [];
		question['checkboxQuestionOptions'] = [];

		if (question.type === 'CHECKBOX') question['checkboxQuestionOptions'] =
			[SurveyService.newAnswer(1), SurveyService.newAnswer(2)];
		if (question.type === 'RADIO') question['radioQuestionOptions'] =
			[SurveyService.newAnswer(1), SurveyService.newAnswer(2)];
		if (question.type === 'MULTIPLE_TEXT') question['multipleTextQuestionOptions'] = [SurveyService.newAnswer(1)];
	}

	questionOptions(question: SurveyQuestion): SurveyQuestionOption[] {
		switch (question.type) {
			case 'CHECKBOX':
				return question.checkboxQuestionOptions;
			case 'MULTIPLE_TEXT':
				return question.multipleTextQuestionOptions;
			case 'RADIO':
				return question.radioQuestionOptions;
			default:
				return [];
		}
	}

	questionTypes: SelectOption[] = [
		{ text: this.t('forms.components.questions.multipleChoice'), value: 'CHECKBOX' },
		{ text: this.t('forms.components.questions.singleChoice'), value: 'RADIO' },
		{ text: this.t('forms.components.questions.textWithSubpoints'), value: 'MULTIPLE_TEXT' },
		{ text: this.t('forms.components.questions.textBox'), value: 'TEXT' }
	];

	get open(): boolean {
		return !!this.form.find((question) => question.open);
	}

	toggleAll(): void {
		const open = this.open;
		this.form.forEach(q => q.open = !open);
	}

	addQuestion(): void {
		if (!this.hasParticipants) {
			this.form.push(SurveyService.newQuestion(this.form.length + 1));
		}
	}

	deleteQuestion(id: number): void {
		const index = this.form.findIndex((question) => question.id === +id);
		this.form.splice(index, 1);
		// correct idx of remaining questions
		this.form.map((question, i) => (question.idx = i + 1));
	}

	deleteAnswer(question: SurveyQuestion, idx: number): void {
		this.questionOptions(question).splice(idx, 1);
	}

	addAnswer(question: SurveyQuestion): void {
		if (!this.hasParticipants) {
			const options = this.questionOptions(question);
			options.push(SurveyService.newAnswer(options.length + 1));
		}
	}

	validateState(index: number, name: string): boolean | null {
		const validate = this.validation.$each[index][name];
		return validate.$dirty ? !validate.$error : null;
	}

	onTitleBlur(question: SurveyQuestion): void {
		if (question.title !== '') this.editQuestion(question);
		else question.edit = false;
	}

	resetOtherAnswers(question: SurveyQuestion, option: SurveyQuestionOption): void {
		question.radioQuestionOptions.forEach(o => {
			if (o.idx !== option.idx) o.rightAnswer = false;
		});
	}

	editQuestion(question: SurveyQuestion): void {
		question.edit = !question.edit;
		this.$forceUpdate();
		setTimeout(() => {
			const speakerRef = 'question-' + question.id;
			const refs = this.$refs[speakerRef] as BFormInput[];
			if (refs.length > 0) refs[0].focus();
		}, 50);
	}

	move(question: SurveyQuestion, movement: 'UP' | 'DOWN', index: number): void {
		console.log(question.idx, movement, index);
		if (movement === 'UP' && question.idx > 0) {
			question.idx = question.idx - 1;
			if (this.form[index - 1].idx === question.idx) {
				this.form[index - 1].idx = this.form[index - 1].idx + 1;
				this.swapItems(index, index - 1);
			}
		}
		if (movement === 'DOWN' && question.idx > 0) {
			question.idx = question.idx + 1;
			if (this.form[index + 1].idx === question.idx) {
				this.form[index + 1].idx = this.form[index + 1].idx - 1;
				this.swapItems(index, index + 1);
			}
		}
		setTimeout(() => this.form.sort((a, b) => a.idx - b.idx), 100);
	}

	swapItems(a: number, b: number): void {
		// swap function, maps form[a] to form[b], and form[b] to form[a]
		[this.form[a], this.form[b]] = [this.form[b], this.form[a]];
	}

	setEvaluated(question: SurveyQuestion) {
		question.evaluate = !!question.points;
	}
}
