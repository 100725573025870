




























































import { Component, Prop } from 'vue-property-decorator';
import WExpandableCard from '@/components/WExpandableCard.vue';
import { CreateSpeakerCard } from '@/models/SpeakerCard';
import Base from '@/views/Base';

@Component({
  components: { WExpandableCard }
})
export default class Contact extends Base {
  @Prop() validation!: any;
  @Prop() speakerCard!: CreateSpeakerCard;
  @Prop({required: false, default: false, type: Boolean}) disabled!: boolean

  homepageBlur() {
    const profile = this.speakerCard.speakerProfile || {homepageUrl: ''};
    const url = profile.homepageUrl || '';

    if (url) {
      // Prüfen, ob die URL mit 'http://' beginnt und durch 'https://' ersetzen
      if (url.startsWith('http://')) {
        profile.homepageUrl = 'https://' + url.substring(7);
      }
      // Prüfen, ob die URL mit 'https://' beginnt
      else if (!url.startsWith('https://')) {
        profile.homepageUrl = 'https://' + url;
      }
    }
  }
}


