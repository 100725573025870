import {Branding} from './Branding';
import {SpeakerImage} from './Registration';
import {FormCustomField} from "@/models/CustomField";
import {Pageable} from "@/models/api";


export interface SurveyApiObject extends Pageable {
	content: Survey[];
}

// Survey
export interface Survey {
	// Section 1: General
	id: number | null;
	title: string;
	url: string; // format: '/example'
	language: string; // e.g. EN, DE
	subTitle?: string;
	description?: string; // html string
	branding?: Branding;
	brandingId: string | null;
	label?: string;
	mailToAllProgress?: string;

	// Section 2: Speakers
	speakers: SurveySpeaker[];

	// Section 3: Questions
	questions: SurveyQuestion[]; // new

	// Section 4: Fields
	saveParticipantData: boolean;
	saveAdditionalParticipantData: boolean;
	participantDataToSave: SurveyParticipantData;
	customFields: FormCustomField[];

	// Section 5: Legal
	surveyLegal: SurveyLegal;

	// Section 6: Multiple Choice
	certificateData: MultipleChoiceCertificateData;
	certificateMailPreset?: FormMail;
	generateCert: boolean;

	// Section 7: Email
	mail: FormMail | null; // old but slightly different
	sendMail: boolean; // new

	// Section 8: Duration
	start: number;
	end: number;

	// Participation Data
	countRegisteredUser?: number;
	countCertFileUpToDate?: number;
	lastParticipationDate?: number;

	// others
	active: boolean;
	bookingIds?: string[];
	dateUpdated?: number;

	ignoreBlacklist?: boolean;
}

export interface SurveyParticipantData {
	salutation: SurveyParticipantDataField;
	address: SurveyParticipantDataField;
	company: SurveyParticipantDataField;
	partnerId: SurveyParticipantDataField;
	comment: SurveyParticipantDataField;
	vvId: SurveyParticipantDataField;
	name: SurveyParticipantDataField;
	phone: SurveyParticipantDataField;
	email: SurveyParticipantDataField;
	customFields: SurveyParticipantDynamicField[];
	customCheckboxes: SurveyParticipantDynamicField[];
}

export interface SurveyParticipantDataField {
	show: boolean;
	required: boolean;
}

export interface SurveyParticipantDynamicField {
	id?: string;
	caption: string;
	required: boolean;
}

export interface SurveyResultTableRow {
	id: string;
	index: number;
	selected: boolean;
	name: string;
	points: number;
	passed: boolean;
	participated: boolean;
	minutes: number;
	dateCreated: number;
	result: SurveyResult;
	response: SurveyResponse;
	originalResponse: SurveyResponse;
	certificateSent: boolean;
}

export interface SurveySpeaker {
	id?: number;
	version?: number;
	name: string;
	idx?: number;

	image?: SpeakerImage;
	edit?: boolean;
}

export type SurveyQuestionType =
	| 'TEXT'
	| 'MULTIPLE_TEXT'
	| 'CHECKBOX'
	| 'RADIO';

export interface SurveyQuestion {
	id: number;
	idx: number;
	title: string;
	description?: string;
	required: boolean;
	checkboxQuestionOptions: SurveyQuestionOption[];
	radioQuestionOptions: SurveyQuestionOption[];
	multipleTextQuestionOptions: SurveyQuestionOption[];
	type: SurveyQuestionType;
	evaluate: boolean;
	points?: number | null;

	open?: boolean;
	edit?: boolean;
}

export interface SurveyQuestionOption {
	id?: number;
	idx: number;
	rightAnswer: boolean;
	caption: string;
}
export interface MultipleChoiceCertificateData {
	// new
	multipleChoiceCreditsCondition?: number;
	participationDurationCondition?: number;
	multipleParticipation: boolean;
	standardCertificate: boolean;
	customCertificate: boolean;
	certificateTemplateFilename?: string;
}

export interface SurveyLegal {
	privacyURL: string;
	imprintName: string;
	imprintCompany: string;
}

export interface FormMail {
	subject: string;
	text: string; // html string
	replyTo: string; // email string
	bcc?: string; // email string
	attachments?: string[];
	type?: SurveyMailType;
	enabled: boolean;
	mailTextTemplateId?: string;
	onlyToNew?: boolean;
}

export enum SurveyMailType {
	AnswersAndPersonalData = 'ANSWERS_AND_PERSONAL_DATA',
	Answers = 'ANSWERS',
	PersonalData = 'PERSONAL_DATA',
	None = 'NONE'
}

// Email Templates, same as Registration email templates
export interface EmailTemplateWrapper {
	templates: EmailTemplate;
}
export interface EmailTemplate {
	[key: string]: EmailTemplateItem[];
}

export interface EmailTemplateItem {
	mailTextTemplateId: number;
	title: string;
	text: string;
}

export interface SurveyStatistic {
	activeSurveys: number;
	pastSurveys: number;
	activeUser: number;
	brandings: number;
}

export interface SurveyResult {
	responseId: number;
	firstname?: string;
	lastname?: string;
	dateCreated: number;
	reachedPoints: number;
	totalPoints: number;
	questionResults: Array<SurveyQuestionResult>;
	passed?: boolean;
	participated?: boolean;
	participationDuration: number;
	response: SurveyResponse;
	originalResponse: SurveyResponse;
	certFileUpToDate: boolean;
}

export interface SurveyQuestionResult {
	questionId: number;
	questionPoints?: number;
	reachedPoints?: number;
	totalPoints?: number;
}

export interface SurveyResponse {
	id: number;
	surveyId: number;
	dateCreated: number;
	firstname?: string;
	lastname?: string;
	email?: string;
	phone?: string;
	company?: string;
	comment?: string;
	partnerId?: string;
	addressStreet?: string;
	addressCity?: string;
	salutation?: string;
	vvId?: string;
	customFieldValues?: Array<SurveyCustomFieldResponse>;
	customCheckboxValues?: Array<SurveyCustomFieldResponse>;
	customValidatedFieldValues?: Array<SurveyCustomValidatedFieldResponse>
	customUrlParams?: Array<{ key: string; value: string }>;
	answers: Array<SurveyResponseAnswer>;
	dateCertMailSent: number;
	dateCertMailToAllSent: number;
}

export interface SurveyCustomFieldResponse {
	id: string;
	value?: string;
}
export interface SurveyCustomValidatedFieldResponse {
	fieldId: number;
	value?: string;
}

export interface SurveyUser {
	id: number;
	dateCreated?: number;
	key: string;
	firstname: string;
	lastname: string;
	email: string;
	phone: string;
	company?: string;
	comment?: string;
	partnerId?: string;
	addressStreet?: string;
	addressCity?: string;
	salutation?: string;
	dateIds: number[];
	values: SurveyUserValue[];
	points: number;
	minutes: number;
}

export interface SurveyUserValue {
	id?: number;
	fieldId: number;
	value: string;
}

export interface SurveyResponseAnswer {
	id: number;
	value?: string;
	values?: Array<number>;
	valueMap?: ValueMap;
	question: SurveyQuestion;
}

export interface ValueMap {
	[key: string]: string;
}

export interface SurveyWrapper{
	content: AdminSurvey[],
	empty?: boolean,
	totalSize: number,
	totalPages: number
}

export interface AdminSurvey {
    id: number;
    dateCreated: Date;
    lastParticipationDate: Date;
    url: string;
    countQuestions: number;
	customerID?: number,
    accountUsername: string;
    company?: string | null;
	certificateState: 'none' | 'uploaded' | 'sent'
}

export interface AdminSurveyStatistic {
	totalActiveSurveys: number,
	totalResponses: number
}
