









































































































































































import { Component } from 'vue-property-decorator';
import HeaderLayout from '@/layouts/nested/HeaderLayout.vue';
import Base from '../Base';
import { NationalNumber } from '@/models/api';
import BookingService from '@/service/BookingService';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: { HeaderLayout }
})
export default class Numbers extends Base {
	nationalNumbers: NationalNumber[] = [];
	editNumber: NationalNumber | null = null;

	newDescription = '';
	newNumber = '';

	mounted(): void {
		this.getNumbers();
	}

	private getNumbers(): void {
		BookingService.getNationalNumbers()
			.then((numbers) => {
				this.nationalNumbers = numbers.sort((a, b) => (a.idx < b.idx ? -1 : 1));
			})
			.catch(this.showNetworkError);
	}

	edit(number: NationalNumber): void {
		this.editNumber = number;
		this.newDescription = number.description;
		this.newNumber = number.displayNumber;
		this.$bvModal.show('description-edit');
	}

	editHidden(): void {
		this.editNumber = null;
		this.newDescription = '';
		this.newNumber = '';
	}

	saveNumberAndDescription(): void {
		// save
		if (this.editNumber) {
			BookingService.updateNationalNumber(this.editNumber, {
				displayNumber: this.newNumber,
				description: this.newDescription,
				active: this.editNumber.active
			})
				.then(() => {
					this.getNumbers();
					this.$bvModal.hide('description-edit');
				})
				.catch((error) => console.log(error));
		}
	}

	toggle(number: NationalNumber): void {
		BookingService.updateNationalNumber(number, {
			active: !number.active,
			description: number.description,
			displayNumber: number.displayNumber
		}).then(() => {
			this.getNumbers();
		});
	}

	move(number: NationalNumber, direction: 'UP' | 'DOWN'): void {
		if (number) {
			BookingService.moveNationalNumber(number, direction)
				.then(() => {
					this.getNumbers();
				})
				.catch(this.showNetworkError);
		}
	}
}
