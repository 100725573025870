
















































































































import {RegistrationStatistic} from '@/models/Registration';
import {SurveyStatistic} from '@/models/Survey';
import {Component, Prop} from 'vue-property-decorator';
import Base from '../Base';

@Component
export default class Forms extends Base {
	@Prop() stats!: RegistrationStatistic;
	@Prop() surveyStats!: SurveyStatistic;
}
