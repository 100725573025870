








































import {Component, Prop} from 'vue-property-decorator';
import Base from './Base';

/**
 * Some documented component
 *
 * @component
 */
@Component({
	components: {}
})
export default class LoginModeratorMask extends Base {
	@Prop() validation!: any;
}
