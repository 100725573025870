




































































import {Component, Watch} from 'vue-property-decorator';
import Base from '@/views/Base';
import ListWithHeaderLayout from '@/layouts/nested/ListWithHeaderLayout.vue';
import {ScheduleType} from '@/models/api';
import BookingService from '@/service/BookingService';
import {EventBus, Events} from '@/EventBus';
import {BookingListItem} from '@/models/Booking';
import ConferenceService from '@/service/ConferenceService';
import {formatDateCreated} from '@/utils/filters';
import PlaybackService from '@/service/PlaybackService';
import {Playback} from '@/models/Playback';
import SelectRegDateModal from '@/components/modals/SelectRegDateModal.vue';
import SelectSurveyModal from '@/components/modals/SelectSurveyModal.vue';
import {SelectOption} from '@/models/common';
import {months} from '@/utils/date';

@Component({
	components: {
		SelectSurveyModal,
		SelectRegDateModal,
		ListWithHeaderLayout
	}
})
export default class ConferenceScheduled extends Base {
	items: BookingListItem[] = [];
	filteredItems: BookingListItem[] = [];

	fields = [
		{
			key: 'topic',
			label: this.t('common.topic'),
			sortable: true,
			sortDirection: 'desc',
			class: 'pl-34 truncate'
		},
		{
			key: 'date',
			label: this.t('common.start'),
			sortable: true,
			sortDirection: 'desc',
			thStyle: { width: '145px' }
		},
		{
			key: 'duration',
			label: this.t('common.duration'),
			thStyle: { width: '100px' },
			title: 'Dauer (MM:SS)'
		},
		{
			key: 'playback',
			label: this.t('common.type'),
			thStyle: { width: '80px' }
		},
		{
			key: 'participants',
			label: this.t('conference.participantShort'),
			sortable: true,
			thStyle: { width: '70px' },
			title: this.t('conference.participant')
		},
		{
			key: 'costCenter',
			label: this.t('conference.costCenterShort'),
			sortable: true,
			thStyle: { width: '130px' },
			title: this.t('conference.costCenter')
		},
		{
			key: 'pin',
			label: this.t('conference.pinShort'),
			thStyle: { width: '130px' },
			title: this.t('conference.pinLong')
		},
		{
			key: 'forms',
			label: this.t('nav.forms'),
			thStyle: { width: '80px' },
			title: this.t('nav.forms')
		},
		{
			key: 'functions',
			label: this.t('conference.functions'),
			thStyle: { width: '90px' }
		},
		{
			key: 'actions',
			label: this.t('common.settings'),
			thStyle: { width: '40px' },
			thClass: 'show-content-md'
		}
	];

	toDeleteBooking: BookingListItem | null = null;
	toEditForm: BookingListItem | null = null;

	playbacks: Playback[] = [];

	selectedDate = ``;
	minDate = '';
	maxDate = '';

	get dateOptions(): SelectOption[] {
		const now = new Date();
		const month = now.getMonth();
		const year = now.getFullYear();
		const options = [];
		const monthsList = months(this);

		for (let i = 0; i <= month + 12; i++) {
			const newMonth = (i + 12) % 12;
			const newYear = i >= 12 ? year + 1 : year;
			options.push({
				text: `${monthsList[newMonth]} ${newYear}`,
				value: newYear + '-' + ('0' + (newMonth + 1)).slice(-2)
			});
		}
		return [{ text: this.t('common.all') as string, value: '' }, ...options];
	}

	@Watch('selectedDate', { immediate: false })
	setFilteredItems(): void {
		if (this.selectedDate === '') {
			this.filteredItems = [...this.items];
		} else {
			this.filteredItems = this.items.filter((item) => {
				const [year, month] = this.selectedDate.split('-');
				const itemDate = item.date;
				return (
					itemDate.getFullYear() === +year && itemDate.getMonth() + 1 === +month
				);
			});
		}
	}

	mounted(): void {
		this.init();

		// Eventbus
		EventBus.$on(Events.DeleteConference, (item: BookingListItem) => {
			this.toDeleteBooking = item;

			if (item.schedule) {
				if (BookingService.isSeries(item.schedule.type)) {
					this.$bvModal.show('series-delete-modal');
				} else {
					this.$bvModal.show('scheduled-conf-delete-modal');
				}
			}
		});

    EventBus.$on(Events.EditConference, (item: BookingListItem) => this.$router.push(`/conference/booking?id=${item.id}&step=0`)
    );

		EventBus.$on(Events.EditConferenceForm, (item: BookingListItem) => {
			this.toEditForm = item;
			// wait for modal to get initialized
			setTimeout(() => {
				this.$bvModal.show('select-reg-date-modal');
			});
		});

		EventBus.$on(Events.EditConferenceSurvey, (item: BookingListItem) => {
			this.toEditForm = item;
			setTimeout(() => {
				this.$bvModal.show('select-survey-modal');
			});
		});

		EventBus.$on(Events.ConferenceDetail, (booking: BookingListItem) => {
			switch (booking.type) {
				case 'room':
				case 'scheduled':
					this.$router.push('/conference/scheduled/' + booking.id);
					break;
				case 'past':
					this.$router.push('/conference/past/' + booking.id);
					break;
				case 'running':
					this.$router.push('/conference/running/' + booking.id);
					break;
			}
		});
		EventBus.$on(Events.StartConference, (bookingId: string) => {
			ConferenceService.startConference(bookingId).then(() => {
				this.toast(this.t('conference.conferenceStarted') as string, 'success');
				this.init();
			});
		});
		EventBus.$on(Events.RepeatConference, (item: BookingListItem) => {
			if (item.type === 'room') {
				this.$router.push(`/conference/booking-room?copyId=${item?.id}&step=0`);
			} else {
				this.$router.push(`/conference/booking?copyId=${item?.id}&step=0`);
			}
		});
	}

	beforeDestroy(): void {
		EventBus.$off(Events.DeleteConference);
		EventBus.$off(Events.EditConference);
		EventBus.$off(Events.EditConferenceSurvey);
		EventBus.$off(Events.ConferenceDetail);
		EventBus.$off(Events.StartConference);
		EventBus.$off(Events.RepeatConference);
	}

	private init(): void {
		this.getBookings();
		this.getPlaybacks();
	}

	getPlaybacks(): void {
		PlaybackService.getPlaybacks().then((p) => (this.playbacks = p));
	}

	async onFormSubmit(): Promise<any> {
		await this.getBookings();
	}

	private getBookings(): Promise<any> {
		return BookingService.getBookings()
			.then((bookings) => {
				this.toEditForm = null;
				console.log('bookings', bookings);
				this.items = bookings
					.filter(
						(bookings) => bookings.schedule?.type !== ScheduleType.ANYTIME
					)
					.map((booking) =>
						BookingService.bookingToConferenceItem(booking, this.playbacks)
					);
				this.setFilteredItems();
			})
			.catch(this.showNetworkError);
	}

	deleteBooking(ok?: () => void): void {
		const item = this.toDeleteBooking;
		this.toDeleteBooking = null;
		if (ok) ok();
		if (item && item.id) {
			BookingService.deleteBooking(item.id)
				.then(() =>
					this.toast(
						this.t('conference.conferenceDeleted') as string,
						'success'
					)
				)
				.catch(this.showNetworkError)
				.then(() => this.init());
		}
	}

	deleteSeries(ok: () => void): void {
		const item = this.toDeleteBooking;
		this.toDeleteBooking = null;
		ok();
		if (item && item.id) {
			BookingService.deleteSeries(item.id)
				.then(() =>
					this.toast(this.t('conference.seriesDeleted') as string, 'success')
				)
				.catch(this.showNetworkError)
				.then(() => this.init());
		}
	}

	formatRegDateStart = formatDateCreated;
}
